import React, { Component } from 'react'
import { SUBMIT_IDEA_URL, GET_METADATA, GENPACT_INTERNAL, ANALYTICS,OTHER_REGIONS, USER_DATA, ACCESS_TOKEN, SUBMIT_GENAI_URL } from '../assets/constants/constants';
import Toast from 'light-toast';
import Select from 'react-select';
import { selectValidation as selectStyle, textBoxValidation } from "../utils/FieldValidation";
import Checksum from "../utils/Checksum";
// comment to be deleted  - Bug Devops ID 419220 released into prod on 27-12-2024 - 2

export class CreateOpp extends Component {
  sessionData = JSON.parse(localStorage.getItem(USER_DATA));
  accessToken = localStorage.getItem(ACCESS_TOKEN);

  constructor(props) {
    super(props);
    if (this.sessionData === null) {
      Toast.loading("This component cannot be accessed directly. Redirecting to application home.", 3000, () => {
      });
      window.location = "/login";
      return;
    }
    this.state = {
      selectStyle: {},
      OppName: "",

      CreatedBy: this.sessionData.profile.oHRId,
      CreatedName: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,

      Account: "",
      accountList: [],
      AccountSelectedOption: [],

      regionList: [],
      updatedRegionList: [],

      AccountId: "",
      Unit: "",
      MangingDept: "Vertical Managed",
      MangingDeptSelectedOption: { value: 0, label: "Vertical Managed" },
      Vertical: "",
      Segment: "",
      Archetype: "",
      GOLOHR: "",
      GOL: "",
      OppStage: "",
      OppState: "",


      UnitSPOCSelectedOption: [],
      UnitOHR: "",
      UnitOHRName: "",


      spocList: [],
      SpocOHR_1: "",
      Spoc_1: "",
      SPOC_1SelectedOption: [],

      SpocOHR_2: "",
      Spoc_2: "",
      SPOC_2SelectedOption: [],

      BBOHR: "",
      BB: "",
      BBSelectedOption: [],
      rolesList: [],

      MBBOHR: "",
      MBB: "",
      MBBSelectedOption: [],
      mbbList: [],

      SolnOwnerOHR: "",
      SolnOwner: "",
      SolnOwnerSelectedOption: [],
      AccFTE: "",


      verticalList: [],
      regionText: "",
      uniqueUnits: [],
      userRole: "",
      GenAIOfferingList: [],
      Offering_1: "",
      Offering_1SelectedOption: [],


      Offering_2: "",
      Offering_2SelectedOption: [],
      Offering_2_Other: "",


    }
  }

  componentDidMount() {
    Toast.loading("Fetching data. Please wait...", () => { });
    const param = {
      EntityType: "",
      EntityName: ""
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      },
      body: JSON.stringify(param)
    };
    fetch(GET_METADATA, requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log("fetch metadata =>", data)
        Toast.hide();
        this.setState({
          accountList: data ? data.Accounts.sort((a, b) => { return a.ACCOUNT > b.ACCOUNT ? 1 : -1; }) : [],
          uniqueUnits: data ? data.UnitSegment ? data.UnitSegment.ResultSets ? data.UnitSegment.ResultSets.Table1 : [] : [] : [],
          regionList: data ? data.Region ? data.Region : [] : [],
          GenAIOfferingList: data ? data.GenAIOffering : [],
        }, () => {
          const updatedRegions = this.state.regionList && this.state.regionList.map(region => {
            if (region.Region === "Other Regions") {
              return { ...region, Region: "Tech Services" };
            }
            return region;
          });
          updatedRegions.push({ Region: "Vertical Managed" })
          updatedRegions.push({ Region: ANALYTICS })
          this.setState({ updatedRegionList: updatedRegions }, () => {
            // console.log("updated region list =>", this.state.updatedRegionList)
          })
          this.fetchUserRole()
        })

      })
      .catch(error => {
        Toast.hide();
        Toast.fail('Error occured while getting Idea Metadata. Please try again!', 3000, () => {
          console.log(JSON.stringify(error));
        });
      });
  }


  fetchUserRole = () => {
    return new Promise((resolve, reject) => {
      Toast.loading("Fetching user role. Please wait...", () => { });
      const param = {
        EntityType: "OHR",
        EntityName: this.sessionData ? this.sessionData.profile ? this.sessionData.profile.oHRId : "" : "",
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.accessToken,
        },
        body: JSON.stringify(param),
      };
      fetch(GET_METADATA, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          data.PowerAppUsers.filter((item) => {
            const userRole = JSON.parse(item.pg_Role).pg_role.trim();
            if (parseInt(this.sessionData.profile.oHRId) === parseInt(item.pg_ohr)) {
              Toast.hide();
              this.setState({ userRole: userRole }, () => {
                console.log("user role is =>", this.state.userRole)
              })
            }
          })
          resolve();
        })
        .catch((error) => {
          Toast.hide();
          console.log(JSON.stringify(error));
        });
    });
  }


  // file upload functionality starts here
  uploadFile = () => {

  }
  // file upload functionality ends here


  // fields functionality starts here

  handleOppNameChange = (e) => {
    let styleStateObj = this.state.selectStyle;
    let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
    if (nonWhiteSpace.length < 50) {
      styleStateObj.OppName = textBoxValidation(true);
      this.setState({
        OppName: e,
        selectStyle: styleStateObj,
      });
    } else {
      const trimmedValue = nonWhiteSpace.slice(0, 50);
      this.setState({
        OppName: trimmedValue,
        selectStyle: styleStateObj,
      });
    }
  }
  handleOppParentIdChange = (e) => {
    // ParentID
    // ParentIDAlpha

  }





  // fields functionality ends here


  // validate function starts here
  validate = () => {
    let isValid = true;
    this.isExceedlimit = false;
    let styleStateObj = this.state.selectStyle;

    console.log("managing department =>", this.state.MangingDept)

    if (!this.state.OppName || this.state.OppName === "" || this.state.OppName === null || this.state.OppName === undefined) {
      isValid = false;
      styleStateObj.OppName = textBoxValidation(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      styleStateObj.OppName = textBoxValidation(true);
      this.setState({
        selectStyle: styleStateObj,
      });
    }


    if (!this.state.AccountSelectedOption || this.state.AccountSelectedOption === "" || this.state.AccountSelectedOption === null || this.state.AccountSelectedOption === undefined || this.state.AccountSelectedOption.length === 0) {
      isValid = false;
      styleStateObj.AccountSelectedOption = selectStyle(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      styleStateObj.AccountSelectedOption = selectStyle(true);
      this.setState({
        selectStyle: styleStateObj,
      });
    }

    if (!this.state.UnitSPOCSelectedOption || this.state.UnitSPOCSelectedOption === "" || this.state.UnitSPOCSelectedOption === null || this.state.UnitSPOCSelectedOption === undefined || this.state.UnitSPOCSelectedOption.length === 0) {
      isValid = false;
      styleStateObj.UnitSPOCSelectedOption = selectStyle(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      styleStateObj.UnitSPOCSelectedOption = selectStyle(true);
      this.setState({
        selectStyle: styleStateObj,
      });
    }


    // if (!this.state.GOLOHR || this.state.GOLOHR === "" || this.state.GOLOHR === null || this.state.GOLOHR === undefined || this.state.GOLOHR.length === 0) {
    //   isValid = false;
    //   styleStateObj.GOLOHR = textBoxValidation(false);
    //   this.setState({
    //     selectStyle: styleStateObj,
    //   });
    // } else {
    //   styleStateObj.GOLOHR = textBoxValidation(true);
    //   this.setState({
    //     selectStyle: styleStateObj,
    //   });
    // }

    if (this.state.GOLOHR) {
      if (!this.state.GOL || this.state.GOL === "" || this.state.GOL === null || this.state.GOL === undefined || this.state.GOL.length === 0) {
        isValid = false;
        styleStateObj.GOL = textBoxValidation(false);
        this.setState({
          selectStyle: styleStateObj,
        });
      } else {
        styleStateObj.GOL = textBoxValidation(true);
        this.setState({
          selectStyle: styleStateObj,
        });
      }
    }




    // if (!this.state.BBSelectedOption || this.state.BBSelectedOption === "" || this.state.BBSelectedOption === null || this.state.BBSelectedOption === undefined || this.state.BBSelectedOption.length === 0) {
    //   isValid = false;
    //   styleStateObj.BBSelectedOption = selectStyle(false);
    //   this.setState({
    //     selectStyle: styleStateObj,
    //   });
    // } else {
    //   styleStateObj.BBSelectedOption = selectStyle(true);
    //   this.setState({
    //     selectStyle: styleStateObj,
    //   });
    // }

    if (!this.state.SPOC_1SelectedOption || this.state.SPOC_1SelectedOption === "" || this.state.SPOC_1SelectedOption === null || this.state.SPOC_1SelectedOption === undefined || this.state.SPOC_1SelectedOption.length === 0) {
      isValid = false;
      styleStateObj.SPOC_1SelectedOption = selectStyle(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      styleStateObj.SPOC_1SelectedOption = selectStyle(true);
      this.setState({
        selectStyle: styleStateObj,
      });
    }

    // if (!this.state.SPOC_2SelectedOption || this.state.SPOC_2SelectedOption === "" || this.state.SPOC_2SelectedOption === null || this.state.SPOC_2SelectedOption === undefined || this.state.SPOC_2SelectedOption.length === 0) {
    //   isValid = false;
    //   styleStateObj.SPOC_2SelectedOption = selectStyle(false);
    //   this.setState({
    //     selectStyle: styleStateObj,
    //   });
    // } else {
    //   styleStateObj.SPOC_2SelectedOption = selectStyle(true);
    //   this.setState({
    //     selectStyle: styleStateObj,
    //   });
    // }


    // if (!this.state.MBBSelectedOption || this.state.MBBSelectedOption === "" || this.state.MBBSelectedOption === null || this.state.MBBSelectedOption === undefined || this.state.MBBSelectedOption.length === 0) {
    //   isValid = false;
    //   styleStateObj.MBBSelectedOption = selectStyle(false);
    //   this.setState({
    //     selectStyle: styleStateObj,
    //   });
    // } else {
    //   styleStateObj.MBBSelectedOption = selectStyle(true);
    //   this.setState({
    //     selectStyle: styleStateObj,
    //   });
    // }


    // if (!this.state.SolnOwnerSelectedOption || this.state.SolnOwnerSelectedOption === "" || this.state.SolnOwnerSelectedOption === null || this.state.SolnOwnerSelectedOption === undefined || this.state.SolnOwnerSelectedOption.length === 0) {
    //   isValid = false;
    //   styleStateObj.SolnOwnerSelectedOption = selectStyle(false);
    //   this.setState({
    //     selectStyle: styleStateObj,
    //   });
    // } else {
    //   styleStateObj.SolnOwnerSelectedOption = selectStyle(true);
    //   this.setState({
    //     selectStyle: styleStateObj,
    //   });
    // }
    console.log(this.state.userRole)

    // if(this.state.userRole){
    //   if(this.state.userRole.trim() === "MBB" || this.state.userRole.trim() === "Opp SPOC" || this.state.userRole.trim() ==="Unit SPOC"){

    //   }else{
    //     isValid = false;
    //     Toast.fail("Only Master BlackBelt, Opportunity Unit SPOC and Opportunity GenAI Unit SPOC are allowed to create the opportunity", 8000,()=>{})
    //   }
    // }



    if (!this.state.Offering_1SelectedOption || this.state.Offering_1SelectedOption === "" || this.state.Offering_1SelectedOption === null || this.state.Offering_1SelectedOption === undefined || this.state.Offering_1SelectedOption.length === 0) {
      isValid = false;
      styleStateObj.Offering_1SelectedOption = selectStyle(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      styleStateObj.Offering_1SelectedOption = selectStyle(true);
      this.setState({
        selectStyle: styleStateObj,
      });
    }

    if (this.state.Offering_1SelectedOption && this.state.Offering_1SelectedOption.label === "Bespoke") {
      if (!this.state.Offering_2_Other || this.state.Offering_2_Other === "" || this.state.Offering_2_Other === null || this.state.Offering_2_Other === undefined || this.state.Offering_2_Other.length === 0) {
        isValid = false;
        styleStateObj.Offering_2_Other = textBoxValidation(false);
        this.setState({
          selectStyle: styleStateObj,
        });
      } else {
        styleStateObj.Offering_2_Other = textBoxValidation(true);
        this.setState({
          selectStyle: styleStateObj,
        });
      }
    } else {
      if (!this.state.Offering_2SelectedOption || this.state.Offering_2SelectedOption === "" || this.state.Offering_2SelectedOption === null || this.state.Offering_2SelectedOption === undefined || this.state.Offering_2SelectedOption.length === 0) {
        isValid = false;
        styleStateObj.Offering_2SelectedOption = selectStyle(false);
        this.setState({
          selectStyle: styleStateObj,
        });
      } else {
        styleStateObj.Offering_2SelectedOption = selectStyle(true);
        this.setState({
          selectStyle: styleStateObj,
        });
      }
    }

    return isValid;
  }
  // validate function ends here


  // submit opp function starts here
  submitOpp = () => {
    if (this.validate()) {
      if (window.confirm("Are you sure you want to submit the opportunity?")) {
        Toast.loading("Submitting opportunity...", () => { });
        let param = {
          isItIdeaOrGenAI: "GenAI",
          OppName: this.state.OppName,
          CreatedBy: this.state.CreatedBy,
          CreatedName: this.state.CreatedName,
          SaveType: "New",
          UpdaterOHR: this.state.CreatedBy,
          Updater: this.state.CreatedName,
          UpdaterRole: this.state.userRole ? this.state.userRole : "",
          Comment: "",
          Action: "Opportunity Created",

          Account: this.state.Account,
          Vertical: this.state.Vertical,
          Unit: this.state.Unit,
          Segment: this.state.Segment,
          MangingDept: this.state.MangingDept ? this.state.MangingDept : "",
          Archetype: this.state.Archetype,
          AccFTE: this.state.AccFTE,

          GOLOHR: this.state.GOLOHR,
          GOL: this.state.GOL,

          UnitOHR: this.state.UnitOHR,
          UnitOHRName: this.state.UnitOHRName,


          SpocOHR_1: this.state.SpocOHR_1,
          Spoc_1: this.state.Spoc_1,

          SpocOHR_2: this.state.SpocOHR_2,
          Spoc_2: this.state.Spoc_2,

          BBOHR: this.state.BBOHR,
          BB: this.state.BB,

          MBBOHR: this.state.MBBOHR,
          MBB: this.state.MBB,

          SolnOwnerOHR: this.state.SolnOwnerOHR,
          SolnOwner: this.state.SolnOwner,

          IsHero: this.state.IsHero,
          Offering_1: this.state.Offering_1,
          Offering_2: this.state.Offering_2 ? this.state.Offering_2 : "",
          Offering_2_Other: this.state.Offering_1 && this.state.Offering_1 === "Bespoke" ? (this.state.Offering_2_Other ? this.state.Offering_2_Other : "") : "",

          OppStage: "1. Client discussion - Not Started",
          OppState: "Inactive",

        }
        console.log(param)

        let finalJsonData = Checksum(param);
        let str1 = finalJsonData.slice(0, 15);
        let str2 = finalJsonData.slice(15, 30);
        let str3 = finalJsonData.slice(30, 40);
        let str4 = finalJsonData.slice(-10);
        let finalData = str3 + str1 + str4 + str2;
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken
          },
          body: JSON.stringify(param)
        };
        fetch(`${SUBMIT_GENAI_URL}Checksum=${finalData}`, requestOptions)
          .then(response => response.json())
          .then(data => {
            console.log("data submission=>", data)
            Toast.hide();
            if (data.message === "Opportunity succesfully Created") {
              Toast.success('New Opportunity created successfully!', 3000, () => {
                window.location = "/";
              })

            } else {
              Toast.hide();
              if (data.error === "Opp name already exists. Please change Opportunity Name to make it unique.") {
                Toast.fail('New Opportunity creation failed, Opportunity name already exists. Ensure that opportunity name has to be unique.', 8000, () => { });
              }

            }
          })
          .catch(error => {
            Toast.hide();
            Toast.fail('New Opportunity creation failed: ' + error, 5000, () => {
              console.log(JSON.stringify(error));
            });

          });
      }
    } else {
      if (!this.isExceedlimit) {
        Toast.fail('Required fields are missing or have invalid values. Please correct and try again.', 3000, () => { });
      }
    }

  }
  // submit opp function ends here



  cancelOpp = () => {
    if (window.confirm("You will lose all entered data on this page if you click on cancel.")) {
      this.props.history.push("/home");
    }
  }

  renderAccountOptions = () => {
    let options = [];
    let accountName = "";
    this.state.accountList && this.state.accountList.map((item) => {
      accountName = item.ACCOUNT.toString().toLowerCase();
      if (!(accountName.startsWith("unidentified") || accountName.startsWith("unmapped") || accountName.startsWith("genpact"))) {
        options.push({
          value: item.ACCOUNT_L2,
          label: item.ACCOUNT
        })
      }
      return true;
    });
    return options;
  }
  handleAccountNameChange = (e) => {
    this.setState({
      verticalList: [],
      Vertical: "",
      Unit: "",
      Segment: "",
      regionSelectedOption: null,
      BBSelectedOption: [],
      BB: "",
      BBOHR: "",
      MBBSelectedOption: [],
      MBB: "",
      MBBOHR: "",
      Account: e.label,
      AccountSelectedOption: e,

      Spoc_1: "",
      SPOC_1SelectedOption: [],
      SpocOHR_1: "",

      SPOC_2SelectedOption: [],
      Spoc_2: "",
      SpocOHR_2: "",

      SolnOwnerSelectedOption: [],
      SolnOwner: '',
      SolnOwnerOHR: '',


      GOLOHR: "",
      GOL: "",

      Archetype: "",
      AccFTE: "",

      UnitSPOCSelectedOption: [],
      UnitOHR: "",
      UnitOHRName: "",


    }, () => {
      let styleStateObj = this.state.selectStyle;
      styleStateObj.AccountSelectedOption = selectStyle(true);

      this.setState({
        selectStyle: styleStateObj
      });
      this.fetchVerticalOptions();

    }, () => {

    });
  }

  fetchVerticalOptions = () => {
    Toast.loading("Fetching Verticals. Please wait...", () => { });
    const param = {
      EntityType: "Vertical",
      EntityName: this.state.Account
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      },
      body: JSON.stringify(param)
    };
    fetch(GET_METADATA, requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log("fetch verticals => response", data)
        Toast.hide();
        let styleStateObj = this.state.selectStyle;
        this.setState({
          verticalList: data.Table1 !== undefined ? data.Table1 : [],
          unitsAndSegmentsList: data.Table2 !== undefined ? data.Table2 : [],
          AccFTE: data.Table3 !== undefined ? data.Table3[0].FTE : "",
          Archetype: data.Table4 !== undefined ? data.Table4[0].Archetype : "",
          // GOLOHR:"850054658",
          // GOL:"Akarsh",

        }, () => { });
        const filteredData = this.state.verticalList && this.state.verticalList.filter(item => item.VERTICAL !== "TS" && item.VERTICAL !== "Tech Services");
        const firstValue = filteredData[0];
        if (firstValue) {
          this.setState({ Vertical: firstValue.VERTICAL }, () => {
            styleStateObj.Vertical = selectStyle(true);
            this.setState({ selectStyle: styleStateObj })

            this.setState({ regionText: OTHER_REGIONS }, () => {
              this.fetchRolesOptions()
            })

          })
        }

        const filteredData1 = this.state.unitsAndSegmentsList && this.state.unitsAndSegmentsList.filter(item => item.VERTICAL !== "TS" && item.VERTICAL !== "Tech Services");
        const filteredValue1 = filteredData1[0];
        if (filteredValue1) {
          this.setState({ Unit: filteredValue1.UNIT, Segment: filteredValue1.SEGMENT }, () => {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.Unit = selectStyle(true);
            styleStateObj.Segment = selectStyle(true);
            this.setState({ selectStyle: styleStateObj, })
          })
        }
      })
      .catch(error => {
        Toast.hide();
        Toast.fail('Error occured while getting Vertical details. Please try again!', 10000, () => {
          console.log(JSON.stringify(error));
        });
      });
  }

  fetchRolesOptions = () => {
    Toast.loading("Fetching roles. Please wait...", () => { });

    let entityType = "BB";
    let entityName = this.state.Vertical.replace(" - ", "-");
    let entityRegion = '';

    if (this.state.MangingDeptSelectedOption) {
      if (this.state.MangingDeptSelectedOption.label === "Americas" || this.state.MangingDeptSelectedOption.label === "China/RoA" ||
        this.state.MangingDeptSelectedOption.label === "Philippines" || this.state.MangingDeptSelectedOption.label === "Europe") {
        entityType = "BB"
        entityName = this.state.MangingDeptSelectedOption.label;
      }
      if (this.state.MangingDeptSelectedOption.label === "Tech Services") {
        entityType = "BB";
        entityName = "Tech Services";
        entityRegion = OTHER_REGIONS
      }
      if (this.state.MangingDeptSelectedOption.label === "Vertical Managed") {
        entityType = "BB";
        entityName = this.state.Vertical.replace(" - ", "-");
        entityRegion = OTHER_REGIONS
      }
      if (this.state.MangingDeptSelectedOption.label === ANALYTICS) {
        entityType = "BB";
        entityName = GENPACT_INTERNAL;
        entityRegion = OTHER_REGIONS
      }
    }

    let param={};
    if (this.state.MangingDeptSelectedOption) {
      if (this.state.MangingDeptSelectedOption.label === "Americas" || this.state.MangingDeptSelectedOption.label === "China/RoA" ||
        this.state.MangingDeptSelectedOption.label === "Philippines" || this.state.MangingDeptSelectedOption.label === "Europe") {
          param = {
            EntityType: entityType,
            EntityName: entityName,
          }
      }
      if (this.state.MangingDeptSelectedOption.label === "Tech Services" || this.state.MangingDeptSelectedOption.label === "Vertical Managed" || this.state.MangingDeptSelectedOption.label === ANALYTICS) {
        param = {
          EntityType: entityType,
          EntityName: entityName,
          EntityRegion: entityRegion
        }
      }
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      },
      body: JSON.stringify(param)
    };
    fetch(GET_METADATA, requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log("fetched data =>", data)
        Toast.hide();
        if (this.state.MangingDeptSelectedOption) {

          if (this.state.MangingDeptSelectedOption.label === "Americas" || this.state.MangingDeptSelectedOption.label === "China/RoA" ||
            this.state.MangingDeptSelectedOption.label === "Philippines" || this.state.MangingDeptSelectedOption.label === "Europe") {
            this.setState({
              rolesList: data.DataverseUserRoles !== undefined ? data.DataverseUserRoles : [],
            }, () => {
              this.renderMBBOptions();
              this.renderSpocList();
              this.renderSolOwnerList();
            });
          }
          if (this.state.MangingDeptSelectedOption.label === "Tech Services" || this.state.MangingDeptSelectedOption.label === "Vertical Managed" || this.state.MangingDeptSelectedOption.label === ANALYTICS) {
            this.setState({
              rolesList: data.DataverseUserRolesForGenAI !== undefined ? data.DataverseUserRolesForGenAI : [],
            }, () => {
              this.renderMBBOptions();
              this.renderSpocList();
              this.renderSolOwnerList();
            });
          }
        }
      })
      .catch(error => {
        Toast.hide();
        Toast.fail('Error occured while getting BlackBelt details. Please try again!', 3000, () => {
          console.log(JSON.stringify(error));
        });
      });
  }

  renderBlackBeltOptions = () => {
    let options = [];
    let result = [];
    this.state.rolesList && this.state.rolesList.map((item) => {
      if (this.state.MangingDeptSelectedOption.label === "Tech Services" || this.state.MangingDeptSelectedOption.label === "Vertical Managed" || this.state.MangingDeptSelectedOption.label === ANALYTICS) {
        const userRole = item.pg_Role.pg_role;
        if (userRole === "BB") {
          options.push({ value: item.pg_ohr, label: item.pg_username });
        }
      } else {
        let userRole;
        if (typeof item.pg_Role === 'string') {
          try {
            userRole = JSON.parse(item.pg_Role).pg_role;
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
        } else if (typeof item.pg_Role === 'object') {
          userRole = item.pg_Role.pg_role;
        }
        if (userRole === "BB") {
          options.push({ value: item.pg_ohr, label: item.pg_username });
        }
      }

      return true;
    });
    result = options.reduce((unique, index) => {
      if (!unique.some(obj => obj.label === index.label && obj.value === index.value)) {
        unique.push(index);
      }
      return unique;
    }, []);

    return result
  }

  handleBlackBeltNameChange = (e) => {
    console.log(e)
    if (parseInt(e.pg_ohr) === parseInt(this.state.CreatedBy)) {
      Toast.fail('Mentor cannot be the same as Opportunity Owner/Requester. Please select a different Mentor.', 3000, () => {
        return;
      });
      return;
    }
    let styleStateObj = this.state.selectStyle;
    styleStateObj.BBSelectedOption = selectStyle(true);
    this.setState({
      selectStyle: styleStateObj,

      BBOHR: e.value,
      BB: e.label,

      BBSelectedOption: {
        value: e.value,
        label: e.label
      }
    });
  }


  renderMBBOptions = () => {
    let options = [];
    let result = [];
    this.state.rolesList && this.state.rolesList.map((item) => {
      if (this.state.MangingDeptSelectedOption.label === "Tech Services" || this.state.MangingDeptSelectedOption.label === "Vertical Managed" || this.state.MangingDeptSelectedOption.label === ANALYTICS) {
        const userRole = item.pg_Role.pg_role;
        if (userRole === "MBB") {
          options.push({ value: item.pg_ohr, label: item.pg_username });
        }
      } else {
        let userRole;
        if (typeof item.pg_Role === 'string') {
          try {
            userRole = JSON.parse(item.pg_Role).pg_role;
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
        } else if (typeof item.pg_Role === 'object') {
          userRole = item.pg_Role.pg_role;
        }

        if (userRole === "MBB") {
          options.push({ value: item.pg_ohr, label: item.pg_username });
        }
      }
      return true;
    });
    result = options.reduce((unique, index) => {
      if (!unique.some(obj => obj.label === index.label && obj.value === index.value)) {
        unique.push(index);
      }
      return unique;
    }, []);

    return result
  }

  handleMBBChange = (e) => {
    if (parseInt(e.pg_ohr) === parseInt(this.state.CreatedBy)) {
      Toast.fail('MBB cannot be the same as Opportunity Owner/Requester. Please select a different MBB.', 3000, () => {
        return;
      });
      return;
    }
    let styleStateObj = this.state.selectStyle;
    styleStateObj.MBBSelectedOption = selectStyle(true);
    this.setState({
      selectStyle: styleStateObj,
      MBB: e.label,
      MBBOHR: e.value,
      MBBSelectedOption: {
        value: e.value,
        label: e.label
      }
    });
  }


  handleParentOppIDChange = (e) => {
    this.setState({ ParentIDAlpha: e }, () => { })
  }


  renderRegion = () => {
    let options = [];
    this.state.updatedRegionList && this.state.updatedRegionList.map((item, index) => {
      options.push({ value: index, label: item.Region })
    })
    return options;
  }


  handleManagingUnitRegionChange = (e) => {

    this.setState({
      BBSelectedOption: [],
      BB: "",
      BBOHR: "",

      MBBSelectedOption: [],
      MBB: "",
      MBBOHR: "",

      Spoc_1: "",
      SPOC_1SelectedOption: [],
      SpocOHR_1: "",

      SPOC_2SelectedOption: [],
      Spoc_2: "",
      SpocOHR_2: "",

      SolnOwnerSelectedOption: [],
      SolnOwner: '',
      SolnOwnerOHR: '',

      UnitSPOCSelectedOption: [],
      UnitOHR: "",
      UnitOHRName: "",

    })
    if (e) {
      console.log("managing entity at change function =>", e)
      if (e.label === "Americas" || e.label === "China/RoA" || e.label === "Philippines" || e.label === "Europe") {
        this.setState({ regionText: e.label }, () => {
          this.fetchRolesOptions()
        })
      }
      if (e.label === "Vertical Managed" || e.label === "Tech Services" || e.label === ANALYTICS) {
        this.setState({ regionText: OTHER_REGIONS }, () => {
          this.fetchRolesOptions()
        })
      }
    }
    if (e.label === "NA") {
      this.setState({
        BBSelectedOption: [],
        BB: "",
        BBOHR: "",

        MBBSelectedOption: [],
        MBB: "",
        MBBOHR: "",

        Spoc_1: "",
        SPOC_1SelectedOption: [],
        SpocOHR_1: "",

        SPOC_2SelectedOption: [],
        Spoc_2: "",
        SpocOHR_2: "",

        SolnOwnerSelectedOption: [],
        SolnOwner: '',
        SolnOwnerOHR: ''

      })
    }
    let styleStateObj = this.state.selectStyle;
    styleStateObj.MangingDeptSelectedOption = selectStyle(true);
    this.setState({ MangingDeptSelectedOption: e, MangingDept: e.label, selectStyle: styleStateObj }, () => {
      console.log("this.state.managing department =>", this.state.MangingDept)
    })
  }


  renderSpocList = () => {
    let options = [];
    let result = [];
    this.state.rolesList && this.state.rolesList.map((item) => {
      if (this.state.MangingDeptSelectedOption.label === "Tech Services" || this.state.MangingDeptSelectedOption.label === "Vertical Managed" || this.state.MangingDeptSelectedOption.label === ANALYTICS) {
        const userRole = item.pg_Role.pg_role;
        if (userRole === "Opp SPOC") {
          options.push({ value: item.pg_ohr, label: item.pg_username });
        }
      } else {
        let userRole;
        if (typeof item.pg_Role === 'string') {
          try {
            userRole = JSON.parse(item.pg_Role).pg_role;
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
        } else if (typeof item.pg_Role === 'object') {
          userRole = item.pg_Role.pg_role;
        }

        if (userRole === "Opp SPOC") {
          options.push({ value: item.pg_ohr, label: item.pg_username });
        }
      }

      return true;
    });
    result = options.reduce((unique, index) => {
      if (!unique.some(obj => obj.label === index.label && obj.value === index.value)) {
        unique.push(index);
      }
      return unique;
    }, []);

    // console.log("RESULT:", result);
    return result
  }


  renderUnitSpocList = () => {
    let options = [];
    let result = [];
    this.state.rolesList && this.state.rolesList.map((item) => {
      if (this.state.MangingDeptSelectedOption.label === "Tech Services" || this.state.MangingDeptSelectedOption.label === "Vertical Managed" || this.state.MangingDeptSelectedOption.label === ANALYTICS) {
        const userRole = item.pg_Role.pg_role;
        if (userRole === "Unit SPOC") {
          options.push({ value: item.pg_ohr, label: item.pg_username });
        }
      } else {
        let userRole;
        if (typeof item.pg_Role === 'string') {
          try {
            userRole = JSON.parse(item.pg_Role).pg_role;
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
        } else if (typeof item.pg_Role === 'object') {
          userRole = item.pg_Role.pg_role;
        }
        if (userRole === "Unit SPOC") {
          options.push({ value: item.pg_ohr, label: item.pg_username });
        }
      }
      return true;
    });
    result = options.reduce((unique, index) => {
      if (!unique.some(obj => obj.label === index.label && obj.value === index.value)) {
        unique.push(index);
      }
      return unique;
    }, []);

    // console.log("RESULT:", result);
    return result
  }


  handleUnitSPOCChange = async (e) => {

    let styleStateObj = this.state.selectStyle;
    styleStateObj.UnitSPOCSelectedOption = selectStyle(true);
    this.setState({ UnitSPOCSelectedOption: e, UnitOHRName: e.label, UnitOHR: e.value, selectStyle: styleStateObj }, () => { })


    // Toast.loading("Checking Unit SPOC Band. Please wait...", () => { });
    // try {
    //   const param = {
    //     EntityType: "OHR",
    //     EntityName: e.value,
    //   }
    //   const requestOptions = {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Authorization': 'Bearer ' + this.accessToken)
    //     },
    //     body: JSON.stringify(param)
    //   };
    //   const response = await fetch(GET_METADATA, requestOptions);
    //   const data = await response.json();
    //   console.log(data.DNAUsers.Table2[0].SubBand)
    //   console.log(typeof data.DNAUsers.Table2[0].SubBand)
    //   if(data.DNAUsers.Table2[0].SubBand === "1" || data.DNAUsers.Table2[0].SubBand === "2"
    //     || data.DNAUsers.Table2[0].SubBand === "3" || data.DNAUsers.Table2[0].SubBand === "4"
    //   ){
    //     Toast.hide();
    //     let styleStateObj = this.state.selectStyle;
    //     styleStateObj.UnitSPOCSelectedOption = selectStyle(true);
    //     this.setState({UnitSPOCSelectedOption:e, UnitOHRName:e.label,UnitOHR:e.value,selectStyle: styleStateObj},()=>{})
    //   }else{
    //     Toast.fail("Unit SPOC has to be 4+ band.",3000,()=>{})
    //     let styleStateObj = this.state.selectStyle;
    //     styleStateObj.UnitSPOCSelectedOption = selectStyle(false);
    //     this.setState({UnitSPOCSelectedOption:[], UnitOHR:"",UnitOHRName:"",selectStyle: styleStateObj},()=>{})
    //   }

    // } catch (error) {
    //   Toast.hide();
    //   Toast.fail('Unit SPOC has to be 4+ band.', 5000, () => {
    //     console.log(JSON.stringify(error));
    //     let styleStateObj = this.state.selectStyle;
    //     styleStateObj.UnitSPOCSelectedOption = selectStyle(false);
    //     this.setState({UnitSPOCSelectedOption:[], UnitOHR:"",UnitOHRName:"",selectStyle: styleStateObj},()=>{})
    //   });
    // }

  }


  handleSPOC1Change = async (e) => {
    if (this.state.Spoc_2 && this.state.Spoc_2 === e.label) {
      Toast.fail("Opportunity SPOC 1 cannot be the same as the Opportunity SPOC 2", 3000, () => { })
      let styleStateObj = this.state.selectStyle;
      styleStateObj.SPOC_1SelectedOption = selectStyle(false);
      this.setState({ SPOC_1SelectedOption: [], Spoc_1: "", SpocOHR_1: "", selectStyle: styleStateObj }, () => { })
    }
    else {
      let styleStateObj = this.state.selectStyle;
      styleStateObj.SPOC_1SelectedOption = selectStyle(true);
      this.setState({ SPOC_1SelectedOption: e, Spoc_1: e.label, SpocOHR_1: e.value, selectStyle: styleStateObj }, () => { })
    }
  }

  handleSPOC2Change = async (e) => {
    if (this.state.Spoc_1 && this.state.Spoc_1 === e.label) {
      Toast.fail("Opportunity SPOC 2 cannot be the same as the Opportunity SPOC 1", 3000, () => { })
      let styleStateObj = this.state.selectStyle;
      styleStateObj.SPOC_2SelectedOption = selectStyle(false);
      this.setState({ SPOC_2SelectedOption: [], Spoc_2: "", SpocOHR_2: "", selectStyle: styleStateObj }, () => { })
      return false;
    } else {
      let styleStateObj = this.state.selectStyle;
      styleStateObj.SPOC_2SelectedOption = selectStyle(true);
      this.setState({ SPOC_2SelectedOption: e, Spoc_2: e.label, SpocOHR_2: e.value, selectStyle: styleStateObj }, () => { })
    }
  }

  renderSolOwnerList = () => {
    let options = [];
    this.state.rolesList && this.state.rolesList.map((item) => {
      if (this.state.MangingDeptSelectedOption.label === "Tech Services" || this.state.MangingDeptSelectedOption.label === "Vertical Managed" || this.state.MangingDeptSelectedOption.label === ANALYTICS) {
        const userRole = item.pg_Role.pg_role;
        if (userRole === "Opp Solution Owner") {
          options.push({ value: item.pg_ohr, label: item.pg_username });
        }
      } else {
        let userRole;
        if (typeof item.pg_Role === 'string') {
          try {
            userRole = JSON.parse(item.pg_Role).pg_role;
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
        } else if (typeof item.pg_Role === 'object') {
          userRole = item.pg_Role.pg_role;
        }

        if (userRole === "Opp Solution Owner") {
          options.push({ value: item.pg_ohr, label: item.pg_username });
        }
      }
      return true;
    });
    return options;
  }

  handleSolutionOwnerChange = async (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.SolnOwnerSelectedOption = selectStyle(true);
    this.setState({ SolnOwnerSelectedOption: e, SolnOwner: e.label, SolnOwnerOHR: e.value, selectStyle: styleStateObj }, () => { })

    // Toast.loading("Checking SPOC Band. Please wait...", () => { });
    // try {
    //   const param = {
    //     EntityType: "OHR",
    //     EntityName: e.value,
    //   }
    //   const requestOptions = {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Authorization': 'Bearer ' + this.accessToken
    //     },
    //     body: JSON.stringify(param)
    //   };
    //   const response = await fetch(GET_METADATA, requestOptions);
    //   const data = await response.json();
    //   console.log(data.DNAUsers.Table2[0].SubBand)
    //   console.log(typeof data.DNAUsers.Table2[0].SubBand)
    //   if(data.DNAUsers.Table2[0].SubBand === "1" || data.DNAUsers.Table2[0].SubBand === "2" 
    //     //|| data.DNAUsers.Table2[0].SubBand === "3" || data.DNAUsers.Table2[0].SubBand === "4"
    //   )
    //   {
    //     Toast.hide();
    //     let styleStateObj = this.state.selectStyle;
    //     styleStateObj.SolnOwnerSelectedOption = selectStyle(true);
    //     this.setState({SolnOwnerSelectedOption:e, SolnOwner:e.label,SolnOwnerOHR:e.value,selectStyle: styleStateObj},()=>{})
    //   }else{
    //     Toast.fail("Opportunity solution owner has to be 4+ band.",3000,()=>{})
    //     let styleStateObj = this.state.selectStyle;
    //     styleStateObj.SolnOwnerSelectedOption = selectStyle(false);
    //     this.setState({SolnOwnerSelectedOption:[], SolnOwner:"",SolnOwnerOHR:"",selectStyle: styleStateObj},()=>{})
    //   }
    // } catch (error) {
    //   Toast.hide();
    //   let styleStateObj = this.state.selectStyle;
    //     styleStateObj.SolnOwnerSelectedOption = selectStyle(false);
    //   this.setState({SolnOwnerSelectedOption:[], SolnOwner:"",SolnOwnerOHR:"",selectStyle: styleStateObj},()=>{})
    //   Toast.fail('Could not fetch opportunity solution owner band.', 5000, () => {
    //     console.log(JSON.stringify(error));
    //   });
    // }

  }


  // ... auto fetch GOL function starts here
  handleGOLOhronCut = (event) => {
    if (event.target.value.length === 9) {
      this.handleGOLOhrChange()
    } else if (event.target.value.length === 0) {
      this.setState({ GOLOHR: "", GOL: "" }, () => { })
      return null
    }
    else {
      Toast.fail("GOL OHR should be exactly of 9 digits", 3000, () => { })
      this.setState({ GOL: "" }, () => { })
    }
  }

  handleGOLOhrChange = (event) => {
    var numbers = /^[0-9]+$/;
    if (event !== null && event !== undefined) {
      if (event.target.value.match(numbers) && event.target.value.length !== 0) {
        if (event.target.value.length === 9) {
          let localGOLOhr = event.target.value.trim();
          this.setState({ GOLOHR: localGOLOhr }, () => { })
          if (localGOLOhr) {
            this.fetchSubmitGOLOHRDetails(localGOLOhr)
          }
        }
        else {
          this.setState({ GOLOHR: "", GOL: "" }, () => { })
        }
      } else {
        this.setState({ GOLOHR: "", GOL: "" }, () => { })
      }
    }
  }
  fetchSubmitGOLOHRDetails = async (localGOLOhr) => {
    Toast.loading("Fetching GOL Owner Full Name Details. Please wait...", () => { });
    try {
      const param = {
        EntityType: "OHR",
        EntityName: localGOLOhr
      }
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.accessToken
        },
        body: JSON.stringify(param)
      };
      const response = await fetch(GET_METADATA, requestOptions);
      const data = await response.json();
      //console.log("sub band =>",data.DNAUsers.Table2[0].SubBand)
      console.log("sub band details =>", data.DNAUsers.Table2[0])
      if (data && data.DNAUsers) {
        if (data.DNAUsers.Table2[0].SubBand) {
          if (data.DNAUsers.Table2[0].SubBand === "1" || data.DNAUsers.Table2[0].SubBand === "2" || data.DNAUsers.Table2[0].SubBand === "3") {
            let requestByName = data ? data.DNAUsers ? data.DNAUsers.Table1[0].pg_username : null : null;
            if (requestByName === "" || requestByName === null || !requestByName) {
              Toast.fail("We could not find GOL full name details based on the entered OHR. Please check and re-enter.", 5000, () => { });
              this.setState({ GOL: "" }, () => { })
            }
            else {
              setTimeout(() => { }, 2000);
              let styleStateObj = this.state.selectStyle;
              styleStateObj.GOL = textBoxValidation(true);
              styleStateObj.GOLOHR = textBoxValidation(true);
              this.setState({ GOL: requestByName, selectStyle: styleStateObj }, () => { })
            }
            Toast.hide();
          } else {
            Toast.fail('GOL has to be either 1 or 2 or 3 band. Please check and re-enter.', 5000, () => { });
          }
        }
      }
    } catch (error) {
      Toast.hide();
      Toast.fail('Please enter a valid GOL Owner OHR', 5000, () => {
        console.log(JSON.stringify(error));
      });
      this.setState({ GOL: "", GOLOHR: "" }, () => { })
    }
  };
  //.. auto fetch GOL function function ends here



  renderGenAIOfferingL1 = () => {
    let options = [];
    const uniqueOfferingL1 = [...new Map(this.state.GenAIOfferingList.map(item => [item["Offering_L1"], item])).values()]
    uniqueOfferingL1.map((item, index) => {
      options.push({ value: index, label: item.Offering_L1 });
      options.sort((a, b) => a.label.localeCompare(b.label))
      return true
    })
    return options;
  }

  heroOfferingL1Change = (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.Offering_1SelectedOption = selectStyle(true);
    this.setState({
      selectStyle: styleStateObj,
      Offering_1: e.label, Offering_1SelectedOption: e,
      Offering_2: "", Offering_2SelectedOption: [],
      Offering_2_Other: "", IsHero: ""
    });

    if (e && e.label === "Bespoke") {
      this.setState({ IsHero: "No" }, () => { })
    }
  }

  renderGenAIOfferingL2 = () => {
    let options = [];
    if (this.state.GenAIOfferingList) {
      let filteredItems = this.state.GenAIOfferingList.filter((item) =>
        item.Offering_L1 === this.state.Offering_1
      )
      filteredItems.map((item, index) => {
        options.push({ value: index, label: item.Offering_L2 })
        options.sort((a, b) => a.label.localeCompare(b.label))
        return true
      })
      return options;
    }
  }
  heroOfferingL2Change = (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.Offering_2SelectedOption = selectStyle(true);
    this.setState({
      selectStyle: styleStateObj,
      Offering_2: e.label, Offering_2SelectedOption: e,
    }, () => {
      this.state.GenAIOfferingList.map((item, index) => {
        if (item.Offering_L1 === this.state.Offering_1 && item.Offering_L2 === e.label) {
          this.setState({ IsHero: item.IsHero })
        }
      })
    });

  }

  heroOfferingBeSpokeChange = (e) => {
    let styleStateObj = this.state.selectStyle;
    let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
    if (nonWhiteSpace.length < 50) {
      styleStateObj.Offering_2_Other = textBoxValidation(true);
      this.setState({
        Offering_2_Other: e,
        selectStyle: styleStateObj,
      });
    } else {
      const trimmedValue = nonWhiteSpace.slice(0, 50);
      this.setState({
        Offering_2_Other: trimmedValue,
        selectStyle: styleStateObj,
      });
    }

  }



  render() {
    return (
      <section className="u-clearfix u-section-1 opp_create_page" id="sec-0f7f">
        <div className="u-clearfix u-sheet u-sheet-1" style={{ marginBottom: 20 }}>
          <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
            <div className="u-container-layout u-container-layout-1">

              <h4 style={{ marginTop: 10, marginBottom: -35, textAlign: 'center', color: '#00AECF', fontFamily: 'Arial', fontSize: 22 }}>Submit your opportunity</h4>
              <div className="u-align-left u-container-style u-expanded-width u-group u-shape-rectangle u-group-2">
                <div className="u-container-layout u-container-layout-2">
                  <div className="main_fields_block">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="genai_fields">
                          <label className="form-attribute"><label className="mandatery_asterisk">*</label> Submitter OHR</label>
                          <input tabIndex={1} className="form-input" type="text" disabled value={this.state.CreatedBy} />

                        </div>
                        <div className="genai_fields">
                          <label className="form-attribute"><label className="mandatery_asterisk">*</label> Submitter Name</label>
                          <input tabIndex={2} className="form-input" type="text" disabled value={this.state.CreatedName} />
                        </div>
                        <div className="genai_fields">
                          <label className="form-attribute" ><label className="mandatery_asterisk">*</label> Opportunity Name</label>
                          <input className="textarea-css form-attribute" type="text"
                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.OppName, }}
                            placeholder="Provide Opportunity Name"
                            onChange={(e) => this.handleOppNameChange(e.target.value)}
                            value={this.state.OppName}
                          />
                        </div>
                        {/* <div className="genai_fields">
                          <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> Parent Opportunity Id</label>
                          <input tabIndex={3} className="textarea-css form-attribute" type="text" value={this.state.ParentIDAlpha} onChange={(e)=>this.handleParentOppIDChange(e.target.value)} placeholder="Provide Parent Alpha ID" />
                        </div> */}
                      </div>
                      <div className="col-md-3">

                        <div className="genai_fields">
                          <label className="form-attribute"><label className="mandatery_asterisk">*</label> Account</label>
                          <Select tabIndex={4} isSearchable={true} placeholder="Choose Account" options={this.renderAccountOptions()} styles={this.state.selectStyle.AccountSelectedOption} onChange={this.handleAccountNameChange} value={this.state.AccountSelectedOption} />
                        </div>
                        <div className="genai_fields">
                          <label className="form-attribute" ><label className="mandatery_asterisk">*</label> Vertical</label>
                          <input className="form-input" type="text" disabled placeholder="Vertical" value={this.state.Vertical} style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Vertical }} />
                        </div>
                        <div className="genai_fields">
                          <label className="form-attribute"><label className="mandatery_asterisk">*</label> Owning Units </label>
                          <input tabIndex={5} className="form-input" type="text" disabled value={this.state.Unit} placeholder="Owning Units" style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Unit }} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="genai_fields">
                          <label className="form-attribute">&nbsp; Managing Entity</label>
                          <Select tabIndex={6} isSearchable={true} placeholder="Please select" value={this.state.MangingDeptSelectedOption} options={this.renderRegion()} onChange={(e) => this.handleManagingUnitRegionChange(e)} />
                        </div>
                        <div className="genai_fields">
                          <label className="form-attribute"><label className="mandatery_asterisk">*</label> Segment</label>
                          <input className="form-input" disabled type="text" value={this.state.Segment} placeholder="Segment" style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Segment }} />
                        </div>


                      </div>
                      <div className="col-md-3">
                        <div className="genai_fields">
                          <label className="form-attribute"><label className="mandatery_asterisk">*</label> Archetype</label>
                          <input className="form-input" type="text" disabled placeholder='ArcheType' value={this.state.Archetype} style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Archetype }} />
                        </div>
                        <div className="genai_fields">
                          <label className="form-attribute" ><label className="mandatery_asterisk">*</label> FTE Count (inclusive of all regions) </label>
                          <input disabled placeholder="Number of FTEs" className="form-input" value={this.state.AccFTE} style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.AccFTE }} />
                        </div>

                      </div>

                    </div>
                  </div>

                  <div className="gapping_class">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="">
                          <div className="genai_fields">
                            <label className="form-attribute" ><label className="mandatery_asterisk">*</label> Unit SPOC</label>
                            <Select isSearchable={true} placeholder="Choose Unit SPOC" options={this.renderUnitSpocList()} onChange={(e) => this.handleUnitSPOCChange(e)} styles={this.state.selectStyle.UnitSPOCSelectedOption} value={this.state.UnitSPOCSelectedOption} />
                          </div>
                        </div>
                        <div className="">
                          <div className="genai_fields">
                            <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp; </label> Black Belt</label>
                            <Select isSearchable={true} placeholder="Choose Blackbelt" options={this.renderBlackBeltOptions()} onChange={(e) => this.handleBlackBeltNameChange(e)} styles={this.state.selectStyle.BBSelectedOption} value={this.state.BBSelectedOption} />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="">
                          <div className="genai_fields">
                            <label className="form-attribute" ><label className="mandatery_asterisk">*</label> Opportunity SPOC - 1</label>
                            <Select isSearchable={true} placeholder="Choose Opportunity SPOC - 1" options={this.renderSpocList()} onChange={(e) => this.handleSPOC1Change(e)} styles={this.state.selectStyle.SPOC_1SelectedOption} value={this.state.SPOC_1SelectedOption} />
                          </div>
                        </div>
                        <div className="">
                          <div className="genai_fields">
                            <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp; </label> Master Black Belt</label>
                            <Select isSearchable={true} placeholder="Choose MBB" onChange={(e) => this.handleMBBChange(e)} styles={this.state.selectStyle.MBBSelectedOption} options={this.renderMBBOptions()} value={this.state.MBBSelectedOption} />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="">
                          <div className="genai_fields">
                            <label className="form-attribute"><label className="mandatery_asterisk">&nbsp; </label> Opportunity SPOC - 2</label>
                            <Select isSearchable={true} placeholder="Choose Opportunity SPOC - 2" options={this.renderSpocList()} onChange={(e) => this.handleSPOC2Change(e)} styles={this.state.selectStyle.SPOC_2SelectedOption} value={this.state.SPOC_2SelectedOption} />
                          </div>
                        </div>
                        <div className="">
                          <div className="genai_fields">
                            <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp; </label> GOL OHR</label>
                            <input pattern="\d*" maxLength="9"
                              placeholder='Enter GOL OHR'
                              defaultValue={this.state.GOLOHR}
                              onChange={(e) => this.handleGOLOhrChange(e)}
                              onCut={(e) => this.handleGOLOhronCut(e)}
                              className="textarea-css form-attribute hide_numeric_arrows" type="number"
                              onKeyDown={(evt) => (evt.key === '.' || evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault()}
                              onWheel={(e) => e.target.blur()}
                              style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.GOLOHR }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="">
                          <div className="genai_fields">
                            <label className="form-attribute"><label className="mandatery_asterisk">&nbsp; </label> Opportunity Solution Owner</label>
                            <Select isSearchable={true} placeholder="Choose Solution Owner" styles={this.state.selectStyle.SolnOwnerSelectedOption} onChange={(e) => this.handleSolutionOwnerChange(e)} value={this.state.SolnOwnerSelectedOption} options={this.renderSolOwnerList()} />
                          </div>
                        </div>
                        <div className="">
                          <div className="genai_fields">
                            <label className="form-attribute"><label className="mandatery_asterisk">{this.state.GOLOHR ? "*" : ""}</label> GOL</label>
                            <input className="form-input" type="text" disabled placeholder='GOL Name' value={this.state.GOL} style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.GOL }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gapping_class">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="genai_fields">
                          <label className="form-attribute" >
                            <label className="mandatery_asterisk">* </label>
                            &nbsp;GenAI Offering L1</label>
                          <div className="">
                            <Select
                              placeholder="Select GenAI Offering L1"
                              onChange={(e) => this.heroOfferingL1Change(e)}
                              value={this.state.Offering_1SelectedOption}
                              options={this.renderGenAIOfferingL1()}
                              styles={this.state.selectStyle.Offering_1SelectedOption}
                            />
                          </div>
                        </div>


                      </div>
                      <div className="col-md-3">
                        <div className="genai_fields">
                          <label className="form-attribute" >
                            <label className="mandatery_asterisk">* </label>
                            &nbsp;GenAI Offering L2</label>
                          <div className="">
                            {
                              this.state.Offering_1SelectedOption && this.state.Offering_1SelectedOption.label === "Bespoke"
                                ?
                                <>
                                  <input
                                    placeholder="Please provide bespoke value"
                                    onChange={(e) => this.heroOfferingBeSpokeChange(e.target.value)}
                                    className="textarea-css form-attribute"
                                    value={this.state.Offering_2_Other}
                                    style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Offering_2_Other }}
                                  />
                                </>
                                :
                                <Select
                                  placeholder="Select GenAI Offering L2"
                                  onChange={(e) => this.heroOfferingL2Change(e)}
                                  value={this.state.Offering_2SelectedOption}
                                  options={this.renderGenAIOfferingL2()}
                                  styles={this.state.selectStyle.Offering_2SelectedOption}
                                />

                            }

                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="">
                          <div className="genai_fields">
                            <label className="form-attribute">&nbsp; Is a hero Product ?</label>
                            <input
                              className="form-input"
                              placeholder="if its hero product"
                              value={this.state.IsHero}
                              disabled={true}
                            />
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="create_opp_btn_group">
                    <div className="btn-group">
                      <button tabIndex={20} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={this.submitOpp}>Submit</button>
                      <button tabIndex={21} className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={this.cancelOpp}>Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    )
  }



}

export default CreateOpp

