import React, { Component } from 'react'
import {
    GET_METADATA, GET_IDEA_URL, USER_DATA, DISCUSSIONLEDBY,GENPACT_INTERNAL, ANALYTICS, ACCESS_TOKEN,OTHER_REGIONS, YESORNO, OPPMASTERSTAGES, GenAI_ENVIRONMENT, GenAI_FUNDING, OPPAPPROVALOPTIONS, SUBMIT_GENAI_URL,PENDING_APPROVAL_FROM_MBB,PENDING_APPROVAL_FROM_SOLUTION_OWNER,PENDING_APPROVAL_FROM_UNIT_SPOC,PENDING_APPROVAL_FROM_CENTRAL_TEAM,ACTIVE,INACTIVE
} from '../assets/constants/constants';

import Toast from 'light-toast';
import Select from 'react-select';
import { selectValidation as selectStyle, textBoxValidation } from "../utils/FieldValidation";
import Checksum from "../utils/Checksum";
import GenAIValueDelivery from './GenAIValueDelivery';
import GenAIFPNAGOL from './GenAIFPNAGOL'
// comment to be deleted  - Bug Devops ID 419220 released into prod on 27-12-2024 - 2
export class GenAIMbbApproval extends Component {
    sessionData = JSON.parse(localStorage.getItem(USER_DATA));
    accessToken = localStorage.getItem(ACCESS_TOKEN);

    constructor(props) {
        super(props);
        //console.log(props)
        if (this.sessionData === null) {
            Toast.loading("This component cannot be accessed directly. Redirecting to application home.", 3000, () => {
            });
            window.location = "/login";
            return;
        }
        this.state = {
            selectStyle: {},
            oppDetails: [],
            RecID: this.props.location.state,
            RecIDAlpha: "",
            OppName: "",
            ParentID: "",
            ParentIDAlpha: "",
            ModifiedDate: "",
            ModifiedBy: "",
            PlanDiscussDate: "",
            DiscussEstDate: "",
            accountList: [],
            AccountSelectedOption: [],

            Account: "",
            Unit: "",
            MangingDept: "",
            Vertical: "",
            Segment: "",
            Archetype: "",
            AccFTE: '',

            GOLOHR: "",
            GOL: "",

            SpocOHR_1: "",
            Spoc_1: "",


            SpocOHR_2: "",
            Spoc_2: "",


            BBOHR: "",
            BB: "",


            MBBOHR: "",
            MBB: "",


            SolnOwnerOHR: "",
            SolnOwner: "",

            CentTeamOHR: "",
            CentTeam: "",


            Cohort: "",
            CohortList: [],
            CohorSelectedOption: [],

            serviceLineList: [],
            ServiceLine: "",
            ServiceSelectedOption: [],
            ServiceLineMain:[],
            Process_L2: "",
            Process_L2SelectedOption: [],

            // audit log
            auditLog: false,
            auditLogData: [],



            ClientDtls: "",
            Problem: "",
            Solution: "",
            OverallFTE: null,
            OverallSOP: null,
            CXO: "",
            LeadHelp: "",
            IsProposalShared: "",
            IsProposalSharedSelectedOption: [],
            // ProposalFileName
            // ProposalFileType,

            SolnEnviron: [],
            SolnFund: [],
            ClientDollar: null,
            GenpactDollar: null,
            IsSOWScope: [],
            IsSOWSigned: [],
            // SOWFileName
            // SOWFileType
            SOWSubDate: "",
            SOWCloseDate: "",
            CompletionDate: "",
            TechOHR: "",
            Tech: "",
            FunctionalOHR: "",
            Functional: "",
            TransitionLed: [{ value: 3, label: "LDT led" }],
            Knowledge: "",
            Algo: "",
            Data: "",
            Infra: "",
            ValueCreated: "",
            // GOLFileName
            // GOLFileType
            FTEUsage: "",

            handleBIEditLog: false,
            OppStage: "",
            OppStageSelectedOption: [],
            OppState: "",


            IsHero: "",
            GenAIOfferingList: [],
            Offering_1: "",
            Offering_1SelectedOption: [],
           

            Offering_2: "",
            Offering_2SelectedOption: [],
            Offering_2_Other: "",


            userRole: "",
            oppApprovalSelectedOption: [],
            Comment: "",

            OLINum: "",
            OppID: "",


            CentTeamApproval: "",
            CentTeamComment: "",
            SolnOwnerComment: "",
            SolnOwnerApproval: "",


            mbbVDLogModal: false,
            mbbVDTempApproval: "",
            mbbStage6Approval: false,



            BiData: false,


            isUserMBB: false,
            isUserUnitSPOC: false,
            isUserOppSPOC: false,
            isUserOppSPOC2: false,
            isUserCentralTeam: false,
            isUserSolOwner: false,


            unitSPOCApprovalRequired: false,
            
            showHrefModal:false,
            CustName:"",
            CustDesgn:"",
            CustSignOffDate:"",
            GOLOHRVD:"",
            GOLNameVD:"",
            FPnAName:"",
            FPnAOHR:"",

            GOLOHRMonetization:"",
            GOLNameMonetization:"",
            FPnAOHRMonetization:"",
            FPnANameMonetization:"",
            SowID:"",

            ProcessUsers: "",
            AccessUsers: "",
            DailyUsers: "",
            WeeklyUsers: "",
            WeeklyTrans: "",
            SolnTrans: "",
            Upvotes: "",
            UpvotesPercent: null,
            Accuracy:null,
            UptimePercent:null,


            regionText: "",
            rolesList: [],
            spocList: [],
            SpocOHR_2: "",
            Spoc_2: "",
            SPOC_2SelectedOption: [],
            
            BBOHR: "",
            BB: "",
            BBSelectedOption: [],
            
            
            MBBOHR: "",
            MBB: "",
            MBBSelectedOption: [],
            mbbList: [],
            
            SolnOwnerOHR: "",
            SolnOwner: "",
            SolnOwnerSelectedOption: [],

            OppStageChange:"",
            GOLOHR:"",
            GOL:"",
            FinalImpactDollarYear:"",
            FinalImpactFTEYear:"",



        }
    }

    async componentDidMount() {
        await this.fetchMetadata()
        await this.fetchOpportunity()
        await this.fetchUserRole()
        await this.fetchRolesOptions();

    }
    fetchMetadata = async () => {
        Toast.loading("Fetching GenAI meta data. Please wait...", () => { });
        const param = {
            EntityType: "",
            EntityName: ""
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.accessToken
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("meta data ", data)
                Toast.hide();

                this.setState({
                    accountList: data ? data.Accounts.sort((a, b) => { return a.ACCOUNT > b.ACCOUNT ? 1 : -1; }) : [],
                    serviceLineList: data ? data.ServiceLines : [],
                    GenAIOfferingList: data ? data.GenAIOffering : [],
                    CohortList: data ? data.Cohort : [],
                }, () => {

                    if (this.state.GenAIOfferingList) {
                        if (this.state.Offering_1) {
                            const uniqueOfferingL1 = [...new Map(this.state.GenAIOfferingList.map(item => [item["Offering_L1"], item])).values()]
                            uniqueOfferingL1.map((item, index) => {
                                if (item.Offering_L1 === this.state.Offering_1) {
                                    this.setState({ Offering_1: item.Offering_L1, Offering_1SelectedOption: { label: item.Offering_L1, value: index } }, () => { })
                                }
                            })
                            if (this.state.Offering_1 === "Bespoke") {
                                this.setState({  IsHero:"No" }, () => { })
                            }
                            
                        }
                        if (this.state.Offering_1 && this.state.Offering_1 === "Bespoke") {
                            this.setState({Offering_2_Other: this.state.oppDetails.Offering_2_Other},()=>{})
                        }
                        else {
                            let filteredItems = this.state.GenAIOfferingList.filter((item) =>
                                item.Offering_L1 === this.state.Offering_1
                            )
                            filteredItems.map((item, index) => {
                                if(item.Offering_L1 === this.state.Offering_1 && item.Offering_L2 === this.state.Offering_2){
                                    this.setState({ Offering_2: item.Offering_L2, Offering_2SelectedOption: { label: item.Offering_L2, value: index } }, () => { })
                                }
                                
                            })
                        }

                        console.log("Offering 2 Other",this.state.Offering_2_Other)
                        console.log("offering_2 ",this.state.Offering_2)
                        console.log("offering_2_selected_option =>",this.state.Offering_2SelectedOption)

                        if (this.state.Offering_1 && this.state.Offering_2){
                            this.state.GenAIOfferingList.map((item, index) => {
                                if (item.Offering_L1 === this.state.Offering_1 && item.Offering_L2 === this.state.Offering_2) {
                                    this.setState({ IsHero: item.IsHero })
                                }
                            })
                        }
                    }

                    this.renderServiceLineOptions()
                })


                if (this.state.ServiceLine) {
                    let localServiceLine = this.state.ServiceLine.replace(/[|,]/g, ",")
                    localServiceLine = localServiceLine.split(",");
                    let options = [];
                    const uniqueServiceLineList = [...new Map(this.state.serviceLineList.map(item => [item.ServiceLineName, item])).values()];
                    let lengthOfServiceLine = uniqueServiceLineList.length + 1;
                    uniqueServiceLineList.push({ value: lengthOfServiceLine, ServiceLineName: "All" })
                    uniqueServiceLineList.map((item) => {
                        localServiceLine.map((local) => {
                            if (local === item.ServiceLineName) {
                                options.push({
                                    value: item.ServiceLineId,
                                    label: item.ServiceLineName
                                })
                            }

                        })
                    });

                    uniqueServiceLineList.map(item=>{
                        if(item.ServiceLineName === this.state.oppDetails.ServiceLineMain){
                            this.setState({ServiceLineMain:{value:item.ServiceLineId, label:item.ServiceLineName}},()=>{})
                        }
                    })

                    this.setState({ ServiceSelectedOption: options }, () => { })


                    if (this.state.oppDetails.Process_L2) {
                        let localSubServiceLine = this.state.oppDetails.Process_L2.replace(/[|,]/g, ",")
                        localSubServiceLine = localSubServiceLine.split(",");
                        let options = [];
                        const uniqueServiceLineList = [...new Map(this.state.serviceLineList.map(item => [item.SubServiceLineName, item])).values()];
                        let lengthOfServiceLine = uniqueServiceLineList.length + 1;
                        uniqueServiceLineList.push({ value: lengthOfServiceLine, label: "All" })

                        uniqueServiceLineList.map((item) => {
                            localSubServiceLine.map((local) => {
                                if (local === item.SubServiceLineName) {
                                    options.push({
                                        value: item.SubServiceLineId,
                                        label: item.SubServiceLineName
                                    })
                                    this.setState({ Process_L2SelectedOption: options }, () => {
                                        let selectedLabels = "";
                                        selectedLabels = this.state.Process_L2SelectedOption.map(option => `${option.label}`).join('|');
                                        this.setState({ Process_L2: selectedLabels }, () => { })
                                    })
                                }
                            })
                        })
                    }
                }
                if (this.state.Cohort) {
                    this.state.CohortList && this.state.CohortList.map((item, index) => {
                        if (item.Cohort === this.state.Cohort) {
                            this.setState({
                                CohorSelectedOption: {
                                    value: index,
                                    label: item.Cohort
                                },
                                Cohort: item.Cohort
                            }, () => { })
                        }
                    })
                }

            })
            .catch(error => {
                Toast.hide();
                Toast.fail('Error occured while getting Metadata. Please try again!', 3000, () => {
                    console.log(JSON.stringify(error));

                });
            });

    }


    fetchOpportunity = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + this.accessToken,
                },
            };
            fetch(`${GET_IDEA_URL}IdeaId=''&RecID=${this.props.location.state}&case=new&casetype=genAI`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log("fetch opportunity details => ", data)
                    this.setState({
                        oppDetails: data ? data.oppDetails ? data.oppDetails.Table1[0] : [] : [],
                        auditLogData: data ? data.oppDetails ? data.oppDetails.Table2 : [] : [],
                        BiData: data ? data.oppDetails ? data.oppDetails.Table3 : [] : [],

                    }, () => { })

                    resolve();
                    if (this.state.oppDetails) {
                        this.setState({
                            OppName: this.state.oppDetails.OppName,
                            RecID: this.state.oppDetails.RecID,
                            RecIDAlpha: this.state.oppDetails.RecIDAlpha,
                            CreatedDate: this.state.oppDetails.ParentIDAlpha,
                            CreatedName: this.state.oppDetails.CreatedName,
                            CreatedBy: this.state.oppDetails.CreatedBy,

                            ModifiedBy: this.state.oppDetails.ModifiedBy,
                            ModifiedDate: this.state.oppDetails.ModifiedDate,

                            OppStage: this.state.oppDetails.OppStage,
                            OppState: this.state.oppDetails.OppState,
                            Cohort: this.state.oppDetails.Cohort,

                            ParentID: this.state.oppDetails.ParentID,
                            ParentIDAlpha: this.state.oppDetails.ParentIDAlpha,


                            Account: this.state.oppDetails.Account,
                            Vertical: this.state.oppDetails.Vertical,
                            Segment: this.state.oppDetails.Segment,
                            Unit: this.state.oppDetails.Unit,
                            MangingDept: this.state.oppDetails.MangingDept,
                            AccFTE: this.state.oppDetails.AccFTE,
                            Archetype: this.state.oppDetails.Archetype,

                            GOLOHR: this.state.oppDetails.GOLOHR,
                            GOL: this.state.oppDetails.GOL,

                            UnitOHR: this.state.oppDetails.UnitOHR,
                            UnitOHRName: this.state.oppDetails.UnitOHRName,
                            SpocOHR_1: this.state.oppDetails.SpocOHR_1,
                            Spoc_1: this.state.oppDetails.Spoc_1,
                            SpocOHR_2: this.state.oppDetails.SpocOHR_2,
                            Spoc_2: this.state.oppDetails.Spoc_2,
                            BBOHR: this.state.oppDetails.BBOHR,
                            BB: this.state.oppDetails.BB,
                            MBBOHR: this.state.oppDetails.MBBOHR,
                            MBB: this.state.oppDetails.MBB,
                            SolnOwnerOHR: this.state.oppDetails.SolnOwnerOHR,
                            SolnOwner: this.state.oppDetails.SolnOwner,

                            Algo: this.state.oppDetails.Algo,
                            CXO: this.state.oppDetails.CXO,
                            ClientDollar: this.state.oppDetails.ClientDollar,
                            ClientDtls: this.state.oppDetails.ClientDtls,
                            CompletionDate: this.state.oppDetails.CompletionDate,

                            Data: this.state.oppDetails.Data,
                            DiscussEstDate: this.state.oppDetails.DiscussEstDate,
                            FTEUsage: this.state.oppDetails.FTEUsage,
                            GenpactDollar: this.state.oppDetails.GenpactDollar,
                            Infra: this.state.oppDetails.Infra,
                            Knowledge: this.state.oppDetails.Knowledge,
                            LeadHelp: this.state.oppDetails.LeadHelp,

                            IsVDMonetizable: this.state.oppDetails.IsVDMonetizable,
                            MonetizationStatus: this.state.oppDetails.MonetizationStatus,


                            OverallFTE: this.state.oppDetails.OverallFTE,
                            OverallSOP: this.state.oppDetails.OverallSOP,
                            PlanDiscussDate: this.state.oppDetails.PlanDiscussDate,
                            Problem: this.state.oppDetails.Problem,

                            SOWCloseDate: this.state.oppDetails.SOWCloseDate,
                            SOWSubDate: this.state.oppDetails.SOWSubDate,
                            Solution: this.state.oppDetails.Solution,
                            ValueCreated: this.state.oppDetails.ValueCreated,
                            ServiceLine: this.state.oppDetails.ServiceLine,

                            TechOHR: this.state.oppDetails.TechOHR,
                            Tech: this.state.oppDetails.Tech,
                            FunctionalOHR: this.state.oppDetails.FunctionalOHR,
                            Functional: this.state.oppDetails.Functional,

                            Offering_1: this.state.oppDetails.Offering_1,
                            Offering_2: this.state.oppDetails.Offering_2,

                            

                            OLINum: this.state.oppDetails.OLINum,
                            OppID: this.state.oppDetails.OppID,

                            ProcessUsers: this.state.oppDetails.ProcessUsers,
                            AccessUsers: this.state.oppDetails.AccessUsers,
                            DailyUsers: this.state.oppDetails.DailyUsers,
                            WeeklyUsers: this.state.oppDetails.WeeklyUsers,
                            WeeklyTrans: this.state.oppDetails.WeeklyTrans,
                            SolnTrans: this.state.oppDetails.SolnTrans,
                            Upvotes: this.state.oppDetails.Upvotes,
                            UpvotesPercent: this.state.oppDetails.UpvotesPercent,
                            Accuracy: this.state.oppDetails.Accuracy,
                            UptimePercent: this.state.oppDetails.UptimePercent,

                            OppStageChange:this.state.oppDetails.OppStageChange,

                            GOLOHR:this.state.oppDetails.GOLOHR,
                            GOL:this.state.oppDetails.GOL,

                        },
                            () => {

                                if(this.state.UnitOHR && this.state.UnitOHRName){
                                    this.setState({
                                        UnitSPOCSelectedOption: {
                                            value: this.state.UnitOHR,
                                            label: this.state.UnitOHRName
                                        }
                                    });
                                }
                                if (this.state.SpocOHR_1 && this.state.Spoc_1) {
                                    this.setState({
                                        SPOC_1SelectedOption: {
                                            value: this.state.SpocOHR_1,
                                            label: this.state.Spoc_1
                                        }
                                    });
                                }
                                if(this.state.Spoc_2 && this.state.SpocOHR_2){
                                    this.setState({
                                        SPOC_2SelectedOption: {
                                            value: this.state.SpocOHR_2,
                                            label: this.state.Spoc_2
                                        }
                                    });
                                }
                                if(this.state.BB && this.state.BBOHR){
                                    this.setState({
                                        BBSelectedOption: {
                                            value: this.state.BBOHR,
                                            label: this.state.BB
                                        }
                                    });
                                }
                                if(this.state.SolnOwner && this.state.SolnOwnerOHR){
                                    this.setState({
                                        SolnOwnerSelectedOption: {
                                            value: this.state.SolnOwnerOHR,
                                            label: this.state.SolnOwner
                                        }
                                    },()=>{
                                        console.log("solution owner selected option =>",this.state.SolnOwnerSelectedOption)
                                        
                                    });
                                }

                                if (this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.label === "Client discussion - Not Started" || this.state.OppStageSelectedOption.label === "Declined by Client" || this.state.OppStageSelectedOption.label === "Descoped" || this.state.OppStageSelectedOption.label === "On-hold")) {
                                    this.setState({ OppState: "Inactive" })
                                }
                                if (this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 5)) {
                                    this.setState({ OppState: PENDING_APPROVAL_FROM_UNIT_SPOC })
                                }


                                if (this.state.OppStage) {
                                    OPPMASTERSTAGES.map((item, index) => {
                                        if (item.label === this.state.OppStage) {
                                            this.setState({ OppStageSelectedOption: item }, () => {
                                                //console.log("OppStageSelectedOption =>", this.state.OppStageSelectedOption)
                                            })
                                        }
                                    })

                                }
                                if (this.state.oppDetails.ModifiedDate) {
                                    let localVariable = this.formatDate(this.state.oppDetails.ModifiedDate)
                                    this.setState({ ModifiedDate: localVariable })
                                }

                                if (this.state.PlanDiscussDate) {
                                    if (this.state.PlanDiscussDate === "1900-01-01T00:00:00") {
                                        this.setState({ PlanDiscussDate: "" }, () => {
                                            //console.log("this.state.PlanDiscussDate at fetch",this.state.PlanDiscussDate)
                                        })
                                    } else {
                                        let localVariable = new Date(this.state.PlanDiscussDate).toLocaleDateString('en-CA').split('/').reverse().join('-')
                                        this.setState({ PlanDiscussDate: localVariable }, () => {
                                            //console.log("this.state.PlanDiscussDate at fetch",this.state.PlanDiscussDate)
                                        })
                                    }
                                }
                                if (this.state.DiscussEstDate) {
                                    if (this.state.DiscussEstDate === "1900-01-01T00:00:00") {
                                        this.setState({ DiscussEstDate: "" }, () => {
                                            //console.log("this.state.DiscussEstDate at fetch",this.state.DiscussEstDate)
                                        })
                                    } else {
                                        let localVariable = new Date(this.state.DiscussEstDate).toLocaleDateString('en-CA').split('/').reverse().join('-')
                                        this.setState({ DiscussEstDate: localVariable }, () => {
                                            //console.log("this.state.DiscussEstDate at fetch",this.state.DiscussEstDate)
                                        })
                                    }
                                }

                                if (this.state.SOWCloseDate) {
                                    if (this.state.SOWCloseDate === "1900-01-01T00:00:00") {
                                        this.setState({ SOWCloseDate: "" }, () => { })
                                    } else {
                                        let localVariable = new Date(this.state.SOWCloseDate).toLocaleDateString('en-CA').split('/').reverse().join('-')
                                        this.setState({ SOWCloseDate: localVariable }, () => { })
                                    }
                                }

                                if (this.state.SOWSubDate) {
                                    if (this.state.SOWSubDate === "1900-01-01T00:00:00") {
                                        this.setState({ SOWSubDate: "" })
                                    } else {
                                        let localVariable = new Date(this.state.SOWSubDate).toLocaleDateString('en-CA').split('/').reverse().join('-')
                                        this.setState({ SOWSubDate: localVariable })
                                    }

                                }

                                if (this.state.oppDetails.CompletionDate) {
                                    if (this.state.oppDetails.CompletionDate === "1900-01-01T00:00:00") {
                                        this.setState({ CompletionDate: "" })
                                    } else {
                                        let localVariable = new Date(this.state.oppDetails.CompletionDate).toLocaleDateString('en-CA').split('/').reverse().join('-')
                                        this.setState({ CompletionDate: localVariable }, () => {
                                            //console.log("completion date=> ",this.state.CompletionDate)
                                        })
                                    }

                                }

                                if (this.state.oppDetails.IsVDMonetizable && this.state.oppDetails.IsVDMonetizable === "No") {
                                    this.setState({ ClientDollar: 0 }, () => { })
                                }

                                if (this.state.oppDetails.IsSOWScope) {
                                    YESORNO.map((item) => {
                                        if (item.label === this.state.oppDetails.IsSOWScope) {
                                            this.setState({ IsSOWScope: item })
                                        }
                                    })
                                }



                                if (this.state.oppDetails.IsSOWSigned) {
                                    YESORNO.map((item) => {
                                        if (item.label === this.state.oppDetails.IsSOWSigned) {
                                            this.setState({ IsSOWSigned: item })
                                        }
                                    })
                                }
                                if (this.state.oppDetails.TransitionLed) {
                                    DISCUSSIONLEDBY.map((item) => {
                                        if (item.label === this.state.oppDetails.TransitionLed) {
                                            this.setState({ TransitionLed: item })
                                        }
                                    })
                                }
                                if (this.state.oppDetails.IsProposalShared) {
                                    YESORNO.map((item) => {
                                        if (item.label === this.state.oppDetails.IsProposalShared) {
                                            this.setState({ IsProposalShared: item.label, IsProposalSharedSelectedOption: item, })
                                        }
                                    })
                                }
                                if (this.state.oppDetails.SolnEnviron) {
                                    GenAI_ENVIRONMENT.map((item) => {
                                        if (item.label === this.state.oppDetails.SolnEnviron) {
                                            this.setState({ SolnEnviron: item })
                                        }
                                    })
                                }

                                if (this.state.oppDetails.SolnFund) {
                                    GenAI_FUNDING.map((item) => {
                                        if (item.label === this.state.oppDetails.SolnFund) {
                                            this.setState({ SolnFund: item })
                                        }
                                    })
                                }

                                if (this.state.oppDetails.OLINum) {
                                    let trimmedOLI = this.state.oppDetails.OLINum.match(/\d+/g).join('');
                                    this.setState({ OLINum: trimmedOLI }, () => { })
                                }
                                if (this.state.oppDetails.OppID) {
                                    let trimmedOpp = this.state.oppDetails.OppID.match(/\d+/g).join('');
                                    this.setState({ OppID: trimmedOpp }, () => {
                                        console.log(this.state.OppID)
                                    })
                                }
                            }
                        );
                    }

                })
                .catch((error) => {
                    console.log(error)
                    Toast.fail(
                        "Error while fetching opportunity details. Please try again!",
                        3000,
                        () => { }
                    );
                    resolve();
                });
        });
    };

    fetchUserRole = () => {
        return new Promise((resolve, reject) => {
            Toast.loading("Fetching user role. Please wait...", () => { });
            const param = {
                EntityType: "OHR",
                EntityName: this.sessionData ? this.sessionData.profile ? this.sessionData.profile.oHRId : "" : "",
            };
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + this.accessToken,
                },
                body: JSON.stringify(param),
            };
            fetch(GET_METADATA, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    let currentUserOHR = this.sessionData ? this.sessionData.profile ? this.sessionData.profile.oHRId : "" : "";
                    data.PowerAppUsers.filter((item) => {
                        const userRole = JSON.parse(item.pg_Role).pg_role.trim();
                        if (parseInt(this.sessionData.profile.oHRId) === parseInt(item.pg_ohr)) {
                            this.setState({ userRole: userRole }, () => {
                                if (this.state.userRole === "MBB") {
                                    if (item.pg_ohr === this.state.oppDetails.MBBOHR) {
                                        if(currentUserOHR === this.state.oppDetails.MBBOHR){
                                            this.setState({ isUserMBB: true }, () => {})
                                        }
                                    }
                                }
                                if (this.state.userRole === "Unit SPOC") {
                                    if (item.pg_ohr === this.state.oppDetails.UnitOHR) {
                                        if(currentUserOHR === this.state.oppDetails.UnitOHR){
                                            this.setState({ isUserUnitSPOC: true }, () => {})
                                        }
                                    }
                                }
                                if (this.state.userRole === "Opp SPOC") {
                                    if (item.pg_ohr === this.state.oppDetails.SpocOHR_1) {
                                        if(currentUserOHR === this.state.oppDetails.SpocOHR_1){
                                            this.setState({ isUserOppSPOC: true }, () => {})
                                        }
                                    }
                                    if (item.pg_ohr === this.state.oppDetails.SpocOHR_2) {
                                        if(currentUserOHR === this.state.oppDetails.SpocOHR_2){
                                            this.setState({ isUserOppSPOC2: true }, () => {})
                                        }
                                    }

                                }
                                if (this.state.userRole === "Central Team") {
                                    this.setState({ isUserCentralTeam: true }, () => {})

                                }

                                if (this.state.userRole === "Opp Solution Owner") {
                                    if(this.state.oppDetails.SolnOwnerOHR){
                                        if (item.pg_ohr === this.state.oppDetails.SolnOwnerOHR) {
                                            if(currentUserOHR === this.state.oppDetails.SolnOwnerOHR){
                                                this.setState({ isUserSolOwner: true }, () => {})
                                            }
                                        }
                                    }
                                }
                                if (this.state.userRole === "BB") {
                                    if(this.state.oppDetails.BBOHR){
                                        if (item.pg_ohr === this.state.oppDetails.BBOHR) {
                                            if(currentUserOHR === this.state.oppDetails.BBOHR){
                                                this.setState({ isUserBB: true }, () => {})
                                            }
                                        }
                                    }
                                }
                            })
                            resolve();
                            Toast.hide();
                        }
                    })
                })
                .catch((error) => {
                    Toast.hide();
                    console.log(JSON.stringify(error));
                });
        });
    }
    
   

    fetchRolesOptions = async () => {
        Toast.loading("Fetching roles. Please wait...", () => { });
        
        let entityType = "BB";
        let entityName = this.state.Vertical;
        let entityRegion = OTHER_REGIONS;
        
        if (this.state.MangingDept) {
            if (this.state.MangingDept === "Americas" || this.state.MangingDept === "China/RoA" ||
                this.state.MangingDept === "Philippines" || this.state.MangingDept === "Europe") {
                entityName = this.state.MangingDept;
            }
            if (this.state.MangingDept === "Tech Services") {
                entityName = "Tech Services";
                entityRegion = OTHER_REGIONS;
            }
            if (this.state.MangingDept === "Vertical Managed") {
                entityName = this.state.Vertical;
                entityRegion = OTHER_REGIONS;
            }
            if (this.state.MangingDept === ANALYTICS) {
                entityName = GENPACT_INTERNAL;
                entityRegion = OTHER_REGIONS;
            }
        }

        let param = {};
        if (this.state.MangingDept) {
            if (this.state.MangingDept === "Americas" || this.state.MangingDept === "China/RoA" ||
                this.state.MangingDept === "Philippines" || this.state.MangingDept === "Europe") {
                    param = {
                        EntityType: entityType,
                        EntityName: entityName,
                    }
            }
            if (this.state.MangingDept === "Tech Services" || this.state.MangingDept === "Vertical Managed" || this.state.MangingDept === ANALYTICS) {
                param = {
                    EntityType: entityType,
                    EntityName: entityName,
                    EntityRegion: entityRegion
                }
            }
            
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.accessToken
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                Toast.hide();
                if (this.state.MangingDept === "Americas" || this.state.MangingDept === "China/RoA" ||
                    this.state.MangingDept === "Philippines" || this.state.MangingDept === "Europe") {
                    this.setState({
                        rolesList: data.DataverseUserRoles !== undefined ? data.DataverseUserRoles : [],
                    }, () => { 
                        Toast.hide();
                    });
                }
                if (this.state.MangingDept === "Tech Services" || this.state.MangingDept === "Vertical Managed" || this.state.MangingDept === ANALYTICS) {
                    this.setState({
                        rolesList: data.DataverseUserRolesForGenAI !== undefined ? data.DataverseUserRolesForGenAI : [],
                    }, () => { 
                        Toast.hide();
                    });
                }
                // if (data.DataverseUserRolesForGenAI) {
                //     this.setState({rolesList: data.DataverseUserRolesForGenAI,}, () => {
                //         Toast.hide();
                //     });
                // }

            })
            .catch(error => {
                Toast.hide();
                Toast.fail('Error occured while fetching roles. Please try again!', 3000, () => {
                    console.log(JSON.stringify(error));
                });

            });
        Toast.hide();
    }

    formatDate = (columnName) => {
        return new Date(columnName).toLocaleDateString('en-CA').split('/').reverse().join('-');
    }


    // file upload functionality starts here
    uploadFile = () => {

    }
    // file upload functionality ends here


    // fields functionality starts here




    handleOppStageChange = (e) => {
        this.setState({ OppStage: e.label, OppStageSelectedOption: e }, () => {
            if (this.state.OppStageSelectedOption.value === 2) {
                this.setState({ OppState: "Active" }, () => { })
            }
            if (this.state.OppStageSelectedOption.value === 4) {
                this.setState({ OppState: "Active" }, () => { })
            }
            if (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 5) {
                this.setState({ OppState: "Active", unitSPOCApprovalRequired: true }, () => { })
            }
            if (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8 || this.state.OppStageSelectedOption.value === 9) {
                this.setState({ OppState: "Inactive" }, () => { })
            }
            if (this.state.OppStageSelectedOption.value === 6) {
                this.setState({ OppState: "Active" }, () => { })
            }
        })



        let styleStateObj = this.state.selectStyle;

        styleStateObj.TransitionLed = selectStyle(true);
        styleStateObj.ServiceSelectedOption = selectStyle(true);
        styleStateObj.Process_L2SelectedOption = selectStyle(true);
        styleStateObj.Offering_1SelectedOption = selectStyle(true);

        styleStateObj.Offering_2SelectedOption = selectStyle(true);
        styleStateObj.Offering_2_Other = textBoxValidation(true);
        styleStateObj.ClientDtls = textBoxValidation(true);
        styleStateObj.Problem = textBoxValidation(true);
        styleStateObj.Solution = textBoxValidation(true);
        styleStateObj.OverallFTE = textBoxValidation(true);
        styleStateObj.OverallSOP = textBoxValidation(true);
        styleStateObj.CXO = textBoxValidation(true);
        styleStateObj.PlanDiscussDate = textBoxValidation(true);
        styleStateObj.DiscussEstDate = textBoxValidation(true);
        styleStateObj.IsProposalSharedSelectedOption = selectStyle(true);
        styleStateObj.SolnEnviron = selectStyle(true);
        styleStateObj.SolnFund = selectStyle(true);
        styleStateObj.ClientDollar = textBoxValidation(true);
        styleStateObj.GenpactDollar = textBoxValidation(true);
        styleStateObj.IsSOWScope = selectStyle(true);
        styleStateObj.IsSOWSigned = selectStyle(true);
        styleStateObj.SOWSubDate = textBoxValidation(true);
        styleStateObj.SOWCloseDate = textBoxValidation(true);
        styleStateObj.CompletionDate = textBoxValidation(true);

        styleStateObj.FunctionalOHR = textBoxValidation(true);
        styleStateObj.Functional = textBoxValidation(true);
        styleStateObj.TechOHR = textBoxValidation(true);
        styleStateObj.Tech = textBoxValidation(true);

        styleStateObj.Knowledge = textBoxValidation(true);
        styleStateObj.Algo = textBoxValidation(true);
        styleStateObj.Data = textBoxValidation(true);
        styleStateObj.Infra = textBoxValidation(true);
        styleStateObj.FTEUsage = textBoxValidation(true);


        styleStateObj.ProcessUsers = textBoxValidation(true);
        styleStateObj.AccessUsers = textBoxValidation(true);
        styleStateObj.DailyUsers = textBoxValidation(true);
        styleStateObj.WeeklyUsers = textBoxValidation(true);
        styleStateObj.WeeklyTrans = textBoxValidation(true);
        styleStateObj.SolnTrans = textBoxValidation(true);
        styleStateObj.Upvotes = textBoxValidation(true);
        styleStateObj.UpvotesPercent = textBoxValidation(true);
        styleStateObj.Accuracy = textBoxValidation(true);
        styleStateObj.UptimePercent = textBoxValidation(true);
        styleStateObj.OppStageChange = textBoxValidation(true);

        this.setState({ selectStyle: styleStateObj, });


    }
    renderOppMasterStages = () => {
        return OPPMASTERSTAGES;
    }

    renderCohort = () => {
        let options = [];
        this.state.CohortList && this.state.CohortList.map((item, index) => {
            options.push({
                value: index,
                label: item.Cohort
            })
        })
        return options;
    }

    handleCohortChange = (e) => {
        this.setState({ Cohort: e.label, CohorSelectedOption: e }, () => { })
    }




    renderGenAIOfferingL1 = () => {
        let options = [];
        const uniqueOfferingL1 = [...new Map(this.state.GenAIOfferingList.map(item => [item["Offering_L1"], item])).values()]
        uniqueOfferingL1.map((item, index) => {
            options.push({ value: index, label: item.Offering_L1 });
            options.sort((a, b) => a.label.localeCompare(b.label))
            return true
        })
        return options;
    }

    heroOfferingL1Change = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.Offering_1SelectedOption = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            Offering_1: e.label, Offering_1SelectedOption: e,
            OverallSOP: null,
            Offering_2: "", Offering_2SelectedOption: [],
            Offering_2_Other:""
        });
        if (e && e.label === "Bespoke") {
            this.setState({ IsHero: "No" })
        }

    }
    renderGenAIOfferingL2 = () => {
        let options = [];
        if (this.state.GenAIOfferingList) {
            let filteredItems = this.state.GenAIOfferingList.filter((item) =>
                item.Offering_L1 === this.state.Offering_1
            )
            filteredItems.map((item, index) => {
                options.push({ value: index, label: item.Offering_L2 })
                options.sort((a, b) => a.label.localeCompare(b.label))

                return true
            })
            return options;
        }
    }
    heroOfferingL2Change = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.Offering_2SelectedOption = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            Offering_2: e.label, Offering_2SelectedOption: e,

        }, () => {
            this.state.GenAIOfferingList.map((item, index) => {
                if (item.Offering_L1 === this.state.Offering_1 && item.Offering_L2 === e.label) {
                    this.setState({ IsHero: item.IsHero, })
                }
            })
        });
    }

    heroOfferingBeSpokeChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 100) {
            styleStateObj.Offering_2_Other = textBoxValidation(true);
            this.setState({
                Offering_2_Other: e,
                selectStyle: styleStateObj,
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 100);
            this.setState({
                Offering_2_Other: trimmedValue,
                selectStyle: styleStateObj,
            });
        }
    }

    handleServiceLineChange = (e) => {
        if (e.length <= 10) {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.ServiceSelectedOption = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj,
                ServiceSelectedOption: e,
                Process_L2SelectedOption: [],
                Process_L2: "",
                ServiceLineMain:[]
            });

            if(e && e.length === 1 ){
                console.log('e[0]=>',e[0])
                this.setState({ ServiceLineMain: e[0] }, () => { 
                    console.log("service line main =>",this.state.ServiceLineMain)
                })
            }


            let selectedLabels = "";
            selectedLabels = e.map(option => `${option.label}`).join('|');
            this.setState({ ServiceLine: selectedLabels }, () => { })
            this.renderSubServiceLineOptions();
        } else {
            window.alert("You can add upto 10 service line options only.")
            return false;
        }
    }

    renderPredominantServiceLine = () => {
        let options = [];
        this.state.ServiceSelectedOption && this.state.ServiceSelectedOption.map((item)=>{
            options.push({value:item.value,label:item.label})
        })
        return options;
    }

    handlePredominantSeriveLineChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.ServiceLineMain = selectStyle(true);
        this.setState({ ServiceLineMain: e, selectStyle: styleStateObj }, () => { })
    }

    handleProcessChange = (e) => {
        if (e.length <= 5) {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.Process_L2SelectedOption = selectStyle(true);
            this.setState({ Process_L2SelectedOption: e, selectStyle: styleStateObj }, () => { })
            let selectedLabels = "";
            selectedLabels = e.map(option => `${option.label}`).join('|');
            this.setState({ Process_L2: selectedLabels }, () => { })
        } else {
            window.alert("Upto 5 Area / Process lines options can only be added.")
            return false;
        }

    }
    clientBGChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 2000) {
            styleStateObj.ClientDtls = textBoxValidation(true);
            this.setState({
                ClientDtls: e,
                selectStyle: styleStateObj,
            }, () => {
                this.state.ClientDtls.replace(/'/g, "\\'");
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 2000);
            this.setState({
                ClientDtls: trimmedValue,
                selectStyle: styleStateObj,
            }, () => {
                this.state.ClientDtls.replace(/'/g, "\\'");
            });
        }
    }
    clientProblemChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 2000) {
            styleStateObj.Problem = textBoxValidation(true);
            this.setState({
                Problem: e,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Problem.replace(/'/g, "\\'");
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 2000);
            this.setState({
                Problem: trimmedValue,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Problem.replace(/'/g, "\\'");
            });
        }
    }
    genAISolutionChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 2000) {
            styleStateObj.Solution = textBoxValidation(true);
            this.setState({
                Solution: e,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Solution.replace(/'/g, "\\'");
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 2000);
            this.setState({
                Solution: trimmedValue,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Solution.replace(/'/g, "\\'");
            });
        }
    }

    overAllFTEinScopeChange = (e) => {
        if (e <= 5000) {
            if (/^\d{0,4}(\.\d{0,2})?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.OverallFTE = textBoxValidation(true);
                this.setState({ OverallFTE: e, selectStyle: styleStateObj }, () => { })
            } else {
                window.alert("You can add upto 4 digits and 2 decimal values only allowed.");
                return;
            }
        }
        if (e === null || e === undefined || e === "") {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.OverallFTE = textBoxValidation(false);
            this.setState({ selectStyle: styleStateObj }, () => { })
        }
    }
    overAllNumberofSOPChange = (e) => {
        if (e <= 1000) {
            if (/^\d{0,4}(\.\d{0,2})?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.OverallSOP = textBoxValidation(true);
                this.setState({ OverallSOP: e, selectStyle: styleStateObj }, () => { })
            } else {
                window.alert("You can add upto 4 digits and 2 decimal values only allowed.");
                return;
            }
        } else {
            // let styleStateObj = this.state.selectStyle;
            // styleStateObj.OverallSOP = textBoxValidation(false);
            // this.setState({ selectStyle: styleStateObj }, () => { })
        }

    }


    needHelpChange = (e) => {

        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 300) {
            styleStateObj.LeadHelp = textBoxValidation(true);
            this.setState({
                LeadHelp: e,
                selectStyle: styleStateObj,
            }, () => {
                this.state.LeadHelp.replace(/'/g, "\\'");
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 300);
            this.setState({
                LeadHelp: trimmedValue,
                selectStyle: styleStateObj,
            }, () => {
                this.state.LeadHelp.replace(/'/g, "\\'");
            });
        }



    }

    CXOReportChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 300) {
            styleStateObj.CXO = textBoxValidation(true);
            this.setState({
                CXO: e,
                selectStyle: styleStateObj,
            }, () => {
                this.state.CXO.replace(/'/g, "\\'");
                //console.log("CXO =>",this.state.CXO)
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 300);
            this.setState({
                CXO: trimmedValue,
                selectStyle: styleStateObj,
            }, () => {
                this.state.CXO.replace(/'/g, "\\'");
            });
        }

    }

    IsProposalSharedChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.IsProposalSharedSelectedOption = selectStyle(true);
        this.setState({ IsProposalShared: e.label, IsProposalSharedSelectedOption: e, selectStyle: styleStateObj }, () => { })
    }

    planDiscussionDateChange = (e) => {
        if (e !== null && e !== undefined) {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.PlanDiscussDate = textBoxValidation(true);
            this.setState({ PlanDiscussDate: e, selectStyle: styleStateObj }, () => { })
        }

    }
    DiscussionEstimateDateChange = (e) => {
        if (e !== null && e !== undefined) {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.DiscussEstDate = textBoxValidation(true);
            this.setState({ DiscussEstDate: e, selectStyle: styleStateObj }, () => { })
        }
    }
    SolutionFundingChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.SolnFund = selectStyle(true);
        if (this.state.oppDetails.IsVDMonetizable && this.state.oppDetails.IsVDMonetizable === "No") {
            this.setState({ ClientDollar: 0 }, () => { })
        } else {
            this.setState({ ClientDollar: "" }, () => { })
        }
        this.setState({
            SolnFund: e, selectStyle: styleStateObj,
            GenpactDollar: "",
        }, () => { })
    }

    clientFundingChange = (e) => {
        if (/^\d{0,10}(\.\d{0,2})?$/.test(e)) {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.ClientDollar = textBoxValidation(true);
            this.setState({
                ClientDollar: e, selectStyle: styleStateObj,

            }, () => {

            })
        } else {
            window.alert("You can add upto 10 digits and 2 decimal values only allowed.");
            return;
        }
    }
    costToGenpactBuildChange = (e) => {
        if (/^\d{0,10}(\.\d{0,2})?$/.test(e)) {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.GenpactDollar = textBoxValidation(true);
            this.setState({ GenpactDollar: e, selectStyle: styleStateObj }, () => { })
        } else {
            window.alert("You can add upto 10 digits and 2 decimal values only allowed.");
            return;
        }
    }

    sowScopePeiceOfWorkChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.IsSOWScope = selectStyle(true);
        this.setState({ IsSOWScope: e, selectStyle: styleStateObj, IsSOWSigned: [] }, () => { })


    }

    sowScopeSignedChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.IsSOWSigned = selectStyle(true);
        this.setState({
            IsSOWSigned: e, selectStyle: styleStateObj
            , SOWSubDate: "",
            SOWCloseDate: "",
        }, () => { })

    }




    expectedSOWSubmissionDateorSowSubmissionDateChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.SOWSubDate = textBoxValidation(true);
        this.setState({ SOWSubDate: e, selectStyle: styleStateObj }, () => { })

    }
    expectedSOWClosureDateorSowClosureDateChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.SOWCloseDate = textBoxValidation(true);
        this.setState({ SOWCloseDate: e, selectStyle: styleStateObj }, () => { })

    }
    expectedDeploymentDateDateorGoLiveeDateChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.CompletionDate = textBoxValidation(true);
        this.setState({ CompletionDate: e, selectStyle: styleStateObj }, () => { })

    }


    transitionLedChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.TransitionLed = selectStyle(true);
        this.setState({ TransitionLed: e, selectStyle: styleStateObj }, () => { })
    }
    domainKnowledgeChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 300) {
            styleStateObj.Knowledge = textBoxValidation(true);
            this.setState({
                Knowledge: e,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Knowledge.replace(/'/g, "\\'");
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 300);
            this.setState({
                Knowledge: trimmedValue,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Knowledge.replace(/'/g, "\\'");
            });
        }

    }
    algorithmChanged = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 300) {
            styleStateObj.Algo = textBoxValidation(true);
            this.setState({
                Algo: e,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Algo.replace(/'/g, "\\'");
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 300);
            this.setState({
                Algo: trimmedValue,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Algo.replace(/'/g, "\\'");
            });
        }

    }

    dataUsedChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 300) {
            styleStateObj.Data = textBoxValidation(true);
            this.setState({
                Data: e,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Data.replace(/'/g, "\\'");
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 300);
            this.setState({
                Data: trimmedValue,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Data.replace(/'/g, "\\'");
            });
        }

    }
    techInfraUsedChanged = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 300) {
            styleStateObj.Infra = textBoxValidation(true);
            this.setState({
                Infra: e,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Infra.replace(/'/g, "\\'");
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 300);
            this.setState({
                Infra: trimmedValue,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Infra.replace(/'/g, "\\'");
            });
        }

    }

    shareHolderValueCreated = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 300) {
            styleStateObj.ValueCreated = textBoxValidation(true);
            this.setState({
                ValueCreated: e,
                selectStyle: styleStateObj,
            }, () => {
                this.state.ValueCreated.replace(/'/g, "\\'");
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 300);
            this.setState({
                ValueCreated: trimmedValue,
                selectStyle: styleStateObj,
            }, () => {
                this.state.ValueCreated.replace(/'/g, "\\'");
            });
        }

    }

    noOfFTEUsageChange = (e) => {
        if (e <= 5000) {
            if (/^\d{0,5}(\.\d{0,2})?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.FTEUsage = textBoxValidation(true);
                this.setState({ FTEUsage: e, selectStyle: styleStateObj }, () => {
                })
            }
            // e = Number(Math.floor(e+'e2')+'e-2');
        }
    }

    handleSFDCOLIChange = (e) => {
        let tempValue = e;
        if (tempValue.length <= 6) {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.OLINum = textBoxValidation(true);
            this.setState({ OLINum: e, selectStyle: styleStateObj }, () => { })
        } else {
            window.alert("OLI number cannot be not more than 6 characters.")
        }
    }

    handleSFDCOppIDChange = (e) => {
        if (e.length <= 6) {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.OppID = textBoxValidation(true);
            this.setState({ OppID: e, selectStyle: styleStateObj }, () => { })
        } else {
            window.alert("Opp ID cannot be not more than 6 characters.")
        }
    }


    TotalPPLInTheProcess = (e) => {
        if (e <= 5000) {
            if (/^\d{0,5}?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.ProcessUsers = textBoxValidation(true);
                this.setState({ ProcessUsers: e, selectStyle: styleStateObj }, () => {
                })
            }
            
        }
    }

    PPLAccessSoln = (e) => {
        if (e <= 5000) {
            if (/^\d{0,5}?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.AccessUsers = textBoxValidation(true);
                this.setState({ AccessUsers: e, selectStyle: styleStateObj }, () => {
                })
            }
        }
    }

    PPLUsingDaily = (e) => {
        if (e <= 5000) {
            if (/^\d{0,5}?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.DailyUsers = textBoxValidation(true);
                this.setState({ DailyUsers: e, selectStyle: styleStateObj }, () => {
                })
            }
        }
    }

    PPLUsingWeekly = (e) => {
        if (e <= 5000) {
            if (/^\d{0,5}?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.WeeklyUsers = textBoxValidation(true);
                this.setState({ WeeklyUsers: e, selectStyle: styleStateObj }, () => {
                })
            }
        }
    }


    TotalWeeklyTransaction = (e) => {
        if (e <= 100000) {
            if (/^\d{0,6}?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.WeeklyTrans = textBoxValidation(true);
                this.setState({ WeeklyTrans: e, selectStyle: styleStateObj }, () => {
                })
            }
        }
    }

    GenAITransactionProcessedSoln = (e) => {
        if (e <= 100000) {
            if (/^\d{0,6}?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.SolnTrans = textBoxValidation(true);
                this.setState({ SolnTrans: e, selectStyle: styleStateObj }, () => {
                })
            }
        }
    }
    noOfUpVotes = (e) => {
        if (e <= 5000) {
            if (/^\d{0,5}?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.Upvotes = textBoxValidation(true);
                this.setState({ Upvotes: e, selectStyle: styleStateObj }, () => {
                })
            }
        }
    }

    PercentOfUpVotes = (e) => {
        if (e <= 100) {
            if (/^\d{0,3}(\.\d{0,2})?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.UpvotesPercent = textBoxValidation(true);
                this.setState({ UpvotesPercent: e, selectStyle: styleStateObj }, () => { })
            } else {
                window.alert("You can add upto 10 digits and 2 decimal values only allowed.");
                return;
            }
        }
    }

    AccuracyOfOutcome = (e) => {
        if (e <= 100) {
            if (/^\d{0,3}(\.\d{0,2})?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.Accuracy = textBoxValidation(true);
                this.setState({ Accuracy: e, selectStyle: styleStateObj }, () => { })
            } else {
                window.alert("You can add upto 10 digits and 2 decimal values only allowed.");
                return;
            }
        }
    }

    UptimeOfSoln = (e) => {
        if (e <= 100) {
            if (/^\d{0,3}(\.\d{0,2})?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.UptimePercent = textBoxValidation(true);
                this.setState({ UptimePercent: e, selectStyle: styleStateObj }, () => { })
            } else {
                window.alert("You can add upto 10 digits and 2 decimal values only allowed.");
                return;
            }
        }
    }


    // fields functionality ends here





    // BI Function starts here
    addMonetizableValues = (e) => {
        let MonetizedBiArray = [];
        let fteValue = 0;
        let dollarValue = 0;
        this.state.businessImpactValueArray.filter((item) => {
            if (item.IsMonetized === "Yes") {
                MonetizedBiArray.push(item);
            }
        });
        if (MonetizedBiArray) {
            const groupedData = MonetizedBiArray.reduce(
                (accumulator, currentValue) => {
                    const impactType = currentValue.impactType;
                    const fteValue = parseFloat(currentValue.fteValue) || 0.0;
                    const dollarValue = parseFloat(currentValue.dollarValue) || 0.0;
                    const existingRow = accumulator.find(
                        (row) => row.impactType === impactType
                    );
                    if (existingRow) {
                        existingRow.fteValue += fteValue;
                        existingRow.dollarValue += dollarValue;
                    } else {
                        accumulator.push({ impactType, fteValue, dollarValue });
                    }
                    return accumulator;
                },
                []
            );
            // console.log("grouped data",groupedData)

            if (groupedData.length === 0) {
                fteValue = 0;
                dollarValue = 0;
            } else {
                fteValue = groupedData[0].fteValue;
                dollarValue = groupedData[0].dollarValue;
            }
            this.setState(
                {
                    totalMonetizedFTE: fteValue,
                    totalMonetizedDollar: dollarValue,
                },
                () => { }
            );
        }
    };


    newBusinessImpactSummary() {
        let myArray = [...this.state.businessImpactValueArray];
        const groupedData = myArray.reduce((accumulator, currentValue) => {
            const impactType = currentValue.impactType;
            const monetizableType = currentValue.monetizableType;
            const fteValue = parseFloat(currentValue.fteValue) || 0.0;
            const dollarValue = parseFloat(currentValue.dollarValue) || 0.0;

            const existingRow = accumulator.find(
                (row) =>
                    row.impactType === impactType &&
                    row.monetizableType === monetizableType
            );
            if (existingRow) {
                existingRow.fteValue += fteValue;
                existingRow.dollarValue += dollarValue;
            } else {
                accumulator.push({
                    impactType,
                    monetizableType,
                    fteValue,
                    dollarValue,
                });
            }
            return accumulator;
        }, []);
        this.setState({ summaryArray: groupedData }, () => { });
        this.addMonetizableValues();
    }

    pull_data = ({ businessImpactValueArray }) => {
        if (businessImpactValueArray) {
            this.setState(
                { businessImpactValueArray: businessImpactValueArray },
                () => {
                    this.newBusinessImpactSummary();
                    let dollarTotal = 0.0;
                    let fteValue = 0.0;
                    let dollarTotalYear = 0.0;
                    let fteValueYear = 0.0;
                    this.state.businessImpactValueArray.map((item) => {
                        if (item && item.DollarImpact) {
                            dollarTotal += parseFloat(item.DollarImpact);
                        }
                        if (item && item.dollarValue) {
                            dollarTotal += parseFloat(item.dollarValue);
                        }
                        if (item && item.FTEImpact) {
                            fteValue += parseFloat(item.FTEImpact);
                        }
                        if (item && item.fteValue) {
                            fteValue += parseFloat(item.fteValue);
                        }
                        // calculate sum of dollar impact year
                        if (item && item.DollarImpactYear) {
                            dollarTotalYear += parseFloat(item.DollarImpactYear);
                        }
                        // calculate sum of FTE dollar impact year
                        if (item && item.FTEImpactYear) {
                            fteValueYear += parseFloat(item.FTEImpactYear);
                        }
                        if (item.ImpactType === "Impact at Genpact") {
                            if (item.MonetizableType === "Increase in Revenue" || item.MonetizableType === "Reduction in Cost") {
                                this.setState({ isFpnaRequired: true }, () => { })
                            }
                        }
                    });
                    this.setState({ finalImpactDollar: dollarTotal }, () => {
                    });
                    this.setState({ finalImpactFTE: fteValue }, () => {
                    });
                    this.setState({ FinalImpactDollarYear:dollarTotalYear }, () => {
                        // console.log("final impact year dollar =>",this.state.FinalImpactDollarYear)
                    });
                    this.setState({ FinalImpactFTEYear:fteValueYear }, () => {
                        // console.log("final impact year FTE =>",this.state.FinalImpactFTEYear)
                    });
                   
                },
                () => {
                }
            );
        }

    };
    pull_file_data = ({
        fileName,
        fileType,
        fileContent,
        showClientApprovalFields,
        FPnAOHRMonetization,
    }) => {
        if (fileName) {
            this.setState({ FPnAFileNameMonetization: fileName }, () => { });
        }
        if (fileType) {
            this.setState({ FPnAFileTypeMonetization: fileType }, () => { });
        }
        if (fileContent) {
            this.setState({ FPnAFileMonetization: fileContent }, () => { });
        }
        this.setState(
            { showClientApprovalFields: showClientApprovalFields },
            () => { }
        );
        this.setState({ FPnAOHRMonetization: FPnAOHRMonetization }, () => {

        });
    };
    remove_bi_data_after_delete = (filteredIdeaArray) => {
        if (filteredIdeaArray) {
            this.setState({ businessImpactValueArray: filteredIdeaArray }, () => {
                this.newBusinessImpactSummary();
                let dollarTotal = 0.0;
                let fteValue = 0.0;
                let dollarTotalYear = 0.0;
                let fteValueYear = 0.0;
                this.state.businessImpactValueArray.map((item) => {
                    if (item && item.DollarImpact) {
                        dollarTotal += parseFloat(item.DollarImpact);
                    }
                    if (item && item.dollarValue) {
                        dollarTotal += parseFloat(item.dollarValue);
                    }
                    if (item && item.FTEImpact) {
                        fteValue += parseFloat(item.FTEImpact);
                    }
                    if (item && item.fteValue) {
                        fteValue += parseFloat(item.fteValue);
                    }
                    // calculate sum of impact $ year
                    if (item && item.DollarImpactYear) {
                        dollarTotalYear += parseFloat(item.DollarImpactYear);
                    }
                    // calculate sum of FTE impact $ year
                    if (item && item.FTEImpactYear) {
                        fteValueYear += parseFloat(item.FTEImpactYear);
                    }
                });
                if (this.state.businessImpactValueArray.some(e => e.ImpactType === 'Impact at Genpact' && ['Increase in Revenue', 'Reduction in Cost'].includes(e.MonetizableType))) {
                    this.setState({ isFpnaRequired: true }, () => { })
                } else {
                    this.setState({ isFpnaRequired: false }, () => { })
                }
                this.setState({ finalImpactDollar: dollarTotal }, () => { });
                this.setState({ finalImpactFTE: fteValue }, () => { });
                this.setState({ FinalImpactDollarYear:dollarTotalYear }, () => {
                    // console.log("final impact year dollar at remove bi function  =>",this.state.FinalImpactDollarYear)
                });
                this.setState({ FinalImpactFTEYear:fteValueYear }, () => {
                    // console.log("final impact year FTE at remove bi function =>",this.state.FinalImpactFTEYear)
                });
            });
        }
    };

    pull_monetization_sowId = (localSowId) => {
        this.setState({ SowID: localSowId }, () => { });
    };
    pull_monetization_fpna_name = (localFpnaName) => {
        this.setState({ FPnANameMonetization: localFpnaName }, () => { });
    };
    pull_monetization_fpna_ohr = (localFpnaohr) => {
        this.setState({ FPnAOHRMonetization: localFpnaohr }, () => { });
    };
    pull_monetizedTotal = ({ fteValue, dollarValue }) => {
        if (fteValue) {
            this.setState({ totalMonetizedDollar: fteValue }, () => { });
        }
        if (dollarValue) {
            this.setState({ totalMonetizedFTE: dollarValue }, () => { });
        }
    };
    pull_monetizableProject = (e) => {
        if (e) {
            this.setState({ monetizableProjectSelectedOption: e }, () => {
            });
        }
    };
    pull_monetizableStatus = (e) => {
        if (e) {
            this.setState({ monetizationStatusSelectedOption: e }, () => {
            });
        }
    };

    handleVDTypeChange = ({ e }) => {
        if (e) {
            this.setState({ impactOptionSelected: e }, () => {
                if (this.state.impactOptionSelected.label === "No Value Delivery") {
                    this.setState(
                        {
                            showClientFields: false,
                            showGOLFields: false,
                            finalImpactDollar: 0,
                            monetizableProjectSelectedOption: null,
                            monetizationStatusSelectedOption: null,
                            showClientApprovalFields: false,
                            isFpnaRequired:false
                        },
                        () => { }
                    );
                }
                if (
                    this.state.impactOptionSelected.label === "Value Delivered to Client"
                ) {
                    this.setState(
                        { showClientFields: true, showGOLFields: true },
                        () => { }
                    );
                }
                if (
                    this.state.impactOptionSelected.label === "Value Delivered to Genpact"
                ) {
                    this.setState({ showClientFields: false, showGOLFields: true });
                }
                if (
                    this.state.impactOptionSelected.label ===
                    "Value Delivered to Client + Genpact"
                ) {
                    this.setState({ showClientFields: true, showGOLFields: true });
                }
            });
        }
    };
    fetchFPAOHRforIncreaseInRevenue = (e) => {
        if (e === true) {
            this.setState({ appropriateAttachment: true }, () => { });
        } else {
            this.setState({ appropriateAttachment: false }, () => { });
        }
    };

    pullMonetizationGOLName = (localGOLName) => {
        this.setState({ GOLNameMonetization: localGOLName }, () => {
        });
    };
    pullMonetizationGOLOhr = (localGOLOhr) => {
        this.setState({ GOLOHRMonetization: localGOLOhr }, () => {
        });
    };
    pullCategoryNotListed = (localShowHref) => {
        this.setState({ showHrefModal: localShowHref }, () => {

        });
    };

    pullPPTDeck = ({ fileName, fileType, fileContent }) => {
        //console.log(fileName,fileType,fileContent)
        this.setState({ filename1: fileName, fileType1: fileType, base64File1: fileContent }, () => { })
    }
    pullClientDocName = (fileName) => {
        this.setState({ signOffFilename: fileName, }, () => { })
    }
    pullClientDocType = (fileType) => {
        if (fileType) {
            this.setState({ signOffFileType: fileType }, () => { })
        }
    }
    pullClientDocContent = (fileContent) => {
        if (fileContent) {
            this.setState({ signOffContent: fileContent }, () => { })
        }
    }

    // gol file details
    pullgolFileName = (fileName) => {
        this.setState({ filename3: fileName }, () => {})
    }
    pullgolFileType = (fileType) => {
        this.setState({ fileType3: fileType }, () => {})
    }
    pullgolContent = (fileContent) => {
        this.setState({ base64File3: fileContent }, () => {})
    }

    // fpna file details
    pullfpnafileName = (fileName) => {
        if (fileName) {
            this.setState({ filename4: fileName }, () => {
                // console.log("fpna doc name",this.state.filename4)
            })
        }
    }
    pullfpnafileType = (fileType) => {
        if (fileType) {
            this.setState({ fileType4: fileType }, () => {
                // console.log("fpna doc type",this.state.fileType4)
            })
        }
    }
    pullfpnafileContent = (fileContent) => {
        this.setState({ base64File4: fileContent }, () => {
            // console.log("fpna doc file content",this.state.base64File4)
        })
    }

    pullCustName = (e) => {
        this.setState({ CustName: e }, () => {
            //console.log(this.state.CustName)
        })
    }
    pullClientDesign = (e) => {
        //console.log(e)
        this.setState({ CustDesgn: e }, () => {
            //console.log("cust desgn at pull function",this.state.CustDesgn)
        })
    }
    pullCustSignOffDate = (e) => {
        this.setState({ CustSignOffDate: e }, () => {})
    }
    pullGOLOHR = (e) => {
        console.log("pull gol ohr vd =>",e)
        this.setState({ GOLOHRVD: e }, () => {
            console.log("pull GOL OHR VD =>",this.state.GOLOHRVD)
        })
    }
    pullGolName = (name) => {
        this.setState({ GOLNameVD: name }, () => {
            //console.log("pull GOL Name VD =>",this.state.GOLNameVD)
        })
    }
    pullFpnaName = (name) => {
        this.setState({ FPnAName: name }, () => {
            //console.log(this.state.FPnAName)
        })
    }
    pullFpnaOhr = (e) => {
        this.setState({ FPnAOHR: e }, () => {
            //console.log(this.state.FPnAOHR)
        })
    }
    pull_fileDetails = (obj) => {
        this.setState({ golFpnaFileDetails: obj }, () => {})
    }

    // BI Function ends here

    // validate function starts here
    validate = () => {
        let isValid = true;
        this.isExceedlimit = false;
        let styleStateObj = this.state.selectStyle;

        // stages 2-3-4-5-6
        if (this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6)) {
            // if (!this.state.FunctionalOHR || this.state.FunctionalOHR === "" || this.state.FunctionalOHR === null || this.state.FunctionalOHR === undefined || this.state.FunctionalOHR.length === 0) {
            //     isValid = false;
            //     styleStateObj.FunctionalOHR = textBoxValidation(false);
            //     this.setState({
            //         selectStyle: styleStateObj,
            //     });
            // } else {
            //     styleStateObj.FunctionalOHR = textBoxValidation(true);
            //     this.setState({
            //         selectStyle: styleStateObj,
            //     });
            // }
            // if (!this.state.Functional || this.state.Functional === "" || this.state.Functional === null || this.state.Functional === undefined || this.state.Functional.length === 0) {
            //     isValid = false;
            //     styleStateObj.Functional = textBoxValidation(false);
            //     this.setState({
            //         selectStyle: styleStateObj,
            //     });
            // } else {
            //     styleStateObj.Functional = textBoxValidation(true);
            //     this.setState({
            //         selectStyle: styleStateObj,
            //     });
            // }
            // if (!this.state.TransitionLed || this.state.TransitionLed === "" || this.state.TransitionLed === null || this.state.TransitionLed === undefined || this.state.TransitionLed.length === 0) {
            //     isValid = false;
            //     styleStateObj.TransitionLed = selectStyle(false);
            //     this.setState({
            //         selectStyle: styleStateObj,
            //     });
            // } else {
            //     styleStateObj.TransitionLed = selectStyle(true);
            //     this.setState({
            //         selectStyle: styleStateObj,
            //     });
            // }
        }


        // stages 3-4-5
        if (this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5)) {
            //service line and area/process validation starts here
            if (!this.state.ServiceSelectedOption || this.state.ServiceSelectedOption === "" || this.state.ServiceSelectedOption === null || this.state.ServiceSelectedOption === undefined || this.state.ServiceSelectedOption.length === 0) {
                isValid = false;
                styleStateObj.ServiceSelectedOption = selectStyle(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.ServiceSelectedOption = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }
            if (!this.state.ServiceLineMain || this.state.ServiceLineMain === "" || this.state.ServiceLineMain === null || this.state.ServiceLineMain === undefined || this.state.ServiceLineMain.length === 0) {
                isValid = false;
                //Toast.fail("6",3000,()=>{})
                styleStateObj.ServiceLineMain = selectStyle(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.ServiceLineMain = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }

            if (this.state.ServiceSelectedOption) {
                if (!this.state.Process_L2SelectedOption || this.state.Process_L2SelectedOption === "" || this.state.Process_L2SelectedOption === null || this.state.Process_L2SelectedOption === undefined || this.state.Process_L2SelectedOption.length === 0) {
                    isValid = false;
                    styleStateObj.Process_L2SelectedOption = selectStyle(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.Process_L2SelectedOption = selectStyle(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }
            }


            //service line and area/process validation ends here

            // Hero Product Offering  validation starts here
            if (!this.state.Offering_1SelectedOption || this.state.Offering_1SelectedOption === "" || this.state.Offering_1SelectedOption === null || this.state.Offering_1SelectedOption === undefined || this.state.Offering_1SelectedOption.length === 0) {
                isValid = false;
                styleStateObj.Offering_1SelectedOption = selectStyle(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.Offering_1SelectedOption = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }



            if (this.state.Offering_1SelectedOption && this.state.Offering_1SelectedOption.label === "Bespoke") {
                if (!this.state.Offering_2_Other || this.state.Offering_2_Other === "" || this.state.Offering_2_Other === null || this.state.Offering_2_Other === undefined || this.state.Offering_2_Other.length === 0) {
                    isValid = false;
                    styleStateObj.Offering_2_Other = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.Offering_2_Other = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }
            }else{
                if (!this.state.Offering_2SelectedOption || this.state.Offering_2SelectedOption === "" || this.state.Offering_2SelectedOption === null || this.state.Offering_2SelectedOption === undefined || this.state.Offering_2SelectedOption.length === 0) {
                    isValid = false;
                    styleStateObj.Offering_2SelectedOption = selectStyle(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.Offering_2SelectedOption = selectStyle(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }
            }
            // Hero Product Offering  validation ends here

            if (!this.state.ClientDtls || this.state.ClientDtls === "" || this.state.ClientDtls === null || this.state.ClientDtls === undefined || this.state.ClientDtls.length === 0) {
                isValid = false;
                styleStateObj.ClientDtls = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.ClientDtls = textBoxValidation(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }

            if (!this.state.Problem || this.state.Problem === "" || this.state.Problem === null || this.state.Problem === undefined || this.state.Problem.length === 0) {
                isValid = false;
                styleStateObj.Problem = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.Problem = textBoxValidation(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }

            if (!this.state.Solution || this.state.Solution === "" || this.state.Solution === null || this.state.Solution === undefined || this.state.Solution.length === 0) {
                isValid = false;
                styleStateObj.Solution = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.Solution = textBoxValidation(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }

            if (this.state.OverallFTE === "" || this.state.OverallFTE === null || this.state.OverallFTE === undefined) {
                isValid = false;
                styleStateObj.OverallFTE = textBoxValidation(false);
                this.setState({ selectStyle: styleStateObj, });
            } else {
                styleStateObj.OverallFTE = textBoxValidation(true);
                this.setState({ selectStyle: styleStateObj, });
            }

            if (this.state.Offering_1SelectedOption && this.state.Offering_1SelectedOption.label === "CKA") {
                if (this.state.OverallSOP === "" || this.state.OverallSOP === null || this.state.OverallSOP === undefined) {
                    isValid = false;
                    styleStateObj.OverallSOP = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.OverallSOP = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }
            }



        }
        // stages 2-3-4-5
        if (this.state.OppStageSelectedOption.value === 2 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) {
            // if (!this.state.CXO || this.state.CXO === "" || this.state.CXO === null || this.state.CXO === undefined || this.state.CXO.length === 0) {
            //     isValid = false;
            //     styleStateObj.CXO = textBoxValidation(false);
            //     this.setState({
            //         selectStyle: styleStateObj,
            //     });
            // } else {
            //     styleStateObj.CXO = textBoxValidation(true);
            //     this.setState({
            //         selectStyle: styleStateObj,
            //     });
            // }
        }

        // if (this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5)) {
        //     if (!this.state.IsProposalSharedSelectedOption || this.state.IsProposalSharedSelectedOption === "" || this.state.IsProposalSharedSelectedOption === null || this.state.IsProposalSharedSelectedOption === undefined || this.state.IsProposalSharedSelectedOption.length === 0) {
        //         isValid = false;
        //         styleStateObj.IsProposalSharedSelectedOption = selectStyle(false);
        //         this.setState({
        //             selectStyle: styleStateObj,
        //         });
        //     } else {
        //         styleStateObj.IsProposalSharedSelectedOption = selectStyle(true);
        //         this.setState({
        //             selectStyle: styleStateObj,
        //         });
        //     }
        // }


        // stage 2
        if (this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2)) {
            // if (!this.state.PlanDiscussDate || this.state.PlanDiscussDate === "" || this.state.PlanDiscussDate === null || this.state.PlanDiscussDate === undefined || this.state.PlanDiscussDate.length === 0) {
            //     isValid = false;
            //     styleStateObj.PlanDiscussDate = textBoxValidation(false);
            //     this.setState({
            //         selectStyle: styleStateObj,
            //     });
            // } else {
            //     styleStateObj.PlanDiscussDate = textBoxValidation(true);
            //     this.setState({
            //         selectStyle: styleStateObj,
            //     });
            // }

            // if (!this.state.DiscussEstDate || this.state.DiscussEstDate === "" || this.state.DiscussEstDate === null || this.state.DiscussEstDate === undefined || this.state.DiscussEstDate.length === 0) {
            //     isValid = false;
            //     styleStateObj.DiscussEstDate = textBoxValidation(false);
            //     this.setState({
            //         selectStyle: styleStateObj,
            //     });
            // } else {
            //     styleStateObj.DiscussEstDate = textBoxValidation(true);
            //     this.setState({
            //         selectStyle: styleStateObj,
            //     });
            // }


        }

        if (this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6)) {

            if (!this.state.SolnEnviron || this.state.SolnEnviron === "" || this.state.SolnEnviron === null || this.state.SolnEnviron === undefined || this.state.SolnEnviron.length === 0) {
                isValid = false;
                styleStateObj.SolnEnviron = selectStyle(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.SolnEnviron = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }

            // solution funding starts here
            if (!this.state.SolnFund || this.state.SolnFund === "" || this.state.SolnFund === null || this.state.SolnFund === undefined || this.state.SolnFund.length === 0) {
                isValid = false;
                styleStateObj.SolnFund = selectStyle(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.SolnFund = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }

            if (this.state.SolnFund && (this.state.SolnFund.value === 1 || this.state.SolnFund.value === 3)) {
                if (this.state.oppDetails.IsVDMonetizable && this.state.oppDetails.IsVDMonetizable === "No") {
                    styleStateObj.ClientDollar = textBoxValidation(true);
                    this.setState({ selectStyle: styleStateObj, });
                }
                if (this.state.oppDetails.IsVDMonetizable === "" || this.state.oppDetails.IsVDMonetizable === null || this.state.oppDetails.IsVDMonetizable === undefined || this.state.oppDetails.IsVDMonetizable === "Yes") {
                    if (this.state.ClientDollar === "" || this.state.ClientDollar === null || this.state.ClientDollar === undefined) {
                        isValid = false;
                        styleStateObj.ClientDollar = textBoxValidation(false);
                        this.setState({
                            selectStyle: styleStateObj,
                        });

                    } else {
                        styleStateObj.ClientDollar = textBoxValidation(true);
                        this.setState({
                            selectStyle: styleStateObj,
                        });
                    }
                }
            }
            console.log("genpact dollar =>", this.state.GenpactDollar)

            if (this.state.SolnFund && (this.state.SolnFund.value === 2 || this.state.SolnFund.value === 3)) {
                if (this.state.GenpactDollar === "" || this.state.GenpactDollar === null || this.state.GenpactDollar === undefined || this.state.GenpactDollar.length === 0) {
                    isValid = false;
                    styleStateObj.GenpactDollar = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.GenpactDollar = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }
            }
            // solution funding ends here

            //sow validation starts here
            if (!this.state.IsSOWScope || this.state.IsSOWScope === "" || this.state.IsSOWScope === null || this.state.IsSOWScope === undefined || this.state.IsSOWScope.length === 0) {
                isValid = false;
                styleStateObj.IsSOWScope = selectStyle(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.IsSOWScope = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }
            if (this.state.IsSOWScope && this.state.IsSOWScope.label === "Yes") {
                if (!this.state.IsSOWSigned || this.state.IsSOWSigned === "" || this.state.IsSOWSigned === null || this.state.IsSOWSigned === undefined || this.state.IsSOWSigned.length === 0) {
                    isValid = false;
                    styleStateObj.IsSOWSigned = selectStyle(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.IsSOWSigned = selectStyle(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }
                // if (!this.state.SOWSubDate || this.state.SOWSubDate === "" || this.state.SOWSubDate === null || this.state.SOWSubDate === undefined || this.state.SOWSubDate.length === 0) {
                //     isValid = false;
                //     styleStateObj.SOWSubDate = textBoxValidation(false);
                //     this.setState({
                //         selectStyle: styleStateObj,
                //     });
                // } else {
                //     styleStateObj.SOWSubDate = textBoxValidation(true);
                //     this.setState({
                //         selectStyle: styleStateObj,
                //     });
                // }

                // if (!this.state.SOWCloseDate || this.state.SOWCloseDate === "" || this.state.SOWCloseDate === null || this.state.SOWCloseDate === undefined || this.state.SOWCloseDate.length === 0) {
                //     isValid = false;
                //     styleStateObj.SOWCloseDate = textBoxValidation(false);
                //     this.setState({
                //         selectStyle: styleStateObj,
                //     });
                // } else {
                //     styleStateObj.SOWCloseDate = textBoxValidation(true);
                //     this.setState({
                //         selectStyle: styleStateObj,
                //     });
                // }


            }
            if (!this.state.CompletionDate || this.state.CompletionDate === "" || this.state.CompletionDate === null || this.state.CompletionDate === undefined || this.state.CompletionDate.length === 0) {
                isValid = false;
                styleStateObj.CompletionDate = textBoxValidation(false);
                this.setState({ selectStyle: styleStateObj, });
            } else {
                styleStateObj.CompletionDate = textBoxValidation(true);
                this.setState({ selectStyle: styleStateObj });
            }
            //sow validation ends here
            if (!this.state.TechOHR || this.state.TechOHR === "" || this.state.TechOHR === null || this.state.TechOHR === undefined || this.state.TechOHR.length === 0) {
                isValid = false;
                styleStateObj.TechOHR = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.TechOHR = textBoxValidation(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }

            if (!this.state.Tech || this.state.Tech === "" || this.state.Tech === null || this.state.Tech === undefined || this.state.Tech.length === 0) {
                isValid = false;
                styleStateObj.Tech = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.Tech = textBoxValidation(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }
            // if (!this.state.Knowledge || this.state.Knowledge === "" || this.state.Knowledge === null || this.state.Knowledge === undefined || this.state.Knowledge.length === 0) {
            //     isValid = false;
            //     styleStateObj.Knowledge = textBoxValidation(false);
            //     this.setState({
            //         selectStyle: styleStateObj,
            //     });
            // } else {
            //     styleStateObj.Knowledge = textBoxValidation(true);
            //     this.setState({
            //         selectStyle: styleStateObj,
            //     });
            // }

            if (!this.state.Algo || this.state.Algo === "" || this.state.Algo === null || this.state.Algo === undefined || this.state.Algo.length === 0) {
                isValid = false;
                styleStateObj.Algo = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.Algo = textBoxValidation(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }

            if (!this.state.Data || this.state.Data === "" || this.state.Data === null || this.state.Data === undefined || this.state.Data.length === 0) {
                isValid = false;
                styleStateObj.Data = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.Data = textBoxValidation(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }

            if (!this.state.Infra || this.state.Infra === "" || this.state.Infra === null || this.state.Infra === undefined || this.state.Infra.length === 0) {
                isValid = false;
                styleStateObj.Infra = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.Infra = textBoxValidation(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }
        }

        if (this.state.OppStageSelectedOption && this.state.OppStageSelectedOption.value === 6) {
            if (this.state.FTEUsage === "" || this.state.FTEUsage === null || this.state.FTEUsage === undefined) {
                isValid = false;
                styleStateObj.FTEUsage = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.FTEUsage = textBoxValidation(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }
        }

        if (this.state.isUserMBB) {
            if (!this.state.oppApprovalSelectedOption || this.state.oppApprovalSelectedOption === "" || this.state.oppApprovalSelectedOption === null || this.state.oppApprovalSelectedOption === undefined || this.state.oppApprovalSelectedOption.length === 0) {
                isValid = false;
                styleStateObj.oppApprovalSelectedOption = selectStyle(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.oppApprovalSelectedOption = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }


            if (this.state.oppApprovalSelectedOption) {
                if (!this.state.Comment || this.state.Comment === "" || this.state.Comment === null || this.state.Comment === undefined || this.state.Comment.length === 0) {
                    isValid = false;
                    styleStateObj.Comment = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.Comment = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }
            }
        }

        // BI Validation starts here
        // if(this.state.OppState !== PENDING_APPROVAL_FROM_MBB){
        //     isValid = false;
        //     Toast.fail("Opportunity can be approved by MBB only when it is pending approval from MBB.",9000,()=>{})
        // }

        // if (this.state.oppDetails.OppState === PENDING_APPROVAL_FROM_UNIT_SPOC) {
        //     isValid = false;
        //     Toast.fail("When opporutnity state is Pending Approval from Unit SPOC, it has to be approved from the Unit SPOC's queue.", 8000, () => { })
        // }

        // if (this.state.oppDetails.OppState === PENDING_APPROVAL_FROM_SOLUTION_OWNER) {
        //     console.log("inside solution owner condition")
        //     isValid = false;
        //     Toast.fail("When opportunity state is Pending Approval from Solution Owner, it has to be approved from the solution owner's queue.", 8000, () => { })
        // }


        if (this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6)) {
            if (this.state.oppDetails.VDType === null || this.state.oppDetails.VDType === "" || this.state.oppDetails.VDType === undefined || !this.state.oppDetails.VDType) {
                isValid = false;
                Toast.fail("Value delivery is mandatory from opportunity Demonstrable POC in Flight or from opportunity stage 2 onwards.", 5000, () => { })
            }
        }
        if (this.state.OppStageSelectedOption) {
            if (this.state.OppStageSelectedOption.value === 2 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) {
                if (this.state.impactOptionSelected === null) {
                    isValid = false;
                    Toast.fail("Please select Value Delivery Type", 3000, () => { });
                }
                if (this.state.impactOptionSelected && this.state.impactOptionSelected.label !== "No Value Delivery") {
                    if (this.state.businessImpactValueArray.length === 0) {
                        Toast.fail("Value Delivery cannot be empty, please fill and resubmit.", 3000, () => { });
                        isValid = false;
                    } else {
                        if (this.state.finalImpactDollar <= 0) {
                            Toast.fail("Total $ Impact cannot be zero. Please check and resubmit.", 3000, () => { });
                            isValid = false;
                        }
                        if (this.state.finalImpactFTE > 0 && this.state.finalImpactDollar <= 0) {
                            Toast.fail("Total $ Impact cannot be zero when FTE Impact is non-zero. Please check and resubmit.", 3000, () => { });
                            isValid = false;
                        }

                        let showDollarZeroErrorAnnualised = false;
                        let showDollarZeroErrorDeploymentYear = false;
                        this.state.businessImpactValueArray.filter((item, index) => {
                            if (parseFloat(item.DollarImpact) === 0 || parseFloat(item.DollarImpact) === "" || !item.DollarImpact || item.DollarImpact === null) {
                                isValid = false;
                                showDollarZeroErrorAnnualised = true;
                            }
                            if (parseFloat(item.DollarImpactYear) === 0 || parseFloat(item.DollarImpactYear) === "" || !item.DollarImpactYear || item.DollarImpactYear === null) {
                                isValid = false;
                                showDollarZeroErrorDeploymentYear = true;
                            }
                            if (item && item.isFTEPresent === true) {
                                if (parseFloat(item.FTEImpact)) {
                                } else {
                                    isValid = false;
                                    Toast.fail("Annualised FTE Impact value cannot be zero.", 3000, () => { });
                                }
                                if (parseFloat(item.FTEImpact) === 0) {
                                    isValid = false;
                                    Toast.fail("Annualised FTE Impact value cannot be zero.", 3000, () => { });
                                }
                                if (parseFloat(item.FTEImpact) > parseFloat(item.DollarImpact)) {
                                    isValid = false;
                                    Toast.fail("Annualised FTE Impact value cannot be greater than $ Impact value", 3000, () => { });
                                }

                                // deployment year condition starts here
                                if (parseFloat(item.FTEImpactYear)) {
                                } else {
                                    isValid = false;
                                    Toast.fail("Deployment Year FTE Impact value cannot be zero.", 3000, () => { });
                                }
                                if (parseFloat(item.FTEImpactYear) === 0) {
                                    isValid = false;
                                    Toast.fail(" Deployment Year FTE Impact value cannot be zero.", 3000, () => { });
                                }
                                if (parseFloat(item.FTEImpactYear) > parseFloat(item.DollarImpactYear)) {
                                    isValid = false;
                                    Toast.fail("Deployment Year FTE Impact cannot be greater than Deployment Year $ Impact value.", 3000, () => { });
                                }
                                // deployment year condition ends here
                            }
                        });

                        if(showDollarZeroErrorAnnualised){
                            Toast.fail("Annualised $ Impact value cannot be zero.", 3000, () => { });
                        }
                        if(showDollarZeroErrorDeploymentYear){
                            Toast.fail("Deployment Year $ Impact value cannot be zero.", 3000, () => { });
                        }
                    }

                    if (this.state.impactOptionSelected && this.state.impactOptionSelected.label !== "No Value Delivery") {
                        if (this.state.monetizableProjectSelectedOption === null ||
                            this.state.monetizableProjectSelectedOption.length === 0 ||
                            this.state.monetizableProjectSelectedOption === undefined
                        ) {
                            isValid = false;
                            Toast.fail("Please select monetizatable project", 5000, () => { });
                        }
                    }

                    if (this.state.impactOptionSelected.label === "Value Delivered to Client + Genpact") {
                        if (this.state.businessImpactValueArray.length > 0) {
                            let impactGenpactTrue = this.state.businessImpactValueArray.some((vendor) => vendor["ImpactType"] === "Impact at Genpact");
                            let impactClientTrue = this.state.businessImpactValueArray.some((vendor) => vendor["ImpactType"] === "Impact at Client");
                            if (impactGenpactTrue === false) {
                                isValid = false;
                                Toast.fail("Please add both Impact types.", 3000, () => { });
                            }
                            if (impactClientTrue === false) {
                                isValid = false;
                                Toast.fail("Please add both Impact types.", 3000, () => { });
                            }
                        }
                    }
                    
                    if(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6)){
                        if (this.state.showClientFields) {
                            if (this.state.CustDesgn === "" || this.state.CustDesgn === null || this.state.CustDesgn === undefined || !this.state.CustDesgn) {
                                isValid = false
                                Toast.fail("Please fill Client Role.", 3000, () => { });
                            }
                            if (this.state.CustSignOffDate === null || this.state.CustSignOffDate === undefined || this.state.CustSignOffDate === "" || !this.state.CustSignOffDate) {
                                isValid = false
                                Toast.fail("Please fill Client Sign-off date.", 3000, () => { });
                            }
                            if (this.state.CustName === null || this.state.CustName === undefined || this.state.CustName === "" || !this.state.CustName) {
                                isValid = false
                                Toast.fail("Please fill Client Name.", 3000, () => { });
                            }
                
                        }
                        if (this.state.showGOLFields) {
                            if (this.state.GOLOHRVD === "" || this.state.GOLOHRVD === null || this.state.GOLOHRVD === undefined || !this.state.GOLOHRVD) {
                                isValid = false;
                                Toast.fail("Please fill GOL OHR.", 3000, () => { })
                            }
                
                            if (this.state.oppDetails.GOLOHRVD ) {
                                if (this.state.GOLNameVD === "" || this.state.GOLNameVD === null || this.state.GOLNameVD === undefined || this.state.GOLNameVD.length === 0 || !this.state.GOLNameVD) {
                                    isValid = false;
                                    Toast.fail("Please fill GOL OHR to fetch GOL Name.",3000,()=>{})
                                }
                            }
                            this.state.businessImpactValueArray && this.state.businessImpactValueArray.map((item) => {
                                if (item.ImpactType === "Impact at Genpact") {
                                    if (item.MonetizableType === "Increase in Revenue" || item.MonetizableType === "Reduction in Cost") {
                                        if (!this.state.FPnAOHR || this.state.FPnAOHR === "" || this.state.FPnAOHR === undefined || this.state.FPnAOHR === null) {
                                            isValid = false;
                                            Toast.fail("Please fill FP&A OHR.", 3000, () => { })
                                        }
                                        if (this.state.FPnAOHR) {
                                            if (!this.state.FPnAName || this.state.FPnAName === null || this.state.FPnAName === undefined || this.state.FPnAName === "") {
                                                isValid = false;
                                                Toast.fail("Please fill FP&A OHR to fetch FP&A Name.", 3000, () => { })
                                            }
                                        }
                                    }
                                }
                            });
                            
                        }
                    }
                    
                    

                    if (this.state.impactOptionSelected) {
                        if (this.state.monetizableProjectSelectedOption && this.state.monetizableProjectSelectedOption.label === "Yes") {
                            if (this.state.monetizationStatusSelectedOption === null || !this.state.monetizationStatusSelectedOption ||
                                this.state.monetizationStatusSelectedOption === undefined || this.state.monetizationStatusSelectedOption.length === 0
                            ) {
                                isValid = false;
                                Toast.fail("Since this project has been marked as Monetizable, monetizable status has to be selected", 5000, () => { });
                            }

                            if (this.state.monetizationStatusSelectedOption && this.state.monetizationStatusSelectedOption.label === "Approved by Client") {
                                if(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6)){
                                    if (this.state.GOLOHRMonetization === "" || this.state.GOLOHRMonetization === null || !this.state.GOLOHRMonetization || this.state.GOLOHRMonetization === undefined) {
                                        isValid = false;
                                        Toast.fail("Please fill up GOL OHR to fetch GOL Name for Monetization", 3000, () => { })
                                    }
                                    if(this.state.GOLOHRMonetization){
                                        if (this.state.GOLNameMonetization === "" || this.state.GOLNameMonetization === null || this.state.GOLNameMonetization === undefined ||  !this.state.GOLNameMonetization) {
                                            isValid = false;
                                            Toast.fail("Please fill up GOL OHR for GOL Name Monetization", 3000, () => { })
                                        }
                                    }
                                    if (this.state.FPnAOHRMonetization === null || this.state.FPnAOHRMonetization === "" || this.state.FPnAOHRMonetization === undefined || !this.state.FPnAOHRMonetization) {
                                        isValid = false;
                                        Toast.fail("Please fill up FP&A OHR Monetization for Monetization", 3000, () => { })
                                    }
                                    if(this.state.FPnAOHRMonetization){
                                        if (this.state.FPnANameMonetization === "" || this.state.FPnANameMonetization === null || this.state.FPnANameMonetization === undefined || !this.state.FPnANameMonetization) {
                                            isValid = false;
                                            Toast.fail("Please fill up FP&A OHR to fetch FP&A Name for Monetization", 3000, () => { })
                                        }
                                    }
                                    if (this.state.SowID === "" || this.state.SowID === null || this.state.SowID === undefined || this.state.SowID.length === 0) {
                                        isValid = false;
                                        Toast.fail("Please fill SOW ID/SFDC/OLI ID for monetization.", 3000, () => { })
                                    }
                                }
                                
                            }

                        }
                        if (this.state.monetizableProjectSelectedOption !== null && this.state.monetizationStatusSelectedOption !== null) {
                            if (this.state.monetizableProjectSelectedOption.label === "Yes")
                                if (this.state.businessImpactValueArray.filter((item) => item.IsMonetized === "Yes").length > 0) { }
                                else {
                                    isValid = false;
                                    Toast.fail("Since this is a monetizable project, there needs to be at least one impact type as ‘Impact at Genpact’ selected and category selected as a ‘Increase in Revenue’ .", 5000, () => { });
                                }
                        }

                        


                    }
                }
            }
        }

        // BI Validation ends here


        if (this.state.oppDetails.OppState === PENDING_APPROVAL_FROM_MBB) {
            if (this.state.isUserMBB) { } else {
                isValid = false;
                Toast.fail("Only users assigned with role of MBB can only update the opportunity, when state is Pending Approval from MBB", 8000, () => { })
            }
        }


        if (this.state.OppStageSelectedOption && this.state.OppStageSelectedOption.value === 6) {
            if (this.state.isUserCentralTeam) {
                isValid = false;
                Toast.fail("Central team cannot update the opportunity.", 5000, () => { })
            }
        }



        console.log("local opp state =>", this.setOpportunityState())
        console.log("local opp action =>", this.setAction())
        console.log("local role =>", this.setUpdaterRole())


        if (this.state.oppDetails.OppStage) {
            if (this.state.oppDetails.OppStage === "1. Client discussion - Not Started") {
                if (
                    this.state.OppStageSelectedOption.value === 1 ||
                    this.state.OppStageSelectedOption.value === 2 ||
                    this.state.OppStageSelectedOption.value === 3 ||
                    this.state.OppStageSelectedOption.value === 5 ||
                    this.state.OppStageSelectedOption.value === 7 ||
                    this.state.OppStageSelectedOption.value === 8 ||
                    this.state.OppStageSelectedOption.value === 9

                ) {

                } else {
                    isValid = false;
                    Toast.fail("From 1st stage, you cannot select 4th or 6th stages.", 3000, () => { })
                }
            }
            if (this.state.oppDetails.OppStage === "2. Client Discussion - Ongoing") {
                if (
                    this.state.OppStageSelectedOption.value === 1 ||
                    this.state.OppStageSelectedOption.value === 2 ||
                    this.state.OppStageSelectedOption.value === 3 ||
                    this.state.OppStageSelectedOption.value === 5 ||
                    this.state.OppStageSelectedOption.value === 7 ||
                    this.state.OppStageSelectedOption.value === 8 ||
                    this.state.OppStageSelectedOption.value === 9
                ) {

                } else {
                    isValid = false;
                    Toast.fail("From 2nd stage, you can only select 3rd, 5th, 7th, 8th or 9th stages.", 3000, () => { })
                }
            }
            if (this.state.oppDetails.OppStage === "3. Demonstrable POC in Flight") {
                if (this.state.OppStageSelectedOption.value === 1 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 9) {

                }
                else {
                    isValid = false;
                    Toast.fail("From 3rd stage, you can only select 4th or 9th stages only.", 3000, () => { })
                }


            }

            if (this.state.oppDetails.OppStage === "4. Demonstrable POC Complete") {
                if (
                    this.state.OppStageSelectedOption.value === 1 ||
                    this.state.OppStageSelectedOption.value === 2 ||
                    this.state.OppStageSelectedOption.value === 3 ||
                    this.state.OppStageSelectedOption.value === 6 ||
                    this.state.OppStageSelectedOption.value === 8
                ) {
                    isValid = false;
                    Toast.fail("From 4th stage, you can only select 5th, 7th or 9th stages only.", 3000, () => { })
                }
            }

            if (this.state.oppDetails.OppStage === "5. Production grade Build in Flight") {
                if (
                    this.state.OppStageSelectedOption.value === 1 ||
                    this.state.OppStageSelectedOption.value === 2 ||
                    this.state.OppStageSelectedOption.value === 3 ||
                    this.state.OppStageSelectedOption.value === 4 ||
                    // this.state.OppStageSelectedOption.value === 5 ||
                    this.state.OppStageSelectedOption.value === 7 ||
                    this.state.OppStageSelectedOption.value === 8

                ) {
                    isValid = false;
                    Toast.fail("From 5th stage, you can only select 6th or 9th stage only.", 3000, () => { })
                }
            }

            if (this.state.oppDetails.OppStage === "6. Production Grade Build Complete" || this.state.oppDetails.OppStage === "7. Declined by Client" || this.state.oppDetails.OppStage === "8. Descoped") {
                if (
                    this.state.OppStageSelectedOption.value === 1 ||
                    this.state.OppStageSelectedOption.value === 2 ||
                    this.state.OppStageSelectedOption.value === 3 ||
                    this.state.OppStageSelectedOption.value === 4 ||
                    this.state.OppStageSelectedOption.value === 5 ||
                    //this.state.OppStageSelectedOption.value === 6 ||
                    this.state.OppStageSelectedOption.value === 7 ||
                    this.state.OppStageSelectedOption.value === 8 ||
                    this.state.OppStageSelectedOption.value === 9
                ) {
                    isValid = false;
                    Toast.fail("During this stage, you cannot make the stage changes.", 3000, () => { })
                }

            }

            if (this.state.oppDetails.OppStage === "9. On-hold") {
                if (
                    this.state.OppStageSelectedOption.value === 6 ||
                    this.state.OppStageSelectedOption.value === 7 ||
                    this.state.OppStageSelectedOption.value === 8
                ) {
                    isValid = false;
                    Toast.fail("From 9th stage, you can select 1st,2nd ,3rd ,4th or 5th stages only.", 3000, () => { })

                }
            }
        }

        // throw validation at stage 5 if solution owner is not selected
        if(this.state.OppStageSelectedOption && this.state.OppStageSelectedOption.value === 5){
            if (!this.state.SolnOwnerSelectedOption || this.state.SolnOwnerSelectedOption === "" || this.state.SolnOwnerSelectedOption === null || this.state.SolnOwnerSelectedOption === undefined || this.state.SolnOwnerSelectedOption.length === 0) {
              isValid = false;
              Toast.fail("Please select solution owner and re-submit.", 3000, () => { })
              styleStateObj.SolnOwnerSelectedOption = selectStyle(false);
              this.setState({
                selectStyle: styleStateObj,
              });
            } else {
              styleStateObj.SolnOwnerSelectedOption = selectStyle(true);
              this.setState({
                selectStyle: styleStateObj,
              });
            }
        }

        // Reason for stage change - validation starts here
        if(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8 || this.state.OppStageSelectedOption.value === 9)){
            if (!this.state.OppStageChange || this.state.OppStageChange === "" || this.state.OppStageChange === null || this.state.OppStageChange === undefined || this.state.OppStageChange.length === 0) {
                isValid = false;
                styleStateObj.OppStageChange = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.OppStageChange = textBoxValidation(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }
        }
        
        // Reason for stage change - validation starts here


        return isValid;
    }
    // validate function ends here


    // submit opp function starts here
    setSaveType = () => {
        let localSaveType = '';
        localSaveType = 'UpdateGenAIFieldsByMBB';
        return localSaveType;
    }

    setOpportunityState = () => {
        let localStateType = '';
        if (this.state.OppStageSelectedOption) {

            if (this.state.OppStageSelectedOption.value === 2 || this.state.OppStageSelectedOption.value === 4) {
                localStateType = "Active";
            }

            if (this.state.OppStageSelectedOption.value === 1 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8 || this.state.OppStageSelectedOption.value === 9) {
                localStateType = "Inactive";
            }

            if (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 5) {
                if (this.state.OppState === PENDING_APPROVAL_FROM_MBB) {
                    if (this.state.oppApprovalSelectedOption && this.state.oppApprovalSelectedOption.value === 1) {
                        localStateType = PENDING_APPROVAL_FROM_UNIT_SPOC;
                    }
                    // if (this.state.unitSPOCApprovalRequired) {
                    //     if (this.state.oppApprovalSelectedOption && this.state.oppApprovalSelectedOption.value === 1) {
                    //         localStateType = "Pending Approval from Unit SPOC";
                    //     }
                    // } else {
                    //     localStateType = "Active";
                    // }
                } else {
                    localStateType = "Active";
                }
            }

            if (this.state.OppStageSelectedOption.value === 6) {
                if (this.state.OppState === PENDING_APPROVAL_FROM_MBB) {
                    if(this.state.oppDetails.SolnOwnerApproval === "Approved"){
                        localStateType = PENDING_APPROVAL_FROM_CENTRAL_TEAM
                    }
                    if(this.state.oppDetails.SolnOwnerApproval === "Rejected"){
                        localStateType = "Active"
                    }
                    if(this.state.oppDetails.SolnOwnerApproval === "" || this.state.oppDetails.SolnOwnerApproval === null || this.state.oppDetails.SolnOwnerApproval === undefined){
                        localStateType = PENDING_APPROVAL_FROM_SOLUTION_OWNER
                    }
                }else{
                    if(this.state.oppDetails.SolnOwnerApproval === "Approved"){
                        localStateType = PENDING_APPROVAL_FROM_CENTRAL_TEAM
                    }
                    if(this.state.oppDetails.SolnOwnerApproval === "Rejected"){
                        localStateType = "Active"
                    }
                    if(this.state.oppDetails.SolnOwnerApproval === "" || this.state.oppDetails.SolnOwnerApproval === null || this.state.oppDetails.SolnOwnerApproval === undefined){
                        localStateType = PENDING_APPROVAL_FROM_SOLUTION_OWNER
                    }
                }
            }
        }
        
        return localStateType;
    }

    setAction = () => {
        let localActionType = "BI Approved"
        return localActionType;
    }

    setUpdaterRole = () => {
        let role = 'MBB';
        return role;
    }
    viewDone = () => {
        this.props.history.push("/view-opportunities");
    }


    updateOpp = (e) => {
        if (this.state.isUserMBB) {
            if (this.validate()) {
                if (window.confirm("Are you sure you want to update the opportunity?")) {
                    Toast.loading("updating opportunity...", () => { });
                    let filteredBIData = this.state.businessImpactValueArray && this.state.businessImpactValueArray.filter(
                        function (e) {
                            return e !== null && e !== undefined;
                        }
                    );
                    let param = {
                        SaveType: 'UpdateGenAIFieldsByMBB',
                        isItIdeaOrGenAI: "GenAI",
                        OppName: this.state.OppName,
                        RecID: this.state.RecID,
                        RecIDAlpha: this.state.RecIDAlpha,

                        ModifiedBy: this.sessionData.profile.oHRId ? this.sessionData.profile.oHRId : "",
                        PlanDiscussDate: this.state.PlanDiscussDate ? this.state.PlanDiscussDate : "",
                        DiscussEstDate: this.state.DiscussEstDate ? this.state.DiscussEstDate : "",

                        Account: this.state.Account,
                        Unit: this.state.Unit,
                        MangingDept: this.state.MangingDept,
                        Vertical: this.state.Vertical,
                        Segment: this.state.Segment,
                        Archetype: this.state.Archetype,
                        //AccFTE: this.state.AccFTE,


                        UnitOHR: this.state.UnitOHR,
                        UnitOHRName: this.state.UnitOHRName,
                        SpocOHR_1: this.state.SpocOHR_1,
                        Spoc_1: this.state.Spoc_1,
                        SpocOHR_2: this.state.SpocOHR_2,
                        Spoc_2: this.state.Spoc_2,
                        BBOHR: this.state.BBOHR,
                        BB: this.state.BB,
                        MBBOHR: this.state.MBBOHR,
                        MBB: this.state.MBB,
                        SolnOwnerOHR: this.state.SolnOwnerOHR,
                        SolnOwner: this.state.SolnOwner,

                        ServiceLine: this.state.ServiceLine,
                        ServiceLineMain: this.state.ServiceLineMain ? this.state.ServiceLineMain.label:"",
                        Process_L2: this.state.Process_L2,

                        ClientDtls: this.state.ClientDtls,
                        Problem: this.state.Problem,
                        Solution: this.state.Solution,
                        OverallFTE: this.state.OverallFTE,
                        OverallSOP: this.state.OverallSOP,

                        CXO: this.state.CXO,
                        LeadHelp: this.state.LeadHelp,
                        IsProposalShared: this.state.IsProposalSharedSelectedOption ? this.state.IsProposalSharedSelectedOption.label : "",
                        SolnEnviron: this.state.SolnEnviron.label,
                        SolnFund: this.state.SolnFund.label,
                        ClientDollar: this.state.ClientDollar ? this.state.ClientDollar : 0,
                        GenpactDollar: this.state.GenpactDollar ? this.state.GenpactDollar : 0,


                        IsSOWScope: this.state.IsSOWScope.label,
                        IsSOWSigned: this.state.IsSOWSigned.label,

                        SOWSubDate: this.state.SOWSubDate ? this.state.SOWSubDate : null,
                        SOWCloseDate: this.state.SOWCloseDate ? this.state.SOWCloseDate : null,
                        CompletionDate: this.state.CompletionDate ? this.state.CompletionDate : null,

                        TechOHR: this.state.TechOHR,
                        Tech: this.state.Tech,
                        FunctionalOHR: this.state.FunctionalOHR,
                        Functional: this.state.Functional,
                        TransitionLed: this.state.TransitionLed ? this.state.TransitionLed.label : "",
                        Knowledge: this.state.Knowledge,
                        Algo: this.state.Algo,
                        Data: this.state.Data,
                        Infra: this.state.Infra,
                        ValueCreated: this.state.ValueCreated,

                        FTEUsage: this.state.FTEUsage,

                        IsHero: this.state.IsHero,
                        Offering_1: this.state.Offering_1,
                        Offering_2: this.state.Offering_2 ? this.state.Offering_2 : "",
                        Offering_2_Other:this.state.Offering_1 && this.state.Offering_1 === "Bespoke" ? (this.state.Offering_2_Other ? this.state.Offering_2_Other : "") : "",

                        Cohort: this.state.CohorSelectedOption ? this.state.CohorSelectedOption.label : "",
                        OppStage: this.state.OppStageSelectedOption ? this.state.OppStageSelectedOption.label : "",
                        OppState: this.setOpportunityState(),

                        Action: this.setAction(),
                        UpdaterOHR: this.sessionData.profile.oHRId ? this.sessionData.profile.oHRId : "",
                        Updater: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
                        UpdaterRole: this.setUpdaterRole(),

                        SolnOwnerApproval: this.state.oppApprovalSelectedOption ? this.state.oppApprovalSelectedOption.label : "",
                        SolnOwnerComment: this.state.SolnOwnerComment,

                        OppApproval: this.state.oppApprovalSelectedOption ? this.state.oppApprovalSelectedOption.label : "",
                        Comment: this.state.Comment,

                        
                        CentTeamOHR: this.state.oppDetails.CentTeamOHR ? this.state.oppDetails.CentTeamOHR : "",
                        CentTeam: this.state.oppDetails.CentTeam ? this.state.oppDetails.CentTeamOHR : "",


                        OLINum: this.state.OLINum ? "OLI-".concat(this.state.OLINum) : "",
                        OppID: this.state.OppID ? "Opp-".concat(this.state.OppID) : "",

                        
                        //Action: "BIApproved",
                        UpdaterRole: "MBB",
                        
                        VDType: this.state.impactOptionSelected ? this.state.impactOptionSelected.label : this.state.oppDetails.VDType,
                        businessImpactValueArray: filteredBIData,
                        IsVDMonetizable: this.state.monetizableProjectSelectedOption ? this.state.monetizableProjectSelectedOption.label : "",
                        MonetizationStatus: this.state.monetizationStatusSelectedOption ? this.state.monetizationStatusSelectedOption.label : "",
                        FinalImpactDollar: this.state.finalImpactDollar ? this.state.finalImpactDollar : "",
                        FinalImpactFTE: this.state.finalImpactFTE ? this.state.finalImpactFTE : "",
                        FinalImpactDollarYear:this.state.FinalImpactDollarYear ? this.state.FinalImpactDollarYear : "",
                        FinalImpactFTEYear:this.state.FinalImpactFTEYear ? this.state.FinalImpactFTEYear : "",

                        // Client details
                        CustName: this.state.CustName ? this.state.CustName : "",
                        CustDesgn: this.state.CustDesgn ? this.state.CustDesgn : "",
                        CustSignOffDate: this.state.CustSignOffDate ? this.state.CustSignOffDate : "",
                        
                        // GOL details
                        GOLNameVD: this.state.GOLNameVD ? this.state.GOLNameVD : "",
                        GOLOHRVD: this.state.GOLOHRVD ? this.state.GOLOHRVD : "",
                        
                        // FPNA Details
                        FPnAName: this.state.FPnAName ? this.state.FPnAName : "",
                        FPnAOHR: this.state.FPnAName ? this.state.FPnAOHR : "",

                        // GOL OHR Monetization
                        GOLOHRMonetization:this.state.GOLOHRMonetization ? this.state.GOLOHRMonetization :"",
                        GOLNameMonetization:this.state.GOLNameMonetization ? this.state.GOLNameMonetization :"",

                        // FPNAOHR Monetization
                        FPnAOHRMonetization: this.state.FPnAOHRMonetization ? this.state.FPnAOHRMonetization : "",
                        FPnANameMonetization: this.state.FPnAOHRMonetization ? this.state.FPnANameMonetization : "",
                        SowID: this.state.SowID ? this.state.SowID : "",


                        // stage 6 fields for sustenenace
                        ProcessUsers: this.state.ProcessUsers,
                        DailyUsers: this.state.DailyUsers,
                        WeeklyUsers: this.state.WeeklyUsers ,
                        WeeklyTrans: this.state.WeeklyTrans ,
                        AccessUsers: this.state.AccessUsers ,
                        Upvotes: this.state.Upvotes ,
                        SolnTrans: this.state.SolnTrans ,
                        UpvotesPercent: this.state.UpvotesPercent,
                        Accuracy:this.state.Accuracy,
                        UptimePercent:this.state.UptimePercent,

                        OppStageChange:this.state.OppStageChange,
                        
                        GOLOHR:this.state.GOLOHR,
                        GOL:this.state.GOL,
                    }
                    console.log("param =>", param)
                    console.log(JSON.stringify(param))
                    let finalJsonData = Checksum(param);
                    let str1 = finalJsonData.slice(0, 15);
                    let str2 = finalJsonData.slice(15, 30);
                    let str3 = finalJsonData.slice(30, 40);
                    let str4 = finalJsonData.slice(-10);
                    let finalData = str3 + str1 + str4 + str2;
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.accessToken
                        },
                        body: JSON.stringify(param)
                    };
                    fetch(`${SUBMIT_GENAI_URL}Checksum=${finalData}`, requestOptions)
                        .then(response => response.json())
                        .then(data => {
                            if (data.message === "Opportunity value delivery succesfully Updated") {
                                Toast.hide();
                                Toast.info("Opportunity updated successfully!", 3000, () => {
                                    window.location = "/gen-ai-mbb-approval-queue";
                                })
                            }
                        })
                        .catch(error => {
                            //reject(error);
                        });


                }
            } else {
                if (!this.isExceedlimit) {
                    Toast.fail('Required fields are missing or have invalid values. Please correct and try again.', 3000, () => { });
                }
            }
        } else {
            Toast.fail('Only User assigned with role of Master Blackbelt of this opportunity can approve.', 5000, () => {
                return false;
            });
        }

    }
    // submit opp function ends here



    cancelOpp = () => {
        if (window.confirm("You will lose all entered data on this page if you click on cancel.")) {
            this.props.history.push("/home");
        }
    }

    raiseFlag = () => {

    }

    handleAuditLog = () => {
        this.setState({ auditLog: true }, () => { })
    }
    formatDateStringWithTime(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        });
    }



    handleParentIDChange = (e) => {

        this.setState({ ParentIDAlpha: e }, () => {

        })
    }


    renderServiceLineOptions = () => {
        let options = [];
        const uniqueServiceLineList = [...new Map(this.state.serviceLineList.map(item => [item.ServiceLineName, item])).values()];
        uniqueServiceLineList.map((item) => {
            options.push({ value: item.ServiceLineId, label: item.ServiceLineName });
            return true;
        });
        return options;

    }

    renderSubServiceLineOptions = () => {
        let options = [];
        if (this.state.ServiceLine) {
            let localServiceLine = this.state.ServiceLine.replace(/[|,]/g, ",")
            localServiceLine = localServiceLine.split(",");
            this.state.serviceLineList && this.state.serviceLineList.map((item) => {
                localServiceLine.map((local) => {
                    if (local === item.ServiceLineName) {
                        options.push({ value: item.SubServiceLineId, label: item.SubServiceLineName });
                        return true;
                    }
                    if (local === "All") {
                        options.push({ value: item.SubServiceLineId, label: item.SubServiceLineName });
                        return true;
                    }
                })
                return true;
            });
        }
        return options;
    }

    // ... auto fetch functional owner function starts here
    handleFunctionalOhronCut = (event) => {
        if (event.target.value.length === 9) {
            this.handleFunctionalOhrChange()
        } else if (event.target.value.length === 0) {
            this.setState({ FunctionalOHR: "", Functional: "" }, () => { })
            return null
        }
        else {
            Toast.fail("Functional owner OHR should be exactly of 9 digits", 3000, () => { })
            this.setState({ Functional: "" }, () => { })
        }
    }

    handleFunctionalOhrChange = (event) => {
        var numbers = /^[0-9]+$/;
        if (event !== null && event !== undefined) {
            if (event.target.value.match(numbers) && event.target.value.length !== 0) {
                if (event.target.value.length === 9) {
                    let localFunctionalOhr = event.target.value.trim();
                    this.setState({ FunctionalOHR: localFunctionalOhr }, () => { })
                    if (localFunctionalOhr) {
                        this.fetchSubmitFunctionalOHRDetails(localFunctionalOhr)
                    }
                }
                else {
                    this.setState({ FunctionalOHR: "", Functional: "" }, () => { })
                }
            } else {
                this.setState({ FunctionalOHR: "", Functional: "" }, () => { })
            }
        }
    }
    fetchSubmitFunctionalOHRDetails = async (localFunctionalOhr) => {
        Toast.loading("Fetching Functional Owner Full Name Details. Please wait...", () => { });
        try {
            const param = {
                EntityType: "SearchOHR",
                EntityName: localFunctionalOhr
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.accessToken
                },
                body: JSON.stringify(param)
            };
            const response = await fetch(GET_METADATA, requestOptions);
            const data = await response.json();
            console.log(data)
            let requestByName = data.oUser ? data.oUser[0].DisplayName : null
            if (requestByName === "") {
                requestByName = data.PowerAppUsers ? (data.PowerAppUsers.length > 0 ? data.PowerAppUsers[0].pg_username : "") : "";
            }
            if (requestByName === "" || requestByName === null || !requestByName) {
                Toast.fail("We could not find Functional Owner full name details based on the entered OHR. Please check and re-enter.", 5000, () => { });
                this.setState({ Functional: "" }, () => { })
            }
            else {
                setTimeout(() => { }, 2000);
                let styleStateObj = this.state.selectStyle;
                styleStateObj.FunctionalOHR = textBoxValidation(true);
                styleStateObj.Functional = textBoxValidation(true);
                this.setState({
                    Functional: requestByName,
                    selectStyle: styleStateObj,
                });
            }
            Toast.hide();
        } catch (error) {
            Toast.hide();
            Toast.fail('Please enter a valid Functional Owner OHR', 5000, () => {
                console.log(JSON.stringify(error));
            });
            let styleStateObj = this.state.selectStyle;
            styleStateObj.FunctionalOHR = textBoxValidation(false);
            styleStateObj.Functional = textBoxValidation(false);
            this.setState({
                selectStyle: styleStateObj,
            });
            this.setState({ Functional: "", FunctionalOHR: "" }, () => { })
        }
    };
    //.. auto fetch function owner function ends here



    // ... auto fetch technical owner function starts here
    handleTechnicalOhronCut = (event) => {
        if (event.target.value.length === 9) {
            this.handleTechnicalOhrChange()
        } else if (event.target.value.length === 0) {
            this.setState({ TechOHR: "", Tech: "" }, () => { })
            return null
        }
        else {
            Toast.fail("Technical owner OHR should be exactly of 9 digits", 3000, () => { })
            this.setState({ Tech: "" }, () => { })
        }
    }

    handleTechnicalOhrChange = (event) => {
        var numbers = /^[0-9]+$/;
        if (event !== null && event !== undefined) {
            if (event.target.value.match(numbers) && event.target.value.length !== 0) {
                if (event.target.value.length === 9) {
                    let localTechnicalOhr = event.target.value.trim();
                    this.setState({ TechOHR: localTechnicalOhr }, () => { })
                    if (localTechnicalOhr) {
                        this.fetchSubmitTechnicalOHRDetails(localTechnicalOhr)
                    }
                }
                else {
                    this.setState({ TechOHR: "", Tech: "" }, () => { })
                }
            } else {
                this.setState({ TechOHR: "", Tech: "" }, () => { })
            }
        }

    }
    fetchSubmitTechnicalOHRDetails = async (localTechnicalOhr) => {
        Toast.loading("Fetching Technical Owner Full Name Details. Please wait...", () => { });
        try {
            const param = {
                EntityType: "SearchOHR",
                EntityName: localTechnicalOhr
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.accessToken
                },
                body: JSON.stringify(param)
            };
            const response = await fetch(GET_METADATA, requestOptions);
            const data = await response.json();
            console.log(data)
            let requestByName = data.oUser ? data.oUser[0].DisplayName : null
            if (requestByName === "") {
                requestByName = data.PowerAppUsers ? (data.PowerAppUsers.length > 0 ? data.PowerAppUsers[0].pg_username : "") : "";
            }
            if (requestByName === "" || requestByName === null || !requestByName) {
                Toast.fail("We could not find Technical Owner full name details based on the entered OHR. Please check and re-enter.", 5000, () => { });
                this.setState({ Tech: "" }, () => { })
            }
            else {
                setTimeout(() => { }, 2000);
                let styleStateObj = this.state.selectStyle;
                styleStateObj.Tech = textBoxValidation(true);
                styleStateObj.TechOHR = textBoxValidation(true);
                this.setState({
                    Tech: requestByName,
                    selectStyle: styleStateObj,
                });

            }
            Toast.hide();
        } catch (error) {
            Toast.hide();
            Toast.fail('Please enter a valid Functional Owner OHR', 5000, () => {
                console.log(JSON.stringify(error));
            });
            let styleStateObj = this.state.selectStyle;
            styleStateObj.Tech = textBoxValidation(false);
            styleStateObj.TechOHR = textBoxValidation(false);
            this.setState({
                selectStyle: styleStateObj,
            });
            this.setState({ Tech: "", TechOHR: "" }, () => { })
        }
    };
    //.. auto fetch technical owner function ends here


    handleEnvironmentChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.SolnEnviron = selectStyle(true);
        this.setState({ SolnEnviron: e, selectStyle: styleStateObj }, () => { })
    }


    // Value delivery approval for MBB starts here
    renderMBBApprovaloptions = () => {
        let options = [];
        OPPAPPROVALOPTIONS.filter((item) => {
            if (item.value === 1) {
                options.push(item);
            }
        })
        return options
    }
    handleVDApprovalStatusChange = (e) => {
        if (this.state.isUserMBB) {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.oppApprovalSelectedOption = textBoxValidation(true);
            this.setState({
                selectStyle: styleStateObj,
                oppApprovalSelectedOption: e,
            });
        } else {
            Toast.fail("Only users assigned with role of MBB can approve the value delivery", 5000, () => { })
            return;
        }
    }
    handleVDCommentChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 500) {
            styleStateObj.Comment = textBoxValidation(true);
            this.setState({
                Comment: e,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Comment.replace(/'/g, "\\'");
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 500);
            this.setState({
                Comment: trimmedValue,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Comment.replace(/'/g, "\\'");
            });
        }
    }
    // Value delivery approval for MBB ends here



    // GenAI CR 383339 - changes starts here

    renderUnitSpocList = () => {
        let options = [];
        let result = [];
        this.state.rolesList && this.state.rolesList.map((item) => {
            if (this.state.MangingDept === "Tech Services" || this.state.MangingDept === "Vertical Managed" || this.state.MangingDept === ANALYTICS) {
                const userRole = item.pg_Role.pg_role;
                if (userRole === "Unit SPOC") {
                    options.push({ value: item.pg_ohr, label: item.pg_username });
                }
            } else {
                let userRole;
                if (typeof item.pg_Role === 'string') {
                    try {
                        userRole = JSON.parse(item.pg_Role).pg_role;
                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }
                } else if (typeof item.pg_Role === 'object') {
                    userRole = item.pg_Role.pg_role;
                }
                if (userRole === "Unit SPOC") {
                    options.push({ value: item.pg_ohr, label: item.pg_username });
                }
            }
            return true;
        });
        result = options.reduce((unique, index) => {
            if (!unique.some(obj => obj.label === index.label && obj.value === index.value)) {
                unique.push(index);
            }
            return unique;
        }, []);
        return result
    }


    handleUnitSPOCChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.UnitSPOCSelectedOption = selectStyle(true);
        this.setState({ UnitSPOCSelectedOption: e, UnitOHRName: e.label, UnitOHR: e.value, selectStyle: styleStateObj }, () => { 
            console.log("unit spoc change => ",this.state.UnitSPOCSelectedOption, this.state.UnitOHRName, this.state.UnitOHR)
        })
    }

    renderBlackBeltOptions = () => {
        let options = [];
        let result = [];
        this.state.rolesList && this.state.rolesList.map((item) => {
            if (this.state.MangingDept === "Tech Services" || this.state.MangingDept === "Vertical Managed" || this.state.MangingDept === ANALYTICS) {
                const userRole = item.pg_Role.pg_role;
                if (userRole === "BB") {
                    options.push({ value: item.pg_ohr, label: item.pg_username });
                }
            } else {
                let userRole;
                if (typeof item.pg_Role === 'string') {
                    try {
                        userRole = JSON.parse(item.pg_Role).pg_role;
                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }
                } else if (typeof item.pg_Role === 'object') {
                    userRole = item.pg_Role.pg_role;
                }
                if (userRole === "BB") {
                    options.push({ value: item.pg_ohr, label: item.pg_username });
                }
            }

            return true;
        });


        result = options.reduce((unique, index) => {
            if (!unique.some(obj => obj.label === index.label && obj.value === index.value)) {
                unique.push(index);
            }
            return unique;
        }, []);
        return result
    }



    handleBlackBeltNameChange = (e) => {
        if (parseInt(e.pg_ohr) === parseInt(this.state.oppDetails.CreatedBy)) {
            Toast.fail('Mentor cannot be the same as opportunity Owner/Requester. Please select a different Mentor.', 3000, () => {
                return;
            });
            return;
        }
        let styleStateObj = this.state.selectStyle;
        styleStateObj.BBSelectedOption = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            BBOHR: e.value,
            BB: e.label,
            BBSelectedOption: {
                value: e.value,
                label: e.label
            }
        },()=>{
            console.log("bb change =>",this.state.BBOHR,this.state.BB,this.state.BBSelectedOption)
        });
    }
    renderSpocList = () => {
        let options = [];
        let result = [];
        this.state.rolesList && this.state.rolesList.map((item) => {
            if (this.state.MangingDept === "Tech Services" || this.state.MangingDept === "Vertical Managed" || this.state.MangingDept === ANALYTICS) {
                const userRole = item.pg_Role.pg_role;
                if (userRole === "Opp SPOC") {
                    options.push({ value: item.pg_ohr, label: item.pg_username });
                }
            } else {
                let userRole;
                if (typeof item.pg_Role === 'string') {
                    try {
                        userRole = JSON.parse(item.pg_Role).pg_role;
                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }
                } else if (typeof item.pg_Role === 'object') {
                    userRole = item.pg_Role.pg_role;
                }

                if (userRole === "Opp SPOC") {
                    options.push({ value: item.pg_ohr, label: item.pg_username });
                }
            }
            return true;
        });
        result = options.reduce((unique, index) => {
            if (!unique.some(obj => obj.label === index.label && obj.value === index.value)) {
                unique.push(index);
            }
            return unique;
        }, []);
        return result
    }
    handleSPOC1Change = (e) => {
        if (this.state.SPOC_2SelectedOption && this.state.SPOC_2SelectedOption === e.label) {
            Toast.fail("Opportunity SPOC 1 cannot be the same as the Opportunity SPOC 2.", 3000, () => { })
            let styleStateObj = this.state.selectStyle;
            styleStateObj.SPOC_1SelectedOption = selectStyle(false);
            this.setState({ SPOC_1SelectedOption: [], Spoc_1: "", SpocOHR_1: "", selectStyle: styleStateObj }, () => { 
                console.log("handle spoc 1 change => ",this.state.SPOC_1SelectedOption, this.state.Spoc_1, this.state.SpocOHR_1)
            })
        }
        else {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.SPOC_1SelectedOption = selectStyle(true);
            this.setState({ SPOC_1SelectedOption: e, Spoc_1: e.label, SpocOHR_1: e.value, selectStyle: styleStateObj }, () => { 
                console.log("handle spoc 1 change => ",this.state.SPOC_1SelectedOption, this.state.Spoc_1, this.state.SpocOHR_1)
            })
        }
    }

    handleSPOC2Change = (e) => {
        if (this.state.oppDetails.Spoc_1 && this.state.oppDetails.Spoc_1 === e.label) {
            Toast.fail("Opportunity SPOC 2 cannot be the same as the Opportunity SPOC 1.", 3000, () => { })
            let styleStateObj = this.state.selectStyle;
            styleStateObj.SPOC_2SelectedOption = selectStyle(false);
            this.setState({ SPOC_2SelectedOption: [], Spoc_2: "", SpocOHR_2: "", selectStyle: styleStateObj}, () => { 
                console.log("spoc2 change =>",this.state.SPOC_2SelectedOption,this.state.Spoc_2,this.state.SpocOHR_2)
            })
            return false;
        } else {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.SPOC_2SelectedOption = selectStyle(true);
            this.setState({ SPOC_2SelectedOption: e, Spoc_2: e.label, SpocOHR_2: e.value, selectStyle: styleStateObj }, () => { 
                console.log("spoc2 change =>",this.state.SPOC_2SelectedOption,this.state.Spoc_2,this.state.SpocOHR_2)
            })
        }
    }



    renderSolOwnerList = () => {
        let options = [];
        this.state.rolesList && this.state.rolesList.map((item) => {
            if (this.state.MangingDept === "Tech Services" || this.state.MangingDept === "Vertical Managed" || this.state.MangingDept === ANALYTICS) {
                const userRole = item.pg_Role.pg_role;
                if (userRole === "Opp Solution Owner") {
                    options.push({ value: item.pg_ohr, label: item.pg_username });
                }
            } else {
                let userRole;
                if (typeof item.pg_Role === 'string') {
                    try {
                        userRole = JSON.parse(item.pg_Role).pg_role;
                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }
                } else if (typeof item.pg_Role === 'object') {
                    userRole = item.pg_Role.pg_role;
                }

                if (userRole === "Opp Solution Owner") {
                    options.push({ value: item.pg_ohr, label: item.pg_username });
                }
            }
            return true;
        });
        return options;
    }

    handleSolutionOwnerChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.SolnOwnerSelectedOption = selectStyle(true);
        this.setState({ SolnOwnerSelectedOption: e, SolnOwner: e.label, SolnOwnerOHR: e.value, selectStyle: styleStateObj}, () => { 
            console.log("solution owner change =>",this.state.SolnOwnerSelectedOption,this.state.SolnOwner,this.state.SolnOwnerOHR)
        })
    }

    handleReasonForStageChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 180) {
            styleStateObj.OppStageChange = textBoxValidation(true);
            this.setState({
                OppStageChange: e,
                selectStyle: styleStateObj,
            }, () => {
                this.state.OppStageChange.replace(/'/g, "\\'");
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 180);
            this.setState({
                OppStageChange: trimmedValue,
                selectStyle: styleStateObj,
            }, () => {
                this.state.OppStageChange.replace(/'/g, "\\'");
            });
        }
    }

    // GenAI CR 383339 - changes ends here



    // ... auto fetch GOL function starts here
    handleGOLOhronCut = (event) => {
        if (event.target.value.length === 9) {
            this.handleGOLOhrChange()
        } else if (event.target.value.length === 0) {
            this.setState({ GOLOHR: "", GOL: "" }, () => { })
            return null
        }
        else {
            Toast.fail("GOL OHR should be exactly of 9 digits", 3000, () => { })
            this.setState({ GOL: "" }, () => { })
        }
    }

    handleGOLOhrChange = (event) => {
        var numbers = /^[0-9]+$/;
        if (event !== null && event !== undefined) {
            if (event.target.value.match(numbers) && event.target.value.length !== 0) {
                if (event.target.value.length === 9) {
                    let localGOLOhr = event.target.value.trim();
                    this.setState({ GOLOHR: localGOLOhr }, () => { })
                    if (localGOLOhr) {
                        this.fetchSubmitGOLOHRDetails(localGOLOhr)
                    }
                }
                else {
                    this.setState({ GOLOHR: "", GOL: "" }, () => { })
                }
            } else {
                this.setState({ GOLOHR: "", GOL: "" }, () => { })
            }
        }
    }
    fetchSubmitGOLOHRDetails = async (localGOLOhr) => {
        Toast.loading("Fetching GOL Owner Full Name Details. Please wait...", () => { });
        try {
            const param = {
                EntityType: "OHR",
                EntityName: localGOLOhr
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.accessToken
                },
                body: JSON.stringify(param)
            };
            const response = await fetch(GET_METADATA, requestOptions);
            const data = await response.json();
            //console.log("sub band =>",data.DNAUsers.Table2[0].SubBand)
            console.log("sub band details =>", data.DNAUsers.Table2[0])
            if (data && data.DNAUsers) {
                if (data.DNAUsers.Table2[0].SubBand) {
                    if (data.DNAUsers.Table2[0].SubBand === "1" || data.DNAUsers.Table2[0].SubBand === "2" || data.DNAUsers.Table2[0].SubBand === "3") {
                        let requestByName = data ? data.DNAUsers ? data.DNAUsers.Table1[0].pg_username : null : null;
                        if (requestByName === "" || requestByName === null || !requestByName) {
                            Toast.fail("We could not find GOL full name details based on the entered OHR. Please check and re-enter.", 5000, () => { });
                            this.setState({ GOL: "" }, () => { })
                        }
                        else {
                            setTimeout(() => { }, 2000);
                            let styleStateObj = this.state.selectStyle;
                            styleStateObj.GOL = textBoxValidation(true);
                            styleStateObj.GOLOHR = textBoxValidation(true);
                            this.setState({ GOL: requestByName, selectStyle: styleStateObj }, () => { })
                        }
                        Toast.hide();
                    } else {
                        Toast.fail('GOL has to be either 1 or 2 or 3 band. Please check and re-enter.', 5000, () => { });
                    }
                }
            }
        } catch (error) {
            Toast.hide();
            Toast.fail('Please enter a valid GOL Owner OHR', 5000, () => {
                console.log(JSON.stringify(error));
            });
            this.setState({ GOL: "", GOLOHR: "" }, () => { })
        }
    };
  //.. auto fetch GOL function function ends here


    render() {
        //console.log(OPPMASTERSTAGES)

        const today = new Date().toISOString().split("T")[0];
        return (
            <section className="u-clearfix u-section-1 opp_create_page" id="sec-0f7f">
                <div className="u-clearfix u-sheet u-sheet-1" style={{ marginBottom: 20 }}>
                    <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
                        <div className="u-container-layout u-container-layout-1">
                            <div className="">
                                <div className="view_idea_top_header_btn_block">
                                    <div className="">
                                        {/* <button style={{ marginRight: "15px" }} className="u-active-custom-color-6 u-border-2 u-btn u-btn-round u-radius-6 update_parent_btn"
                                            onClick={this.showClusterModal}>Update Parent {this.state.RecID}
                                        </button> */}
                                    </div>
                                    <div className="">
                                        {/* <button style={{ marginRight: "15px" }} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                            onClick={this.raiseFlag}>Raise Flag
                                        </button> */}
                                    </div>
                                    <div className="">
                                        <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                            onClick={(e) => this.handleAuditLog(e)}
                                        //disabled={this.state.auditLogData && this.state.auditLogData.length === 0 ? false : true}
                                        >
                                            Audit Trail
                                        </button>
                                    </div>




                                </div>
                                <div className="text-left mt-4">
                                    {this.state.showHrefModal ? (
                                        <>
                                            <div className="custom_modal href_modal">
                                                <div className="modal_body href_modal_body">
                                                    <div className="href_modal_dialog">
                                                        <p>
                                                            To add a new category please raise a Helpmate
                                                            ticket, Click here to View SOP
                                                        </p>
                                                        <div className="href_modal_body_buttons">
                                                            <div className="btn-group href_modal_body_buttons">
                                                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3">
                                                                    <a
                                                                        href="https://genpactonline.sharepoint.com/:b:/r/sites/LeanDigitalTransformation/Documents/KM%20Page%20-%202021/Certification%20Guidelines,%20SOPs%20%26%20Handbooks/Certification%20guidelines,%20SOPs%20%26%20Handbooks/Systems%20%26%20Tools/Helpmate-Data%20Related%20Request/ProGEAR%20Value%20Delivery%20Category%20Addition%20SOP.pdf?csf=1&web=1&e=5kKbFg"
                                                                        style={{ color: "#FFF" }}
                                                                        target="_blank"
                                                                    >
                                                                        Click Here
                                                                    </a>
                                                                </button>
                                                                <button
                                                                    className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6"
                                                                    onClick={(e) =>
                                                                        this.setState({ showHrefModal: false })
                                                                    }
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                    {/* {this.state.clusterModal ? (
                                            <>
                                                <div className="cluster_modal_main_block">
                                                    <div className="custom_modal cluster_modal_block">
                                                        <div className="modal_body" style={{ width: "400px" }}>
                                                            <div className="row">
                                                                <div className="col-md-11">
                                                                    <h3 className="modal_title text-center">
                                                                        Update Parent
                                                                    </h3>
                                                                </div>
                                                                <div className="col-md-1">
                                                                    <button
                                                                        type="button"
                                                                        className="close text-right"
                                                                        onClick={(e) =>
                                                                            this.setState({ clusterModal: false })
                                                                        }
                                                                    >
                                                                        &times;
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="clustor_modal_fields_block">
                                                                <div className="clustor_modal_field">
                                                                    <label className="form-attribute">
                                                                        <span style={{ color: "red" }}>*</span> Project
                                                                        Type:
                                                                    </label>
                                                                    {this.state.displayParentAlpha ? (
                                                                        <>
                                                                            <input
                                                                                disabled={true}
                                                                                value={
                                                                                    this.state.projectTypeSelectedOption
                                                                                        ? this.state.projectTypeSelectedOption
                                                                                            .label
                                                                                        : ""
                                                                                }
                                                                                className="form-input"
                                                                                placeholder="Choose project type"
                                                                            />
                                                                        </>
                                                                    ) : (
                                                                        <Select
                                                                            isDisabled={
                                                                                this.state.enableClusterAction
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            isSearchable={false}
                                                                            options={projectType}
                                                                            value={this.state.projectTypeSelectedOption}
                                                                            styles={this.state.selectStyle.projectType}
                                                                            placeholder="Choose project type"
                                                                            onChange={this.changeProjectType}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="clustor_modal_field">
                                                                    <label className="form-attribute">
                                                                        <span style={{ color: "red" }}>*</span> Project
                                                                        ID:
                                                                    </label>

                                                                    {this.state.displayParentAlpha ? (
                                                                        <>
                                                                            <input
                                                                                value={
                                                                                    this.state.projectIDSelectedOption &&
                                                                                        this.state.projectIDSelectedOption.label
                                                                                        ? this.state.projectIDSelectedOption
                                                                                            .label
                                                                                        : ""
                                                                                }
                                                                                disabled={true}
                                                                                className="form-input"
                                                                                placeholder="Choose project ID"
                                                                            />
                                                                        </>
                                                                    ) : (
                                                                        <Select
                                                                            isSearchable
                                                                            onInputChange={
                                                                                this.handleClusterProjectInputChange
                                                                            }
                                                                            options={this.displayProjectID()}
                                                                            value={this.state.projectIDSelectedOption}
                                                                            styles={
                                                                                this.state.selectStyle
                                                                                    .projectIDSelectedOption
                                                                            }
                                                                            isDisabled={
                                                                                this.state.enableClusterAction
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            placeholder="Choose project ID"
                                                                            onChange={this.changeProjectID}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="cluster_modal_btn_group">
                                                                <button
                                                                    className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                                                    disabled={
                                                                        this.state.isClusterSaveDisabled ? true : false
                                                                    }
                                                                    onClick={this.saveParentLink}
                                                                >
                                                                    Save
                                                                </button>
                                                                <button
                                                                    className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6"
                                                                    onClick={this.removeParentFunc}
                                                                    style={{ marginRight: "15px" }}
                                                                    disabled={
                                                                        this.state.enableClusterAction ? false : true
                                                                    }
                                                                >
                                                                    Remove Parent
                                                                </button>
                                                                {this.state.displayParentAlpha ? (
                                                                    <>
                                                                        <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6 show_hierarchy_link_btn">
                                                                            <Link
                                                                                className="show_hierarchy_link_btn_anchor"
                                                                                to={`/showTree?ID=${this.state.ideaItem.IdeaId}`}
                                                                                target="_blank"
                                                                            >
                                                                                Show Hierarchy
                                                                            </Link>
                                                                        </button>
                                                                    </>
                                                                ) : (
                                                                    <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6 show_hierarchy_link_btn">
                                                                        Show Hierarchy
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : null} */}


                                </div>

                                <div className="gen_ai_audit_log">
                                    {
                                        this.state.auditLog
                                            ?
                                            <>
                                                <div className="custom_modal">
                                                    <div className="modal_body">
                                                        <div className="col-md-12">
                                                            <div className="">
                                                                <div className="row">
                                                                    <div className="col-md-10">
                                                                        <h3 className="modal_title">
                                                                            Audit Trail
                                                                        </h3>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <button type="button" className="close text-right" onClick={(e) => this.setState({ auditLog: false, })}>
                                                                            &times;
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="genai_popup_fields">
                                                                <div className="qc_responsive_comment_log">
                                                                    <table className='table-responsive table-bordered' style={{ width: "100%" }}>
                                                                        <thead className="">
                                                                            <tr>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Date & Time</label>
                                                                                </th>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>OHR</label>
                                                                                </th>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Name</label>
                                                                                </th>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Role</label>
                                                                                </th>
                                                                                {/* <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Action</label>
                                                                                </th> 
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Opportunity Stage</label>
                                                                                </th>*/}
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Opportunity State</label>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {this.state.auditLogData && this.state.auditLogData.map((item, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td className="text-center audit_log_sub_fields_value_block">
                                                                                            <label>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                        </td>
                                                                                        <td className=" text-center audit_log_sub_fields_value_block">
                                                                                            <label>
                                                                                                {item.UpdaterOHR}
                                                                                            </label>
                                                                                        </td>
                                                                                        <td className=" text-center audit_log_sub_fields_value_block">
                                                                                            <label>
                                                                                                {item.Updater}
                                                                                            </label>
                                                                                        </td>
                                                                                        <td className=" text-center audit_log_sub_fields_value_block">
                                                                                            <label>
                                                                                                {item.UpdaterRole}
                                                                                            </label>
                                                                                        </td>
                                                                                        {/* <td className=" text-center audit_log_sub_fields_value_block">
                                                                                            <label>
                                                                                                {item.Action}
                                                                                            </label>
                                                                                        </td>
                                                                                        <td className="text-center audit_log_sub_fields_value_block">
                                                                                            <label>
                                                                                                {item.OppStage}
                                                                                            </label>
                                                                                        </td> */}
                                                                                        <td className=" text-center audit_log_sub_fields_value_block">
                                                                                            <label>
                                                                                                {item.OppState}
                                                                                            </label>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }

                                </div>

                                <div className="gen_ai_audit_log">
                                    {
                                        this.state.mbbVDLogModal && this.state.auditLogData
                                            ?
                                            <>
                                                <div className="custom_modal">
                                                    <div className="modal_body">
                                                        <div className="col-md-12">
                                                            <div className="">
                                                                <div className="row">
                                                                    <div className="col-md-10">
                                                                        <h3 className="modal_title">
                                                                            Value delivery approval comment log
                                                                        </h3>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <button type="button" className="close text-right" onClick={(e) => this.setState({ mbbVDLogModal: false, })}>
                                                                            &times;
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="genai_popup_fields">
                                                                <div className="qc_responsive_comment_log">
                                                                    <table className='table-responsive table-bordered' style={{ width: "100%" }}>
                                                                        <thead className="">
                                                                            <tr>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Date & Time</label>
                                                                                </th>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>OHR</label>
                                                                                </th>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Name</label>
                                                                                </th>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Opportunity State</label>
                                                                                </th>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Action</label>
                                                                                </th>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Comment</label>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {this.state.auditLogData && this.state.auditLogData.map((item, index) => {
                                                                                if (item.Action === "BI Approved") {
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td className="text-center audit_log_sub_fields_value_block">
                                                                                                <label>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                            </td>
                                                                                            <td className=" text-center audit_log_sub_fields_value_block">
                                                                                                <label>
                                                                                                    {item.UpdaterOHR}
                                                                                                </label>
                                                                                            </td>
                                                                                            <td className=" text-center audit_log_sub_fields_value_block">
                                                                                                <label>
                                                                                                    {item.Updater}
                                                                                                </label>
                                                                                            </td>
                                                                                            <td className=" text-center audit_log_sub_fields_value_block">
                                                                                                <label>
                                                                                                    {item.OppState}
                                                                                                </label>
                                                                                            </td>
                                                                                            <td className=" text-center audit_log_sub_fields_value_block">
                                                                                                <label>
                                                                                                    {item.Action}
                                                                                                </label>
                                                                                            </td>
                                                                                            <td className=" text-center audit_log_sub_fields_value_block">
                                                                                                <label>
                                                                                                    {item.Comment}
                                                                                                </label>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                }

                                                                            }
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                            <h4 style={{ marginTop: 45, marginBottom: -35, textAlign: 'center', color: '#00AECF', fontFamily: 'Arial', fontSize: 22 }}>MBB review opportunity: {this.state.RecIDAlpha}</h4>
                            <div className="u-align-left u-container-style u-expanded-width u-group u-shape-rectangle u-group-2">
                                <div className="u-container-layout u-container-layout-2">
                                    <div className="main_fields_block">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute"> Submitter OHR</label>
                                                    <input tabIndex={1} className="form-input" type="text" value={this.state.CreatedBy} disabled />
                                                </div>
                                                <div className="genai_fields">
                                                    <label className="form-attribute"> Submitter Name</label>
                                                    <input tabIndex={2} className="form-input" type="text" value={this.state.CreatedName} disabled />
                                                </div>
                                                {/* <div className="genai_fields">
                                                    <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> Parent Opportunity Id</label>
                                                    <input tabIndex={3} className="textarea-css form-attribute" type="text" value={this.state.ParentIDAlpha} onChange={(e)=>this.handleParentIDChange(e.target.value)} />
                                                </div> */}
                                                <div className="genai_fields">
                                                    <label className="form-attribute" > Opportunity Name</label>
                                                    <input tabIndex={4} className="form-input" type="text" disabled value={this.state.OppName} />
                                                </div>
                                                {
                                                    this.state.ModifiedDate
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" > Last Modified on</label>
                                                                <input tabIndex={4} className="form-input" type="text" disabled value={this.state.ModifiedDate} />
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute"> Account</label>
                                                    <input disabled placeholder="Account" value={this.state.Account} className="form-input" />
                                                </div>
                                                <div className="genai_fields">
                                                    <label className="form-attribute" > Vertical</label>
                                                    <input tabIndex={8} className="form-input" type="text" disabled value={this.state.Vertical} placeholder="Vertical" />
                                                </div>
                                                <div className="genai_fields">
                                                    <label className="form-attribute"> Owning Units </label>
                                                    <input tabIndex={6} className="form-input" type="text" disabled value={this.state.Unit} placeholder="Unit" />
                                                </div>
                                                {
                                                    this.state.ModifiedBy
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" > Last Modified by OHR</label>
                                                                <input tabIndex={4} className="form-input" type="text" disabled value={this.state.ModifiedBy} />
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" > Managing Entity</label>
                                                    <input tabIndex={7} placeholder="Managing Entity" value={this.state.MangingDept ? this.state.MangingDept : "NA"} className="form-input" disabled />
                                                </div>
                                                <div className="genai_fields">
                                                    <label className="form-attribute"> Segment</label>
                                                    <input tabIndex={9} className="form-input" type="text" disabled value={this.state.Segment} placeholder="Segment" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute"> Archetype</label>
                                                    <input tabIndex={10} className="form-input" type="text" disabled value={this.state.Archetype} placeholder="Archetype" />
                                                </div>
                                                <div className="genai_fields">
                                                    <label className="form-attribute" > FTE Count (inclusive of all regions)</label>
                                                    <input placeholder="Number of FTEs" disabled value={this.state.AccFTE} type='number' className="form-input" />
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" ><label className="mandatery_asterisk">*</label> Unit SPOC</label>
                                                        <Select isSearchable={true} placeholder="Choose Unit SPOC" options={this.renderUnitSpocList()} onChange={(e) => this.handleUnitSPOCChange(e)} styles={this.state.selectStyle.UnitSPOCSelectedOption} value={this.state.UnitSPOCSelectedOption} isDisabled={this.state.isUserUnitSPOC ? true : false} classNamePrefix={this.state.isUserUnitSPOC ? "an-simple-select" : ""} />
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp; </label> Black Belt</label>
                                                        <Select isSearchable={true} placeholder="Choose Blackbelt" options={this.renderBlackBeltOptions()} onChange={(e) => this.handleBlackBeltNameChange(e)} styles={this.state.selectStyle.BBSelectedOption} value={this.state.BBSelectedOption} 
                                                            isDisabled={this.state.isUserBB ? true : false} 
                                                            classNamePrefix={this.state.isUserBB ? "an-simple-select" : ""}    
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" ><label className="mandatery_asterisk">*</label> Opportunity SPOC - 1</label>
                                                        <Select isSearchable={true} placeholder="Choose Opportunity SPOC - 1" options={this.renderSpocList()} onChange={(e) => this.handleSPOC1Change(e)} styles={this.state.selectStyle.SPOC_1SelectedOption} value={this.state.SPOC_1SelectedOption} isDisabled={this.state.isUserOppSPOC ? true : false} classNamePrefix={this.state.isUserOppSPOC ? "an-simple-select" : ""} />
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" > Master Black Belt</label>
                                                        <input placeholder="Master Blackbelt" disabled value={this.state.MBB} className="form-input" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute"><label className="mandatery_asterisk">&nbsp;</label> Opportunity SPOC - 2</label>
                                                        <Select isSearchable={true} placeholder="Choose Opportunity SPOC - 2" options={this.renderSpocList()} onChange={(e) => this.handleSPOC2Change(e)} styles={this.state.selectStyle.SPOC_2SelectedOption} value={this.state.SPOC_2SelectedOption} 
                                                            isDisabled={this.state.isUserOppSPOC2 ? true : false} classNamePrefix={this.state.isUserOppSPOC2 ? "an-simple-select" : ""}    
                                                        />
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" > GOL OHR</label>
                                                        {/* <input type="text" value={this.state.oppDetails.GOLOHR} disabled className="form-input" placeholder='GOL OHR' /> */}
                                                        <input pattern="\d*" maxLength="9"
                                                            placeholder='GOL OHR'
                                                            defaultValue={this.state.GOLOHR}
                                                            onChange={(e) => this.handleGOLOhrChange(e)}
                                                            onCut={(e) => this.handleGOLOhronCut(e)}
                                                            type="number"
                                                            onKeyDown={(evt) => (evt.key === '.' || evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault()}
                                                            onWheel={(e) => e.target.blur()}
                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.GOLOHR }}
                                                            className="textarea-css form-attribute hide_numeric_arrows"
                                                        />
                                                    </div>
                                                </div>


                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute">
                                                            <label className="mandatery_asterisk">
                                                                {this.state.OppStageSelectedOption && this.state.OppStageSelectedOption.value === 5 ? <label className="mandatery_asterisk">*&nbsp;</label>: " "}
                                                            </label> 
                                                            Opportunity Solution Owner
                                                        </label>
                                                        <Select isSearchable={true} placeholder="Choose Solution Owner" styles={this.state.selectStyle.SolnOwnerSelectedOption} onChange={(e) => this.handleSolutionOwnerChange(e)} value={this.state.SolnOwnerSelectedOption} options={this.renderSolOwnerList()} 
                                                            isDisabled={this.state.isUserSolOwner || this.state.oppDetails.OppState === PENDING_APPROVAL_FROM_SOLUTION_OWNER ? true : false} 
                                                            classNamePrefix={this.state.isUserSolOwner || this.state.oppDetails.OppState === PENDING_APPROVAL_FROM_SOLUTION_OWNER ? "an-simple-select" : ""}    
                                                        />
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute"> GOL</label>
                                                        <input className="form-input" type="text" disabled value={this.state.GOL} placeholder="GOL Name" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>



                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" ><label className="mandatery_asterisk">*</label> Opportunity Stage</label>
                                                    <Select 
                                                        isSearchable={true}
                                                        options={this.renderOppMasterStages()}
                                                        placeholder="Select Opportunity Stage"
                                                        onChange={(e) => this.handleOppStageChange(e)}
                                                        value={this.state.OppStageSelectedOption}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >
                                                            {/* <label className="mandatery_asterisk">&nbsp;</label>  */}
                                                            Opportunity State</label>
                                                        <input disabled value={this.state.OppState} className="form-input" />
                                                    </div>
                                                </div>


                                            </div>
                                            <div className="col-md-4">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> Cohort</label>
                                                    <Select 
                                                        placeholder="Cohort Value" 
                                                        value={this.state.CohorSelectedOption} 
                                                        onChange={(e) => this.handleCohortChange(e)} 
                                                        options={this.renderCohort()} 
                                                        isDisabled={ this.state.OppState && this.state.OppState === "Completed" ? true : false}
                                                        classNamePrefix={ this.state.OppState && this.state.OppState === "Completed" ? "an-simple-select" : ""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                            </div>
                                        </div>
                                    </div>



                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="">
                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : " "}&nbsp;</label>
                                                                        Service Line
                                                                    </label>
                                                                    <Select
                                                                        classNamePrefix={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) ? "an-simple-select" : ""}
                                                                        isSearchable={true}
                                                                        placeholder="Select service line"
                                                                        value={this.state.ServiceSelectedOption}
                                                                        styles={this.state.selectStyle.ServiceSelectedOption}
                                                                        options={this.renderServiceLineOptions()}
                                                                        onChange={(e) => this.handleServiceLineChange(e)}
                                                                        isMulti={true}
                                                                        isDisabled={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8)) ? true : false}
                                                                    />
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </div>

                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" >
                                                        <label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : " "}&nbsp;</label>
                                                        Predominant Service Line
                                                    </label>
                                                    <Select
                                                        isSearchable={true}
                                                        placeholder="Select predominant service line"
                                                        value={this.state.ServiceLineMain}
                                                        styles={this.state.selectStyle.ServiceLineMain}
                                                        options={this.renderPredominantServiceLine()}
                                                        onChange={(e) => this.handlePredominantSeriveLineChange(e)}
                                                        isDisabled={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8 ) || (this.state.ServiceSelectedOption && this.state.ServiceSelectedOption.length === 1)) ? true : false}
                                                        classNamePrefix={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8 ) || (this.state.ServiceSelectedOption && this.state.ServiceSelectedOption.length === 1)) ? "an-simple-select" : ""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : " "}&nbsp;</label>
                                                                    Area / Process</label>
                                                                <Select
                                                                    classNamePrefix={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) ? "an-simple-select" : ""}
                                                                    isSearchable={true}
                                                                    styles={this.state.selectStyle.Process_L2SelectedOption}
                                                                    placeholder="Select Area/Process"
                                                                    onChange={(e) => this.handleProcessChange(e)}
                                                                    value={this.state.Process_L2SelectedOption}
                                                                    isMulti={true}
                                                                    options={this.renderSubServiceLineOptions()}
                                                                    isDisabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) ? true : false}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="col-md-3"></div>
                                        </div>
                                    </div>


                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : " "}&nbsp;</label>
                                                                    GenAI Offering L1</label>
                                                                <div className="">
                                                                    <Select
                                                                        classNamePrefix={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) ? "an-simple-select" : ""}
                                                                        placeholder="GenAI Offering L1 value" onChange={(e) => this.heroOfferingL1Change(e)}
                                                                        isDisabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) ? true : false}
                                                                        value={this.state.Offering_1SelectedOption} options={this.renderGenAIOfferingL1()}
                                                                        styles={this.state.selectStyle.Offering_1SelectedOption}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }
                                                

                                            </div>
                                            <div className="col-md-3">

                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? "*" : " "}&nbsp;</label>
                                                                    GenAI Offering L2</label>
                                                                <div className="">
                                                                    {
                                                                        this.state.Offering_1SelectedOption && this.state.Offering_1SelectedOption.label === "Bespoke"
                                                                            ?
                                                                            <>
                                                                                <input 
                                                                                    placeholder="Provide GenAI Offering bespoke value"  
                                                                                    onChange={(e) => this.heroOfferingBeSpokeChange(e.target.value)}
                                                                                    className={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) ? "form-input" : "textarea-css form-attribute"}
                                                                                    value={this.state.Offering_2_Other}
                                                                                    disabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) ? true : false}
                                                                                    style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Offering_2_Other }}
                                                                                    title={this.state.Offering_2_Other}
                                                                                />
                                                                            </>
                                                                            :
                                                                            <Select
                                                                                placeholder="GenAI Offering L2 value" 
                                                                                onChange={(e) => this.heroOfferingL2Change(e)}
                                                                                value={this.state.Offering_2SelectedOption} 
                                                                                options={this.renderGenAIOfferingL2()}
                                                                                styles={this.state.selectStyle.Offering_2SelectedOption}
                                                                                isDisabled={((this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8)) ? true : false}
                                                                                classNamePrefix={((this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8)) ? "an-simple-select" : ""}
                                                                            />

                                                                    }
                                                                    
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }
                                                
                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : " "}&nbsp;</label>
                                                                        Is a hero Product ?
                                                                    </label>
                                                                    <input
                                                                        className="form-input"
                                                                        placeholder="if its hero product"
                                                                        value={this.state.IsHero}
                                                                        disabled={true}
                                                                    />
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>

                                            </div>
                                            <div className="col-md-3"></div>
                                        </div>
                                    </div>



                                    <div className={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? "gapping_class" : ""}>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="">
                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >
                                                                        <label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? "*" : " "}&nbsp;</label>
                                                                        Client Background
                                                                        <span className="field_max_characters">(max. 2000 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea
                                                                            className={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8 ) ? "form-input textarea_width" : "textarea-css form-attribute textarea_width"}
                                                                            type="text" placeholder='Provide value'
                                                                            onChange={(e) => this.clientBGChange(e.target.value)} value={this.state.ClientDtls}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.ClientDtls }}
                                                                            disabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8 ) ? true : false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>
                                                <div className="">
                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >
                                                                        <label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? "*" : " "}&nbsp;</label>
                                                                        GenAI Solution
                                                                        <span className="field_max_characters">(max. 2000 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea
                                                                            className={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8 ) ? "form-input textarea_width" : "textarea-css form-attribute textarea_width"}
                                                                            type="text"
                                                                            placeholder='Provide value' onChange={(e) => this.genAISolutionChange(e.target.value)} value={this.state.Solution}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Solution }}
                                                                            disabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8 ) ? true : false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>

                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">
                                                <div className="">
                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >
                                                                        <label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? "*" : " "}&nbsp;</label>
                                                                        Client Problem
                                                                        <span className="field_max_characters">(max. 2000 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea placeholder='Provide value'
                                                                            className={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8 ) ? "form-input textarea_width" : "textarea-css form-attribute textarea_width"}
                                                                            type="text" onChange={(e) => this.clientProblemChange(e.target.value)} value={this.state.Problem}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Problem }}
                                                                            disabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8 ) ? true : false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-3">
                                            {
                                                this.state.OppStageSelectedOption
                                                    ?
                                                    <>
                                                        <div className="">
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? "*" : " "}&nbsp;</label>
                                                                    Overall number of FTEs in scope</label>
                                                                <input
                                                                    className={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) ? "form-input hide_numeric_input_arrows " : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                                    type="number"
                                                                    placeholder="Max limit of 5000 FTEs"
                                                                    onChange={(e) => this.overAllFTEinScopeChange(e.target.value)}
                                                                    value={this.state.OverallFTE}
                                                                    style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.OverallFTE }}
                                                                    onKeyDown={(evt) =>
                                                                        (evt.key === "e" ||
                                                                            evt.key === "E" ||
                                                                            evt.key === "+" ||
                                                                            evt.key === "-") &&
                                                                        evt.preventDefault()
                                                                    }
                                                                    onWheel={(e) => e.target.blur()}
                                                                    disabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) ? true : false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }

                                        </div>
                                        <div className="col-md-3">
                                            <div className="">
                                                {
                                                    this.state.OppStageSelectedOption && (this.state.Offering_1SelectedOption && this.state.Offering_1SelectedOption.label === "CKA")
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? "*" : " "}&nbsp;</label>
                                                                    Overall number of SOPs in scope
                                                                </label>
                                                                <input
                                                                    className={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                                    type="number"
                                                                    maxLength={1000}
                                                                    onChange={(e) => this.overAllNumberofSOPChange(e.target.value)}
                                                                    value={this.state.OverallSOP}
                                                                    placeholder="Max limit of 1000 SOPs"
                                                                    style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.OverallSOP }}
                                                                    onKeyDown={(evt) =>
                                                                        (evt.key === "e" ||
                                                                            evt.key === "E" ||
                                                                            evt.key === "+" ||
                                                                            evt.key === "-") &&
                                                                        evt.preventDefault()
                                                                    }
                                                                    onWheel={(e) => e.target.blur()}
                                                                    disabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) ? true : false}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                        </div>
                                        <div className="col-md-3"></div>
                                        <div className="col-md-3"></div>
                                    </div>



                                    <div className="row">
                                        <div className="col-md-5">
                                            {
                                                this.state.OppStageSelectedOption
                                                    ?
                                                    <>
                                                        <div className="genai_fields">
                                                            <label className="form-attribute">
                                                                <label className="mandatery_asterisk">&nbsp; </label>
                                                                &nbsp;Help Needed from Leadership
                                                                <span className="field_max_characters">(max. 300 chars)</span>
                                                            </label>
                                                            <div className="">
                                                                <textarea
                                                                    placeholder="Provide value"
                                                                    className="textarea-css form-attribute textarea_width"
                                                                    type="text"
                                                                    onChange={(e) => this.needHelpChange(e.target.value)}
                                                                    value={this.state.LeadHelp}
                                                              

                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }

                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-5">
                                            <div className="">
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute">
                                                                    <label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? " " : " "}&nbsp;&nbsp;</label>
                                                                    CXO Report - Brief Status
                                                                    <span className="field_max_characters">(max. 300 chars)</span>
                                                                </label>
                                                                <div className="">
                                                                    <textarea
                                                                        placeholder="Provide value"
                                                                        className="textarea-css form-attribute textarea_width"
                                                                        type="text"
                                                                        onChange={(e) => this.CXOReportChange(e.target.value)}
                                                                        value={this.state.CXO}
                                                                        
                                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.CXO }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                        </div>
                                    </div>


                                    {
                                        this.state.OppStageSelectedOption
                                            ?
                                            <>
                                                <div className="gapping_class">
                                                    <div className="">
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="">
                                                                    <div className="genai_fields">
                                                                        <label htmlFor="futureDate" className="form-attribute" >
                                                                            <label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2) ? " " : " "}&nbsp;&nbsp;</label>
                                                                            Next Planned Discussion Date</label>
                                                                        <input
                                                                            className="textarea-css form-attribute"
                                                                            type="date"
                                                                            onChange={(e) => this.planDiscussionDateChange(e.target.value)}
                                                                            value={this.state.PlanDiscussDate} min={today} id="futureDate"
                                                                            //disabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2) ? false : true}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.PlanDiscussDate, color: "#122539" }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="">
                                                                    <div className="genai_fields">
                                                                        <label className="form-attribute" style={{ whiteSpace: "nowrap" }}><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2) ? " " : " "}&nbsp;&nbsp;</label>
                                                                            Date by when discussion outcome will be known ?
                                                                        </label>
                                                                        <input
                                                                            className="textarea-css form-attribute"
                                                                            type="date"
                                                                            min={today}
                                                                            onChange={(e) => this.DiscussionEstimateDateChange(e.target.value)}
                                                                            value={this.state.DiscussEstDate}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.DiscussEstDate, color: "#122539" }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }



                                    {
                                        this.state.OppStageSelectedOption
                                            ?
                                            <>
                                                <div className="gapping_class">
                                                    <div className="">
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="">
                                                                    <div className="">
                                                                        <div className="genai_fields">
                                                                            <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? " " : ""}&nbsp;&nbsp;</label>Proposal Shared ?</label>
                                                                            <div className="">
                                                                                <Select
                                                                                    placeholder="Proposal shared ?"
                                                                                    onChange={(e) => this.IsProposalSharedChange(e)}
                                                                                    value={this.state.IsProposalSharedSelectedOption}
                                                                                    options={YESORNO}
                                                                                    styles={this.state.selectStyle.IsProposalSharedSelectedOption}
                                                                                />
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                {/* <div className="genai_fields">
                                                                    <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> Proposal File <span className="file_max_size">Max size: 5MB</span></label>
                                                                    <div className="file_upload_plus_mark_block">
                                                                    
                                                                        <label htmlFor="proposed-file" className="file_upload_plus_mark">&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                                        <input id="proposed-file" 
                                                                            type="file" style={{ display: 'none' }} 
                                                                            onChange={(event) => this.uploadFile(event, "file1")} 
                                                                            accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" 
                                                                        />
                                                                        <button className="file_name">File name here</button>
                                                                    </div>
                                                                    <div className="">
                                                                        <label className="file_upload_instruction">[Note] Supported File Types : .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .ppt, .pptx, .pdf, .jpeg, .jpg, .png, .msg, .png, .mpp.</label>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                            <div className="col-md-3">

                                                            </div>
                                                            <div className="col-md-3">

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }

                                    {
                                        this.state.OppStageSelectedOption
                                            ?
                                            <>
                                                <div className="gapping_class">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                        Environment</label>
                                                                    <div className="">
                                                                        <Select onChange={(e) => this.handleEnvironmentChange(e)}
                                                                            placeholder={"Select solution environment"}
                                                                            value={this.state.SolnEnviron}
                                                                            options={GenAI_ENVIRONMENT}
                                                                            // classNamePrefix={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "" : "an-simple-select"}
                                                                            // isDisabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? false : true}
                                                                            styles={this.state.selectStyle.SolnEnviron}
                                                                        />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            {
                                                                this.state.OppStageSelectedOption
                                                                    ?
                                                                    <>
                                                                        <div className="genai_fields">
                                                                            <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                                Funding
                                                                            </label>
                                                                            <Select
                                                                                options={GenAI_FUNDING}
                                                                                isSearchable={true} placeholder="Select Solution Funding"
                                                                                value={this.state.SolnFund}
                                                                                onChange={(e) => this.SolutionFundingChange(e)}
                                                                                styles={this.state.selectStyle.SolnFund}
                                                                            // classNamePrefix={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "" : "an-simple-select"}
                                                                            // isDisabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? false : true}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                            <div className="">


                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            {
                                                                this.state.SolnFund && (this.state.SolnFund.value === 1 || this.state.SolnFund.value === 3)
                                                                    ?
                                                                    <>
                                                                        <div className="">
                                                                            <div className="genai_fields">
                                                                                <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                                    Client Funding (USD)
                                                                                </label>
                                                                                <input
                                                                                    type="number"
                                                                                    className='textarea-css form-attribute hide_numeric_input_arrows'
                                                                                    value={this.state.ClientDollar}
                                                                                    placeholder='Enter $ value'
                                                                                    onChange={(e) => this.clientFundingChange(e.target.value)}
                                                                                    style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.ClientDollar }}
                                                                                    onKeyDown={(evt) =>
                                                                                        (evt.key === "e" ||
                                                                                            evt.key === "E" ||
                                                                                            evt.key === "+" ||
                                                                                            evt.key === "-") &&
                                                                                        evt.preventDefault()
                                                                                    }
                                                                                    onWheel={(e) => e.target.blur()}
                                                                                // className={this.state.oppDetails.IsVDMonetizable && this.state.oppDetails.IsVDMonetizable === "No" ? "form-input" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                                                // disabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) && (this.state.oppDetails.IsVDMonetizable !== "No") ? false : true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }

                                                        </div>
                                                        <div className="col-md-3">
                                                            {
                                                                this.state.SolnFund && (this.state.SolnFund.value === 2 || this.state.SolnFund.value === 3)
                                                                    ?
                                                                    <>
                                                                        <div className="genai_fields">
                                                                            <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                                Cost to Genpact for Build (USD)</label>
                                                                            <input
                                                                                className="textarea-css form-attribute hide_numeric_input_arrows"
                                                                                type="number"
                                                                                placeholder='Enter $ value'
                                                                                value={this.state.GenpactDollar} onChange={(e) => this.costToGenpactBuildChange(e.target.value)}
                                                                                style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.GenpactDollar }}
                                                                                onKeyDown={(evt) =>
                                                                                    (evt.key === "e" ||
                                                                                        evt.key === "E" ||
                                                                                        evt.key === "+" ||
                                                                                        evt.key === "-") &&
                                                                                    evt.preventDefault()
                                                                                }
                                                                                onWheel={(e) => e.target.blur()}
                                                                            //disabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? false : true}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }

                                                        </div>


                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }



                                    {
                                        this.state.OppStageSelectedOption
                                            ?
                                            <>
                                                <div className="gapping_class">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            {
                                                                this.state.OppStageSelectedOption
                                                                    ?
                                                                    <>
                                                                        <div className="genai_fields">
                                                                            <label className="form-attribute" style={{ whiteSpace: "nowrap" }}><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                                Is a SOW in scope for this piece of work ?</label>
                                                                            <Select
                                                                                isSearchable={true}
                                                                                placeholder="Choose value"
                                                                                value={this.state.IsSOWScope}
                                                                                onChange={(e) => this.sowScopePeiceOfWorkChange(e)}
                                                                                options={YESORNO}
                                                                                styles={this.state.selectStyle.IsSOWScope}
                                                                            // classNamePrefix={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "" : "an-simple-select"}
                                                                            // isDisabled={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6)) ? false : true}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-md-3">
                                                            {
                                                                this.state.IsSOWScope && this.state.IsSOWScope.label === "Yes"
                                                                    ?
                                                                    <>
                                                                        {
                                                                            this.state.OppStageSelectedOption
                                                                                ?
                                                                                <>
                                                                                    <div className="">
                                                                                        <div className="genai_fields">
                                                                                            <label className="form-attribute" style={{ whiteSpace: "nowrap" }}><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                                                SOW signed & agreed with the client ?</label>
                                                                                            <Select
                                                                                                isSearchable={true}
                                                                                                placeholder="Choose SOW Signed"
                                                                                                value={this.state.IsSOWSigned}
                                                                                                onChange={(e) => this.sowScopeSignedChange(e)}
                                                                                                options={YESORNO}
                                                                                                styles={this.state.selectStyle.IsSOWSigned}
                                                                                            // classNamePrefix={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "" : "an-simple-select"}
                                                                                            // isDisabled={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6)) ? false : true}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                null
                                                                        }
                                                                    </>
                                                                    :
                                                                    null
                                                            }


                                                        </div>
                                                        <div className="col-md-3">
                                                            {
                                                                this.state.IsSOWScope && this.state.IsSOWScope.label === "Yes"
                                                                    ?
                                                                    <>
                                                                        <div className="genai_fields">
                                                                            <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? " " : ""}&nbsp;&nbsp;</label>
                                                                                Expected SOW submission Date / SOW submission Date
                                                                            </label>
                                                                            <input
                                                                                className="textarea-css form-attribute"
                                                                                type="date"
                                                                                placeholder="dd-mm-yyyy"
                                                                                onChange={(e) => this.expectedSOWSubmissionDateorSowSubmissionDateChange(e.target.value)}
                                                                                value={this.state.SOWSubDate}
                                                                                min={this.state.IsSOWSigned && this.state.IsSOWSigned.label === "Yes" ? null : today}
                                                                                style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.SOWSubDate, color: "#122539" }}
                                                                            //disabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? false : true}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                            <div className="">
                                                                {/* <div className="genai_fields">
                                                    <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> Upload a copy of the SOW <span className="file_max_size">Max size: 5MB</span></label>
                                                    <div className="file_upload_plus_mark_block">
                                                        <label htmlFor="SOW-COPY" className="file_upload_plus_mark">&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                        <input id="SOW-COPY" 
                                                            type="file" style={{ display: 'none' }} 
                                                            onChange={(event) => this.uploadFile(event, "file1")} 
                                                            accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" 
                                                        />
                                                        <button className="file_name">File name here</button>
                                                    </div>
                                                    <div className="">
                                                        <label className="file_upload_instruction">[Note] Supported File Types : .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .ppt, .pptx, .pdf, .jpeg, .jpg, .png, .msg, .png, .mpp.</label>
                                                    </div>
                                                    
                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            {
                                                                this.state.IsSOWScope && this.state.IsSOWScope.label === "Yes"
                                                                    ?
                                                                    <>
                                                                        <div className="">
                                                                            <div className="genai_fields">
                                                                                <label className="form-attribute"><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? " " : ""}&nbsp;&nbsp;</label>
                                                                                    Expected SOW Closure Date / SOW closure Date
                                                                                </label>
                                                                                <input
                                                                                    className="textarea-css form-attribute"
                                                                                    type="date"
                                                                                    placeholder="dd-mm-yyyy"
                                                                                    min={this.state.IsSOWSigned && this.state.IsSOWSigned.label === "Yes" ? null : today}
                                                                                    onChange={(e) => this.expectedSOWClosureDateorSowClosureDateChange(e.target.value)}
                                                                                    value={this.state.SOWCloseDate}
                                                                                    style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.SOWCloseDate, color: "#122539" }}
                                                                                //disabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? false : true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                this.state.OppStageSelectedOption
                                                                    ?
                                                                    <>
                                                                        <div className="">
                                                                            <div className="genai_fields">
                                                                                <label className="form-attribute" style={{ whiteSpace: "nowrap" }}><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                                    Expected Deployment Date/ Go-Live Date</label>
                                                                                <input
                                                                                    className="textarea-css form-attribute"
                                                                                    type="date"
                                                                                    placeholder="dd-mm-yyyy"
                                                                                    //min={this.state.OppState && this.state.OppState === "Completed" ? null : today}
                                                                                    onChange={(e) => this.expectedDeploymentDateDateorGoLiveeDateChange(e.target.value)}
                                                                                    value={this.state.CompletionDate}
                                                                                    style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.CompletionDate, color: "#122539" }}
                                                                                //disabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? false : true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                            {/* <div className="genai_fields">
                                                <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> SOW File</label>
                                                <div className="">
                                                    <button className="file_name">SOW File</button>
                                                </div>
                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }


                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? " " : ""}&nbsp;&nbsp;</label>
                                                                        Functional Owner OHR</label>
                                                                    <input
                                                                        pattern="\d*" maxLength="9"
                                                                        placeholder='Enter functional owner OHR'
                                                                        defaultValue={this.state.FunctionalOHR}
                                                                        onChange={(e) => this.handleFunctionalOhrChange(e)}
                                                                        onCut={(e) => this.handleFunctionalOhronCut(e)}
                                                                        className="textarea-css form-attribute hide_numeric_input_arrows"
                                                                        
                                                                        onKeyDown={(evt) => (evt.key === '.' || evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault()}
                                                                        onWheel={(e) => e.target.blur()}
                                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.FunctionalOHR }}
                                                                    //key={this.state.FunctionalOHR}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="col-md-3">
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? " " : ""}&nbsp;&nbsp;</label>
                                                                        Functional Owner</label>
                                                                    <input className="form-input" placeholder="Functional Owner" type="text" disabled value={this.state.Functional} style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Functional }} />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="col-md-3">
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? "*" : ""}&nbsp;</label>
                                                                    Technical Owner OHR</label>
                                                                <input type='number' maxLength={9}
                                                                    placeholder='Enter Technical owner OHR'
                                                                    defaultValue={this.state.TechOHR}
                                                                    onChange={(e) => this.handleTechnicalOhrChange(e)}
                                                                    onCut={(e) => this.handleTechnicalOhronCut(e)}
                                                                    className="textarea-css form-attribute hide_numeric_input_arrows"
                                                                    onKeyDown={(evt) => (evt.key === '.' || evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault()}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.TechOHR }}
                                                                // disabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? false : true}
                                                                //key={this.state.TechOHR}

                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="col-md-3">
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? "*" : ""}&nbsp;</label>
                                                                        Technical Owner</label>
                                                                    <input className="form-input" placeholder="Technical Owner" type="text" disabled value={this.state.Tech} style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Tech }} />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-3">
                                            {
                                                this.state.OppStageSelectedOption
                                                    ?
                                                    <>
                                                        <div className="genai_fields">
                                                            <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? " " : ""}&nbsp;&nbsp;</label>
                                                                Discussion Led By</label>
                                                            <Select
                                                                isSearchable={true}
                                                                placeholder="Choose value"
                                                                onChange={(e) => this.transitionLedChange(e)}
                                                                value={this.state.TransitionLed}
                                                                options={DISCUSSIONLEDBY}
                                                                styles={this.state.selectStyle.TransitionLed}
                                                            // classNamePrefix={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "" : "an-simple-select"}
                                                            // isDisabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? false : true}

                                                            />
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }

                                        </div>
                                    </div>





                                    <div className="">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="">
                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >
                                                                        <label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? " " : ""}&nbsp;&nbsp;</label>
                                                                        Domain Knowledge
                                                                        <span className="field_max_characters">(max. 300 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea
                                                                            className='textarea-css form-attribute textarea_width'
                                                                            placeholder="Provide value"
                                                                            type="text"
                                                                            onChange={(e) => this.domainKnowledgeChange(e.target.value)}
                                                                            value={this.state.Knowledge}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Knowledge }}
                                                                        //disabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? false : true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }


                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >
                                                                        <label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                        Data used
                                                                        <span className="field_max_characters">(max. 300 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea
                                                                            className='textarea-css form-attribute textarea_width'
                                                                            placeholder="Provide value" type="text"
                                                                            onChange={(e) => this.dataUsedChange(e.target.value)}
                                                                            value={this.state.Data}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Data }}
                                                                        //disabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? false : true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >
                                                                        <label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                        Algorithms Used
                                                                        <span className="field_max_characters">(max. 300 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea
                                                                            className='textarea-css form-attribute textarea_width'
                                                                            placeholder="Provide value" type="text"
                                                                            onChange={(e) => this.algorithmChanged(e.target.value)}
                                                                            value={this.state.Algo}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Algo }}
                                                                        // disabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? false : true}
                                                                        />
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }


                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >
                                                                        <label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                        Tech Infrastructure used
                                                                        <span className="field_max_characters">(max. 300 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea
                                                                            className='textarea-css form-attribute textarea_width'
                                                                            placeholder="Provide value"
                                                                            type="text"
                                                                            onChange={(e) => this.techInfraUsedChanged(e.target.value)}
                                                                            value={this.state.Infra}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Infra }}
                                                                        // disabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? false : true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }


                                            </div>
                                        </div>
                                    </div>


                                    <div className="">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="">
                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >
                                                                        <label className="mandatery_asterisk">&nbsp;</label> Share holder value created
                                                                        <span className="field_max_characters">(max. 300 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea
                                                                            // className={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6) ? "textarea-css form-attribute textarea_width" : "form-input textarea_width"}
                                                                            // disabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6) ? false : true}
                                                                            className="textarea-css form-attribute textarea_width"
                                                                            placeholder="Provide value"
                                                                            type="text"
                                                                            value={this.state.ValueCreated}
                                                                            onChange={(e) => this.shareHolderValueCreated(e.target.value)}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.ValueCreated }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">

                                            </div>
                                        </div>
                                    </div>



                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="genai_fields">
                                                <label className="form-attribute" ><label className="">&nbsp;SFDC OLI Number</label></label>
                                                <div className="OLI_Number_format">
                                                    <span><label>OLI-</label></span>
                                                    <input 
                                                        value={this.state.OLINum}
                                                        type="number"
                                                        onChange={(e) => this.handleSFDCOLIChange(e.target.value)}
                                                        placeholder='Provide OLI XXXXXXX number'
                                                        disabled={this.state.OppState && this.state.OppState === "Completed" ? true : false}
                                                        className={this.state.OppState && this.state.OppState === "Completed" ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.OLINum }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="genai_fields">
                                                <label className="form-attribute" ><label className="">SFDC OPP ID</label></label>
                                                <div className="OLI_Number_format">
                                                    <span><label>Opp-</label></span>
                                                    <input
                                                        disabled={this.state.OppState && this.state.OppState === "Completed" ? true : false}
                                                        className={this.state.OppState && this.state.OppState === "Completed" ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                        value={this.state.OppID}
                                                        type="number"
                                                        onChange={(e) => this.handleSFDCOppIDChange(e.target.value)}
                                                        placeholder='Provide XXXXXXX number'
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.OppID }}
                                                    />
                                                </div>
                                            </div>


                                        </div>
                                        <div className="col-md-3">
                                            {
                                                this.state.OppStageSelectedOption
                                                    ?
                                                    <>
                                                        <div className="genai_fields">
                                                            <label className="form-attribute" style={{ whiteSpace: "nowrap" }}><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                Number of FTEs currently using this solution
                                                            </label>
                                                            <input
                                                                className="textarea-css form-attribute hide_numeric_input_arrows"
                                                                value={this.state.FTEUsage}
                                                                type="number"
                                                                onChange={(e) => this.noOfFTEUsageChange(e.target.value)}
                                                                placeholder='Max limit of 5000'
                                                                style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.FTEUsage }}
                                                                onKeyDown={(evt) =>
                                                                    (evt.key === "e" ||
                                                                        evt.key === "E" ||
                                                                        evt.key === "+" ||
                                                                        evt.key === "-") &&
                                                                    evt.preventDefault()
                                                                }
                                                                onWheel={(e) => e.target.blur()}
                                                            //disabled={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 6) ? false : true}
                                                            />
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }

                                        </div>


                                    </div>



                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;Total People in the process
                                                    </label>
                                                    <input
                                                        value={this.state.ProcessUsers}
                                                        type="number"
                                                        onChange={(e) => this.TotalPPLInTheProcess(e.target.value)}
                                                        placeholder='Max limit of 5000'
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.ProcessUsers }}
                                                        onKeyDown={(evt) =>
                                                            (evt.key === "e" ||
                                                                evt.key === "E" ||
                                                                evt.key === "+" ||
                                                                evt.key === "-") &&
                                                            evt.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()}
                                                        className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8)) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                        disabled={((this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8))) ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;# of people with access to the solution
                                                    </label>
                                                    <input
                                                        value={this.state.AccessUsers}
                                                        type="number"
                                                        onChange={(e) => this.PPLAccessSoln(e.target.value)}
                                                        placeholder='Max limit of 5000'
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.AccessUsers }}
                                                        onKeyDown={(evt) =>
                                                            (evt.key === "e" ||
                                                                evt.key === "E" ||
                                                                evt.key === "+" ||
                                                                evt.key === "-") &&
                                                            evt.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()}
                                                        className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8)) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                        disabled={((this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8))) ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;People using it daily
                                                    </label>
                                                    <input
                                                        value={this.state.DailyUsers}
                                                        type="number"
                                                        onChange={(e) => this.PPLUsingDaily(e.target.value)}
                                                        placeholder='Max limit of 5000'
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.DailyUsers }}
                                                        onKeyDown={(evt) =>
                                                            (evt.key === "e" ||
                                                                evt.key === "E" ||
                                                                evt.key === "+" ||
                                                                evt.key === "-") &&
                                                            evt.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()}
                                                        className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8)) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                        disabled={((this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8))) ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;People using it weekly
                                                    </label>
                                                    <input
                                                        value={this.state.WeeklyUsers}
                                                        type="number"
                                                        onChange={(e) => this.PPLUsingWeekly(e.target.value)}
                                                        placeholder='Max limit of 5000'
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.WeeklyUsers }}
                                                        onKeyDown={(evt) =>
                                                            (evt.key === "e" ||
                                                                evt.key === "E" ||
                                                                evt.key === "+" ||
                                                                evt.key === "-") &&
                                                            evt.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()}
                                                        className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8)) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                        disabled={((this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8))) ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;Total Weekly transactions
                                                    </label>
                                                    <input
                                                        value={this.state.WeeklyTrans}
                                                        type="number"
                                                        max={100000}
                                                        onChange={(e) => this.TotalWeeklyTransaction(e.target.value)}
                                                        placeholder='Max limit of 100000'
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.WeeklyTrans }}
                                                        onKeyDown={(evt) =>
                                                            (evt.key === "e" ||
                                                                evt.key === "E" ||
                                                                evt.key === "+" ||
                                                                evt.key === "-") &&
                                                            evt.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()}
                                                        className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8)) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                        disabled={((this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8))) ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" >
                                                        &nbsp;Transactions processed through GenAI solution
                                                    </label>
                                                    <input
                                                        value={this.state.SolnTrans}
                                                        type="number"
                                                        max={100000}
                                                        onChange={(e) => this.GenAITransactionProcessedSoln(e.target.value)}
                                                        placeholder='Max limit of 100000'
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.SolnTrans }}
                                                        onKeyDown={(evt) =>
                                                            (evt.key === "e" ||
                                                                evt.key === "E" ||
                                                                evt.key === "+" ||
                                                                evt.key === "-") &&
                                                            evt.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()}
                                                        className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8)) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                        disabled={((this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8))) ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;Number of upvotes
                                                    </label>
                                                    <input
                                                        value={this.state.Upvotes}
                                                        type="number"
                                                        onChange={(e) => this.noOfUpVotes(e.target.value)}
                                                        placeholder='Max limit of 5000'
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Upvotes }}
                                                        onKeyDown={(evt) =>
                                                            (evt.key === "e" ||
                                                                evt.key === "E" ||
                                                                evt.key === "+" ||
                                                                evt.key === "-") &&
                                                            evt.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()}
                                                        className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8)) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                        disabled={((this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8))) ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;% of upvotes
                                                    </label>
                                                    <input
                                                        value={this.state.UpvotesPercent}
                                                        type="number"
                                                        onChange={(e) => this.PercentOfUpVotes(e.target.value)}
                                                        placeholder='Max limit of 100 and 2 decimal places allowed'
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.UpvotesPercent }}
                                                        onKeyDown={(evt) =>
                                                            (evt.key === "e" ||
                                                                evt.key === "E" ||
                                                                evt.key === "+" ||
                                                                evt.key === "-") &&
                                                            evt.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()}
                                                        className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8)) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                        disabled={((this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8))) ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;Accuracy of outcome
                                                    </label>
                                                    <input
                                                        value={this.state.Accuracy}
                                                        type="number"
                                                        onChange={(e) => this.AccuracyOfOutcome(e.target.value)}
                                                        placeholder='Max limit of 100 and 2 decimal places allowed'
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Accuracy }}
                                                        onKeyDown={(evt) =>
                                                            (evt.key === "e" ||
                                                                evt.key === "E" ||
                                                                evt.key === "+" ||
                                                                evt.key === "-") &&
                                                            evt.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()}
                                                        className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8)) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                        disabled={((this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8))) ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;Uptime of the solution
                                                    </label>
                                                    <input
                                                        value={this.state.UptimePercent}
                                                        type="number"
                                                        onChange={(e) => this.UptimeOfSoln(e.target.value)}
                                                        placeholder='Max limit of 100 and 2 decimal places allowed'
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.UptimePercent }}
                                                        onKeyDown={(evt) =>
                                                            (evt.key === "e" ||
                                                                evt.key === "E" ||
                                                                evt.key === "+" ||
                                                                evt.key === "-") &&
                                                            evt.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()}
                                                        className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8)) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                        disabled={((this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8))) ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* Reason for stage change starts here  */}
                                    <div className="">
                                        <div className="row">
                                            <div className="col-md-6">                                            
                                                {
                                                    this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8 || this.state.OppStageSelectedOption.value === 9)
                                                    ?
                                                    <>
                                                        <div className="genai_fields">
                                                            <label className="form-attribute" >
                                                                <label className="mandatery_asterisk">*&nbsp;</label>
                                                                Reason for stage change
                                                                <span className="field_max_characters">(max. 180 chars)</span>
                                                            </label>
                                                            <div className="">
                                                                <textarea
                                                                    // className={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8 || this.state.OppStageSelectedOption.value === 9) || !this.state.fieldsEditable) ? "form-input textarea_width" : "textarea-css form-attribute textarea_width"}
                                                                    className="textarea-css form-attribute textarea_width"
                                                                    type="text"
                                                                    placeholder='Please specify reason for the stage change.'
                                                                    onChange={(e) => this.handleReasonForStageChange(e.target.value)}
                                                                    value={this.state.OppStageChange}
                                                                    style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.OppStageChange }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }
                                            </div>
                                        </div>
                                    </div>
                                    {/* Reason for stage change ends here */}


                                    <div className="row">
                                        <div className="col-md-3">
                                            {/* <div className="genai_fields">
                                                <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> Go-Live Upload <span className="file_max_size">Max size: 5MB</span></label>
                                                <div className="file_upload_plus_mark_block">
                                                    <label htmlFor="GO-LIVE" className="file_upload_plus_mark">&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                    <input id="GO-LIVE" 
                                                        type="file" style={{ display: 'none' }} 
                                                        onChange={(event) => this.uploadFile(event, "file1")} 
                                                        accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" 
                                                    />
                                                    <button className="file_name">File name here</button>
                                                </div>
                                                <div className="">
                                                        <label className="file_upload_instruction">[Note] Supported File Types : .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .ppt, .pptx, .pdf, .jpeg, .jpg, .png, .msg, .png, .mpp.</label>
                                                    </div>
                                            </div> */}
                                        </div>
                                        <div className="col-md-3">
                                            {/* <div className="genai_fields">
                                                <label className="form-attribute" ><label className="mandatery_asterisk">*</label> Go-Live email file name</label>
                                                <button className="file_name" > Go-Live email file name</button>
                                            </div> */}
                                        </div>
                                        <div className="col-md-3">
                                            {/* <div className="genai_fields">
                                                <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> GOL/FP&A/Client Approval <span className="file_max_size">Max size: 5MB</span></label>
                                                <div className="file_upload_plus_mark_block">
                                                    <label htmlFor="GOL/FP&A/Client-Approval" className="file_upload_plus_mark">&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                    <input id="GOL/FP&A/Client-Approval" 
                                                        type="file" style={{ display: 'none' }} 
                                                        onChange={(event) => this.uploadFile(event, "file1")} 
                                                        accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" 
                                                    />
                                                    <button className="file_name">File name here</button>
                                                </div>
                                                <div className="">
                                                        <label className="file_upload_instruction">[Note] Supported File Types : .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .ppt, .pptx, .pdf, .jpeg, .jpg, .png, .msg, .png, .mpp.</label>
                                                    </div>
                                                
                                            </div> */}
                                        </div>
                                        <div className="col-md-3">

                                            {/* <div className="genai_fields">
                                                <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> GOL/FP&A/Client Approval</label>
                                                <button className="file_name" >GOL/FP&A/Client Approval- filename</button>
                                            </div> */}
                                        </div>
                                    </div>


                                    {

                                        this.state.oppDetails.VDType
                                            ?
                                            <>

                                                <div className="">
                                                    <GenAIFPNAGOL 
                                                        IdeaId={this.state.RecID} 
                                                        viewIdea={false}
                                                        vdSelected={this.state.impactOptionSelected ? this.state.impactOptionSelected.label : this.state.oppDetails.VDType}
                                                        fetchPPTDeck={this.pullPPTDeck}
                                                        fetchClientDocName={this.pullClientDocName}
                                                        fetchClientDocType={this.pullClientDocType}
                                                        fetchClientDocContent={this.pullClientDocContent}
                                                        fetchGolDocName={this.pullgolFileName}
                                                        fetchGolDocType={this.pullgolFileType}
                                                        fetchGolDocContent={this.pullgolContent}
                                                        fetchfpnaDocName={this.pullfpnafileName}
                                                        fetchfpnaDocType={this.pullfpnafileType}
                                                        fetchfpnaDocContent={this.pullfpnafileContent}
                                                        
                                                        fetchClientName={this.pullCustName}
                                                        fetchClientRole={this.pullClientDesign}
                                                        fetchClientSignOffDate={this.pullCustSignOffDate}

                                                        fetchGolOhr={this.pullGOLOHR}
                                                        fetchGolName={this.pullGolName}

                                                        fetchFpnaOhr={this.pullFpnaOhr}
                                                        fetchFpnaName={this.pullFpnaName}

                                                        fetchFileDetails={this.pull_fileDetails}
                                                        sendBitoFPNA={this.state.businessImpactValueArray}
                                                        sendIsFpnaRequired={this.state.isFpnaRequired}
                                                        showMandatory={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6)) ? true : false}
                                                    />
                                                </div>


                                                <div className="gapping_class">
                                                    <GenAIValueDelivery
                                                        func={this.pull_data}
                                                        viewIdea={false}
                                                        vdType={this.handleVDTypeChange}
                                                        IdeaId={this.state.RecID}
                                                        fetchMonetizationFileDetails={this.pull_file_data}
                                                        fetchMonetizationFpnaOhr={this.pull_monetization_fpna_ohr}
                                                        fetchMonetizationFpnaName={this.pull_monetization_fpna_name}
                                                        fetchMonetizationSowID={this.pull_monetization_sowId}
                                                        bbMonetizationTotal={this.pull_monetizedTotal}
                                                        monetizableProject={this.pull_monetizableProject}
                                                        monetizableStatus={this.pull_monetizableStatus}
                                                        fetchFPAOHRforIncreaseInRevenue={this.fetchFPAOHRforIncreaseInRevenue}
                                                        bbMonetizationApproval={false}
                                                        postFpnaValid={true}
                                                        fetchDeletedBIRows={this.remove_bi_data_after_delete}
                                                        fetchMonetizationGOLName={this.pullMonetizationGOLName}
                                                        fetchMonetizationGOLOhr={this.pullMonetizationGOLOhr}
                                                        fetchCategoryNotListed={this.pullCategoryNotListed}
                                                        showMandatory={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6 ) ? true : false}
                                                    />
                                                </div>
                                            </>
                                            :
                                            null
                                    }




                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10, marginTop: "0px" }}>Value delivery Approval</p>
                                                <div className="">
                                                    <div className="">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" ><label className="mandatery_asterisk">*</label> Approval Status</label>
                                                                    <Select options={this.renderMBBApprovaloptions()} placeholder="Select approval status" onChange={(e) => this.handleVDApprovalStatusChange(e)}
                                                                        value={this.state.oppApprovalSelectedOption}
                                                                        styles={this.state.selectStyle.oppApprovalSelectedOption}

                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="">
                                                        <div className="" style={{ marginBottom: "15px", display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                                                            <div className="">
                                                                <label className="form-attribute" ><label className="mandatery_asterisk">*</label> Approval Comments
                                                                    <span style={{ fontWeight: "normal", fontSize: 12, fontStyle: "italic", color: "grey", }}>&nbsp;(max. 500 chars)</span>
                                                                </label>
                                                            </div>
                                                            <div className="" >
                                                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e) => this.setState({ mbbVDLogModal: true })}>Comment Log</button>
                                                            </div>
                                                        </div>
                                                        <textarea
                                                            className="textarea-css form-attribute"
                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Comment, }}
                                                            rows={3}
                                                            cols={88}
                                                            placeholder="Please provide your comments in support of the value delivery approval."
                                                            onChange={(e) => this.handleVDCommentChange(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="create_opp_btn_group">
                                        <div className="btn-group">
                                            {
                                                this.state.OppState && this.state.OppState === "Completed"
                                                    ?
                                                    <>
                                                        <button tabIndex={20} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={this.viewDone}>Done</button>
                                                    </>
                                                    :
                                                    <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                                        onClick={(e) => this.updateOpp(e)} tabIndex={20}>Approve</button>
                                            }

                                           
                                            <button tabIndex={21} className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={this.cancelOpp}>Cancel</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        )
    }



}

export default GenAIMbbApproval
