import React, { Component } from 'react'
import { SUBMIT_IDEA_URL, AWAITING_AI_BOT_REVIEW, GET_METADATA, OTHER_REGIONS, GET_IDEA_URL, DATA_BRIDGE_CAMPAIGN, X_POLLINATION_CAMPAIGN, USER_DATA, ACCESS_TOKEN, AI_DOC_LINK, AWAITING_E360_SPOC_APPROVAL } from '../assets/constants/constants';
import Toast from 'light-toast';
import Select from 'react-select';
import { selectValidation as selectStyle, textBoxValidation } from "../utils/FieldValidation";
import Checksum from "../utils/Checksum";
import GenAIValueDelivery from './GenAIValueDelivery';
import GenAIFPNAGOL from './GenAIFPNAGOL'

export class ViewOpp extends Component {
    sessionData = JSON.parse(localStorage.getItem(USER_DATA));
    accessToken = localStorage.getItem(ACCESS_TOKEN);

    constructor(props) {
        super(props);
        if (this.sessionData === null) {
            Toast.loading("This component cannot be accessed directly. Redirecting to application home.", 3000, () => {
            });
            window.location = "/login";
            return;
        }
        this.state = {
            RecID: this.props.location.state,
            RecIDAlpha: "",
            OppName: "",
            ParentID: "",
            ParentIDAlpha: "",
            CreatedDate: "",
            CreatedBy: "",
            CreatedName: "",
            ModifiedDate: "",
            ModifiedBy: "",
            PlanDiscussDate: "",
            DiscussEstDate: "",
            Account: "",
            Unit: "",
            MangingDept: "",
            Vertical: "",
            Segment: "",
            Archetype: "",
            LCPOHR: "",
            LCP: "",
            GRMOHR: "",
            GRM: "",
            UnitOHR: "",
            Unit: "",
            SegOHR: "",
            Seg: "",
            GOLOHR: "",
            GOL: "",
            SGOLOHR: "",
            SGOL: "",
            OppStage: "",
            OppState: "",
            SpocOHR_1: "",
            Spoc_1: "",
            SpocOHR_2: "",
            Spoc_2: "",
            BBOHR: "",
            BB: "",
            MBBOHR: "",
            MBB: "",
            SolnOwnerOHR: "",
            SolnOwner: "",
            Cohort: "",
            AccFTE: "",
            oppDetails: [],
            BiData: []


        }
    }



    async componentDidMount() {
        Toast.loading("Fetching GenAI metadata. Please wait...", () => { });
        await this.fetchMetadata();
        Toast.loading("Fetching opportunity details. Please wait...", () => { });
        await this.fetchOpportunity();
        Toast.hide()
    }

    fetchMetadata = () => {
        Toast.loading("Fetching GenAI meta data. Please wait...", () => { });
        const param = {
            EntityType: "",
            EntityName: ""
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.accessToken
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("meta data ", data)
                Toast.hide();
                this.setState({
                    accountList: data ? data.Accounts.sort((a, b) => { return a.ACCOUNT > b.ACCOUNT ? 1 : -1; }) : [],
                    serviceLineList: data ? data.ServiceLines : [],
                    subServiceLineList: data ? data.ServiceLines : [],
                    GenAIOfferingList: data ? data.GenAIOffering : [],

                }, () => {

                    if (this.state.GenAIOfferingList) {
                        if (this.state.Offering_1) {
                            const uniqueOfferingL1 = [...new Map(this.state.GenAIOfferingList.map(item => [item["Offering_L1"], item])).values()]
                            uniqueOfferingL1.map((item, index) => {
                                if (item.Offering_L1 === this.state.Offering_1) {
                                    this.setState({ Offering_1: item.Offering_L1, Offering_1SelectedOption: { label: item.Offering_L1, value: index } }, () => { })
                                }

                            })
                            if (this.state.Offering_1 === "Others") {
                                this.setState({ Offering_1: "Others", Offering_1SelectedOption: { label: "Others", value: 0 } }, () => { })
                            }
                        }
                        if (this.state.Offering_2) {
                            let filteredItems = this.state.GenAIOfferingList.filter((item) =>
                                item.Offering_L1 === this.state.Offering_1
                            )
                            filteredItems.map((item, index) => {
                                this.setState({ Offering_2: item.Offering_L2, Offering_2SelectedOption: { label: item.Offering_L2, value: index } }, () => { })
                            })
                            if (this.state.Offering_2 === "Others") {
                                this.setState({ Offering_2: "Others", Offering_2SelectedOption: { label: "Others", value: 0 } }, () => { })
                            }
                        }
                    }


                })
                if (this.state.oppDetails.ServiceLine) {
                    let localServiceLine = this.state.oppDetails.ServiceLine.replace(/[|,]/g, ",")
                    localServiceLine = localServiceLine.split(",");
                    let abc = localServiceLine.toString();
                    this.setState({ ServiceLine: abc }, () => { })


                    if (this.state.oppDetails.Process_L2) {
                        let localSubServiceLine = this.state.oppDetails.Process_L2.replace(/[|,]/g, ",")
                        localSubServiceLine = localSubServiceLine.split(",");
                        let abc = localSubServiceLine.toString();
                        this.setState({ Process_L2: abc }, () => { })
                    }
                }
            })
            .catch(error => {
                Toast.hide();
                Toast.fail('Error occured while getting Metadata. Please try again!', 3000, () => {
                    console.log(JSON.stringify(error));
                });
            });
    }


    fetchOpportunity = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.accessToken,
                },
            };
            //fetch(`${GET_IDEA_URL}IdeaId=''&RecID=${this.props.location.state}&isItIdeaOrGenAI='GenAI'`, requestOptions)
            fetch(`${GET_IDEA_URL}IdeaId=''&RecID=${this.props.location.state}&case=new&casetype=genAI`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log("fetch opp details", data);
                    resolve();
                    this.setState({
                        oppDetails: data ? data.oppDetails ? data.oppDetails.Table1[0] : [] : [],
                        auditTrailData: data ? data.oppDetails ? data.oppDetails.Table2 : [] : [],
                        BiData: data ? data.oppDetails ? data.oppDetails.Table3 : [] : [],
                    },
                        () => {

                            if (this.state.oppDetails.ModifiedDate) {
                                let localVariable = this.formatDate(this.state.oppDetails.ModifiedDate)
                                this.setState({ ModifiedDate: localVariable })
                            }


                            //console.log("Expected SOW submission Date / SOW submission Date", this.state.oppDetails)
                            if (this.state.oppDetails.PlanDiscussDate) {
                                if (this.state.oppDetails.PlanDiscussDate === "1900-01-01T00:00:00") {
                                    this.setState({ PlanDiscussDate: "" }, () => {
                                        //console.log("this.state.PlanDiscussDate at fetch",this.state.PlanDiscussDate)
                                    })
                                } else {
                                    let localVariable = new Date(this.state.oppDetails.PlanDiscussDate).toLocaleDateString('en-CA').split('/').reverse().join('-')
                                    this.setState({ PlanDiscussDate: localVariable }, () => {
                                        //console.log("this.state.PlanDiscussDate at fetch",this.state.PlanDiscussDate)
                                    })
                                }
                            }

                            if (this.state.oppDetails.DiscussEstDate) {
                                if (this.state.oppDetails.DiscussEstDate === "1900-01-01T00:00:00") {
                                    this.setState({ DiscussEstDate: "" }, () => { })
                                } else {
                                    let localVariable = new Date(this.state.oppDetails.DiscussEstDate).toLocaleDateString('en-CA').split('/').reverse().join('-')
                                    this.setState({ DiscussEstDate: localVariable }, () => { })
                                }
                            }

                            if (this.state.oppDetails.SOWSubDate) {
                                if (this.state.oppDetails.SOWSubDate === "1900-01-01T00:00:00") {
                                    this.setState({ SOWSubDate: "" }, () => { })
                                } else {
                                    let localVariable = new Date(this.state.oppDetails.SOWSubDate).toLocaleDateString('en-CA').split('/').reverse().join('-')
                                    this.setState({ SOWSubDate: localVariable }, () => { })
                                }
                            }

                            if (this.state.oppDetails.SOWCloseDate) {
                                if (this.state.oppDetails.SOWCloseDate === "1900-01-01T00:00:00") {
                                    this.setState({ SOWCloseDate: "" })
                                } else {
                                    let localVariable = new Date(this.state.oppDetails.SOWCloseDate).toLocaleDateString('en-CA').split('/').reverse().join('-')
                                    this.setState({ SOWCloseDate: localVariable })
                                }

                            }

                            if (this.state.oppDetails.CompletionDate) {
                                if (this.state.oppDetails.CompletionDate === "1900-01-01T00:00:00") {
                                    this.setState({ CompletionDate: "" })
                                } else {
                                    let localVariable = new Date(this.state.oppDetails.CompletionDate).toLocaleDateString('en-CA').split('/').reverse().join('-')
                                    this.setState({ CompletionDate: localVariable }, () => { })
                                }

                            }
                        }
                    );
                })
                .catch((error) => {
                    console.log(error)
                    Toast.fail(
                        "Error while fetching opportunity details. Please try again!",
                        3000,
                        () => { }
                    );
                    resolve();
                });
        });
    };


    // file download functionality starts here
    downloadFile = () => {

    }
    // file download functionality ends here



    // BI Function starts here
    addMonetizableValues = (e) => {
        let MonetizedBiArray = [];
        let fteValue = 0;
        let dollarValue = 0;
        this.state.businessImpactValueArray.filter((item) => {
            if (item.IsMonetized === "Yes") {
                MonetizedBiArray.push(item);
            }
        });
        if (MonetizedBiArray) {
            const groupedData = MonetizedBiArray.reduce(
                (accumulator, currentValue) => {
                    const impactType = currentValue.impactType;
                    const fteValue = parseFloat(currentValue.fteValue) || 0.0;
                    const dollarValue = parseFloat(currentValue.dollarValue) || 0.0;
                    const existingRow = accumulator.find(
                        (row) => row.impactType === impactType
                    );
                    if (existingRow) {
                        existingRow.fteValue += fteValue;
                        existingRow.dollarValue += dollarValue;
                    } else {
                        accumulator.push({ impactType, fteValue, dollarValue });
                    }
                    return accumulator;
                },
                []
            );
            // console.log("grouped data",groupedData)

            if (groupedData.length === 0) {
                fteValue = 0;
                dollarValue = 0;
            } else {
                fteValue = groupedData[0].fteValue;
                dollarValue = groupedData[0].dollarValue;
            }
            this.setState(
                {
                    totalMonetizedFTE: fteValue,
                    totalMonetizedDollar: dollarValue,
                },
                () => { }
            );
        }
    };


    newBusinessImpactSummary() {
        let myArray = [...this.state.businessImpactValueArray];
        const groupedData = myArray.reduce((accumulator, currentValue) => {
            const impactType = currentValue.impactType;
            const monetizableType = currentValue.monetizableType;
            const fteValue = parseFloat(currentValue.fteValue) || 0.0;
            const dollarValue = parseFloat(currentValue.dollarValue) || 0.0;

            const existingRow = accumulator.find(
                (row) =>
                    row.impactType === impactType &&
                    row.monetizableType === monetizableType
            );
            if (existingRow) {
                existingRow.fteValue += fteValue;
                existingRow.dollarValue += dollarValue;
            } else {
                accumulator.push({
                    impactType,
                    monetizableType,
                    fteValue,
                    dollarValue,
                });
            }
            return accumulator;
        }, []);
        this.setState({ summaryArray: groupedData }, () => { });
        this.addMonetizableValues();
    }

    pull_data = ({ businessImpactValueArray }) => {
        if (businessImpactValueArray) {
            this.setState(
                { businessImpactValueArray: businessImpactValueArray },
                () => {
                    this.newBusinessImpactSummary();
                    let dollarTotal = 0.0;
                    let fteValue = 0.0;
                    this.state.businessImpactValueArray.map((item) => {
                        if (item && item.DollarImpact) {
                            dollarTotal += parseFloat(item.DollarImpact);
                        }
                        if (item && item.dollarValue) {
                            dollarTotal += parseFloat(item.dollarValue);
                        }
                        if (item && item.FTEImpact) {
                            fteValue += parseFloat(item.FTEImpact);
                        }
                        if (item && item.fteValue) {
                            fteValue += parseFloat(item.fteValue);
                        }
                    });
                    this.setState({ finalImpactDollar: dollarTotal }, () => {
                    });
                    this.setState({ finalImpactFTE: fteValue }, () => {
                    });
                },
                () => {
                }
            );
        }
    };
    pull_file_data = ({
        fileName,
        fileType,
        fileContent,
        showClientApprovalFields,
        FPnAOHRMonetization,
    }) => {
        if (fileName) {
            this.setState({ FPnAFileNameMonetization: fileName }, () => { });
        }
        if (fileType) {
            this.setState({ FPnAFileTypeMonetization: fileType }, () => { });
        }
        if (fileContent) {
            this.setState({ FPnAFileMonetization: fileContent }, () => { });
        }
        this.setState(
            { showClientApprovalFields: showClientApprovalFields },
            () => { }
        );
        this.setState({ FPnAOHRMonetization: FPnAOHRMonetization }, () => {

        });
    };
    remove_bi_data_after_delete = (filteredIdeaArray) => {
        if (filteredIdeaArray) {
            this.setState({ businessImpactValueArray: filteredIdeaArray }, () => {
                this.newBusinessImpactSummary();
                let dollarTotal = 0.0;
                let fteValue = 0.0;
                this.state.businessImpactValueArray.map((item) => {
                    if (item && item.DollarImpact) {
                        dollarTotal += parseFloat(item.DollarImpact);
                    }
                    if (item && item.dollarValue) {
                        dollarTotal += parseFloat(item.dollarValue);
                    }
                    if (item && item.FTEImpact) {
                        fteValue += parseFloat(item.FTEImpact);
                    }
                    if (item && item.fteValue) {
                        fteValue += parseFloat(item.fteValue);
                    }
                });
                this.setState({ finalImpactDollar: dollarTotal }, () => { });
                this.setState({ finalImpactFTE: fteValue }, () => { });
            });
        }
    };

    pull_monetization_sowId = (localSowId) => {
        this.setState({ SowID: localSowId }, () => { });
    };
    pull_monetization_fpna_name = (localFpnaName) => {
        this.setState({ FPnANameMonetization: localFpnaName }, () => { });
    };
    pull_monetization_fpna_ohr = (localFpnaohr) => {
        this.setState({ FPnAOHRMonetization: localFpnaohr }, () => { });
    };
    pull_monetizedTotal = ({ fteValue, dollarValue }) => {
        if (fteValue) {
            this.setState({ totalMonetizedDollar: fteValue }, () => { });
        }
        if (dollarValue) {
            this.setState({ totalMonetizedFTE: dollarValue }, () => { });
        }
    };
    pull_monetizableProject = (e) => {
        if (e) {
            this.setState({ monetizableProjectSelectedOption: e }, () => {
            });
        }
    };
    pull_monetizableStatus = (e) => {
        if (e) {
            this.setState({ monetizationStatusSelectedOption: e }, () => {
            });
        }
    };

    handleVDTypeChange = ({ e }) => {
        if (e) {
            this.setState({ impactOptionSelected: e }, () => {
                if (this.state.impactOptionSelected.label === "No Value Delivery") {
                    this.setState(
                        {
                            showClientFields: false,
                            showGOLFields: false,
                            finalImpactDollar: 0,
                            monetizableProjectSelectedOption: null,
                            monetizationStatusSelectedOption: null,
                            showClientApprovalFields: false,
                        },
                        () => { }
                    );
                }
                if (
                    this.state.impactOptionSelected.label === "Value Delivered to Client"
                ) {
                    this.setState(
                        { showClientFields: true, showGOLFields: false },
                        () => { }
                    );
                }
                if (
                    this.state.impactOptionSelected.label === "Value Delivered to Genpact"
                ) {
                    this.setState({ showClientFields: false, showGOLFields: true });
                }
                if (
                    this.state.impactOptionSelected.label ===
                    "Value Delivered to Client + Genpact"
                ) {
                    this.setState({ showClientFields: true, showGOLFields: true });
                }
            });
        }
    };
    fetchFPAOHRforIncreaseInRevenue = (e) => {
        if (e === true) {
            this.setState({ appropriateAttachment: true }, () => { });
        } else {
            this.setState({ appropriateAttachment: false }, () => { });
        }
    };

    pullMonetizationGOLName = (localGOLName) => {
        this.setState({ golMonetizationName: localGOLName }, () => {
        });
    };
    pullMonetizationGOLOhr = (localGOLOhr) => {
        this.setState({ golMonetizationOhr: localGOLOhr }, () => {
        });
    };
    pullCategoryNotListed = (localShowHref) => {
        this.setState({ showHrefModal: localShowHref }, () => {

        });
    };
    pullPPTDeck = ({ fileName, fileType, fileContent }) => {
        //console.log(fileName,fileType,fileContent)
        this.setState({ filename1: fileName, fileType1: fileType, base64File1: fileContent }, () => { })
    }
    pullClientDocName = (fileName) => {
        this.setState({ signOffFilename: fileName, }, () => { })
    }
    pullClientDocType = (fileType) => {
        if (fileType) {
            this.setState({ signOffFileType: fileType }, () => { })
        }
    }
    pullClientDocContent = (fileContent) => {
        if (fileContent) {
            this.setState({ signOffContent: fileContent }, () => { })
        }
    }

    // gol file details
    pullgolFileName = (fileName) => {
        this.setState({ filename3: fileName }, () => { })
    }
    pullgolFileType = (fileType) => {
        this.setState({ fileType3: fileType }, () => { })
    }
    pullgolContent = (fileContent) => {
        this.setState({ base64File3: fileContent }, () => { })
    }

    // fpna file details
    pullfpnafileName = (fileName) => {
        if (fileName) {
            this.setState({ filename4: fileName }, () => {
                // console.log("fpna doc name",this.state.filename4)
            })
        }
    }
    pullfpnafileType = (fileType) => {
        if (fileType) {
            this.setState({ fileType4: fileType }, () => {
                // console.log("fpna doc type",this.state.fileType4)
            })
        }
    }
    pullfpnafileContent = (fileContent) => {
        this.setState({ base64File4: fileContent }, () => {
            // console.log("fpna doc file content",this.state.base64File4)
        })
    }

    pullCustName = (e) => {
        this.setState({ CustName: e }, () => {
            //console.log(this.state.CustName)
        })
    }
    pullClientDesign = (e) => {
        this.setState({ CustDesgn: e }, () => {
            //console.log(this.state.CustDesgn)
        })
    }
    pullCustSignOffDate = (e) => {
        this.setState({ CustSignOffDate: e }, () => { })
    }
    pullGOLOHR = (e) => {
        this.setState({ GOLOHRVD: e }, () => {
            //console.log("pull GOL OHR VD=>",this.state.GOLOHRVD)
        })
    }
    pullGolName = (name) => {
        this.setState({ GOLNameVD: name }, () => {
            //console.log("pull GOL Name VD =>",this.state.GOLNameVD)
        })
    }
    pullFpnaName = (name) => {
        this.setState({ FPnAName: name }, () => {
            //console.log(this.state.FPnAName)
        })
    }
    pullFpnaOhr = (e) => {
        this.setState({ FPnAOHR: e }, () => {
            //console.log(this.state.FPnAOHR)
        })
    }
    pull_fileDetails = (obj) => {
        this.setState({ golFpnaFileDetails: obj }, () => { })
    }

    // BI Function ends here




    viewDone = () => {
        this.props.history.push("/home");
    }


    raiseFlag = () => {

    }
    handleEditIdea = (id) => {
        this.props.history.push({
            pathname: "/update-opportunity",
            state: id
        })

    }
    handleAuditTrail = () => {
        this.setState({ auditTrail: true }, () => { })
    }

    formatDate = (columnName) => {
        return new Date(columnName).toLocaleDateString('en-CA').split('/').reverse().join('-');
    }

    formatDateStringWithTime(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        });
    }

    render() {
        return (
            <section className="u-clearfix u-section-1 opp_create_page" id="sec-0f7f">
                <div className="u-clearfix u-sheet u-sheet-1" style={{ marginBottom: 20 }}>
                    <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
                        <div className="u-container-layout u-container-layout-1">
                            <div className="">
                                <div className="view_idea_top_header_btn_block">
                                    <div className="">
                                        {/* <button style={{ marginRight: "15px" }} className="u-active-custom-color-6 u-border-2 u-btn u-btn-round u-radius-6 update_parent_btn"
                                                onClick={this.showClusterModal}>Update Parent
                                            </button> */}
                                    </div>
                                    <div className="">
                                        {/* <button style={{ marginRight: "15px" }} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                                onClick={this.raiseFlag}>Raise Flag
                                            </button> */}
                                    </div>
                                    {/* <div className="">
                                        <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                            onClick={() => this.handleEditIdea(this.state.oppDetails.RecID ? this.state.oppDetails.RecID : "")}>
                                            Edit Opportunity
                                        </button>
                                    </div> */}
                                    <div className="">
                                        <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                            onClick={(e) => this.handleAuditTrail(e)}>
                                            Audit Trail
                                        </button>
                                    </div>
                                </div>
                                <div className="text-left mt-4">
                                    {/* {this.state.clusterModal ? (
                                            <>
                                                <div className="cluster_modal_main_block">
                                                    <div className="custom_modal cluster_modal_block">
                                                        <div className="modal_body" style={{ width: "400px" }}>
                                                            <div className="row">
                                                                <div className="col-md-11">
                                                                    <h3 className="modal_title text-center">
                                                                        Update Parent
                                                                    </h3>
                                                                </div>
                                                                <div className="col-md-1">
                                                                    <button
                                                                        type="button"
                                                                        className="close text-right"
                                                                        onClick={(e) =>
                                                                            this.setState({ clusterModal: false })
                                                                        }
                                                                    >
                                                                        &times;
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="clustor_modal_fields_block">
                                                                <div className="clustor_modal_field">
                                                                    <label className="form-attribute">
                                                                        <span style={{ color: "red" }}>*</span> Project
                                                                        Type:
                                                                    </label>
                                                                    {this.state.displayParentAlpha ? (
                                                                        <>
                                                                            <input
                                                                                disabled={true}
                                                                                value={
                                                                                    this.state.projectTypeSelectedOption
                                                                                        ? this.state.projectTypeSelectedOption
                                                                                            .label
                                                                                        : ""
                                                                                }
                                                                                className="form-input"
                                                                                placeholder="Choose project type"
                                                                            />
                                                                        </>
                                                                    ) : (
                                                                        <Select
                                                                            isDisabled={
                                                                                this.state.enableClusterAction
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            isSearchable={false}
                                                                            options={projectType}
                                                                            value={this.state.projectTypeSelectedOption}
                                                                            styles={this.state.selectStyle.projectType}
                                                                            placeholder="Choose project type"
                                                                            onChange={this.changeProjectType}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="clustor_modal_field">
                                                                    <label className="form-attribute">
                                                                        <span style={{ color: "red" }}>*</span> Project
                                                                        ID:
                                                                    </label>

                                                                    {this.state.displayParentAlpha ? (
                                                                        <>
                                                                            <input
                                                                                value={
                                                                                    this.state.projectIDSelectedOption &&
                                                                                        this.state.projectIDSelectedOption.label
                                                                                        ? this.state.projectIDSelectedOption
                                                                                            .label
                                                                                        : ""
                                                                                }
                                                                                disabled={true}
                                                                                className="form-input"
                                                                                placeholder="Choose project ID"
                                                                            />
                                                                        </>
                                                                    ) : (
                                                                        <Select
                                                                            isSearchable
                                                                            onInputChange={
                                                                                this.handleClusterProjectInputChange
                                                                            }
                                                                            options={this.displayProjectID()}
                                                                            value={this.state.projectIDSelectedOption}
                                                                            styles={
                                                                                this.state.selectStyle
                                                                                    .projectIDSelectedOption
                                                                            }
                                                                            isDisabled={
                                                                                this.state.enableClusterAction
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            placeholder="Choose project ID"
                                                                            onChange={this.changeProjectID}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="cluster_modal_btn_group">
                                                                <button
                                                                    className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                                                    disabled={
                                                                        this.state.isClusterSaveDisabled ? true : false
                                                                    }
                                                                    onClick={this.saveParentLink}
                                                                >
                                                                    Save
                                                                </button>
                                                                <button
                                                                    className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6"
                                                                    onClick={this.removeParentFunc}
                                                                    style={{ marginRight: "15px" }}
                                                                    disabled={
                                                                        this.state.enableClusterAction ? false : true
                                                                    }
                                                                >
                                                                    Remove Parent
                                                                </button>
                                                                {this.state.displayParentAlpha ? (
                                                                    <>
                                                                        <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6 show_hierarchy_link_btn">
                                                                            <Link
                                                                                className="show_hierarchy_link_btn_anchor"
                                                                                to={`/showTree?ID=${this.state.ideaItem.IdeaId}`}
                                                                                target="_blank"
                                                                            >
                                                                                Show Hierarchy
                                                                            </Link>
                                                                        </button>
                                                                    </>
                                                                ) : (
                                                                    <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6 show_hierarchy_link_btn">
                                                                        Show Hierarchy
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : null} */}
                                </div>

                                <div className="gen_ai_audit_log">
                                    {
                                        this.state.auditTrail
                                            ?
                                            <>
                                                <div className="custom_modal">
                                                    <div className="modal_body">
                                                        <div className="col-md-12">
                                                            <div className="">
                                                                <div className="row">
                                                                    <div className="col-md-10">
                                                                        <h3 className="modal_title">
                                                                            Audit Trail
                                                                        </h3>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <button type="button" className="close text-right" onClick={(e) => this.setState({ auditTrail: false, })}>
                                                                            &times;
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="genai_popup_fields">
                                                                <div className="qc_responsive_comment_log">
                                                                    <table className='table-responsive table-bordered' style={{ width: "100%" }}>
                                                                        <thead className="">
                                                                            <tr>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Date & Time</label>
                                                                                </th>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>OHR</label>
                                                                                </th>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Name</label>
                                                                                </th>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Role</label>
                                                                                </th>
                                                                                {/* <th className=" text-center audit_log_sub_fields_header">
                                                                                        <label>Action</label>
                                                                                    </th> 
                                                                                    <th className=" text-center audit_log_sub_fields_header">
                                                                                        <label>Opportunity Stage</label>
                                                                                    </th>*/}
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Opportunity State</label>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {this.state.auditTrailData && this.state.auditTrailData.map((item, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td className="text-center audit_log_sub_fields_value_block">
                                                                                            <label>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                        </td>
                                                                                        <td className=" text-center audit_log_sub_fields_value_block">
                                                                                            <label>
                                                                                                {item.UpdaterOHR}
                                                                                            </label>
                                                                                        </td>
                                                                                        <td className=" text-center audit_log_sub_fields_value_block">
                                                                                            <label>
                                                                                                {item.Updater}
                                                                                            </label>
                                                                                        </td>
                                                                                        <td className=" text-center audit_log_sub_fields_value_block">
                                                                                            <label>
                                                                                                {item.UpdaterRole}
                                                                                            </label>
                                                                                        </td>
                                                                                        {/* <td className=" text-center audit_log_sub_fields_value_block">
                                                                                                <label>
                                                                                                    {item.Action}
                                                                                                </label>
                                                                                            </td>
                                                                                            <td className="text-center audit_log_sub_fields_value_block">
                                                                                                <label>
                                                                                                    {item.OppStage}
                                                                                                </label>
                                                                                            </td> */}
                                                                                        <td className=" text-center audit_log_sub_fields_value_block">
                                                                                            <label>
                                                                                                {item.OppState}
                                                                                            </label>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }

                                </div>


                            </div>
                            <h4 style={{ marginTop: 10, marginBottom: -35, textAlign: 'center', color: '#00AECF', fontFamily: 'Arial', fontSize: 22 }}>View opportunity: {this.state.oppDetails.RecIDAlpha ? this.state.oppDetails.RecIDAlpha : ""}</h4>
                            <div className="u-align-left u-container-style u-expanded-width u-group u-shape-rectangle u-group-2">
                                <div className="u-container-layout u-container-layout-2">
                                    <div className="main_fields_block">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute">Submitter OHR</label>
                                                    <input tabIndex={1} className="form-input" type="text" value={this.state.oppDetails.CreatedBy ? this.state.oppDetails.CreatedBy : ""} disabled readOnly />
                                                </div>
                                                <div className="genai_fields">
                                                    <label className="form-attribute">Submitter Name</label>
                                                    <input tabIndex={2} className="form-input" type="text" value={this.state.oppDetails.CreatedName ? this.state.oppDetails.CreatedName : ""} disabled readOnly />
                                                </div>
                                                {/* <div className="genai_fields">
                                                    <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> Parent Opportunity Id</label>
                                                    <input tabIndex={3} className="textarea-css form-attribute" type="text" value={this.state.ParentIDAlpha} onChange={(e)=>this.handleParentIDChange(e.target.value)} />
                                                </div> */}
                                                <div className="genai_fields">
                                                    <label className="form-attribute" >Opportunity Name</label>
                                                    <input tabIndex={4} className="form-input" type="text" disabled value={this.state.oppDetails.OppName ? this.state.oppDetails.OppName : ""} readOnly/>
                                                </div>
                                                {
                                                    this.state.oppDetails.ModifiedDate
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" >Last Modified on</label>
                                                                <input tabIndex={4} className="form-input" disabled value={this.state.ModifiedDate} style={{ color: "#122539" }} readOnly/>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute">Account</label>
                                                    <input disabled placeholder="Account" value={this.state.oppDetails.Account ? this.state.oppDetails.Account : ""} className="form-input"  readOnly />
                                                </div>
                                                <div className="genai_fields">
                                                    <label className="form-attribute" >Vertical</label>
                                                    <input tabIndex={8} className="form-input" type="text" disabled value={this.state.oppDetails.Vertical ? this.state.oppDetails.Vertical : ""} placeholder="Vertical"  readOnly />
                                                </div>
                                                <div className="genai_fields">
                                                    <label className="form-attribute">Owning Units </label>
                                                    <input tabIndex={6} className="form-input" type="text" disabled value={this.state.oppDetails.Unit ? this.state.oppDetails.Unit : ""} placeholder="Unit"  readOnly />
                                                </div>
                                                {
                                                    this.state.oppDetails.ModifiedBy
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" >Last Modified by OHR</label>
                                                                <input tabIndex={4} className="form-input" type="text" disabled value={this.state.oppDetails.ModifiedBy ? this.state.oppDetails.ModifiedBy : ""}  readOnly />
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" >Managing Entity</label>
                                                    <input tabIndex={7} placeholder="Managing Entity" value={this.state.oppDetails.MangingDept ? this.state.oppDetails.MangingDept : "NA"} className="form-input" disabled  readOnly />
                                                </div>

                                                <div className="genai_fields">
                                                    <label className="form-attribute">Segment</label>
                                                    <input tabIndex={9} className="form-input" type="text" disabled value={this.state.oppDetails.Segment ? this.state.oppDetails.Segment : ""} placeholder="Segment"  readOnly />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute">Archetype</label>
                                                    <input tabIndex={10} className="form-input" type="text" disabled value={this.state.oppDetails.Archetype ? this.state.oppDetails.Archetype : ""} placeholder="Archetype"  readOnly />
                                                </div>
                                                <div className="genai_fields">
                                                    <label className="form-attribute" >FTE Count (inclusive of all regions)</label>
                                                    <input placeholder="Number of FTEs" disabled value={this.state.oppDetails.AccFTE ? this.state.oppDetails.AccFTE : ""} className="form-input"  readOnly />
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >Unit SPOC</label>
                                                        <input className="form-input" placeholder="Unit SPOC" type="text" disabled value={this.state.oppDetails.UnitOHRName ? this.state.oppDetails.UnitOHRName : ""}  readOnly />

                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >Black Belt</label>
                                                        <input className="form-input" placeholder="Black Belt" type="text" disabled value={this.state.oppDetails.BB ? this.state.oppDetails.BB : ""}  readOnly />

                                                    </div>
                                                </div>


                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >Opp SPOC - 1</label>
                                                        <input className="form-input" placeholder="Opp SPOC 1" type="text" disabled value={this.state.oppDetails.Spoc_1 ? this.state.oppDetails.Spoc_1 : ""}  readOnly />
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >Master Black Belt</label>
                                                        <input className="form-input" placeholder="MBB" type="text" disabled value={this.state.oppDetails.MBB ? this.state.oppDetails.MBB : ""}  readOnly />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute">Opp SPOC - 2</label>
                                                        <input className="form-input" placeholder="Opp SPOC 2" type="text" disabled value={this.state.oppDetails.Spoc_2 ? this.state.oppDetails.Spoc_2 : ""}  readOnly />
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >GOL OHR</label>
                                                        <input className="form-input" type="text" placeholder="GOL OHR" disabled value={this.state.oppDetails.GOLOHR ? this.state.oppDetails.GOLOHR : ""} readOnly  />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute">Opp Solution Owner</label>
                                                        <input className="form-input" placeholder="Solution Owner" type="text" disabled value={this.state.oppDetails.SolnOwner ? this.state.oppDetails.SolnOwner : ""} readOnly />
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute">GOL</label>
                                                        <input className="form-input" type="text" disabled placeholder="GOL Name" value={this.state.oppDetails.GOL ? this.state.oppDetails.GOL : ""} readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >Opportunity Stage</label>
                                                        <input className="form-input" placeholder="Opportunity Stage" type="text" disabled value={this.state.oppDetails.OppStage ? this.state.oppDetails.OppStage : ""} readOnly />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute">Opportunity State</label>
                                                    <input className="form-input" placeholder="Opportunity State" type="text" disabled value={this.state.oppDetails.OppState ? this.state.oppDetails.OppState : ""} readOnly />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute">Cohort</label>
                                                    <input className="form-input" type="text" placeholder="Cohort Value" value={this.state.oppDetails.Cohort ? this.state.oppDetails.Cohort : ""} readOnly />
                                                </div>

                                            </div>
                                            <div className="col-md-3">


                                            </div>
                                        </div>
                                    </div>


                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >
                                                            Service Line </label>
                                                        <div>
                                                            <textarea
                                                                type="text" placeholder='Service line value'
                                                                style={{ maxWidth: "100%", border: "1px solid lightgrey" }}
                                                                disabled value={this.state.ServiceLine} className="form-input textarea_width" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" >Predominant Service Line</label>
                                                    <input
                                                        placeholder="Predominant service line"
                                                        value={this.state.oppDetails.ServiceLineMain}
                                                        disabled
                                                        className="form-input"
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >
                                                            Area / Process</label>
                                                        <div>
                                                            <textarea
                                                                type="text" placeholder='Area / Process value'
                                                                style={{ maxWidth: "100%", border: "1px solid lightgrey" }}
                                                                disabled value={this.state.Process_L2} className="form-input textarea_width" />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3"></div>
                                        </div>
                                    </div>


                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" >
                                                        GenAI Offering L1</label>
                                                    <div className="">

                                                        <input disabled value={this.state.oppDetails.Offering_1} className="form-input" readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">

                                                <div className="genai_fields">
                                                    <label className="form-attribute" >
                                                        GenAI Offering L2</label>
                                                    <div className="">
                                                        {
                                                            this.state.oppDetails.Offering_1 && this.state.oppDetails.Offering_1 === "Bespoke"
                                                            ?
                                                            <>
                                                                <input disabled value={this.state.oppDetails.Offering_2_Other} className="form-input" readOnly />
                                                            </>
                                                            :
                                                            <input disabled value={this.state.oppDetails.Offering_2} className="form-input" readOnly />
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >
                                                            Is a hero Product?</label>

                                                        <input disabled value={this.state.oppDetails.IsHero} className="form-input" placeholder='Is this a hero product ?' readOnly />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-3"></div>
                                        </div>
                                    </div>

                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >Client Background
                                                            <span className="field_max_characters">(max. 2000 chars)</span>
                                                        </label>
                                                        <div className="">
                                                            <textarea className="form-input textarea_width" type="text" placeholder='Client Background' disabled value={this.state.oppDetails.ClientDtls ? this.state.oppDetails.ClientDtls : ""} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >GenAI Solution
                                                            <span className="field_max_characters">(max. 2000 chars)</span>
                                                        </label>
                                                        <div className="">
                                                            <textarea className="form-input textarea_width" type="text" placeholder='GenAI Solution' disabled value={this.state.oppDetails.Solution ? this.state.oppDetails.Solution : ""} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >Client Problem
                                                            <span className="field_max_characters">(max. 2000 chars)</span>
                                                        </label>
                                                        <div className="">
                                                            <textarea placeholder='Client Problem' className="form-input textarea_width" type="text" disabled value={this.state.oppDetails.Problem ? this.state.oppDetails.Problem : ""} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" >Overall number of FTEs in scope</label>
                                                    <input className="form-input" placeholder="Max limit of 5000 FTEs in scope" type="text" value={this.state.oppDetails.OverallFTE}  readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            {
                                                (this.state.oppDetails.Offering_1 && this.state.oppDetails.Offering_1 === "CKA")
                                                    ?
                                                    <>
                                                        <div className="">
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" >Overall number of SOPs in scope</label>
                                                                <input className="form-input" placeholder='Max limit of 1000 SOPs' type="text" value={this.state.oppDetails.OverallSOP} readOnly  />
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }

                                        </div>
                                        <div className="col-md-3"></div>
                                        <div className="col-md-3"></div>
                                    </div>



                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="genai_fields">
                                                <label className="form-attribute">
                                                    Help Needed from Leadership
                                                    <span className="field_max_characters">(max. 300 chars)</span>
                                                </label>
                                                <div className="">
                                                    <textarea placeholder="Help Needed from Leadership" className="form-input textarea_width" type="text" disabled value={this.state.oppDetails.LeadHelp ? this.state.oppDetails.LeadHelp : ""} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-5">
                                            <div className="">
                                                <div className="genai_fields">
                                                    <label className="form-attribute">CXO Report - Brief Status
                                                        <span className="field_max_characters">(max. 300 chars)</span>
                                                    </label>
                                                    <div className="">
                                                        <textarea placeholder="CXO Report - Brief Status" className="form-input textarea_width" type="text" disabled value={this.state.oppDetails.CXO ? this.state.oppDetails.CXO : ""} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >Next Planned Discussion Date</label>
                                                        <input className="form-input" disabled value={this.state.PlanDiscussDate ? this.state.PlanDiscussDate : ""} style={{ color: "#122539" }} placeholder='dd-mm-yyyy' readOnly  />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <div className="">
                                                            <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>Date by when discussion outcome will be known ?</label>
                                                        </div>
                                                        <input className="form-input" disabled value={this.state.DiscussEstDate ? this.state.DiscussEstDate : ""} style={{ color: "#122539" }} placeholder='dd-mm-yyyy' readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >Proposal Shared ?</label>
                                                        <div className="">
                                                            <input className="form-input" placeholder="Proposal Shared ?" type="text" disabled value={this.state.oppDetails.IsProposalShared ? this.state.oppDetails.IsProposalShared : ""} readOnly />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-md-3">
                                                    <div className="">
                                                        <div className="genai_fields">
                                                            <label className="form-attribute" > Proposal File</label>
                                                            <div className="file_upload_plus_mark_block">
                                                                <button className="file_name view_genai_file_name">{this.state.oppDetails.ProposalFileName}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                        </div>
                                    </div>

                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" >
                                                        Environment
                                                    </label>
                                                    <div className="">
                                                        <input className="form-input" placeholder="Solution Owner" type="text" disabled value={this.state.oppDetails.SolnEnviron ? this.state.oppDetails.SolnEnviron : ""} readOnly />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >Funding</label>
                                                        <input className="form-input" placeholder="Solution Owner" type="text" disabled value={this.state.oppDetails.SolnFund ? this.state.oppDetails.SolnFund : ""} readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                {
                                                    this.state.oppDetails.SolnFund && (this.state.oppDetails.SolnFund === "Client" || this.state.oppDetails.SolnFund === "Client + Genpact")
                                                        ?
                                                        <>
                                                            <div className="">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >Client Funding (USD) </label>
                                                                    <input className="form-input" type="text" value={this.state.oppDetails.ClientDollar} readOnly />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="col-md-3">
                                                {
                                                    this.state.oppDetails.SolnFund && (this.state.oppDetails.SolnFund === "Genpact" || this.state.oppDetails.SolnFund === "Client + Genpact")
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" >Cost to Genpact for Build (USD)</label>
                                                                <input className="form-input" type="text" value={this.state.oppDetails.GenpactDollar} readOnly />
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>


                                        </div>
                                    </div>

                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>Is a SOW in scope for this piece of work ?</label>
                                                    <input className="form-input" placeholder="Solution Owner" type="text" disabled value={this.state.oppDetails.IsSOWScope ? this.state.oppDetails.IsSOWScope : ""} readOnly />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                {
                                                    this.state.oppDetails.IsSOWScope && this.state.oppDetails.IsSOWScope === "Yes"
                                                        ?
                                                        <>
                                                            <div className="">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>SOW signed & agreed with the client ?</label>
                                                                    <input className="form-input" placeholder="Solution Owner" type="text" disabled value={this.state.oppDetails.IsSOWSigned ? this.state.oppDetails.IsSOWSigned : ""} readOnly />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="col-md-3">
                                                {
                                                    this.state.oppDetails.IsSOWScope && this.state.oppDetails.IsSOWScope === "Yes"
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" >Expected SOW submission Date / SOW submission Date</label>
                                                                <input className="form-input" disabled value={this.state.SOWSubDate ? this.state.SOWSubDate : ""} style={{ color: "#122539" }} placeholder='dd-mm-yyyy' readOnly />
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="col-md-3">
                                                {
                                                    this.state.oppDetails.IsSOWScope && this.state.oppDetails.IsSOWScope === "Yes"
                                                        ?
                                                        <>
                                                            <div className="">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >Expected SOW Closure Date/ SOW closure Date</label>
                                                                    <input className="form-input" disabled value={this.state.SOWCloseDate ? this.state.SOWCloseDate : ""} style={{ color: "#122539" }} placeholder='dd-mm-yyyy' readOnly />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>Expected Deployment Date/ Go-Live Date</label>
                                                        <input className="form-input" disabled value={this.state.CompletionDate ? this.state.CompletionDate : this.state.CompletionDate} style={{ color: "#122539" }} placeholder='dd-mm-yyyy' readOnly />
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >Functional Owner OHR</label>
                                                        <input className="form-input" type="text" value={this.state.oppDetails.FunctionalOHR ? this.state.oppDetails.FunctionalOHR : ""} placeholder='Functional Owner OHR' readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >Functional Owner</label>
                                                        <input className="form-input" type="text" disabled value={this.state.oppDetails.Functional ? this.state.oppDetails.Functional : ""} placeholder='Functional Owner' readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" >Technical Owner OHR</label>
                                                    <input className="form-input" type="text" value={this.state.oppDetails.TechOHR ? this.state.oppDetails.TechOHR : ""} placeholder='Technical Owner OHR' readOnly />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >Technical Owner</label>
                                                        <input className="form-input" type="text" disabled value={this.state.oppDetails.Tech ? this.state.oppDetails.Tech : ""} placeholder='Technical Owner' readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="genai_fields">
                                                <label className="form-attribute" >Discussion Led By</label>
                                                <input className="form-input" placeholder="Transition led ?" type="text" disabled value={this.state.oppDetails.TransitionLed ? this.state.oppDetails.TransitionLed : "LDT led"} readOnly />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >Domain Knowledge
                                                            <span className="field_max_characters">(max. 300 chars)</span>
                                                        </label>
                                                        <div className="">
                                                            <textarea className="form-input textarea_width" placeholder="Domain Knowledge" type="text"
                                                                disabled value={this.state.oppDetails.Knowledge ? this.state.oppDetails.Knowledge : ""} />
                                                        </div>
                                                    </div>
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >Data used
                                                            <span className="field_max_characters">(max. 300 chars)</span>
                                                        </label>
                                                        <div className="">
                                                            <textarea className="form-input textarea_width" placeholder="Data used" type="text"
                                                                disabled value={this.state.oppDetails.Data ? this.state.oppDetails.Data : ""} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >Algorithms Used
                                                            <span className="field_max_characters">(max. 300 chars)</span>
                                                        </label>
                                                        <div className="">
                                                            <textarea className="form-input textarea_width" placeholder="Algorithms Used" type="text" disabled
                                                                value={this.state.oppDetails.Algo ? this.state.oppDetails.Algo : ""} />
                                                        </div>
                                                    </div>


                                                </div>
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >Tech Infrastructure used
                                                            <span className="field_max_characters">(max. 300 chars)</span>
                                                        </label>
                                                        <div className="">
                                                            <textarea className="form-input textarea_width" placeholder="Tech Infrastructure used" type="text" disabled
                                                                value={this.state.oppDetails.Infra ? this.state.oppDetails.Infra : ""} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >Share holder value created
                                                            <span className="field_max_characters">(max. 300 chars)</span>
                                                        </label>
                                                        <div className="">
                                                            <textarea className="form-input textarea_width" placeholder="Share holder value created"
                                                                type="text" disabled value={this.state.oppDetails.ValueCreated ? this.state.oppDetails.ValueCreated : ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="genai_fields">
                                                <label className="form-attribute" >SFDC OLI Number</label>
                                                <input className="form-input" placeholder='OLI-XXXXXXX value' disabled type="text" value={this.state.oppDetails.OLINum ? this.state.oppDetails.OLINum : ""}  readOnly />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="genai_fields">
                                                <label className="form-attribute" >SFDC OPP ID</label>
                                                <input className="form-input" placeholder='Opp-XXXXXXX value' disabled type="text" value={this.state.oppDetails.OppID ? this.state.oppDetails.OppID : ""}  readOnly />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="genai_fields">
                                                <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>Number of FTEs currently using this solution</label>
                                                <input className="form-input" placeholder='Max limit of 5000 FTEs' disabled type="text" value={this.state.oppDetails.FTEUsage}  readOnly />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute">
                                                        &nbsp;Total People in the process
                                                    </label>
                                                    <input
                                                        value={this.state.oppDetails.ProcessUsers}
                                                        type="number"
                                                        placeholder='Total People in the process'
                                                        className="form-input hide_numeric_input_arrows"
                                                        disabled
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;# of people with access to the solution
                                                    </label>
                                                    <input
                                                        value={this.state.oppDetails.AccessUsers}
                                                        type="number"
                                                        placeholder='# of people with access to the solution'
                                                        className="form-input hide_numeric_input_arrows"
                                                        disabled
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;People using it daily
                                                    </label>
                                                    <input
                                                        value={this.state.oppDetails.DailyUsers}
                                                        type="number"
                                                        placeholder='People using it daily'
                                                        className="form-input hide_numeric_input_arrows"
                                                        disabled
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;People using it weekly
                                                    </label>
                                                    <input
                                                        value={this.state.oppDetails.WeeklyUsers}
                                                        type="number"
                                                        placeholder='People using it weekly'
                                                        className="form-input hide_numeric_input_arrows"
                                                        disabled
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;Total Weekly transactions
                                                    </label>
                                                    <input
                                                        value={this.state.oppDetails.WeeklyTrans}
                                                        type="number"
                                                        className="form-input hide_numeric_input_arrows"
                                                        placeholder="Total weekly transcations"
                                                        disabled
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" >
                                                        &nbsp;Transactions processed through GenAI solution
                                                    </label>
                                                    <input
                                                        value={this.state.oppDetails.SolnTrans}
                                                        type="number"
                                                        placeholder="transaction processed through GenAI Solution"
                                                        className="form-input hide_numeric_input_arrows"
                                                        disabled
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;Number of upvotes
                                                    </label>
                                                    <input
                                                        value={this.state.oppDetails.Upvotes}
                                                        type="number"
                                                        placeholder='Number of upvotes'
                                                        className="form-input hide_numeric_input_arrows"
                                                        disabled
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;% of upvotes
                                                    </label>
                                                    <input
                                                        value={this.state.oppDetails.UpvotesPercent}
                                                        type="number"
                                                        className="form-input hide_numeric_input_arrows"
                                                        placeholder="% of upvotes"
                                                        disabled
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;Accuracy of outcome
                                                    </label>
                                                    <input
                                                        value={this.state.oppDetails.Accuracy}
                                                        type="number"
                                                        placeholder='Accuracy of outcome'
                                                        className="form-input hide_numeric_input_arrows"
                                                        disabled
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;Uptime of the solution
                                                    </label>
                                                    <input
                                                        value={this.state.oppDetails.UptimePercent}
                                                        type="number"
                                                        placeholder='Uptime of the solution'
                                                        className="form-input hide_numeric_input_arrows"
                                                        disabled
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* Reason for stage change starts here  */}
                                    <div className="">
                                        <div className="row">
                                            <div className="col-md-6">                                            
                                                {
                                                    this.state.oppDetails.OppStage && (this.state.oppDetails.OppStage === "7. Declined by Client" || this.state.oppDetails.OppStage=== "8. Descoped" || this.state.oppDetails.OppStage === "9. On-hold")
                                                    ?
                                                    <>
                                                        <div className="genai_fields">
                                                            <label className="form-attribute" >Reason for stage change<span className="field_max_characters">(max. 180 chars)</span>
                                                            </label>
                                                            <div className="">
                                                                <textarea
                                                                    disabled
                                                                    className="form-input textarea_width"
                                                                    type="text"
                                                                    placeholder='Please specify reason for the stage change.'
                                                                    value={this.state.oppDetails.OppStageChange}
                                                                    
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }
                                            </div>
                                        </div>
                                    </div>
                                    {/* Reason for stage change ends here */}



                                    {/* <div className="row">
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" > Go-Live Upload</label>
                                                    <div className="file_upload_plus_mark_block">
                                                        <button className="file_name view_genai_file_name">File name here</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" > GOL/FP&A/Client Approval</label>
                                                    <div className="file_upload_plus_mark_block">
                                                        <button className="file_name view_genai_file_name">File name here</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                            </div>
                                        </div> */}

                                    {
                                        this.state.oppDetails.VDType
                                            ?
                                            <>
                                                <div className="">
                                                    <GenAIFPNAGOL
                                                        IdeaId={this.state.RecID}
                                                        viewIdea={true}
                                                        vdSelected={this.state.impactOptionSelected ? this.state.impactOptionSelected.label : this.state.oppDetails.VDType}
                                                        fetchPPTDeck={this.pullPPTDeck}
                                                        fetchClientDocName={this.pullClientDocName}
                                                        fetchClientDocType={this.pullClientDocType}
                                                        fetchClientDocContent={this.pullClientDocContent}
                                                        fetchGolDocName={this.pullgolFileName}
                                                        fetchGolDocType={this.pullgolFileType}
                                                        fetchGolDocContent={this.pullgolContent}
                                                        fetchfpnaDocName={this.pullfpnafileName}
                                                        fetchfpnaDocType={this.pullfpnafileType}
                                                        fetchfpnaDocContent={this.pullfpnafileContent}

                                                        fetchClientName={this.pullCustName}
                                                        fetchClientRole={this.pullClientDesign}
                                                        fetchClientSignOffDate={this.pullCustSignOffDate}

                                                        fetchGolOhr={this.pullGOLOHR}
                                                        fetchGolName={this.pullGolName}

                                                        fetchFpnaOhr={this.pullFpnaOhr}
                                                        fetchFpnaName={this.pullFpnaName}

                                                        fetchFileDetails={this.pull_fileDetails}
                                                        sendBitoFPNA={this.state.businessImpactValueArray}
                                                        sendIsFpnaRequired={this.state.isFpnaRequired}
                                                        showMandatory={this.state.OppStageSelectedOption && this.state.OppStageSelectedOption.value === 6 ? true : false}
                                                    />
                                                </div>
                                                <div className="gapping_class">
                                                    <GenAIValueDelivery
                                                        func={this.pull_data}
                                                        viewIdea={true}
                                                        vdType={this.handleVDTypeChange}
                                                        IdeaId={this.props.location.state}
                                                        fetchMonetizationFileDetails={this.pull_file_data}
                                                        fetchMonetizationFpnaOhr={this.pull_monetization_fpna_ohr}
                                                        fetchMonetizationFpnaName={this.pull_monetization_fpna_name}
                                                        fetchMonetizationSowID={this.pull_monetization_sowId}
                                                        bbMonetizationTotal={this.pull_monetizedTotal}
                                                        monetizableProject={this.pull_monetizableProject}
                                                        monetizableStatus={this.pull_monetizableStatus}
                                                        fetchFPAOHRforIncreaseInRevenue={this.fetchFPAOHRforIncreaseInRevenue}
                                                        bbMonetizationApproval={false}
                                                        postFpnaValid={false}
                                                        fetchDeletedBIRows={this.remove_bi_data_after_delete}
                                                        fetchMonetizationGOLName={this.pullMonetizationGOLName}
                                                        fetchMonetizationGOLOhr={this.pullMonetizationGOLOhr}
                                                        fetchCategoryNotListed={this.pullCategoryNotListed}
                                                        showMandatory={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? true : false}
                                                    />
                                                </div>
                                            </>
                                            :
                                            null
                                    }




                                    <div className="create_opp_btn_group">
                                        <div className="btn-group">
                                            <button tabIndex={20} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={this.viewDone}>Done</button>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        )
    }



}

export default ViewOpp

