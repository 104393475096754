import React,{useState,useEffect} from 'react';
import { GET_IDEA_URL, REVIEW_IDEA_URL,UPDATE_IDEA_URL,AWAITING_MBB_APPROVAL, GET_METADATA, APPROVED, PENDING, SENT_BACK, REJECTED, DATA_BRIDGE_CAMPAIGN, X_POLLINATION_CAMPAIGN, ACCESS_TOKEN, COMPLETED_STATUS, USER_DATA, SIGNOFF_CONTENT, PPT_CONTENT, REQUEST_CANCELLATION,BI_FORM_DATE,GOL_APPROVAL_CONTENT,AWAITING_QAULITY_CHECK_REVIEW } from '../assets/constants/constants';
import Toast from 'light-toast';
import Select from 'react-select';
import { selectValidation as selectStyle, textBoxValidation } from "../utils/FieldValidation";

let mbbWarningShown = false;

function GenAIValueDelivery(props) {
    //console.log("props from GenAI value delivery component",props)
    const [accessToken] = useState(localStorage.getItem(ACCESS_TOKEN))
    const [ data,setData]= useState({})
    const [ ideaItem,setIdeaItem]= useState({})
    
    // vd states
    const [ finalImpactDollar,setFinalImpactDollar]= useState("")
    const [ finalImpactFTE,setFinalImpactFTE]= useState("")
    const [ businessImpactMasterList,setBusinessImpactMasterList ]= useState([])
    const [ businessImpactValueArray,setBusinessImpactValueArray ]= useState([])
    const [ fteTotal,setFteTotal]= useState("")
    const [ dollarTotal,setDollarTotal]= useState("")

    const [ filteredImpactTypeState,setFilteredImpactTypeState] = useState(null)
    const [ filteredImpactTypeSelectedOption,setFilteredImpactTypeSelectedOption ]= useState([])
    const [ filteredMonetizableTypeState,setFilteredMonetizableTypeState ]= useState([])
    const [ filteredMonetizableSelectedOption,setFilteredMonetizableSelectedOption ]= useState([])

    const [ filteredBISubCategoryState,setFilteredBISubCategoryState] = useState([])
    const [ filteredBISubCategorySelectedOption,setFilteredBISubCategorySelectedOption]= useState([])
    const [ mbbLimit,setMbbLimit]=useState(50000)
    const [ summaryArray,setSummaryArray]=useState([])
    const [ monetizableValues]=useState([
        {
            value:0,
            label:"Improvement in transaction based billing"
        },
        {
            value:1,
            label:"Increase in billable HC"
        },
        {
            value:2,
            label:"Increase in billable hours"
        },
        {
            value:3,
            label:"Revenue from digital deployment"
        },
        {
            value:4,
            label:"Revenue from special projects"
        },
        {
            value:5,
            label:"Value share from improvement project"
        },
        {
            value:6,
            label:"Fixed Fee"
        },
    ])
    const [ totalMonetizedFTE,setTotalMonetizedFTE]=useState(0)
    const [ totalMonetizedDollar,setTotalMonetizedDollar]=useState(0)

    // qc states
    const [ monetizableCheckbox,setMonetizableCheckbox]=useState(false)
    const [ appropriateAttachment,setAppropriateAttachment]=useState(false)
    const [ showHrefModal,setShowHrefModal]=useState(false)
    const [ biThreshold,setBiThreshold]=useState('')
    const [ vrconditiontype,setVrconditiontype]=useState()
    const [ vrVerticalMapping,setVrVerticalMapping]=useState()
    const [ vrRegionMapping,setVrRegionMapping]=useState()

    const [golOhr,setGolOhr]=useState("")
    const [golName,setGolName]=useState("");
    
    const [fpnaOhr,setFpnaOhr]=useState("")
    const [fpnaName,setFpnaName]=useState("")
   
    const [clientApprovalOptions] = useState([
        { value: '0', label: 'Yes' },
        { value: '1', label: 'No' },
    ]) ;

    // monetization states
    const [ SowID,setSowID]=useState('')
    const [ FPnANameMonetization,setFPnANameMonetization]=useState('')
    const [ FPnAOHRMonetization,setFPnAOHRMonetization]=useState('')
    const [ FPnAEmailMonetization,setFPnAEmailMonetization]=useState('')
    
    const [GOLOHRMonetization,setGOLOHRMonetization]=useState("")
    const [GOLNameMonetization,setGOLNameMonetization]=useState("")
    const [GOLEmailMonetization,setGOLEmailMonetization]=useState("")
    
    const [ showClientApprovalFields,setShowClientApprovalFields]=useState(false)
    const [ monetizationStatusSelectedOption,setMonetizationStatusSelectedOption]=useState(null)
    const [ monetizableStatus,setMonetizableStatus]=useState(false)
    const [ monetizableProjectSelectedOption,setMonetizableProjectSelectedOption]=useState(null)
    const [postMonetizableOptions] = useState([
        {
            value:0,
            label:"Discussion to be initiated with Client"
        },
        {
            value:1,
            label:"Discussion ongoing with Client"
        },
        {
            value:2,
            label:"Rejected by Client"
        },
        {
            value:4,
            label:"Approved by Client"
        },
    ])

    // vd type states
    const [impactOptionSelected,setImpactOptionSelected]=useState(null)
    const [impactOptions]=useState([
        {
            value:0,
            label:"No Value Delivery"
        },
        {
            value:1,
            label:"Value Delivered to Client"
        },
        {
            value:2,
            label:"Value Delivered to Genpact"
        },
        {
            value:3,
            label:"Value Delivered to Client + Genpact"
        }
    ])

    // monetization file states
    const [ FPnAFileNameMonetization,setFPnAFileNameMonetization]=useState('')
    const [ FPnAFileTypeMonetization,setFPnAFileTypeMonetization]=useState('')
    const [ FPnAFileMonetization,setFPnAFileMonetization]=useState('')
    

    const [monetizableFieldDisabled,setMonetizableFieldDisabled,] = useState(false)
    const [displayMonetizedWarning, setDisplayMonetizedWarning] = useState(false)

    const [monetizedAlertCounter, setMonetizedAlertCounter] = useState(0)
    

    useEffect(() => {
        fetchIdea();
        fetchMetadata();
    }, [props.ideaId]);

    const fetchMetadata = ()=> {
        return new Promise((resolve,reject)=>{
            const param = {
                EntityType: "",
                EntityName: ""
            }
            const requestOptions = {
                method: 'POST',
                headers: { 
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + accessToken,
            },
                body: JSON.stringify(param)
            };
            fetch(GET_METADATA, requestOptions)
            .then((response) => response.json())
            .then(data=>{
                if(data.BIMasterDataVerseNew){
                    setBusinessImpactMasterList(data.BIMasterDataVerseNew)
                }
                else{
                    setBusinessImpactMasterList(null)
                }
                resolve();
            })
            .catch((error)=>{
                reject();
                Toast.fail('Error occured while getting metadata. Please try again!', 3000, () => {
                    console.log(JSON.stringify(error));
                });
            })
        })
    }

    const fetchIdea = () => {
        return new Promise ((resolve,reject)=>{
            Toast.loading("Fetching value delivery details...", () => { });
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + accessToken,
                }
            };
            fetch(`${GET_IDEA_URL}IdeaId=''&RecID=${props.IdeaId}&case=new&casetype=genAI`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                Toast.hide();
                setData(data);
                resolve();
                if(data.oppDetails.Table3 && data.oppDetails.Table3){
                    let localBIData = data.oppDetails.Table3;
                    localBIData.sort((a, b) => a.ImpactType.localeCompare(b.ImpactType))
                    setBusinessImpactValueArray(localBIData);
                }
    
                if(data.oppDetails.Table1[0].FinalImpactFTE){
                    let localfinalImpactFTE = data.oppDetails.Table1[0].FinalImpactFTE
                    setFinalImpactFTE(localfinalImpactFTE);
                }
                if(data.oppDetails.Table1[0].FinalImpactDollar){
                    let localfinalImpactdollar = data.oppDetails.Table1[0].FinalImpactDollar
                    setFinalImpactDollar(localfinalImpactdollar);
                }
                
                if(data.oppDetails.Table1[0].VDType){
                    impactOptions.map((item)=>{
                        if(data.oppDetails.Table1[0].VDType === item.label){
                            const selectedOption = {
                                value: item.value,
                                label: item.label
                            };
                            setImpactOptionSelected(selectedOption)
                            if(selectedOption){
                                loadVDType(selectedOption)
                            }
                        }
                        
                    })
                }
                //console.log("vd monetizable =>",data.oppDetails.Table1[0].IsVDMonetizable)
                if(data.oppDetails.Table1[0].IsVDMonetizable){
                    clientApprovalOptions.map((item)=>{
                        if(data.oppDetails.Table1[0].IsVDMonetizable === item.label){
                            const selectedOption = {
                                value: item.value,
                                label: item.label
                            };
                            setMonetizableProjectSelectedOption(selectedOption)
                            if(selectedOption){
                                handleMonetizableProjectChange(selectedOption)
                                setMonetizableFieldDisabled(true)
                            }
                        }
                    })
                }
                if(data.oppDetails.Table1[0].MonetizationStatus){
                    postMonetizableOptions.map((item)=>{
                        if(data.oppDetails.Table1[0].MonetizationStatus === item.label){
                            const selectedOption = {
                                value: item.value,
                                label: item.label
                            };
                            
                            setMonetizationStatusSelectedOption(selectedOption)
                            if(selectedOption){
                                loadMonetizableStatus(selectedOption);
                            }
                            
                        }
                    })
                }
    
                if(data.oppDetails.Table1[0].FPnAOHRMonetization){
                    let localFPnAOHRMonetization = data.oppDetails.Table1[0].FPnAOHRMonetization
                    setFPnAOHRMonetization(localFPnAOHRMonetization)
                } else {
                    let localFPnAOHRMonetization = data.oppDetails.Table1[0].FPnAOHR
                    setFPnAOHRMonetization(localFPnAOHRMonetization)
    
                }
    
                if(data.oppDetails.Table1[0].SowID){
                    let localSowIdhere= data.oppDetails.Table1[0].SowID
                    props.fetchMonetizationSowID(localSowIdhere)
                    setSowID(localSowIdhere)
                }
                if(data.oppDetails.Table1[0].FPnANameMonetization){
                    let localFpnaNameMonetization = data.oppDetails.Table1[0].FPnANameMonetization
                    setFPnANameMonetization(localFpnaNameMonetization)
                    props.fetchMonetizationFpnaName(localFpnaNameMonetization)
                } else {
                    let localFpnaNameMonetization = data.oppDetails.Table1[0].FPnAName
                    setFPnANameMonetization(localFpnaNameMonetization)
    
                }
                if(data.oppDetails.Table1[0].FPnAFileNameMonetization){
                    let localFpnaNameFileNameMonetization = data.oppDetails.Table1[0].FPnAFileNameMonetization
                    setFPnAFileNameMonetization(localFpnaNameFileNameMonetization)
                }
                if(businessImpactValueArray && monetizationStatusSelectedOption && monetizationStatusSelectedOption.label === "Approved by Client"){
                    let MonetizedBiArray =[];
                    let fteValue = 0;
                    let dollarValue = 0;
                    businessImpactValueArray.filter((item)=>{
                        if(item.IsMonetized === "Yes"){
                            MonetizedBiArray.push(item)
                        }
                    })
    
                    if(MonetizedBiArray){
                        const groupedData = MonetizedBiArray.reduce((accumulator, currentValue) => 
                        {
                            const ImpactType = currentValue.ImpactType;
                            const fteValue = parseFloat(currentValue.FTEImpact) || 0.0;
                            const dollarValue = parseFloat(currentValue.DollarImpact) || 0.0;
                            const existingRow = accumulator.find(row => row.ImpactType === ImpactType );
                            if (existingRow) 
                            {
                                existingRow.fteValue += fteValue
                                existingRow.dollarValue += dollarValue
                            } 
                            else 
                            {
                                accumulator.push({ ImpactType, fteValue, dollarValue });
                            }
                            return accumulator;
                        }, []);
                        if(groupedData.length === 0) {
                            fteValue = 0
                            dollarValue = 0
                        } else{
                            fteValue = groupedData[0].fteValue
                            dollarValue = groupedData[0].dollarValue
                        }
                        setTotalMonetizedFTE(fteValue)
                        setTotalMonetizedDollar(dollarValue)
                    }
                }
    
                let localGOLMonetizationName = "";
                if(data.oppDetails.Table1[0].GOLNameMonetization){
                    localGOLMonetizationName = data.oppDetails.Table1[0].GOLNameMonetization
                    setGOLNameMonetization(localGOLMonetizationName)
                    props.fetchMonetizationGOLName(localGOLMonetizationName)
                }else{
                    if(data.oppDetails.Table1[0].GOLNameVD){
                        localGOLMonetizationName = data.oppDetails.Table1[0].GOLNameVD
                        setGOLNameMonetization(localGOLMonetizationName)
                    }
                }
                if(data.oppDetails.Table1[0].GOLNameVD){
                    let local_GOLNameVD = data.oppDetails.Table1[0].GOLNameVD
                    setGolName(local_GOLNameVD)
                }
                let localGOLMonetizationOhr = "";
                if(data.oppDetails.Table1[0].GOLOHRMonetization){
                    localGOLMonetizationOhr = data.oppDetails.Table1[0].GOLOHRMonetization
                    setGOLOHRMonetization(localGOLMonetizationOhr)
                }else{
                    if(data.oppDetails.Table1[0].GOLOHRVD){
                        localGOLMonetizationOhr = data.oppDetails.Table1[0].GOLOHRVD
                        setGOLOHRMonetization(localGOLMonetizationOhr)
                    }
                }
                if(data.oppDetails.Table1[0].GOLOHRVD){
                    let local_GOLOHRVD = data.oppDetails.Table1[0].GOLOHRVD
                    setGolOhr(local_GOLOHRVD)
                }
                if(data.oppDetails.Table1[0].FPnAOHR){
                    let localfpnaOhr = data.oppDetails.Table1[0].FPnAOHR
                    setFpnaOhr(localfpnaOhr)
                }
                if(data.oppDetails.Table1[0].FPnAName){
                    let localfpnaname = data.oppDetails.Table1[0].FPnAName
                    setFpnaName(localfpnaname)
                }
                
                
            })
            .catch((error) => {
                Toast.fail("Error while fetching opportunity details. Please try again!", 3000, () => { });
                reject();
            });
        })
    };

   
    useEffect(() => {
        if (businessImpactValueArray) {
            newBusinessImpactSummary()
        }
        let myArray = businessImpactValueArray;
        const groupedData = myArray.reduce((accumulator, currentValue) => {
        const ImpactType = currentValue.ImpactType;
        const MonetizableType = currentValue.MonetizableType;
        const FTEImpact = parseFloat(currentValue.FTEImpact) || 0.0;
        const DollarImpact = parseFloat(currentValue.DollarImpact) || 0.0;

        const existingRow = accumulator.find(row => row.ImpactType === ImpactType && row.MonetizableType === MonetizableType);
        if (existingRow) {
            existingRow.FTEImpact += FTEImpact
            existingRow.DollarImpact += DollarImpact
        } else {
            accumulator.push({ ImpactType, MonetizableType, FTEImpact, DollarImpact });
        }
        return accumulator;
        }, []);

        let sortedSummaryIndex =   groupedData.sort((a, b) => a.ImpactType.toLowerCase().localeCompare(b.ImpactType.toLowerCase()))
        if(sortedSummaryIndex){
            setSummaryArray(sortedSummaryIndex)
        }

        let filteredImpactType = businessImpactMasterList && businessImpactMasterList.map(item => item.pg_impacttype).filter(
            (value, index, self) => self.indexOf(value) === index
        )
        filteredImpactType = Object.entries(filteredImpactType)
        setFilteredImpactTypeState(filteredImpactType)


        let filtereedMonetizabletype = businessImpactMasterList && businessImpactMasterList.map(item => item.pg_monetizabletype).filter(
            (value, index, self) => self.indexOf(value) === index
        )
        filtereedMonetizabletype = Object.entries(filtereedMonetizabletype)
        setFilteredMonetizableTypeState(filtereedMonetizabletype)


        let filteredBiSubCategory = businessImpactMasterList && businessImpactMasterList.map(item => item.pg_bisubcategory).filter(
            (value, index, self) => self.indexOf(value) === index
        )
        filteredBiSubCategory = Object.entries(filteredBiSubCategory)

        setFilteredBISubCategoryState(filteredBiSubCategory)

        if (monetizableProjectSelectedOption) {
            if (monetizableProjectSelectedOption.label === "Yes") {
                setMonetizableStatus(true)
            }
            if (monetizableProjectSelectedOption.label === "No") {
                setMonetizableStatus(false)
                setMonetizationStatusSelectedOption(null)
                setShowClientApprovalFields(false)
                setTotalMonetizedDollar(0.0)
                setTotalMonetizedFTE(0.0)
                setFPnAFileNameMonetization()
                setSowID("")
            }
        }

        if (monetizationStatusSelectedOption) {
            if (monetizationStatusSelectedOption.label === "Approved by Client") {
                setShowClientApprovalFields(true)
            } else {
                setShowClientApprovalFields(false)
                setTotalMonetizedDollar(0.0)
                setTotalMonetizedFTE(0.0)
                setFPnAFileNameMonetization()
                setSowID("")
            }
        }

        if (monetizationStatusSelectedOption) {
            addMonetizableValues();
        }

        props.fetchMonetizationFpnaOhr(FPnAOHRMonetization)
        props.fetchMonetizationGOLName(GOLNameMonetization)
        props.fetchMonetizationFpnaName(FPnANameMonetization)
        props.fetchMonetizationGOLOhr(GOLOHRMonetization)
    }, [businessImpactValueArray, businessImpactMasterList, impactOptionSelected,
        filteredImpactTypeSelectedOption, filteredMonetizableSelectedOption, filteredBISubCategorySelectedOption,
        monetizableProjectSelectedOption, monetizationStatusSelectedOption,
        filteredImpactTypeSelectedOption,
        filteredMonetizableSelectedOption,
        filteredBISubCategorySelectedOption,
        FPnANameMonetization,
        finalImpactDollar, finalImpactFTE,
        GOLNameMonetization, GOLOHRMonetization
    ]);

    const handleMonetizableProjectChange=(e)=>{
        setMonetizationStatusSelectedOption([])
        props.monetizableProject(e)
        if(e !== null || e !== undefined){
            const selectedOption = {
                value: e.value,
                label: e.label
            };
            setMonetizableProjectSelectedOption(selectedOption)
        }
    }
    const handleMonetizableStatusChange=(e)=>{
        props.monetizableStatus(e)
        loadTotalValue()

        setGOLOHRMonetization(golOhr)
        setGOLNameMonetization(golName)
        setFPnANameMonetization(fpnaName)
        setFPnAOHRMonetization(fpnaOhr)
        setSowID("")

        


        if(e !== null || e !== undefined){
            const selectedOption = {
                value: e.value,
                label: e.label
            };
            setMonetizationStatusSelectedOption(selectedOption)
            setFilteredImpactTypeSelectedOption([])
            setFilteredMonetizableSelectedOption([])
            setFilteredBISubCategorySelectedOption([])
            if(selectedOption.label === "Approved by Client"){
                props.fetchMonetizationFpnaName(fpnaName)
                props.fetchMonetizationFpnaOhr(FPnAOHRMonetization)
                props.fetchMonetizationGOLName(golName)
                props.fetchMonetizationGOLOhr(golOhr)
                props.fetchMonetizationSowID(SowID)
            }
        }
    }


    const renderFilteredImpactType=(e)=>{
        let options = [];
        if( impactOptionSelected && impactOptionSelected.label === "Value Delivered to Client + Genpact"){
            if(props.bbMonetizationApproval){
                filteredImpactTypeState.slice(-1).map((item) => {
                    options.push({
                        value: item[0],
                        label: item[1]
                    })
                    return options;
                });
            }else{
                filteredImpactTypeState.map((item) => {
                    options.push({
                        value: item[0],
                        label: item[1]
                    })
                    return options;
                });
            }
            
        }
        if( impactOptionSelected && impactOptionSelected.label === "Value Delivered to Genpact"){
            filteredImpactTypeState.slice(-1).map((item) => {
                options.push({
                    value: item[0],
                    label: item[1]
                })
                return options;
            });
        }
        if( impactOptionSelected && impactOptionSelected.label === "Value Delivered to Client"){
            filteredImpactTypeState.slice(0,1).map((item) => {
                options.push({
                    value: item[0],
                    label: item[1]
                })
                return options;
            });
        }
        return options;

    }
    const handleImpactTypeChange=(e)=>{
        if(e !== null || e !== undefined){
            const selectedOption = {
                value: e.value,
                label: e.label
            };
            setFilteredImpactTypeSelectedOption(selectedOption)
        }
        setFilteredMonetizableSelectedOption([])
        setFilteredBISubCategorySelectedOption([])
    }

    const renderFilteredMonetizableType=(e)=>{
        // console.log("filtered impact type state =>",filteredImpactTypeState)
        if(filteredImpactTypeState){
            let options = [];
            if(props.bbMonetizationApproval){
                options.push({
                    value: 0,
                    label: "Increase in Revenue"
                })
                return options;
            }else{
                if(filteredImpactTypeSelectedOption !== null){
                    filteredMonetizableTypeState.map((item) => {
                        options.push({
                            value: item[0],
                            label: item[1]
                        })
                        return options;
                    });
                    return options;
                }
            }
        }
    }

    const handleMonetizableTypeChange=(e)=>{
        setMonetizableProjectSelectedOption([])
        if(e !== null || e !== undefined){
            const selectedOption = {
                value: e.value,
                label: e.label
              };
            setFilteredMonetizableSelectedOption(selectedOption)
        }
        setFilteredBISubCategorySelectedOption([])
    }

    const renderFilteredBiSubCategoryType=(e)=>{
        let options = [];
        if(props.bbMonetizationApproval){
            monetizableValues.map((item, index) => {
                options.push({
                    value: item.value,
                    label: item.label
                });
                return options;
            });
            return options;
        }else{
            businessImpactMasterList && businessImpactMasterList.forEach((item, index) => {
                if (
                    filteredImpactTypeSelectedOption &&
                    filteredMonetizableSelectedOption &&
                    item.pg_impacttype === filteredImpactTypeSelectedOption.label &&
                    item.pg_monetizabletype === filteredMonetizableSelectedOption.label
                ) {
                  options.push({
                    value: index,
                    label: item.pg_bisubcategory
                  });
                }
            });
        }
        
        
        return options;
    }

    const handleBiSubCategoryChange=(e)=>{
        if(e !== null || e !== undefined){
            const selectedOption = {
                value: e.value,
                label: e.label
            };
            setFilteredBISubCategorySelectedOption(selectedOption)
        }
    }

    
    const addSelectedValues=(e,monetizedAlertCounter)=>{
        
        if(filteredBISubCategorySelectedOption){
            if(filteredBISubCategorySelectedOption.label !== "Category Not Listed")
            {
                if( filteredImpactTypeSelectedOption && filteredMonetizableSelectedOption && filteredBISubCategorySelectedOption )
                    {
                        businessImpactMasterList && businessImpactMasterList.map((item)=>{
                            if(item.pg_impacttype === filteredImpactTypeSelectedOption.label && item.pg_monetizabletype === filteredMonetizableSelectedOption.label && item.pg_bisubcategory === filteredBISubCategorySelectedOption.label){
                                let obj = {};
                                if(props.bbMonetizationApproval){
                                    obj = {
                                        ImpactType:filteredImpactTypeSelectedOption.label,
                                        MonetizableType:filteredMonetizableSelectedOption.label,
                                        BISubCategory:filteredBISubCategorySelectedOption.label,
                                        SrNo:item.pg_srno,
                                        IsMonetized:"Yes",
                                        FTEImpact:"",
                                        DollarImpact:"",
                                        FTEImpactYear:"",
                                        DollarImpactYear:"",
                                    }
                                }
                                else if(item.pg_impacttype==="Impact at Genpact" && item.pg_monetizabletype==="Increase in Revenue"){
                                    obj = {
                                        ImpactType:filteredImpactTypeSelectedOption.label,
                                        MonetizableType:filteredMonetizableSelectedOption.label,
                                        BISubCategory:filteredBISubCategorySelectedOption.label,
                                        SrNo:item.pg_srno,
                                        IsMonetized:"Yes",
                                        FTEImpact:"",
                                        DollarImpact:"",
                                        FTEImpactYear:"",
                                        DollarImpactYear:"",
                                    }
                                }
                                else{
                                    obj = {
                                        ImpactType:filteredImpactTypeSelectedOption.label,
                                        MonetizableType:filteredMonetizableSelectedOption.label,
                                        BISubCategory:filteredBISubCategorySelectedOption.label,
                                        SrNo:item.pg_srno,
                                        FTEImpact:"",
                                        DollarImpact:"",
                                        FTEImpactYear:"",
                                        DollarImpactYear:"",
                                        
                                    }
                                }
                                let isDuplicate = businessImpactValueArray.find(item => item.SrNo === obj.SrNo);
                                if (!isDuplicate) 
                                {
                                    businessImpactValueArray.push(obj)
                                    businessImpactValueArray.sort((a, b) => a.ImpactType.toLowerCase().localeCompare(b.ImpactType.toLowerCase()))
                                    newBusinessImpactSummary()
                                    setFilteredImpactTypeSelectedOption([])
                                    setFilteredMonetizableSelectedOption([])
                                    setFilteredBISubCategorySelectedOption([])
                                }
                                else
                                {
                                    window.alert("Same combination of Impact type, Category and Sub category cannot be added again")
                                    setFilteredImpactTypeSelectedOption([])
                                    setFilteredMonetizableSelectedOption([])
                                    setFilteredBISubCategorySelectedOption([])
                                }
                            }
    
                            if( filteredImpactTypeSelectedOption.label==="Impact at Genpact"){
                                if((filteredMonetizableSelectedOption.label === "Increase in Revenue" || filteredMonetizableSelectedOption.label === "Reduction in Cost")){
                                    props.fetchFPAOHRforIncreaseInRevenue(true)
                                    setAppropriateAttachment(true)
                                    setFilteredImpactTypeSelectedOption([])
                                    setFilteredMonetizableSelectedOption([])
                                    setFilteredBISubCategorySelectedOption([])
                                }
                            }
                        })
                        
                    }else{
                        window.alert("Please Select Impact Type, Category and Sub Category")
                        setFilteredImpactTypeSelectedOption([])
                        setFilteredMonetizableSelectedOption([])
                        setFilteredBISubCategorySelectedOption([])
                    }
            }else{
                setShowHrefModal(true)
                setFilteredImpactTypeSelectedOption([])
                setFilteredMonetizableSelectedOption([])
                setFilteredBISubCategorySelectedOption([])
                props.fetchCategoryNotListed(true)
                
            }
            props.monetizableProject(monetizableProjectSelectedOption)
            
            if(impactOptionSelected && (impactOptionSelected.label ==="Value Delivered to Genpact" || impactOptionSelected.label==="Value Delivered to Client + Genpact")){
                businessImpactValueArray.map((item)=>{
                    if(item.ImpactType === "Impact at Genpact"){
                        if(item.MonetizableType && (item.MonetizableType === "Increase in Revenue")) {
                            const monetizableSelectedOption = {
                                value: 0,
                                label: "Yes"
                            };
                            setMonetizableFieldDisabled(true)
                            props.monetizableProject(monetizableSelectedOption)
                            setMonetizableProjectSelectedOption(monetizableSelectedOption)
                        }
                    }
                })
            }
            if (impactOptionSelected && (impactOptionSelected.label === "Value Delivered to Genpact" || impactOptionSelected.label === "Value Delivered to Client + Genpact")) {
                console.log("some value =>",businessImpactValueArray.some(e => e.IsMonetized === 'Yes'))
                if(businessImpactValueArray.some(e => e.IsMonetized === 'Yes')){
                    if(monetizedAlertCounter === 0){
                        window.alert("Since you have selected Monetizable category, so this project is selected as Monetized project.");
                        setMonetizedAlertCounter(monetizedAlertCounter+1)
                    }
                }else{
                    setMonetizedAlertCounter(0)
                }
            }
        }
    }
    
    const checkIsFtePresent=(item)=>{
        let myBIMasterList = businessImpactMasterList
        for (const pg_item of myBIMasterList) {
            if (
                item.ImpactType === pg_item.pg_impacttype &&
                item.MonetizableType === pg_item.pg_monetizabletype &&
                item.BISubCategory === pg_item.pg_bisubcategory &&
                pg_item.pg_isfteimpact === "Yes"
                // item.SrNo === pg_item.pg_srno
            ) {
                return true; 
            }
        }
        return false;
    }


    const handleBIFTEChange=(ImpactType, MonetizableType, BISubCategory, FTEImpact, index, event, isDollarPresent)=>{
        FTEImpact = Number(Math.floor(FTEImpact+'e2')+'e-2');
        if (FTEImpact < 0) {
            alert("You cannot set Potential Annualised Impact (# of FTE) to a negative value.");
            event.target.value = 0;
            FTEImpact = 0;
        }
        if (FTEImpact > parseInt(100)) {
            alert("You cannot set Annualised Impact (# of FTE) to more than 100. Please update accordingly.");
            event.target.value = '';
            FTEImpact = 0;
        }
        
        let businessImpactItem = businessImpactValueArray;
        if (!businessImpactItem[index]) {
            businessImpactItem[index] = {};
            businessImpactItem[index].FTEImpact = 0.0;
            businessImpactItem[index].DollarImpact = 0.0;
        }
        businessImpactItem[index].ImpactType = ImpactType;
        businessImpactItem[index].MonetizableType = MonetizableType;
        businessImpactItem[index].BISubCategory = BISubCategory;
        businessImpactItem[index].FTEImpact = FTEImpact;
      
        if (isDollarPresent){
            if (businessImpactItem[index].DollarImpact && businessImpactItem[index].DollarImpact !== "") {
                if (parseInt(businessImpactItem[index].DollarImpact) < parseInt(FTEImpact)) {
                    alert("Annualised FTE Impact cannot be more than $ Impact, please recheck.");
                }
            } 
        }

        let fteTotal = 0.0;
        businessImpactItem.map((item) => {
            if (item && item.FTEImpact) {
                fteTotal += parseFloat(item.FTEImpact);
            }
            return true;
        });
       
        setBusinessImpactValueArray(businessImpactItem)
        setFteTotal(fteTotal)
        setFinalImpactFTE(fteTotal)
        newBusinessImpactSummary();
        addMonetizableValues();
    }

    const handleBIDollarChange=(ImpactType,SrNo, MonetizableType, BISubCategory, DollarImpact, index, event, isFTEPresent)=>{
        DollarImpact = Number(Math.floor(DollarImpact + 'e2') + 'e-2');
        if (DollarImpact < 0) {
            alert("You cannot set Potential Annualised $ Impact to a negative value.");
            event.target.value = 0;
            DollarImpact = 0;
        }
        if (DollarImpact > 500000000) {
            alert("You cannot set Potential Annualised $ Impact to more than 500 million. Please update accordingly.");
            event.target.value = '';
            DollarImpact = 0;
        }
        let updatedData = [];
        updatedData = businessImpactValueArray.map(item => {
            if (item.SrNo === SrNo) {
                item.DollarImpact = DollarImpact;
                item.ImpactType = ImpactType;
                item.MonetizableType = MonetizableType;
                item.BISubCategory = BISubCategory;
                item.isFTEPresent = isFTEPresent;
            } else {
                updatedData.push({
                    SrNo: SrNo,
                    ImpactType: ImpactType,
                    MonetizableType: MonetizableType,
                    BISubCategory: BISubCategory,
                    isFTEPresent: isFTEPresent,
                    FTEImpact: 0.0,
                    DollarImpact: 0.0,
                    FTEImpactYear: 0.0,
                    DollarImpactYear: 0.0,
                })
            }
            return item;
        });
        if (isFTEPresent) {
            if (updatedData[index].fteValue && updatedData[index].fteValue !== "") {
                if (parseInt(updatedData[index].fteValue) > parseInt(DollarImpact)) {
                    alert("Annualised FTE Impact cannot be more than $ Impact, please recheck.");
                }
            }
        }
        let dollarTotal = 0.0;
        updatedData.map((item) => {
            if (item && item.DollarImpact) {
                dollarTotal += parseFloat(item.DollarImpact);
            }
            return true;
        });

        setBusinessImpactValueArray(updatedData)
        setDollarTotal(dollarTotal)
        setFinalImpactDollar(dollarTotal)
        newBusinessImpactSummary();
        addMonetizableValues();
    }



    // ..... deployment year bi dollar change function starts here........
    const handleBIFTEChangeForDeployment = (ImpactType, MonetizableType, BISubCategory, FTEImpactYear, index, event, isDollarPresent)=>{
        FTEImpactYear = Number(Math.floor(FTEImpactYear+'e2')+'e-2');
        if (FTEImpactYear < 0) {
            alert("You cannot set Potential Deployment year Impact (# of FTE) to a negative value.");
            event.target.value = 0;
            FTEImpactYear = 0;
        }
        if (FTEImpactYear > parseInt(100)) {
            alert("You cannot set Deployment year Impact (# of FTE) to more than 100. Please update accordingly.");
            event.target.value = '';
            FTEImpactYear = 0;
        }
        let businessImpactItem = businessImpactValueArray;
        if (!businessImpactItem[index]) {
            businessImpactItem[index] = {};
            businessImpactItem[index].FTEImpactYear = 0.0;
            businessImpactItem[index].DollarImpactYear = 0.0;
        }
        businessImpactItem[index].ImpactType = ImpactType;
        businessImpactItem[index].MonetizableType = MonetizableType;
        businessImpactItem[index].BISubCategory = BISubCategory;
        businessImpactItem[index].FTEImpactYear = FTEImpactYear;
        
        if (isDollarPresent){
            if (businessImpactItem[index].DollarImpactYear && businessImpactItem[index].DollarImpactYear !== "") {
                if (parseInt(businessImpactItem[index].DollarImpactYear) < parseInt(FTEImpactYear)) {
                    alert("Deployment year FTE Impact cannot be more than $ Impact, please recheck.");
                }
            } 
        }
        setBusinessImpactValueArray(businessImpactItem)
        newBusinessImpactSummary();
    }
    const handleBIDollarChangeForDeployment=(ImpactType,SrNo, MonetizableType, BISubCategory, DollarImpactYear, index, event, isFTEPresent)=>{
        DollarImpactYear = Number(Math.floor(DollarImpactYear+'e2')+'e-2');
        if (DollarImpactYear < 0) {
            alert("You cannot set Potential Deployment year $ Impact to a negative value.");
            event.target.value = 0;
            DollarImpactYear = 0;
        }
        if (DollarImpactYear > 500000000) {
            alert("You cannot set Potential Deployment year $ Impact to more than 500 million. Please update accordingly.");
            event.target.value = '';
            DollarImpactYear = 0;
        }

        let businessImpactItem = businessImpactValueArray;
        if (!businessImpactItem.find(item => item.SrNo === SrNo)) {
            businessImpactItem.push({
                SrNo: SrNo,
                ImpactType: ImpactType,
                MonetizableType: MonetizableType,
                BISubCategory: BISubCategory,
                isFTEPresent: isFTEPresent,
                FTEImpact: 0.0,
                DollarImpact: 0.0,
                FTEImpactYear:0.0,
                DollarImpactYear:0.0,
            });
        }

        businessImpactItem = businessImpactItem.map(item => {
            if (item.SrNo === SrNo) {
                item.ImpactType = ImpactType;
                item.MonetizableType = MonetizableType;
                item.BISubCategory = BISubCategory;
                item.isFTEPresent = isFTEPresent;
                item.DollarImpactYear = DollarImpactYear;
            }
            return item;
        });
        if(isFTEPresent){
            if (businessImpactItem[index].fteValue && businessImpactItem[index].fteValue !== ""){
                if(parseInt(businessImpactItem[index].fteValue) > parseInt(DollarImpactYear)){
                    alert("Deployment year FTE Impact cannot be more than $ Impact, please recheck.");
                }
            }
        }
        setBusinessImpactValueArray(businessImpactItem)
        newBusinessImpactSummary();
        
    }
    // ..... deployment year bi dollar change function ends here........

    

    const newBusinessImpactSummary = async () => {
        let myArray = [...businessImpactValueArray];
        
        const groupedData = myArray.reduce((accumulator, currentValue) => {
            //console.log("current value at new business impact summary =>",currentValue)
            const impactType = currentValue.impactType;
            const monetizableType = currentValue.monetizableType;
            const fteValue = parseFloat(currentValue.fteValue) || 0.0;
            const dollarValue = parseFloat(currentValue.dollarValue) || 0.0;

            const existingRow = accumulator.find(row => row.impactType === impactType && row.monetizableType === monetizableType);
           
            if (existingRow) {
                existingRow.fteValue += fteValue
                existingRow.dollarValue += dollarValue
            } else {
                accumulator.push({ impactType, monetizableType, fteValue, dollarValue });
            }
            return accumulator;
        }, []);
        await props.func({ businessImpactValueArray })

        if (businessImpactValueArray && monetizableProjectSelectedOption && monetizableProjectSelectedOption.label === "Yes") {
            if (monetizationStatusSelectedOption && monetizationStatusSelectedOption.label !== "Approved by Client") {
                let localtotaldollar = 0.0;
                let localtotalfte = 0.0;
                let abc = [];
                businessImpactValueArray.filter((item) => {
                    if (item.IsMonetized === "No" || item.IsMonetized === "" || !item.IsMonetized) {
                        abc.push(item)
                    }
                })

                abc.map((item) => {
                    if (item && item.DollarImpact) {
                        localtotaldollar += parseFloat(item.DollarImpact);
                    }
                    if (item && item.FTEImpact) {
                        localtotalfte += parseFloat(item.FTEImpact);
                    }
                })
                setFinalImpactDollar(localtotaldollar)
                setFinalImpactFTE(localtotalfte)
            }
            if (monetizationStatusSelectedOption && monetizationStatusSelectedOption.label === "Approved by Client") {
                let localtotaldollar = 0.0;
                let localtotalfte = 0.0;
                businessImpactValueArray.map((item) => {
                    if (item && item.DollarImpact) {
                        localtotaldollar += parseFloat(item.DollarImpact);
                    }
                    if (item && item.FTEImpact) {
                        localtotalfte += parseFloat(item.FTEImpact);
                    }
                })
                setFinalImpactDollar(localtotaldollar)
                setFinalImpactFTE(localtotalfte)
            }
        }
    }

    const loadTotalValue=()=>{
        if(businessImpactValueArray && monetizableProjectSelectedOption && monetizableProjectSelectedOption.label === "Yes" && monetizationStatusSelectedOption !== null ){
            if(monetizationStatusSelectedOption.label !== "Approved by Client"){
                let localtotaldollar = 0.0;
                let localtotalfte = 0.0;
                let abc = [];
                businessImpactValueArray.filter((item)=>{
                    if(item.IsMonetized === "No" || item.IsMonetized === "" || !item.IsMonetized){
                        abc.push(item)
                    }
                })
               
                abc.map((item)=>{
                    if(item && item.DollarImpact){
                        localtotaldollar += parseFloat(item.DollarImpact);
                    }
                    if(item && item.FTEImpact){
                        localtotalfte += parseFloat(item.FTEImpact);
                    }
                })
                setFinalImpactDollar(localtotaldollar)  
                setFinalImpactFTE(localtotalfte)  
            }
            if(monetizationStatusSelectedOption.label === "Approved by Client"){
                let localtotaldollar = 0.0;
                let localtotalfte = 0.0;
                businessImpactValueArray.map((item)=>{
                    if(item && item.DollarImpact){
                        localtotaldollar += parseFloat(item.DollarImpact);
                    }
                    if(item && item.FTEImpact){
                        localtotalfte += parseFloat(item.FTEImpact);
                    }
                })
                setFinalImpactDollar(localtotaldollar)  
                setFinalImpactFTE(localtotalfte)  
            }
        }
    }

    const addMonetizableValues =()=>{
        let MonetizedBiArray =[];
        let fteValue = 0;
        let dollarValue = 0;
        businessImpactValueArray.filter((item)=>{
            // console.log("businessImpactValueArray =>",item)
            if(item.IsMonetized === "Yes"){
                MonetizedBiArray.push(item)
            }
        })
        // console.log("monetized array +>",MonetizedBiArray)
        if(MonetizedBiArray){
            const groupedData = MonetizedBiArray.reduce((accumulator, currentValue) => 
            {
                const ImpactType = currentValue.ImpactType;
                const fteValue = parseFloat(currentValue.FTEImpact) || 0.0;
                const dollarValue = parseFloat(currentValue.DollarImpact) || 0.0;
                const existingRow = accumulator.find(row => row.ImpactType === ImpactType );
                if (existingRow) 
                {
                    existingRow.fteValue += fteValue
                    existingRow.dollarValue += dollarValue
                } 
                else 
                {
                    accumulator.push({ ImpactType, fteValue, dollarValue });
                }
                return accumulator;
            }, []);
            if(groupedData.length === 0) {
                fteValue = 0
                dollarValue = 0
            } else{
                fteValue = groupedData[0].fteValue
                dollarValue = groupedData[0].dollarValue
            }


            setTotalMonetizedFTE(fteValue)
            setTotalMonetizedDollar(dollarValue)
            props.bbMonetizationTotal({fteValue,dollarValue})
        }
    }
    const removeBiFormRow=(index,item)=>{
        let DeleteSrno = item.SrNo;
        let filteredIdeaArray = businessImpactValueArray.filter((item) => {
            return item.SrNo !== DeleteSrno;
        });

        
        setBusinessImpactValueArray(filteredIdeaArray)
        newBusinessImpactSummary()
        props.fetchDeletedBIRows(filteredIdeaArray)

        filteredIdeaArray.filter((item) => {
            if(item.IsMonetized !== "Yes"){
                setMonetizableCheckbox(false)
            }
        });

        setAppropriateAttachment(false)
        if (filteredIdeaArray.length > 0 && filteredIdeaArray.some(e => e.impactType === 'Impact at Genpact' && ['Increase in Revenue', 'Reduction in Cost'].includes(e.monetizableType))) {
            setAppropriateAttachment(true)
        }

        if (filteredIdeaArray.some(e => e.ImpactType === 'Impact at Genpact' && ['Increase in Revenue'].includes(e.MonetizableType))) {
            setMonetizableFieldDisabled(true);
        }else{
            setMonetizedAlertCounter(0)
            if(!props.bbMonetizationApproval){
                setMonetizableFieldDisabled(false);
                setMonetizableProjectSelectedOption([])
                props.monetizableProject([])
                setMonetizationStatusSelectedOption([])
                props.monetizableStatus([])
            }
            
        }


        let dollarTotal = 0.0;
        let fteValue=0.0;
        filteredIdeaArray.map((item) => {
            if (item && item.DollarImpact)  
            {
                dollarTotal += parseFloat(item.DollarImpact);
            }
            if (item && item.dollarValue)  
            {
                dollarTotal += parseFloat(item.dollarValue);
            }
            if (item && item.FTEImpact)  
            {
                fteValue += parseFloat(item.FTEImpact)
            }
            if (item && item.fteValue)  
            {
                fteValue += parseFloat(item.fteValue)
            }
            setFinalImpactFTE(fteValue)
            setFinalImpactDollar(dollarTotal)
        })
    }
    
    const renderMonetizableOptions=()=>{
        let options = [];
        postMonetizableOptions.map((item) => {
            options.push({
                value: item.value,
                label: item.label
            })
            return options;
        });
        return options;
    }
    
    const handleSowIdChange=(e)=>{
        if(e.length <= 30){
            setSowID(e)
            props.fetchMonetizationSowID(e)
        }else{
            window.alert("SOW ID/SFDC/OLI ID cannot be more than 30 characters.");
            return;
        }
        
    }
    
    // auto fetch FPNA name for monetization starts here...
    const handleFpnaOhrMonetizationOnCut=(event)=>{
        let abc = event.target.value.trim();
        setFPnAOHRMonetization(abc)
        if (event.value === undefined) 
        {
            setFPnAOHRMonetization("")
            setFPnANameMonetization("")
        }
        else if ( event.value.length === 9) 
        { 
            handleFpnaOhrMonetizationChange() 
        } 
        else 
        {
            setFPnANameMonetization("")

        }
    }
    const handleFpnaOhrMonetizationChange=(event)=>{
        setFpnaName("")
        setFpnaOhr("")
        let abc = event.target.value.trim();
        setFPnAOHRMonetization(abc);
        var numbers = /^[0-9]+$/;
        if(event !== null && event !== undefined){
            if(event.target.value.match(numbers) && event.target.value.length !== 0)
            {
                if (event.target.value.length === 9) 
                {
                    fetchSubmitFpnaOHRMonetizationDetails(event.target.value)
                }
                else
                {
                    setFPnANameMonetization("")
                    setFPnAOHRMonetization("")
                }
            }else{
                setFPnANameMonetization("")
                setFPnAOHRMonetization("")
            }
        }
    }
    const fetchSubmitFpnaOHRMonetizationDetails = (value) => {
        Toast.loading("Fetching FP&A Full Name Details for monetization. Please wait...", () => { });
        const param = {
            EntityType: "SearchOHR",
            EntityName: value
        }
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + accessToken,
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log("fpna fetch data =>",data)
            let requestByName = data.oUser ? data.oUser[0].DisplayName : null
            if(requestByName === ""){
                requestByName = data.PowerAppUsers ? (data.PowerAppUsers.length > 0 ? data.PowerAppUsers[0].pg_username : "") : "";
            }
            Toast.hide();
            if (requestByName === "" || requestByName === null || requestByName === undefined ) {
                Toast.fail("We could not find FP&A full name details based on the entered OHR. Please check and reenter.", 5000, () => { });
                setFPnANameMonetization("")
                setFPnAOHRMonetization("")
            }else{
                setFPnANameMonetization(requestByName)
            }
        })
        .catch(error => {
            Toast.hide();
            Toast.fail('Please enter a valid FP&A OHR', 5000, () => { });
        });
    }
    // auto fetch fpna full name ends here 


    // auto fetch gol name for monetization starts here
    const handleGOLOhrMonetizationOnCut=(event)=>{
        setGolOhr("")
        setGolName("")
        let abc = event.target.value.trim();
        setGOLOHRMonetization(abc)
        if (event.value === undefined) 
        {
            setGOLOHRMonetization("")
            setGOLNameMonetization("")
        }
        else if ( event.value.length === 9) 
        { 
            handleGOLOhrMonetizationChange() 
        } 
        else 
        {
            setGOLNameMonetization("")
        }
    }
    const handleGOLOhrMonetizationChange=(event)=>{
        setGolOhr("")
        setGolName("")
        let abc = event.target.value.trim();
        setGOLOHRMonetization(abc);
        var numbers = /^[0-9]+$/;
        if(event !== null && event !== undefined){
            if(event.target.value.match(numbers) && event.target.value.length !== 0)
            {
                if (event.target.value.length === 9) 
                {
                    fetchSubmitGOLOHRMonetizationDetails(event.target.value)
                }
                else
                {
                    setGOLNameMonetization("")
                    setGOLOHRMonetization("")
                }
            } else {
                setGOLOHRMonetization("")
                setGOLNameMonetization("")
            }
        }
    }


    const fetchSubmitGOLOHRMonetizationDetails = (value) => {
        Toast.loading("Fetching GOL Full Name Details for monetization. Please wait...", () => { });
        const param = {
            EntityType: "SearchOHR",
            EntityName: value
        }
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + accessToken,
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
        .then(response => response.json())
        .then(data => {
            let requestByName = data.oUser ? data.oUser[0].DisplayName : null
            if(requestByName === ""){
                requestByName = data.PowerAppUsers ? (data.PowerAppUsers.length > 0 ? data.PowerAppUsers[0].pg_username : "") : "";
            }
            Toast.hide();
            if (requestByName === "" || requestByName === null || requestByName === undefined ) {
                Toast.fail("We could not find GOL full name details based on the entered OHR. Please check and reenter.", 5000, () => { });
                setGOLNameMonetization("")
                setGOLEmailMonetization("")
            }else{
                setGOLNameMonetization(requestByName)
            }
        })
        .catch(error => {
            Toast.hide();
            Toast.fail('Please enter a valid GOL OHR', 5000, () => { });
        });
    }
    
    // auto fetch gol name for monetization ends here


    
    // auto fetch fpna full name for monetization starts here 

    const renderImpactOptions=()=>{
        let options = [];
        if(props.bbMonetizationApproval){
            impactOptions.slice(-2).map((item) => {
                options.push({
                    value: item.value,
                    label: item.label
                })
                return options;
            });
        }else{
            impactOptions.map((item) => {
                options.push({
                    value: item.value,
                    label: item.label
                })
                return options;
            });
        }
        return options;
    }

    const loadVDType=(e)=>{
        props.vdType({e})
    }
    const loadMonetizableStatus=(e)=>{
        props.monetizableStatus(e)
    }
    // const loadBI=({e})=>{
    //     props.func({e})
    // }
    

    const handleVDTypeChange=(e)=>{
        if(window.confirm("Value Delivery form will be filtered basis the Value Delivery option selected. Do you wish to continue?")){
            setMonetizableFieldDisabled(false);
            setMonetizableProjectSelectedOption([])
            props.monetizableProject([])
            setMonetizationStatusSelectedOption([])
            props.monetizableStatus([])
            props.vdType({e})
            if(e){
                const selectedOption = {
                    value: e.value,
                    label: e.label
                };
                if(selectedOption.label === "No Value Delivery"){
                    setBusinessImpactValueArray([])
                    setMonetizableProjectSelectedOption([])
                    setMonetizationStatusSelectedOption([])
                    setSowID("");
                    setFinalImpactDollar("")
                    
                }
                setImpactOptionSelected(selectedOption);
                if(selectedOption.label === "Value Delivered to Client"){
                    filterBIRows("Value Delivered to Client");
                    // setMonetizationStatusSelectedOption([])
                    // setMonetizableProjectSelectedOption([])
                }
                if(selectedOption.label === "Value Delivered to Genpact"){
                    filterBIRows("Value Delivered to Genpact");
                }
                
            }
        }
    }
    const filterBIRows=(label)=>{
        return new Promise((resolve,reject)=>{
            let localVDType="";
            if(label==="Value Delivered to Client"){
                localVDType = "Impact at Client"; 
            }
            if(label==="Value Delivered to Genpact"){
                localVDType = "Impact at Genpact"; 
            }
            let businessImpactItem = [...businessImpactValueArray];
            businessImpactItem = businessImpactItem.filter(item => {
                return item.ImpactType === localVDType;
            });
            let localtotaldollar = 0.0;
            let localtotalfte = 0.0;
            businessImpactItem.map((item)=>{
                if(item && item.DollarImpact){
                    localtotaldollar += parseFloat(item.DollarImpact);
                }
                if(item && item.FTEImpact){
                    localtotalfte += parseFloat(item.FTEImpact);
                }
            })
            setFinalImpactDollar(localtotaldollar)  
            setFinalImpactFTE(localtotalfte)  
            setBusinessImpactValueArray(businessImpactItem)
            resolve();
        })
    }


    // function to fetch fpna file for monetization
    const showMonetizationContent = () => {
        return new Promise((resolve, reject) => {
            Toast.loading("Fetching FP&A Sign-off for monetization content...", () => { });
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + accessToken,
                }
            };
            let signoffFileType = FPnAFileTypeMonetization;
            fetch(`${GET_IDEA_URL}IdeaId=${props.IdeaId}&${PPT_CONTENT}=true&fpnacontent=true&fpnamonetizationcontent=true`, requestOptions)
            .then(response => response.json())
            .then(data => {
                const signoffMetadata = data.fpnaContent ? data.fpnaContent : "";
                const signoffContent = "data:" + signoffFileType + ";base64," + signoffMetadata;
                if(signoffContent){
                    setFPnAFileMonetization(signoffContent)
                    var fileData = signoffContent;
                    var fileName = FPnAFileNameMonetization;
                    var w = window.open("");
                    const downloadLink = w.document.createElement("a");
                    downloadLink.href = fileData;
                    downloadLink.download = fileName;
                    downloadLink.click();
                    Toast.hide();
                }
                resolve();
            })
            .catch(error => {
                Toast.fail("Error while fetching FP&A Sign-off for monetization content. Please try again!", 3000, () => { });
                resolve();
            });
        });
    }



    const myFIleChange=(event, fileType)=>{
        var file = event.target.files[0];
        var currFilename = file.name;
        var currFilesize = file.size;
        if(currFilesize > 5000000){
            alert("The size of the document you are uploading, is more than 5MB. Please upload a smaller document.");
            return;            
        }
        var validExtensions = ['.xls', '.xlsx', '.xlsm', '.csv', '.doc', '.docx', '.pdf', '.ppt', '.pptx', '.pdf', '.jpeg', '.jpg', '.png', '.msg', '.png', '.mpp'];
        var currFileExtension = currFilename.substring(currFilename.lastIndexOf("."));
        var isFileTypeValid = validExtensions.filter((val) => val.toLowerCase().includes(currFileExtension.toLowerCase()));
        if (isFileTypeValid.length <= 0) {
            alert("The type of document you are uploading is not supported. Please upload only supported file types.");
            return;
        }
        if(fileType==="file1"){
            if (currFilename === FPnAFileNameMonetization) {
                Window.alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
        }
        
        var reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = function () {
            let fileContents = btoa(reader.result);
            if (fileType === "file1"){
                setFPnAFileNameMonetization(file.name)
                setFPnAFileTypeMonetization(file.type)
                setFPnAFileMonetization(fileContents)

                let fileName = file.name;
                let fileType = file.type;
                let fileContent = fileContents;
                props.fetchMonetizationFileDetails({fileName,fileType,fileContent,
                    showClientApprovalFields})

                    
                    
            }
            
        };
        reader.onerror = function () {
            console.log('There are some problems loading document contents.');
        };
        
       
    }


    return (
        <div className="">
            <div className="select_impact_type">
                <div className="row">
                    <div className="col-md-9">
                        <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Impact Type</p>
                        <div className="row d_flex_align_items_center">
                            <div className="col-md-6 text-left">
                                <span className="form-attribute">
                                    {
                                        !props.viewIdea ?
                                            <>
                                                {
                                                    props.showMandatory
                                                        ?
                                                        <>
                                                            <span style={{ color: 'red' }}>* </span>
                                                        </>
                                                        :
                                                        <span style={{ color: 'red' }}>&nbsp;</span>
                                                }
                                            </>
                                            :
                                            null
                                    }
                                    What is the type of Value Delivery on this project ?
                                </span> 
                            </div>
                            <div className="col-md-5 text-left">
                                {
                                    props.viewIdea && impactOptionSelected
                                    ?
                                    <>
                                        <input disabled value={impactOptionSelected.label} className="form-input disabled_input" readOnly />
                                    </>
                                    :
                                    props.bbMonetizationApproval && impactOptionSelected
                                    ?
                                    <>
                                        <input disabled value={impactOptionSelected.label} className="form-input disabled_input" readOnly />
                                    </>
                                    :
                                    <Select isSearchable={false} options={renderImpactOptions()} 
                                        placeholder="Choose Impact Type"  
                                        onChange={(e)=>handleVDTypeChange(e)} value={impactOptionSelected} 
                                        isDisabled={props.viewIdea}
                                    />
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

            {
                impactOptionSelected && impactOptionSelected.label !== "No Value Delivery" 
                ?
                <>
                    <div className="text-left pb-4 monetizable_project_block">
                        <p className="u-align-left u-text u-text-2">Monetizable Project ?</p>
                        <div className="row" style={{marginTop:"10px"}}>
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-4 gol_field_div text-left">
                                        <span className="form-attribute">
                                                {
                                                    !props.viewIdea ?
                                                        <>
                                                            {
                                                                props.showMandatory
                                                                    ?
                                                                    <>
                                                                        <span style={{ color: 'red' }}>* </span>
                                                                    </>
                                                                    :
                                                                    <span style={{ color: 'red' }}>&nbsp;</span>
                                                            }
                                                        </>
                                                        :
                                                        null
                                                }
                                            Is this a monetizable project ?
                                        </span> 
                                    </div>
                                    <div className="col-md-3 genai_fields">
                                        {
                                           props.viewIdea && monetizableProjectSelectedOption
                                           ?
                                           <>
                                                <input disabled value={monetizableProjectSelectedOption.label} className="form-input disabled_input"  readOnly />
                                           </> 
                                           :
                                           props.bbMonetizationApproval && monetizableProjectSelectedOption
                                           ?
                                           <>
                                                <input disabled value={monetizableProjectSelectedOption.label} className="form-input disabled_input" readOnly />
                                           </>
                                           :
                                            <Select 
                                                options={clientApprovalOptions} 
                                                onChange={handleMonetizableProjectChange} 
                                                value={monetizableProjectSelectedOption} 
                                                isDisabled={monetizableFieldDisabled ? true : false}
                                                classNamePrefix={ monetizableFieldDisabled ? "an-simple-select" : ""}
                                            />

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                null
            }
            
            {/* ..................monetization client approval process starts here.......... */}

                {
                    monetizableStatus && impactOptionSelected && monetizableProjectSelectedOption && monetizableProjectSelectedOption.label==="Yes"  
                    ?
                    <>
                        <div className="text-left monetizable_status_div">
                            <p className="u-align-left u-text u-text-2">Monetizable Status ?</p>
                            <div className="row" style={{marginTop:"10px"}}>
                                <div className="col-md-8">
                                    <div className="row gol_field_div">
                                        <div className="col-md-4  text-left">
                                            <div className="">
                                                <span className="form-attribute">
                                                    {
                                                        !props.viewIdea ?
                                                            <>
                                                                {
                                                                    props.showMandatory
                                                                        ?
                                                                        <>
                                                                            <span style={{ color: 'red' }}>* </span>
                                                                        </>
                                                                        :
                                                                        <span style={{ color: 'red' }}>&nbsp;</span>
                                                                }
                                                            </>
                                                            :
                                                            null
                                                    }
                                                    Monetizable Status
                                                </span> 
                                            </div>
                                        </div>
                                        <div className="col-md-5 text-left">
                                            {
                                                
                                                props.viewIdea && monetizationStatusSelectedOption
                                                ?
                                                <>
                                                    <input disabled value={monetizationStatusSelectedOption.label} className="form-input disabled_input" readOnly />
                                                    
                                                </> 
                                                :
                                                <Select 
                                                    options={renderMonetizableOptions()} 
                                                    onChange={handleMonetizableStatusChange} 
                                                    value={monetizationStatusSelectedOption} 
                                                />
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                                                                        
                    </>
                    :
                    null
                } 
            
                {
                    impactOptionSelected && impactOptionSelected.label !== "No Value Delivery" 
                    && monetizableProjectSelectedOption && monetizableProjectSelectedOption.label === "Yes" 
                    && monetizationStatusSelectedOption && monetizationStatusSelectedOption.label==="Approved by Client"
                    ?
                    <>
                        <div className="monetization_client_fields" style={{paddingRight:"30px"}}>
                            <p className="u-align-left u-text u-text-2">Monetization Client Approval Details</p>
                            <div className="monetization_client_field_block">
                                <div className="row u-align-left">
                                    <div className="col-md-5">
                                        {
                                            props.postFpnaValid
                                            ?
                                            <>
                                                <div className="monetization_client_field">
                                                    <div className="row gol_field_div">
                                                        <div className="col-md-6 ">
                                                            <div className="">
                                                                <span className="form-attribute">
                                                                    {props.showMandatory ? <><span style={{ color: 'red' }}>* </span> </> : null }
                                                                    GOL OHR for Monetization
                                                                </span> 
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            {
                                                                props.viewIdea
                                                                ?
                                                                <>
                                                                    {
                                                                       GOLOHRMonetization
                                                                       ?
                                                                       <>
                                                                        <input disabled value={GOLOHRMonetization ? GOLOHRMonetization : ""} className="form-input disabled_input"  readOnly />
                                                                       </> 
                                                                       :
                                                                        null
                                                                    }
                                                                    
                                                                </>
                                                                :
                                                                GOLOHRMonetization
                                                                ?
                                                                <input 
                                                                    pattern="\d*" 
                                                                    maxLength="9" 
                                                                    onCut={(e)=>handleGOLOhrMonetizationOnCut(e)} 
                                                                    onChange={(e)=>handleGOLOhrMonetizationChange(e)}  
                                                                    className="textarea-css form-attribute hide_numeric_arrows" type="number" 
                                                                    defaultValue={GOLOHRMonetization}
                                                                    onKeyDown={ (evt) => (evt.key === '.' || evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault() }
                                                                    onWheel={(e) => e.target.blur()}
                                                                />
                                                                :
                                                                <input 
                                                                    pattern="\d*" 
                                                                    maxLength="9" 
                                                                    onCut={(e)=>handleGOLOhrMonetizationOnCut(e)} 
                                                                    onChange={(e)=>handleGOLOhrMonetizationChange(e)}  
                                                                    className="textarea-css form-attribute hide_numeric_arrows" type="number" 
                                                                    defaultValue={golOhr}
                                                                    onKeyDown={ (evt) => (evt.key === '.' || evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault() }
                                                                    onWheel={(e) => e.target.blur()}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="monetization_client_field">
                                                    <div className="row gol_field_div">
                                                        <div className="col-md-6 ">
                                                            <div className="">
                                                                <span className="form-attribute">
                                                                    {props.showMandatory ? <><span style={{ color: 'red' }}>* </span> </> : null }
                                                                    FP&A OHR for Monetization
                                                                </span> 
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            {
                                                                props.viewIdea
                                                                ?
                                                                <>
                                                                    {
                                                                        FPnAOHRMonetization
                                                                        ?
                                                                        <>
                                                                            <input disabled value={FPnAOHRMonetization} className="form-input disabled_input" readOnly />
                                                                        </>
                                                                        :
                                                                            <input disabled className="form-input disabled_input" />

                                                                    }
                                                                </>
                                                                :
                                                                FPnAOHRMonetization
                                                                ?
                                                                <input 
                                                                    pattern="\d*" maxLength="9" 
                                                                    onCut={(e)=>handleFpnaOhrMonetizationOnCut(e)} 
                                                                    onChange={(e)=>handleFpnaOhrMonetizationChange(e)}  
                                                                    className="textarea-css form-attribute hide_numeric_arrows" type="number" 
                                                                    defaultValue={FPnAOHRMonetization}
                                                                    onKeyDown={ (evt) => (evt.key === '.' || evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault() }
                                                                    onWheel={(e) => e.target.blur()}
                                                                /> 
                                                                :
                                                                <input pattern="\d*" maxLength="9" onCut={(e)=>handleFpnaOhrMonetizationOnCut(e)} 
                                                                    onChange={(e)=>handleFpnaOhrMonetizationChange(e)}  
                                                                    className="textarea-css form-attribute hide_numeric_arrows" type="number" 
                                                                    defaultValue={fpnaOhr}
                                                                    onKeyDown={ (evt) => (evt.key === '.' || evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault() }
                                                                    onWheel={(e) => e.target.blur()}
                                                                /> 
                                                            }
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                        }
                                        
                                        <div className="monetization_client_field">
                                            <div className="row gol_field_div">
                                                <div className="col-md-6 ">
                                                    <div className="">
                                                        <span className="form-attribute">
                                                            {props.showMandatory ? <><span style={{ color: 'red' }}>* </span> </> : null }
                                                            SOW ID/SFDC/OLI ID
                                                        </span> 
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    {
                                                        props.viewIdea && SowID
                                                        ?
                                                        <>
                                                            <input value={SowID} className="form-input disabled_input" disabled readOnly />
                                                        </>
                                                        :
                                                        <input 
                                                            value={SowID} 
                                                            onChange={(e)=>handleSowIdChange(e.target.value)} 
                                                            className="textarea-css form-attribute" type="text" 
                                                        />

                                                    }
                                                    
                                                </div> 
                                            </div>
                                        </div> 
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-5">
                                        {
                                            props.postFpnaValid
                                            ?
                                            <>
                                                <div className="monetization_client_field">
                                                    <div className="row gol_field_div">
                                                        <div className="col-md-5">
                                                            <div className="">
                                                                <span className="form-attribute">
                                                                    {props.showMandatory ? <><span style={{ color: 'red' }}>* </span> </> : null }
                                                                    GOL Name for Monetization
                                                                </span> 
                                                            </div>
                                                        </div>
                                                        <div className="col-md-7">
                                                            {
                                                                !GOLNameMonetization
                                                                ?
                                                                <>
                                                                    <input disabled className="form-input disabled_input" 
                                                                        type="text" value={golName} readOnly />
                                                                </>
                                                                :
                                                                <input disabled className="form-input disabled_input" 
                                                                    type="text" value={GOLNameMonetization} readOnly />
                                                            }
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="monetization_client_field">
                                                    <div className="row gol_field_div">
                                                        <div className="col-md-5">
                                                            <div className="">
                                                                <span className="form-attribute">
                                                                    {props.showMandatory ? <><span style={{ color: 'red' }}>* </span> </> : null }
                                                                    FP&A Name for Monetization
                                                                </span> 
                                                            </div>
                                                        </div>
                                                        <div className="col-md-7">
                                                            {
                                                                !FPnANameMonetization
                                                                ?
                                                                <>
                                                                <input disabled className="form-input disabled_input" 
                                                                    type="text" value={fpnaName} readOnly />
                                                                </>
                                                                :
                                                                <input disabled className="form-input disabled_input" 
                                                                    type="text" value={FPnANameMonetization} readOnly />
                                                            }
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                        }
                                        
                                    </div>
                                </div>
                                {/* <div className="">
                                    <div className="row u-align-left">
                                        <div className="col-md-6">
                                            {
                                                props.postFpnaValid
                                                ?
                                                <>
                                                    <div className="">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>
                                                                    {!props.viewIdea ? <><span style={{ color: 'red' }}>* </span> </> : null }
                                                                    FP&A Sign-off Document
                                                                    <small style={{color:"#939393", fontSize:"12px"}}>&nbsp;(Max Size: 5MB)</small>
                                                                </label> 
                                                            </div>
                                                            <div className="col-md-4">
                                                                {
                                                                    !props.viewIdea
                                                                    ?
                                                                    <>
                                                                    
                                                                        <label htmlFor="file-upload2" 
                                                                            className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2">&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                                        <input id="file-upload2" 
                                                                            type="file" style={{ display: 'none' }} 
                                                                            onChange={(event) => myFIleChange(event, "file1")} 
                                                                            accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" 
                                                                        />
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-10">
                                                                <label className={FPnAFileNameMonetization && FPnAFileNameMonetization.length > 50 ? "upload_file_text_overflow" :""} style={{cursor:"pointer", color: "#337ab7", fontWeight:"bold"}} onClick={(e)=>showMonetizationContent()}><i>&nbsp;&nbsp;{FPnAFileNameMonetization}</i></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                null
                                            }
                                            
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </>
                    :
                    null
                }
            {/* ..................monetization client approval process ends here.......... */}


            {/* ...........monetization amount starts here......... */}

                {
                    monetizationStatusSelectedOption && impactOptionSelected && impactOptionSelected.label !== "No Value Delivery"
                    ?
                    <>
                        <div className="total_monetized_amount_block">
                            <p id="BI Summary" className="u-align-left u-text u-text-2">Total Monetized Amount</p>
                            <div className="row mt-3" style={{ marginLeft: 0, marginRight: 0 }}>
                                <div className="col-md-2 col-xs-12 total_monetizable_header">
                                    <label >FTE Impact (#)</label>
                                </div>
                                <div className="col-md-3 col-xs-6 total_monetizable_header">
                                    <label>$ Impact</label>
                                </div>
                            </div>
                            <div className="row total_monetizable_data_row">
                                <div className="col-md-2 col-xs-12 borderClass">
                                    <label >
                                        {isNaN(totalMonetizedFTE) ? 0 : totalMonetizedFTE}
                                    </label>
                                </div>
                                <div className="col-md-3 col-xs-6 borderClass">
                                    <label>
                                        {isNaN(totalMonetizedDollar) ? 0 : totalMonetizedDollar}
                                    </label>
                                </div>
                            </div>
                            
                        </div>
                    </>
                    :
                    null
                }
                    
            {/* ...........monetization amount ends here......... */}

                
            <div className="BI_component ">
                {
                    impactOptionSelected
                    ?
                    <>
                        <div className="gapping_class">
                            <h3 className="idea_form_header" style={{marginTop:"0px"}}>Business additional details</h3>
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="">
                                        <div className="final_additional_block_field">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <label className="form-attribute">Final Impact (# of FTE)</label>
                                                </div>
                                                <div className="col-md-7">
                                                    <input className="form-input" disabled name="finalimpactFTE" value={impactOptionSelected && impactOptionSelected.label === "No Value Delivery" ? 0 : (finalImpactFTE ? finalImpactFTE : 0)} 
                                                    readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="final_additional_block_field">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <label className="form-attribute">Final Impact ($)</label> 
                                                </div>
                                                <div className="col-md-7">
                                                    <input className="form-input" disabled name="finalimpactdollar" 
                                                        value={impactOptionSelected && impactOptionSelected.label === "No Value Delivery" ? 0 : finalImpactDollar} 
                                                        readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                                <div className="col-md-2"></div>
                                <div className="col-md-5">
                                    <div className="final_additional_block_field">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <label className="form-attribute">Is it a Zero Impact Project</label>
                                            </div>
                                            <div className="col-md-7">
                                                {
                                                    impactOptionSelected && impactOptionSelected.label !== "No Value Delivery"
                                                    ?
                                                    <>
                                                        <label className="switch">
                                                            <input disabled type="checkbox" checked={false} readOnly  />
                                                            <span className="slider round not_allowed_curoser" />
                                                        </label>
                                                    </>
                                                    :
                                                    <label className="switch">
                                                        <input disabled type="checkbox" checked={true} readOnly />
                                                        <span className="slider round not_allowed_curoser" />
                                                    </label>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="final_additional_block_field">
                                        {
                                            impactOptionSelected && impactOptionSelected.label !== "No Value Delivery"
                                            ?
                                            <>
                                                <p>If Final Impact $ is greater than 250K OR FTE number is greater than 10, please review thoroughly</p>    
                                            </>
                                            :
                                            null
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    null
                }
                

                {
                    impactOptionSelected && impactOptionSelected.label !== "No Value Delivery"
                    ?
                    <>
                        <div className=" VD_Summary idea_common_block pb-5" style={{borderTop:"1px solid lightgrey"}}>
                            <h3 className="idea_form_header">Annualized Value Delivery Summary</h3>
                            <div className="row mt-3">
                                <div className="col-md-2 col-xs-12 VD_Summary_table_header">
                                    <div className="">
                                        <label>Impact Type</label>
                                    </div>
                                </div>
                                <div className="col-md-3 col-xs-6 VD_Summary_table_header">
                                    <div className="">
                                        <label>Category</label>
                                    </div>
                                </div>
                                <div className="col-md-2 col-xs-6 text-center VD_Summary_table_header">
                                    <div className="">
                                        <label>FTE Impact (#)</label>
                                    </div>
                                </div>
                                <div className="col-md-2 col-xs-6 text-center VD_Summary_table_header">
                                    <div className="">
                                        <label>$ Impact</label>
                                    </div>
                                </div>
                            </div>
                            { summaryArray.length > 0 && summaryArray.map((currItem, index) => {
                                return (
                                    <>
                                        <div className="" key={index}>
                                            <div className="row">
                                                <div className="col-md-2 col-xs-12 VD_Summary_table_row">
                                                    <div className="">
                                                        <label>{currItem.ImpactType}</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-xs-6 VD_Summary_table_row">
                                                    <div className="">
                                                        <label>{currItem.MonetizableType}</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-2 col-xs-6 text-center VD_Summary_table_row">
                                                    <div className="">
                                                        <label>{isNaN(currItem.FTEImpact) ? 0 : currItem.FTEImpact}</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-2 col-xs-6 text-center VD_Summary_table_row">
                                                    <div className="">
                                                        <label>{isNaN(currItem.DollarImpact) ? 0 : currItem.DollarImpact}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </>
                                )
                            })}
                        </div>
                        <div className="VD_form idea_common_block_last_block">
                            <div className="">
                                <h3 className="idea_form_header">Value Delivery Form</h3>
                                {
                                    props.viewIdea === false
                                    ?
                                    <>
                                        <div className="row d_flex_align_items_center">
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="row">
                                                        <div className="col-md-1" style={{paddingRight: "0px" }}>
                                                            <span className="form-attribute cursorClass" title="Select Impact Type">
                                                            {
                                                                props.showMandatory
                                                                    ?
                                                                    <>
                                                                        <span style={{ color: 'red' }}>*</span>
                                                                    </>
                                                                    :
                                                                    <span style={{ color: 'red' }}>&nbsp;</span>
                                                            }
                                                            </span> 
                                                        </div>
                                                        <div className="col-md-10" style={{paddingLeft: "5px" }}>
                                                            <Select options={renderFilteredImpactType()} 
                                                                isSearchable={false} placeholder="Select Impact type" 
                                                                onChange={handleImpactTypeChange}
                                                                value={filteredImpactTypeSelectedOption}
                                                                isDisabled={props.bbMonetizationApproval && monetizationStatusSelectedOption && monetizationStatusSelectedOption.label !== "Approved by Client" ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="">
                                                    <div className="row">
                                                        <div className="col-md-1" style={{paddingRight: "0px" }}>
                                                            <span className="form-attribute cursorClass" title="Select Impact Type">
                                                            {
                                                                props.showMandatory
                                                                    ?
                                                                    <>
                                                                        <span style={{ color: 'red' }}>*</span>
                                                                    </>
                                                                    :
                                                                    <span style={{ color: 'red' }}>&nbsp;</span>
                                                            }
                                                            </span> 
                                                        </div>
                                                        <div className="col-md-10" style={{paddingLeft: "0px" }}>
                                                            <Select options={renderFilteredMonetizableType()} isSearchable={false} 
                                                                placeholder="Select Category" onChange={handleMonetizableTypeChange}  
                                                                value={filteredMonetizableSelectedOption}
                                                                isDisabled={props.bbMonetizationApproval && monetizationStatusSelectedOption && monetizationStatusSelectedOption.label !== "Approved by Client" ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-md-4">
                                                <div className="">
                                                    <div className="row">
                                                        <div className="col-md-1" style={{paddingRight: "0px" }}>
                                                            <span className="form-attribute cursorClass" title="Select Impact Type">
                                                            {
                                                                props.showMandatory
                                                                    ?
                                                                    <>
                                                                        <span style={{ color: 'red' }}>*</span>
                                                                    </>
                                                                    :
                                                                    <span style={{ color: 'red' }}>&nbsp;</span>
                                                            }
                                                            </span> 
                                                        </div>
                                                        <div className="col-md-10" style={{paddingLeft: "0px" }}>
                                                            <Select options={renderFilteredBiSubCategoryType()} isSearchable={false} 
                                                                value={filteredBISubCategorySelectedOption}
                                                                placeholder="Select Sub Category" onChange={handleBiSubCategoryChange}  
                                                                isDisabled={props.bbMonetizationApproval && monetizationStatusSelectedOption && monetizationStatusSelectedOption.label !== "Approved by Client" ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <div className="">
                                                    <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e)=>addSelectedValues(e,monetizedAlertCounter)}
                                                    disabled={props.bbMonetizationApproval && monetizationStatusSelectedOption && monetizationStatusSelectedOption.label !== "Approved by Client" ? true : false}
                                                    >Add</button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    null
                                }
                                
                            </div>
                            


                            {/* .... scrollable new vd table starts here.... */}
                                <div className="gen_ai_bi_table">
                                    <table className="table table-bordered">
                                        <thead className="thead-light">
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th colSpan="2">Annualized</th>
                                                <th colSpan="2">Deployment Year</th>
                                                {/* <th colSpan="2">Realized</th> */}
                                                <th></th>
                                                <th></th>
                                            </tr>
                                            <tr>
                                                <th>Impact Type</th>
                                                <th>Sub Category</th>
                                                <th>Sub Division</th>
                                                <th>FTE Impact (#)</th>
                                                <th>$ Impact</th>
                                                <th>FTE Impact (#)</th>
                                                <th>$ Impact</th>
                                                {/* <th>FTE Impact (#)</th>
                                                <th>$ Impact</th> */}
                                                <th>Monetized</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                            <tbody>
                                                {
                                                    props.viewIdea
                                                        ?
                                                        <>
                                                            {
                                                                businessImpactValueArray.map((item, index) => {
                                                                    return (
                                                                        <tr key={item.SrNo}>
                                                                            <td><label>{item.ImpactType}</label></td>
                                                                            <td><label>{item.MonetizableType}</label></td>
                                                                            <td className="sub_category"><label>{item.BISubCategory}</label></td>
                                                                            {/* Annualized */}
                                                                            <td>
                                                                                {
                                                                                    (item.FTEImpact > 0.0)
                                                                                    ?
                                                                                    <>
                                                                                        <input className="form-input gen_ai_vd_input" disabled type="number" value={item.FTEImpact} readOnly />
                                                                                    </>
                                                                                    :
                                                                                    null
                                                                                }

                                                                            </td>
                                                                            <td>
                                                                                <input className="form-input gen_ai_vd_input" type="number" disabled min="0" max="500000000" defaultValue={item.DollarImpact} placeholder={0.0} />
                                                                            </td>
                                                                            {/* Annualized */}
                                                                            
                                                                            {/* Deployment Year */}
                                                                            <td>
                                                                                {
                                                                                    (item.FTEImpactYear > 0.0)
                                                                                        ?
                                                                                        <>
                                                                                            <input className="form-input gen_ai_vd_input" disabled type="number" value={item.FTEImpactYear} readOnly />
                                                                                        </>
                                                                                        :
                                                                                        null
                                                                                }

                                                                            </td>
                                                                            <td>
                                                                                <input className="form-input gen_ai_vd_input" type="number" disabled min="0" max="500000000" defaultValue={item.DollarImpactYear} placeholder={0.0} />
                                                                            </td>
                                                                            {/* Deployment Year */}

                                                                            {/* Realised */}
                                                                            {/* <td>
                                                                                {
                                                                                    (item.FTEImpact > 0.0)
                                                                                    ?
                                                                                    <>
                                                                                        <input className="form-input gen_ai_vd_input" disabled type="number" value={item.FTEImpact} />
                                                                                    </>
                                                                                    :
                                                                                    null
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                <input className="form-input gen_ai_vd_input" type="number" disabled min="0" max="500000000" defaultValue={item.DollarImpact} placeholder={0.0} />
                                                                            </td> */}
                                                                            {/* Realised */}


                                                                            <td>
                                                                                {
                                                                                    item.IsMonetized === "Yes"
                                                                                        ?
                                                                                        <>
                                                                                            <input type="checkbox" checked={true} style={{ cursor: "no-drop" }} readOnly />
                                                                                        </>
                                                                                        :
                                                                                        <input type="checkbox" checked={false} style={{ cursor: "no-drop" }} readOnly />
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </>

                                                        :
                                                        <>
                                                            {
                                                                businessImpactValueArray.map((item, index) => {
                                                                    //console.log("displayed item =>",item)
                                                                    const isFtePresent = checkIsFtePresent(item);
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td className=""><label>{item.ImpactType}</label></td>
                                                                            <td className=""><label>{item.MonetizableType}</label></td>
                                                                            <td className="sub_category"><label>{item.BISubCategory}</label></td>
                                                                            {/* Annualised starts here */}
                                                                            <td className="">
                                                                                {
                                                                                    isFtePresent && (
                                                                                        <>
                                                                                            {
                                                                                                props.bbMonetizationApproval
                                                                                                    ?
                                                                                                    <>
                                                                                                        {
                                                                                                            item.ImpactType === "Impact at Genpact" && item.MonetizableType === "Increase in Revenue" && monetizationStatusSelectedOption.label === "Approved by Client" && item.IsMonetized === "Yes"
                                                                                                                ?
                                                                                                                <>
                                                                                                                    <input type="number" min="0" style={{ border: '1px solid lightgrey' }}
                                                                                                                        placeholder={0} max={99}
                                                                                                                        onChange={(event) => handleBIFTEChange(item.ImpactType, item.MonetizableType, item.BISubCategory, event.target.value, index, event, isFtePresent)}
                                                                                                                        value={item.FTEImpact}
                                                                                                                        className="bi-component-form-input hide_numeric_input_arrows gen_ai_vd_input"
                                                                                                                        disabled={false}
                                                                                                                    />
                                                                                                                </>
                                                                                                                :
                                                                                                                <input type="number" min="0" style={{ border: '1px solid lightgrey' }}
                                                                                                                    placeholder={0} max={99}
                                                                                                                    onChange={(event) => handleBIFTEChange(item.ImpactType, item.MonetizableType, item.BISubCategory, event.target.value, index, event, isFtePresent)}
                                                                                                                    value={item.FTEImpact}
                                                                                                                    className="bi-component-form-input bi-component-disabled-form-input hide_numeric_input_arrows gen_ai_vd_input"
                                                                                                                    disabled={true}
                                                                                                                />
                                                                                                        }
                                                                                                    </>
                                                                                                    :
                                                                                                    <input type="number" min="0" style={{ border: '1px solid lightgrey' }}
                                                                                                        placeholder={0} max={99}
                                                                                                        onChange={(event) => handleBIFTEChange(item.ImpactType, item.MonetizableType, item.BISubCategory, event.target.value, index, event, isFtePresent)}
                                                                                                        value={item.FTEImpact}
                                                                                                        className="bi-component-form-input hide_numeric_input_arrows gen_ai_vd_input"
                                                                                                        disabled={false}
                                                                                                    />
                                                                                            }

                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </td>
                                                                            <td className="">
                                                                                {
                                                                                    props.bbMonetizationApproval
                                                                                        ?
                                                                                        <>
                                                                                            {
                                                                                                item.ImpactType === "Impact at Genpact" && item.MonetizableType === "Increase in Revenue" && monetizationStatusSelectedOption.label === "Approved by Client" && item.IsMonetized === "Yes"
                                                                                                    ?
                                                                                                    <>
                                                                                                        <input type="number" min="0" max="500000000"
                                                                                                            style={{ border: '1px solid lightgrey' }}
                                                                                                            placeholder={0.0}
                                                                                                            onChange={(event) => handleBIDollarChange(item.ImpactType, item.SrNo, item.MonetizableType, item.BISubCategory, event.target.value, index, event, isFtePresent)}
                                                                                                            onBlur={(event) => handleBIDollarChange(item.ImpactType, item.SrNo, item.MonetizableType, item.BISubCategory, event.target.value, index, event, isFtePresent)}
                                                                                                            value={item.DollarImpact}
                                                                                                            className="bi-component-form-input hide_numeric_input_arrows gen_ai_vd_input"
                                                                                                            disabled={false}
                                                                                                        />
                                                                                                    </>
                                                                                                    :
                                                                                                    <input type="number" min="0" max="500000000"
                                                                                                        style={{ border: '1px solid lightgrey' }}
                                                                                                        placeholder={0.0}
                                                                                                        value={item.DollarImpact}
                                                                                                        className="bi-component-form-input bi-component-disabled-form-input hide_numeric_input_arrows gen_ai_vd_input"
                                                                                                        disabled={true}
                                                                                                    />

                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        <input type="number" min="0" max="500000000"
                                                                                            style={{ border: '1px solid lightgrey' }}
                                                                                            placeholder={0.0}
                                                                                            onChange={(event) => handleBIDollarChange(item.ImpactType, item.SrNo, item.MonetizableType, item.BISubCategory, event.target.value, index, event, isFtePresent)}
                                                                                            onBlur={(event) => handleBIDollarChange(item.ImpactType, item.SrNo, item.MonetizableType, item.BISubCategory, event.target.value, index, event, isFtePresent)}
                                                                                            value={item.DollarImpact}
                                                                                            className="bi-component-form-input hide_numeric_input_arrows gen_ai_vd_input"
                                                                                            disabled={false}
                                                                                        />
                                                                                }
                                                                            </td>
                                                                            {/* Annualised starts here */}

                                                                            {/* Deployment Year starts here*/}
                                                                            <td className="">
                                                                                {
                                                                                    isFtePresent && (
                                                                                        <>
                                                                                            {
                                                                                                props.bbMonetizationApproval
                                                                                                    ?
                                                                                                    <>
                                                                                                        {
                                                                                                            item.ImpactType === "Impact at Genpact" && item.MonetizableType === "Increase in Revenue" && monetizationStatusSelectedOption.label === "Approved by Client" && item.IsMonetized === "Yes"
                                                                                                                ?
                                                                                                                <>
                                                                                                                    <input 
                                                                                                                        type="number" 
                                                                                                                        min="0" s
                                                                                                                        style={{ border: '1px solid lightgrey' }}
                                                                                                                        placeholder={0} max={99}
                                                                                                                        onChange={(event) => handleBIFTEChangeForDeployment(item.ImpactType, item.MonetizableType, item.BISubCategory, event.target.value, index, event, isFtePresent)}
                                                                                                                        value={item.FTEImpactYear}
                                                                                                                        className="bi-component-form-input hide_numeric_input_arrows gen_ai_vd_input"
                                                                                                                        disabled={false}
                                                                                                                    />
                                                                                                                </>
                                                                                                                :
                                                                                                                <input 
                                                                                                                    type="number" 
                                                                                                                    min="0" 
                                                                                                                    style={{ border: '1px solid lightgrey' }}
                                                                                                                    placeholder={0} max={99}
                                                                                                                    defaultValue={item.FTEImpactYear}
                                                                                                                    className="bi-component-form-input bi-component-disabled-form-input hide_numeric_input_arrows gen_ai_vd_input"
                                                                                                                    disabled={true}
                                                                                                                />
                                                                                                        }
                                                                                                    </>
                                                                                                    :
                                                                                                    <input 
                                                                                                        type="number" 
                                                                                                        min="0" 
                                                                                                        style={{ border: '1px solid lightgrey' }}
                                                                                                        placeholder={0} max={99}
                                                                                                        onChange={(event) => handleBIFTEChangeForDeployment(item.ImpactType, item.MonetizableType, item.BISubCategory, event.target.value, index, event, isFtePresent)}
                                                                                                        value={item.FTEImpactYear}
                                                                                                        className="bi-component-form-input hide_numeric_input_arrows gen_ai_vd_input"
                                                                                                        disabled={false}
                                                                                                    />
                                                                                            }

                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </td>
                                                                            <td className="">
                                                                                {
                                                                                    props.bbMonetizationApproval
                                                                                        ?
                                                                                        <>
                                                                                            {
                                                                                                item.ImpactType === "Impact at Genpact" && item.MonetizableType === "Increase in Revenue" && monetizationStatusSelectedOption.label === "Approved by Client" && item.IsMonetized === "Yes"
                                                                                                    ?
                                                                                                    <>
                                                                                                        <input 
                                                                                                            type="number" 
                                                                                                            min="0" 
                                                                                                            max="500000000"
                                                                                                            style={{ border: '1px solid lightgrey' }}
                                                                                                            placeholder={0.0}
                                                                                                            onChange={(event) => handleBIDollarChangeForDeployment(item.ImpactType, item.SrNo, item.MonetizableType, item.BISubCategory, event.target.value, index, event, isFtePresent)}
                                                                                                            value={item.DollarImpactYear}
                                                                                                            className="bi-component-form-input hide_numeric_input_arrows gen_ai_vd_input"
                                                                                                            disabled={false}
                                                                                                        />
                                                                                                    </>
                                                                                                    :
                                                                                                    <input 
                                                                                                        type="number" 
                                                                                                        min="0" 
                                                                                                        max="500000000"
                                                                                                        style={{ border: '1px solid lightgrey' }}
                                                                                                        placeholder={0.0}
                                                                                                        value={item.DollarImpactYear}
                                                                                                        className="bi-component-form-input bi-component-disabled-form-input hide_numeric_input_arrows gen_ai_vd_input"
                                                                                                        disabled={true}
                                                                                                    />

                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        <input 
                                                                                            type="number" 
                                                                                            min="0" 
                                                                                            max="500000000"
                                                                                            style={{ border: '1px solid lightgrey' }}
                                                                                            placeholder={0.0}
                                                                                            onChange={(event) => handleBIDollarChangeForDeployment(item.ImpactType, item.SrNo, item.MonetizableType, item.BISubCategory, event.target.value, index, event, isFtePresent)}
                                                                                            value={item.DollarImpactYear}
                                                                                            className="bi-component-form-input hide_numeric_input_arrows gen_ai_vd_input"
                                                                                            disabled={false}
                                                                                        />
                                                                                }
                                                                            </td>
                                                                            {/* Deployment Year ends here*/}


                                                                            {/* Realised starts here */}
                                                                            {/* <td className="">
                                                                                {
                                                                                    isFtePresent && (
                                                                                        <>
                                                                                            {
                                                                                                props.bbMonetizationApproval
                                                                                                    ?
                                                                                                    <>
                                                                                                        {
                                                                                                            item.ImpactType === "Impact at Genpact" && item.MonetizableType === "Increase in Revenue" && monetizationStatusSelectedOption.label === "Approved by Client" && item.IsMonetized === "Yes"
                                                                                                                ?
                                                                                                                <>
                                                                                                                    <input type="number" min="0" style={{ border: '1px solid lightgrey' }}
                                                                                                                        placeholder={0} max={99}
                                                                                                                        onBlur={(event) => handleBIFTEChangeForRealised(item.ImpactType, item.MonetizableType, item.BISubCategory, event.target.value, index, event, isFtePresent)}
                                                                                                                        defaultValue={item.FTEImpactActual}
                                                                                                                        className="bi-component-form-input hide_numeric_input_arrows gen_ai_vd_input"
                                                                                                                        disabled={false}
                                                                                                                    />
                                                                                                                </>
                                                                                                                :
                                                                                                                <input type="number" min="0" style={{ border: '1px solid lightgrey' }}
                                                                                                                    placeholder={0} max={99}
                                                                                                                    onBlur={(event) => handleBIFTEChangeForRealised(item.ImpactType, item.MonetizableType, item.BISubCategory, event.target.value, index, event, isFtePresent)}
                                                                                                                    defaultValue={item.FTEImpactActual}
                                                                                                                    className="bi-component-form-input bi-component-disabled-form-input hide_numeric_input_arrows gen_ai_vd_input"
                                                                                                                    disabled={true}
                                                                                                                />
                                                                                                        }
                                                                                                    </>
                                                                                                    :
                                                                                                    <input type="number" min="0" style={{ border: '1px solid lightgrey' }}
                                                                                                        placeholder={0} max={99}
                                                                                                        onBlur={(event) => handleBIFTEChangeForRealised(item.ImpactType, item.MonetizableType, item.BISubCategory, event.target.value, index, event, isFtePresent)}
                                                                                                        defaultValue={item.FTEImpactActual}
                                                                                                        className="bi-component-form-input hide_numeric_input_arrows gen_ai_vd_input"
                                                                                                        disabled={false}
                                                                                                    />
                                                                                            }

                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </td>
                                                                            <td className="">
                                                                                {
                                                                                    props.bbMonetizationApproval
                                                                                        ?
                                                                                        <>
                                                                                            {
                                                                                                item.ImpactType === "Impact at Genpact" && item.MonetizableType === "Increase in Revenue" && monetizationStatusSelectedOption.label === "Approved by Client" && item.IsMonetized === "Yes"
                                                                                                    ?
                                                                                                    <>
                                                                                                        <input type="number" min="0" max="500000000"
                                                                                                            style={{ border: '1px solid lightgrey' }}
                                                                                                            placeholder={0.0}
                                                                                                            onBlur={(event) => handleBIDollarChangeForRealised(item.ImpactType, item.SrNo, item.MonetizableType, item.BISubCategory, event.target.value, index, event, isFtePresent)}
                                                                                                            defaultValue={item.DollarImpactActual}
                                                                                                            className="bi-component-form-input hide_numeric_input_arrows gen_ai_vd_input"
                                                                                                            disabled={false}
                                                                                                        />
                                                                                                    </>
                                                                                                    :
                                                                                                    <input type="number" min="0" max="500000000"
                                                                                                        style={{ border: '1px solid lightgrey' }}
                                                                                                        placeholder={0.0}
                                                                                                        onBlur={(event) => handleBIDollarChangeForRealised(item.ImpactType, item.SrNo, item.MonetizableType, item.BISubCategory, event.target.value, index, event, isFtePresent)}
                                                                                                        defaultValue={item.DollarImpactActual}
                                                                                                        className="bi-component-form-input bi-component-disabled-form-input hide_numeric_input_arrows gen_ai_vd_input"
                                                                                                        disabled={true}
                                                                                                    />

                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        <input type="number" min="0" max="500000000"
                                                                                            style={{ border: '1px solid lightgrey' }}
                                                                                            placeholder={0.0}
                                                                                            onBlur={(event) => handleBIDollarChangeForRealised(item.ImpactType, item.SrNo, item.MonetizableType, item.BISubCategory, event.target.value, index, event, isFtePresent)}
                                                                                            defaultValue={item.DollarImpactActual}
                                                                                            className="bi-component-form-input hide_numeric_input_arrows gen_ai_vd_input"
                                                                                            disabled={false}
                                                                                        />
                                                                                }
                                                                            </td> */}

                                                                            {/* Realised ends here */}


                                                                            <td className="">
                                                                                {
                                                                                    props.bbMonetizationApproval
                                                                                        ?
                                                                                        <>
                                                                                            {
                                                                                                item.ImpactType === "Impact at Genpact" && item.MonetizableType === "Increase in Revenue" ?
                                                                                                    <>
                                                                                                        <input type="checkbox" checked={true} readOnly={true} style={{ cursor: "no-drop" }} />
                                                                                                    </>
                                                                                                    :
                                                                                                    <input disabled={true} type="checkbox" style={{ cursor: "no-drop" }} />
                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                item.ImpactType === "Impact at Genpact" && item.MonetizableType === "Increase in Revenue" ?
                                                                                                    <>
                                                                                                        <input type="checkbox" checked={true} readOnly={true} style={{ cursor: "no-drop" }} />
                                                                                                    </>
                                                                                                    :
                                                                                                    <input disabled={true} type="checkbox" style={{ cursor: "no-drop" }} />
                                                                                            }
                                                                                        </>

                                                                                }
                                                                            </td>
                                                                            <td className="">
                                                                                {
                                                                                    props.bbMonetizationApproval
                                                                                        ?
                                                                                        <>
                                                                                            {
                                                                                                item.ImpactType === "Impact at Genpact" && item.MonetizableType === "Increase in Revenue" && monetizationStatusSelectedOption.label === "Approved by Client" && item.IsMonetized === "Yes"
                                                                                                ?
                                                                                                <>
                                                                                                    <button onClick={() => removeBiFormRow(index, item)}
                                                                                                        disabled={false}
                                                                                                        className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{ padding: "2px 3px" }}>&nbsp;&nbsp;<i className="fa fa-trash" aria-hidden="true" /> &nbsp;Delete&nbsp;&nbsp;
                                                                                                    </button>
                                                                                                </>
                                                                                                :
                                                                                                <button
                                                                                                    disabled={true}
                                                                                                    className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{ padding: "2px 3px" }}>&nbsp;&nbsp;<i className="fa fa-trash" aria-hidden="true" /> &nbsp;Delete&nbsp;&nbsp;
                                                                                                </button>

                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        <button onClick={() => removeBiFormRow(index, item)}
                                                                                            disabled={false}
                                                                                            className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{ padding: "2px 3px" }}>&nbsp;&nbsp;<i className="fa fa-trash" aria-hidden="true" /> &nbsp;Delete&nbsp;&nbsp;
                                                                                        </button>

                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </>


                                                }
                                        </tbody>
                                    </table>

                                </div>
                            {/* ....scrollable new vd table ends here.... */}
                        </div>
                    </>
                    :
                    null
                }
                
                
                
            </div>
    </div>
            
       
  )
}

export default GenAIValueDelivery