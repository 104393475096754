import React, { Component } from 'react'
import { GET_METADATA, GET_IDEA_URL, USER_DATA, ACCESS_TOKEN, YESORNO, OPPMASTERSTAGES, GenAI_ENVIRONMENT, GenAI_FUNDING, OPPAPPROVALOPTIONS, SUBMIT_GENAI_URL } from '../assets/constants/constants';
//import { YESORNO, OPPMASTERSTAGES,, GenAI_ENVIRONMENT, GenAI_FUNDING, oppApprovalOptions } from '../assets/constants//oppConstants';
import Toast from 'light-toast';
import Select from 'react-select';
import { selectValidation as selectStyle, textBoxValidation } from "../utils/FieldValidation";
import Checksum from "../utils/Checksum";
import GenAIValueDelivery from './GenAIValueDelivery';
import GenAIFPNAGOL from './GenAIFPNAGOL'


export class GenAICentralTeamApproval extends Component {
    sessionData = JSON.parse(localStorage.getItem(USER_DATA));
    accessToken = localStorage.getItem(ACCESS_TOKEN);

    constructor(props) {
        super(props);
        console.log(props)
        if (this.sessionData === null) {
            Toast.loading("This component cannot be accessed directly. Redirecting to application home.", 3000, () => {
            });
            window.location = "/login";
            return;
        }
        this.state = {
            selectStyle: {},
            oppDetails: [],
            RecID: this.props.location.state,
            RecIDAlpha: "",
            OppName: "",
            ParentID: "",
            ParentIDAlpha: "",
            ModifiedDate: "",
            ModifiedBy: "",
            PlanDiscussDate: "",
            DiscussEstDate: "",
            accountList: [],
            AccountSelectedOption: [],

            Account: "",
            Unit: "",
            MangingDept: "",
            Vertical: "",
            Segment: "",
            Archetype: "",
            AccFTE: '',

            GOLOHR: "",
            GOL: "",

            SpocOHR_1: "",
            Spoc_1: "",


            SpocOHR_2: "",
            Spoc_2: "",


            BBOHR: "",
            BB: "",


            MBBOHR: "",
            MBB: "",


            SolnOwnerOHR: "",
            SolnOwner: "",

            CentTeamOHR: "",
            CentTeam: "",

            ServiceLine: "",
            ServiceSelectedOption: [],

            // audit log
            auditLog: false,
            auditLogData: [],

            serviceLineList: [],
            serviceLinetSelectedOption: [],



            Process_L2: "",
            Process_L2SelectedOption: [],
            subServiceLineList: [],

            ClientDtls: "",
            Problem: "",
            Solution: "",
            OverallFTE: "",
            OverallSOP: "",
            CXO: "",
            LeadHelp: "",
            IsProposalShared: "",
            IsProposalSharedSelectedOption: [],
            // ProposalFileName
            // ProposalFileType,

            SolnEnviron: [],
            SolnFund: [],
            ClientDollar: "",
            GenpactDollar: "",
            IsSOWScope: [],
            IsSOWSigned: [],
            // SOWFileName
            // SOWFileType
            SOWSubDate: "",
            SOWCloseDate: "",
            CompletionDate: "",
            TechOHR: "",
            Tech: "",
            FunctionalOHR: "",
            Functional: "",

            Knowledge: "",
            Algo: "",
            Data: "",
            Infra: "",
            ValueCreated: "",
            // GOLFileName
            // GOLFileType
            FTEUsage: "",
         


            handleBIEditLog: false,
            OppStage: "",
            OppStageSelectedOption: [],
            OppState: "",


            IsHero: "",
            IsHeroSelectedOption: [],
            GenAIOfferingList: [],
            Offering_1: "",
            Offering_1SelectedOption: [],
            Offering_L1_Other: "",

            Offering_2: "",
            Offering_2SelectedOption: [],
            Offering_L2_Other: "",


            userRole: "Unit SPOC",



            OLINum: "",
            OppID: "",


            CentTeamApproval: "",
            centTeamApprovalSelectedOption: [],
            CentTeamComment: "",

            SolnOwnerComment: "",
            SolnOwnerApproval: "",

            centralTeamCommentLog: false,
            BiData: [],


            showHrefModal: false,
            CustName: "",
            CustDesgn: "",
            CustSignOffDate: "",
            GOLOHRVD: "",
            GOLNameVD: "",
            FPnAName: "",
            FPnAOHR: "",

            GOLOHRMonetization: "",
            GOLNameMonetization: "",
            FPnAOHRMonetization: "",
            FPnANameMonetization: "",
            SowID: "",
            centralTeamCommentsOnly: [],


            Cohort: "",
            CohortList: [],
            CohorSelectedOption: [],

            

        }
    }

    async componentDidMount() {
        await this.fetchMetadata()
        await this.fetchOpportunity()

    }
    fetchMetadata = () => {
        Toast.loading("Fetching GenAI meta data. Please wait...", () => { });
        const param = {
            EntityType: "",
            EntityName: ""
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.accessToken
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("meta data ", data)
                Toast.hide();
                this.setState({
                    accountList: data ? data.Accounts.sort((a, b) => { return a.ACCOUNT > b.ACCOUNT ? 1 : -1; }) : [],
                    serviceLineList: data ? data.ServiceLines : [],
                    subServiceLineList: data ? data.ServiceLines : [],
                    GenAIOfferingList: data ? data.GenAIOffering : [],
                    CohortList: data ? data.Cohort : [],

                }, () => {

                    if (this.state.GenAIOfferingList) {
                        if (this.state.Offering_1) {
                            const uniqueOfferingL1 = [...new Map(this.state.GenAIOfferingList.map(item => [item["Offering_L1"], item])).values()]
                            uniqueOfferingL1.map((item, index) => {
                                if (item.Offering_L1 === this.state.Offering_1) {
                                    this.setState({ Offering_1: item.Offering_L1, Offering_1SelectedOption: { label: item.Offering_L1, value: index } }, () => { })
                                }

                            })
                            if (this.state.Offering_1 === "Others") {
                                this.setState({ Offering_1: "Others", Offering_1SelectedOption: { label: "Others", value: 0 } }, () => { })
                            }
                        }
                        if (this.state.Offering_2) {
                            let filteredItems = this.state.GenAIOfferingList.filter((item) =>
                                item.Offering_L1 === this.state.Offering_1
                            )
                            filteredItems.map((item, index) => {
                                this.setState({ Offering_2: item.Offering_L2, Offering_2SelectedOption: { label: item.Offering_L2, value: index } }, () => { })
                            })
                            if (this.state.Offering_2 === "Others") {
                                this.setState({ Offering_2: "Others", Offering_2SelectedOption: { label: "Others", value: 0 } }, () => { })
                            }
                        }
                    }



                    this.renderServiceLineOptions()
                    
                    if (this.state.Cohort) {
                        this.state.CohortList && this.state.CohortList.map((item, index) => {
                            if (item.Cohort === this.state.Cohort) {
                                this.setState({
                                    CohorSelectedOption: {
                                        value: index,
                                        label: item.Cohort
                                    },
                                    Cohort: item.Cohort
                                }, () => { })
                            }
                        })
                    }


                })


                if (this.state.ServiceLine) {
                    let localServiceLine = this.state.ServiceLine.replace(/[|,]/g, ",")
                    localServiceLine = localServiceLine.split(",");
                    let abc = localServiceLine.toString();
                    this.setState({ ServiceLine: abc }, () => { })


                    if (this.state.oppDetails.Process_L2) {
                        let localSubServiceLine = this.state.oppDetails.Process_L2.replace(/[|,]/g, ",")
                        localSubServiceLine = localSubServiceLine.split(",");
                        let abc = localSubServiceLine.toString();
                        this.setState({ Process_L2: abc }, () => { })
                    }
                }
            })
            .catch(error => {
                Toast.hide();
                Toast.fail('Error occured while getting Metadata. Please try again!', 3000, () => {
                    console.log(JSON.stringify(error));
                });
            });
    }


    fetchOpportunity = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.accessToken,
                },
            };
            fetch(`${GET_IDEA_URL}IdeaId=''&RecID=${this.props.location.state}&case=new&casetype=genAI`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log("fetch opportunity details => ", data)
                    this.setState({
                        oppDetails: data ? data.oppDetails ? data.oppDetails.Table1[0] : [] : [],
                        auditLogData: data ? data.oppDetails ? data.oppDetails.Table2 : [] : [],
                        BiData: data ? data.oppDetails ? data.oppDetails.Table3 : [] : [],

                    }, () => { })
                    resolve();
                    if (this.state.oppDetails) {
                        this.setState({
                            OppName: this.state.oppDetails.OppName,
                            RecID: this.state.oppDetails.RecID,
                            RecIDAlpha: this.state.oppDetails.RecIDAlpha,
                            CreatedDate: this.state.oppDetails.ParentIDAlpha,
                            CreatedName: this.state.oppDetails.CreatedName,
                            CreatedBy: this.state.oppDetails.CreatedBy,

                            ModifiedBy: this.state.oppDetails.ModifiedBy,
                            ModifiedDate: this.state.oppDetails.ModifiedDate,

                            OppStage: this.state.oppDetails.OppStage,
                            OppState: this.state.oppDetails.OppState,


                            ParentID: this.state.oppDetails.ParentID,
                            ParentIDAlpha: this.state.oppDetails.ParentIDAlpha,


                            Account: this.state.oppDetails.Account,
                            Vertical: this.state.oppDetails.Vertical,
                            Segment: this.state.oppDetails.Segment,
                            Unit: this.state.oppDetails.Unit,
                            MangingDept: this.state.oppDetails.MangingDept,
                            AccFTE: this.state.oppDetails.AccFTE,
                            Archetype: this.state.oppDetails.Archetype,

                            GOLOHR: this.state.oppDetails.GOLOHR,
                            GOL: this.state.oppDetails.GOL,
                            SpocOHR_1: this.state.oppDetails.SpocOHR_1,
                            Spoc_1: this.state.oppDetails.Spoc_1,
                            SpocOHR_2: this.state.oppDetails.SpocOHR_2,
                            Spoc_2: this.state.oppDetails.Spoc_2,
                            BBOHR: this.state.oppDetails.BBOHR,
                            BB: this.state.oppDetails.BB,
                            MBBOHR: this.state.oppDetails.MBBOHR,
                            MBB: this.state.oppDetails.MBB,
                            SolnOwnerOHR: this.state.oppDetails.SolnOwnerOHR,
                            SolnOwner: this.state.oppDetails.SolnOwner,

                            Algo: this.state.oppDetails.Algo,
                            CXO: this.state.oppDetails.CXO,
                            ClientDollar: this.state.oppDetails.ClientDollar,
                            ClientDtls: this.state.oppDetails.ClientDtls,

                            CompletionDate: this.state.oppDetails.CompletionDate,

                            Data: this.state.oppDetails.Data,
                            DiscussEstDate: this.state.oppDetails.DiscussEstDate,
                            FTEUsage: this.state.oppDetails.FTEUsage,
                            GenpactDollar: this.state.oppDetails.GenpactDollar,
                            Infra: this.state.oppDetails.Infra,
                            Knowledge: this.state.oppDetails.Knowledge,
                            LeadHelp: this.state.oppDetails.LeadHelp,

                            IsVDMonetizable: this.state.oppDetails.IsVDMonetizable,
                            MonetizationStatus: this.state.oppDetails.MonetizationStatus,


                            OverallFTE: this.state.oppDetails.OverallFTE,
                            OverallSOP: this.state.oppDetails.OverallSOP,
                            PlanDiscussDate: this.state.oppDetails.PlanDiscussDate,
                            Problem: this.state.oppDetails.Problem,

                            SOWCloseDate: this.state.oppDetails.SOWCloseDate,
                            SOWSubDate: this.state.oppDetails.SOWSubDate,
                            Solution: this.state.oppDetails.Solution,
                            ValueCreated: this.state.oppDetails.ValueCreated,
                            ServiceLine: this.state.oppDetails.ServiceLine,

                            TechOHR: this.state.oppDetails.TechOHR,
                            Tech: this.state.oppDetails.Tech,
                            FunctionalOHR: this.state.oppDetails.FunctionalOHR,
                            Functional: this.state.oppDetails.Functional,

                            Offering_1: this.state.oppDetails.Offering_1,
                            Offering_2: this.state.oppDetails.Offering_2,

                            Offering_1_Other: this.state.oppDetails.Offering_L1_Other,
                            Offering_2_Other: this.state.oppDetails.Offering_L2_Other,

                            OLINum: this.state.oppDetails.OLINum,
                            OppID: this.state.oppDetails.OppID,

                            Cohort: this.state.oppDetails.Cohort,

                        },
                            () => {

                                let local_centralTeamCommentsOnly = this.state.auditLogData && this.state.auditLogData.filter((item, index) => {
                                    return item.UpdaterRole === "Opp Solution Owner" || item.UpdaterRole === "Central Team"
                                })
                                if (local_centralTeamCommentsOnly) {
                                    this.setState({ centralTeamCommentsOnly: local_centralTeamCommentsOnly }, () => { })
                                }
                                console.log("central team comments only =>", this.state.centralTeamCommentsOnly)


                                if (this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.label === "Client discussion - Not Started" || this.state.OppStageSelectedOption.label === "Declined by Client" || this.state.OppStageSelectedOption.label === "Descoped" || this.state.OppStageSelectedOption.label === "On-hold")) {
                                    this.setState({ OppState: "Inactive" })
                                }
                                if (this.state.OppStage) {
                                    OPPMASTERSTAGES.map((item, index) => {
                                        if (item.label === this.state.OppStage) {
                                            this.setState({ OppStageSelectedOption: item }, () => {
                                                console.log("OppStageSelectedOption =>", this.state.OppStageSelectedOption)
                                            })
                                        }
                                    })

                                }
                                if (this.state.oppDetails.ModifiedDate) {
                                    let localVariable = this.formatDate(this.state.oppDetails.ModifiedDate)
                                    this.setState({ ModifiedDate: localVariable })
                                }

                                if (this.state.oppDetails.PlanDiscussDate) {
                                    if (this.state.PlanDiscussDate === "1900-01-01T00:00:00") {
                                        this.setState({ PlanDiscussDate: "" }, () => {
                                            //console.log("this.state.PlanDiscussDate at fetch",this.state.PlanDiscussDate)
                                        })
                                    } else {
                                        let localVariable = new Date(this.state.oppDetails.PlanDiscussDate).toLocaleDateString('en-CA').split('/').reverse().join('-')
                                        this.setState({ PlanDiscussDate: localVariable }, () => {
                                            //console.log("this.state.PlanDiscussDate at fetch",this.state.PlanDiscussDate)
                                        })
                                    }
                                }

                                if (this.state.oppDetails.DiscussEstDate) {
                                    if (this.state.oppDetails.DiscussEstDate === "1900-01-01T00:00:00") {
                                        this.setState({ DiscussEstDate: "" }, () => { })
                                    } else {
                                        let localVariable = new Date(this.state.oppDetails.DiscussEstDate).toLocaleDateString('en-CA').split('/').reverse().join('-')
                                        this.setState({ DiscussEstDate: localVariable }, () => { })
                                    }
                                }

                                if (this.state.oppDetails.SOWSubDate) {
                                    if (this.state.oppDetails.SOWSubDate === "1900-01-01T00:00:00") {
                                        this.setState({ SOWSubDate: "" }, () => { })
                                    } else {
                                        let localVariable = new Date(this.state.oppDetails.SOWSubDate).toLocaleDateString('en-CA').split('/').reverse().join('-')
                                        this.setState({ SOWSubDate: localVariable }, () => { })
                                    }
                                }

                                if (this.state.oppDetails.SOWCloseDate) {
                                    if (this.state.oppDetails.SOWCloseDate === "1900-01-01T00:00:00") {
                                        this.setState({ SOWCloseDate: "" })
                                    } else {
                                        let localVariable = new Date(this.state.oppDetails.SOWCloseDate).toLocaleDateString('en-CA').split('/').reverse().join('-')
                                        this.setState({ SOWCloseDate: localVariable })
                                    }

                                }

                                if (this.state.oppDetails.CompletionDate) {
                                    if (this.state.oppDetails.CompletionDate === "1900-01-01T00:00:00") {
                                        this.setState({ CompletionDate: "" })
                                    } else {
                                        let localVariable = new Date(this.state.oppDetails.CompletionDate).toLocaleDateString('en-CA').split('/').reverse().join('-')
                                        this.setState({ CompletionDate: localVariable }, () => { })
                                    }

                                }

                                if (this.state.oppDetails.IsVDMonetizable === "No") {
                                    this.setState({ ClientDollar: 0 }, () => { })
                                }

                                if (this.state.oppDetails.IsSOWScope) {
                                    YESORNO.map((item) => {
                                        if (item.label === this.state.oppDetails.IsSOWScope) {
                                            this.setState({ IsSOWScope: item })
                                        }
                                    })
                                }



                                if (this.state.oppDetails.IsSOWSigned) {
                                    YESORNO.map((item) => {
                                        if (item.label === this.state.oppDetails.IsSOWSigned) {
                                            this.setState({ IsSOWSigned: item })
                                        }
                                    })
                                }

                                if (this.state.oppDetails.IsProposalShared) {
                                    YESORNO.map((item) => {
                                        if (item.label === this.state.oppDetails.IsProposalShared) {
                                            this.setState({ IsProposalShared: item.label, IsProposalSharedSelectedOption: item, })
                                        }
                                    })
                                }
                                if (this.state.oppDetails.SolnEnviron) {
                                    GenAI_ENVIRONMENT.map((item) => {
                                        if (item.label === this.state.oppDetails.SolnEnviron) {
                                            this.setState({ SolnEnviron: item })
                                        }
                                    })
                                }

                                if (this.state.oppDetails.SolnFund) {
                                    GenAI_FUNDING.map((item) => {
                                        if (item.label === this.state.oppDetails.SolnFund) {
                                            this.setState({ SolnFund: item })
                                        }
                                    })
                                }
                                if (this.state.oppDetails.IsHero) {
                                    YESORNO.map((item) => {
                                        if (item.label === this.state.oppDetails.IsHero) {
                                            this.setState({ IsHeroSelectedOption: item, IsHero: item.label })
                                        }
                                    })
                                }

                                

                            }
                        );
                    }

                })
                .catch((error) => {
                    console.log(error)
                    Toast.fail(
                        "Error while fetching opportunity details. Please try again!",
                        3000,
                        () => { }
                    );
                    resolve();
                });
        });
    };
    formatDate = (columnName) => {
        return new Date(columnName).toLocaleDateString('en-CA').split('/').reverse().join('-');
    }
    // file upload functionality starts here
    uploadFile = () => {

    }
    // file upload functionality ends here


    // fields functionality starts here

    isHeroProduct = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.IsHeroSelectedOption = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            IsHero: e.label, IsHeroSelectedOption: e
        });

    }

    

    heroOfferingL1Change = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.Offering_1SelectedOption = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            Offering_1: e.label, Offering_1SelectedOption: e, OverallSOP: "",
            Offering_2: "", Offering_2SelectedOption: []
        });

    }
    heroOfferingL1OthersChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 100) {
            styleStateObj.Offering_L1_Other = textBoxValidation(true);
            this.setState({
                Offering_L1_Other: e,
                selectStyle: styleStateObj,
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 50);
            this.setState({
                Offering_L1_Other: trimmedValue,
                selectStyle: styleStateObj,
            });
        }
    }

    
    heroOfferingL2Change = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.Offering_2SelectedOption = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            Offering_2: e.label, Offering_2SelectedOption: e,

        }, () => {
            this.state.GenAIOfferingList.map((item, index) => {
                if (item.Offering_L1 === this.state.Offering_1 && item.Offering_L2 === e.label) {
                    this.setState({ IsHero: item.IsHero, IsHeroSelectedOption: { value: 0, label: item.IsHero } })
                }
            })
        });
    }

    heroOfferingL2OthersChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 100) {
            styleStateObj.Offering_L2_Other = textBoxValidation(true);
            this.setState({
                Offering_L2_Other: e,
                selectStyle: styleStateObj,
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 100);
            this.setState({
                Offering_L2_Other: trimmedValue,
                selectStyle: styleStateObj,
            });
        }
    }

    handleServiceLineChange = (e) => {
        if (e.length <= 5) {

            let styleStateObj = this.state.selectStyle;
            styleStateObj.serviceLinetSelectedOption = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj,
                serviceLinetSelectedOption: e,
                Process_L2SelectedOption: [],
                Process_L2: ""
            });
            let selectedLabels = "";
            selectedLabels = e.map(option => `${option.label}`).join('|');
            this.setState({ ServiceLine: selectedLabels }, () => { })
            this.renderSubServiceLineOptions();
        } else {
            window.alert("You can add upto 5 service line options only.")
            return false;
        }
    }

    handleProcessChange = (e) => {
        if (e.length <= 5) {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.Process_L2SelectedOption = selectStyle(true);
            this.setState({ Process_L2SelectedOption: e, selectStyle: styleStateObj }, () => { })
            let selectedLabels = "";
            selectedLabels = e.map(option => `${option.label}`).join('|');
            this.setState({ Process_L2: selectedLabels }, () => { })
        } else {
            window.alert("You can add upto 5 Area / Process line options only.")
            return false;
        }

    }
    clientBGChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 2000) {
            styleStateObj.ClientDtls = textBoxValidation(true);
            this.setState({
                ClientDtls: e,
                selectStyle: styleStateObj,
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 2000);
            this.setState({
                ClientDtls: trimmedValue,
                selectStyle: styleStateObj,
            });
        }
    }
    clientProblemChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 2000) {
            styleStateObj.Problem = textBoxValidation(true);
            this.setState({
                Problem: e,
                selectStyle: styleStateObj,
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 2000);
            this.setState({
                Problem: trimmedValue,
                selectStyle: styleStateObj,
            });
        }
    }
    genAISolutionChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 2000) {
            styleStateObj.Solution = textBoxValidation(true);
            this.setState({
                Solution: e,
                selectStyle: styleStateObj,
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 2000);
            this.setState({
                Solution: trimmedValue,
                selectStyle: styleStateObj,
            });
        }
    }

    overAllFTEinScopeChange = (e) => {
        if (e <= 5000) {
            if (/^\d{0,6}(\.\d{0,2})?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.OverallFTE = textBoxValidation(true);
                this.setState({ OverallFTE: e, selectStyle: styleStateObj }, () => { })
            } else {
                window.alert("You can add upto 6 digits and 2 decimal values only allowed.");
                return;
            }
        } else {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.OverallFTE = textBoxValidation(false);
            this.setState({ selectStyle: styleStateObj }, () => { })
        }
    }
    overAllNumberofSOPChange = (e) => {
        if (e <= 1000) {
            if (/^\d{0,6}(\.\d{0,2})?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.OverallSOP = textBoxValidation(true);
                this.setState({ OverallSOP: e, selectStyle: styleStateObj }, () => { })
            } else {
                window.alert("You can add upto 6 digits and 2 decimal values only allowed.");
                return;
            }
        } else {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.OverallSOP = textBoxValidation(false);
            this.setState({ selectStyle: styleStateObj }, () => { })
        }

    }


    needHelpChange = (e) => {

        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 300) {
            styleStateObj.LeadHelp = textBoxValidation(true);
            this.setState({
                LeadHelp: e,
                selectStyle: styleStateObj,
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 300);
            this.setState({
                LeadHelp: trimmedValue,
                selectStyle: styleStateObj,
            });
        }



    }

    CXOReportChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 300) {
            styleStateObj.CXO = textBoxValidation(true);
            this.setState({
                CXO: e,
                selectStyle: styleStateObj,
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 300);
            this.setState({
                CXO: trimmedValue,
                selectStyle: styleStateObj,
            });
        }

    }

    IsProposalSharedChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.IsSOWScope = selectStyle(true);
        this.setState({ IsProposalShared: e.label, IsProposalSharedSelectedOption: e, selectStyle: styleStateObj }, () => { })
    }

    planDiscussionDateChange = (e) => {
        if (e !== null && e !== undefined) {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.PlanDiscussDate = textBoxValidation(true);
            this.setState({ PlanDiscussDate: e, selectStyle: styleStateObj }, () => { })
        }

    }
    DiscussionEstimateDateChange = (e) => {
        if (e !== null && e !== undefined) {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.DiscussEstDate = textBoxValidation(true);
            this.setState({ DiscussEstDate: e, selectStyle: styleStateObj }, () => { })
        }
    }
    SolutionFundingChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.SolnFund = selectStyle(true);
        this.setState({
            SolnFund: e, selectStyle: styleStateObj,
            ClientDollar: '',
            GenpactDollar: '',
        }, () => { })
    }

    clientFundingChange = (e) => {
        if (/^\d{0,12}(\.\d{0,2})?$/.test(e)) {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.ClientDollar = textBoxValidation(true);
            this.setState({
                ClientDollar: e, selectStyle: styleStateObj,

            }, () => { })
        } else {
            window.alert("You can add upto 12 digits and 2 decimal values only allowed.");
            return;
        }

    }
    costToGenpactBuildChange = (e) => {
        if (/^\d{0,12}(\.\d{0,2})?$/.test(e)) {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.GenpactDollar = textBoxValidation(true);
            this.setState({ GenpactDollar: e, selectStyle: styleStateObj }, () => { })
        } else {
            window.alert("You can add upto 12 digits and 2 decimal values only allowed.");
            return;
        }
    }

    sowScopePeiceOfWorkChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.IsSOWScope = selectStyle(true);
        this.setState({ IsSOWScope: e, selectStyle: styleStateObj, IsSOWSigned: [] }, () => { })


    }

    sowScopeSignedChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.IsSOWSigned = selectStyle(true);
        this.setState({ IsSOWSigned: e, selectStyle: styleStateObj }, () => { })

    }




    expectedSOWSubmissionDateorSowSubmissionDateChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.SOWSubDate = textBoxValidation(true);
        this.setState({ SOWSubDate: e, selectStyle: styleStateObj }, () => { })

    }
    expectedSOWClosureDateorSowClosureDateChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.SOWCloseDate = textBoxValidation(true);
        this.setState({ SOWCloseDate: e, selectStyle: styleStateObj }, () => { })

    }
    expectedDeploymentDateDateorGoLiveeDateChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.CompletionDate = textBoxValidation(true);
        this.setState({ CompletionDate: e, selectStyle: styleStateObj }, () => { })

    }

    domainKnowledgeChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 300) {
            styleStateObj.Knowledge = textBoxValidation(true);
            this.setState({
                Knowledge: e,
                selectStyle: styleStateObj,
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 300);
            this.setState({
                Knowledge: trimmedValue,
                selectStyle: styleStateObj,
            });
        }

    }
    algorithmChanged = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 300) {
            styleStateObj.Algo = textBoxValidation(true);
            this.setState({
                Algo: e,
                selectStyle: styleStateObj,
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 300);
            this.setState({
                Algo: trimmedValue,
                selectStyle: styleStateObj,
            });
        }

    }

    dataUsedChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 300) {
            styleStateObj.Data = textBoxValidation(true);
            this.setState({
                Data: e,
                selectStyle: styleStateObj,
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 300);
            this.setState({
                Data: trimmedValue,
                selectStyle: styleStateObj,
            });
        }

    }
    techInfraUsedChanged = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 300) {
            styleStateObj.Infra = textBoxValidation(true);
            this.setState({
                Infra: e,
                selectStyle: styleStateObj,
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 300);
            this.setState({
                Infra: trimmedValue,
                selectStyle: styleStateObj,
            });
        }

    }

    shareHolderValueCreated = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 300) {
            styleStateObj.ValueCreated = textBoxValidation(true);
            this.setState({
                ValueCreated: e,
                selectStyle: styleStateObj,
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 300);
            this.setState({
                ValueCreated: trimmedValue,
                selectStyle: styleStateObj,
            });
        }

    }

    noOfFTEUsageChange = (e) => {
        if (e <= 5000) {
            if (/^\d*$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.FTEUsage = textBoxValidation(true);
                this.setState({ FTEUsage: e, selectStyle: styleStateObj }, () => { })
            } else {
                window.alert("Please enter whole number");
                return false;
            }
        }
    }

    
    

    // fields functionality ends here





    // BI Function starts here
    addMonetizableValues = (e) => {
        let MonetizedBiArray = [];
        let fteValue = 0;
        let dollarValue = 0;
        this.state.businessImpactValueArray.filter((item) => {
            if (item.IsMonetized === "Yes") {
                MonetizedBiArray.push(item);
            }
        });
        if (MonetizedBiArray) {
            const groupedData = MonetizedBiArray.reduce(
                (accumulator, currentValue) => {
                    const impactType = currentValue.impactType;
                    const fteValue = parseFloat(currentValue.fteValue) || 0.0;
                    const dollarValue = parseFloat(currentValue.dollarValue) || 0.0;
                    const existingRow = accumulator.find(
                        (row) => row.impactType === impactType
                    );
                    if (existingRow) {
                        existingRow.fteValue += fteValue;
                        existingRow.dollarValue += dollarValue;
                    } else {
                        accumulator.push({ impactType, fteValue, dollarValue });
                    }
                    return accumulator;
                },
                []
            );
            // console.log("grouped data",groupedData)

            if (groupedData.length === 0) {
                fteValue = 0;
                dollarValue = 0;
            } else {
                fteValue = groupedData[0].fteValue;
                dollarValue = groupedData[0].dollarValue;
            }
            this.setState(
                {
                    totalMonetizedFTE: fteValue,
                    totalMonetizedDollar: dollarValue,
                },
                () => { }
            );
        }
    };


    newBusinessImpactSummary() {
        let myArray = [...this.state.businessImpactValueArray];
        const groupedData = myArray.reduce((accumulator, currentValue) => {
            const impactType = currentValue.impactType;
            const monetizableType = currentValue.monetizableType;
            const fteValue = parseFloat(currentValue.fteValue) || 0.0;
            const dollarValue = parseFloat(currentValue.dollarValue) || 0.0;

            const existingRow = accumulator.find(
                (row) =>
                    row.impactType === impactType &&
                    row.monetizableType === monetizableType
            );
            if (existingRow) {
                existingRow.fteValue += fteValue;
                existingRow.dollarValue += dollarValue;
            } else {
                accumulator.push({
                    impactType,
                    monetizableType,
                    fteValue,
                    dollarValue,
                });
            }
            return accumulator;
        }, []);
        this.setState({ summaryArray: groupedData }, () => { });
        this.addMonetizableValues();
    }

    pull_data = ({ businessImpactValueArray }) => {
        if (businessImpactValueArray) {
            this.setState(
                { businessImpactValueArray: businessImpactValueArray },
                () => {
                    this.newBusinessImpactSummary();
                    let dollarTotal = 0.0;
                    let fteValue = 0.0;
                    this.state.businessImpactValueArray.map((item) => {
                        if (item && item.DollarImpact) {
                            dollarTotal += parseFloat(item.DollarImpact);
                        }
                        if (item && item.dollarValue) {
                            dollarTotal += parseFloat(item.dollarValue);
                        }
                        if (item && item.FTEImpact) {
                            fteValue += parseFloat(item.FTEImpact);
                        }
                        if (item && item.fteValue) {
                            fteValue += parseFloat(item.fteValue);
                        }
                    });
                    this.setState({ finalImpactDollar: dollarTotal }, () => {
                    });
                    this.setState({ finalImpactFTE: fteValue }, () => {
                    });
                },
                () => {
                }
            );
        }
    };
    pull_file_data = ({
        fileName,
        fileType,
        fileContent,
        showClientApprovalFields,
        FPnAOHRMonetization,
    }) => {
        if (fileName) {
            this.setState({ FPnAFileNameMonetization: fileName }, () => { });
        }
        if (fileType) {
            this.setState({ FPnAFileTypeMonetization: fileType }, () => { });
        }
        if (fileContent) {
            this.setState({ FPnAFileMonetization: fileContent }, () => { });
        }
        this.setState(
            { showClientApprovalFields: showClientApprovalFields },
            () => { }
        );
        this.setState({ FPnAOHRMonetization: FPnAOHRMonetization }, () => {

        });
    };
    remove_bi_data_after_delete = (filteredIdeaArray) => {
        if (filteredIdeaArray) {
            this.setState({ businessImpactValueArray: filteredIdeaArray }, () => {
                this.newBusinessImpactSummary();
                let dollarTotal = 0.0;
                let fteValue = 0.0;
                this.state.businessImpactValueArray.map((item) => {
                    if (item && item.DollarImpact) {
                        dollarTotal += parseFloat(item.DollarImpact);
                    }
                    if (item && item.dollarValue) {
                        dollarTotal += parseFloat(item.dollarValue);
                    }
                    if (item && item.FTEImpact) {
                        fteValue += parseFloat(item.FTEImpact);
                    }
                    if (item && item.fteValue) {
                        fteValue += parseFloat(item.fteValue);
                    }
                });
                this.setState({ finalImpactDollar: dollarTotal }, () => { });
                this.setState({ finalImpactFTE: fteValue }, () => { });
            });
        }
    };

    pull_monetization_sowId = (localSowId) => {
        this.setState({ SowID: localSowId }, () => { });
    };
    pull_monetization_fpna_name = (localFpnaName) => {
        this.setState({ FPnANameMonetization: localFpnaName }, () => { });
    };
    pull_monetization_fpna_ohr = (localFpnaohr) => {
        this.setState({ FPnAOHRMonetization: localFpnaohr }, () => { });
    };
    pull_monetizedTotal = ({ fteValue, dollarValue }) => {
        if (fteValue) {
            this.setState({ totalMonetizedDollar: fteValue }, () => { });
        }
        if (dollarValue) {
            this.setState({ totalMonetizedFTE: dollarValue }, () => { });
        }
    };
    pull_monetizableProject = (e) => {
        if (e) {
            this.setState({ monetizableProjectSelectedOption: e }, () => {
            });
        }
    };
    pull_monetizableStatus = (e) => {
        if (e) {
            this.setState({ monetizationStatusSelectedOption: e }, () => {
            });
        }
    };

    handleVDTypeChange = ({ e }) => {
        if (e) {
            this.setState({ impactOptionSelected: e }, () => {
                if (this.state.impactOptionSelected.label === "No Value Delivery") {
                    this.setState(
                        {
                            showClientFields: false,
                            showGOLFields: false,
                            finalImpactDollar: 0,
                            monetizableProjectSelectedOption: null,
                            monetizationStatusSelectedOption: null,
                            showClientApprovalFields: false,
                        },
                        () => { }
                    );
                }
                if (
                    this.state.impactOptionSelected.label === "Value Delivered to Client"
                ) {
                    this.setState(
                        { showClientFields: true, showGOLFields: false },
                        () => { }
                    );
                }
                if (
                    this.state.impactOptionSelected.label === "Value Delivered to Genpact"
                ) {
                    this.setState({ showClientFields: false, showGOLFields: true });
                }
                if (
                    this.state.impactOptionSelected.label ===
                    "Value Delivered to Client + Genpact"
                ) {
                    this.setState({ showClientFields: true, showGOLFields: true });
                }
            });
        }
    };
    fetchFPAOHRforIncreaseInRevenue = (e) => {
        if (e === true) {
            this.setState({ appropriateAttachment: true }, () => { });
        } else {
            this.setState({ appropriateAttachment: false }, () => { });
        }
    };

    pullMonetizationGOLName = (localGOLName) => {
        this.setState({ GOLNameMonetization: localGOLName }, () => {
        });
    };
    pullMonetizationGOLOhr = (localGOLOhr) => {
        this.setState({ GOLOHRMonetization: localGOLOhr }, () => {
        });
    };
    pullCategoryNotListed = (localShowHref) => {
        this.setState({ showHrefModal: localShowHref }, () => {

        });
    };

    pullPPTDeck = ({ fileName, fileType, fileContent }) => {
        //console.log(fileName,fileType,fileContent)
        this.setState({ filename1: fileName, fileType1: fileType, base64File1: fileContent }, () => { })
    }
    pullClientDocName = (fileName) => {
        this.setState({ signOffFilename: fileName, }, () => { })
    }
    pullClientDocType = (fileType) => {
        if (fileType) {
            this.setState({ signOffFileType: fileType }, () => { })
        }
    }
    pullClientDocContent = (fileContent) => {
        if (fileContent) {
            this.setState({ signOffContent: fileContent }, () => { })
        }
    }

    // gol file details
    pullgolFileName = (fileName) => {
        this.setState({ filename3: fileName }, () => { })
    }
    pullgolFileType = (fileType) => {
        this.setState({ fileType3: fileType }, () => { })
    }
    pullgolContent = (fileContent) => {
        this.setState({ base64File3: fileContent }, () => { })
    }

    // fpna file details
    pullfpnafileName = (fileName) => {
        if (fileName) {
            this.setState({ filename4: fileName }, () => {
                // console.log("fpna doc name",this.state.filename4)
            })
        }
    }
    pullfpnafileType = (fileType) => {
        if (fileType) {
            this.setState({ fileType4: fileType }, () => {
                // console.log("fpna doc type",this.state.fileType4)
            })
        }
    }
    pullfpnafileContent = (fileContent) => {
        this.setState({ base64File4: fileContent }, () => {
            // console.log("fpna doc file content",this.state.base64File4)
        })
    }

    pullCustName = (e) => {
        this.setState({ CustName: e }, () => {
            //console.log(this.state.CustName)
        })
    }
    pullClientDesign = (e) => {
        this.setState({ CustDesgn: e }, () => {
            //console.log(this.state.CustDesgn)
        })
    }
    pullCustSignOffDate = (e) => {
        this.setState({ CustSignOffDate: e }, () => { })
    }
    pullGOLOHR = (e) => {
        this.setState({ GOLOHRVD: e }, () => {
            //console.log("pull GOL OHR VD=>",this.state.GOLOHRVD)
        })
    }
    pullGolName = (name) => {
        this.setState({ GOLNameVD: name }, () => {
            //console.log("pull GOL Name VD =>",this.state.GOLNameVD)
        })
    }
    pullFpnaName = (name) => {
        this.setState({ FPnAName: name }, () => {
            //console.log(this.state.FPnAName)
        })
    }
    pullFpnaOhr = (e) => {
        this.setState({ FPnAOHR: e }, () => {
            //console.log(this.state.FPnAOHR)
        })
    }
    pull_fileDetails = (obj) => {
        this.setState({ golFpnaFileDetails: obj }, () => { })
    }



    // BI Function ends here

    // validate function starts here
    validate = () => {
        let isValid = true;
        this.isExceedlimit = false;
        let styleStateObj = this.state.selectStyle;

        if(this.state.CohorSelectedOption === null || this.state.CohorSelectedOption === undefined || this.state.CohorSelectedOption === "" || !this.state.CohorSelectedOption || this.state.CohorSelectedOption.length  === 0){
            isValid = false;
            styleStateObj.CohorSelectedOption = selectStyle(false);
            this.setState({selectStyle: styleStateObj,
            });
        }else{
            styleStateObj.CohorSelectedOption = selectStyle(true);
            this.setState({selectStyle: styleStateObj});
        }




        if (!this.state.centTeamApprovalSelectedOption || this.state.centTeamApprovalSelectedOption === "" || this.state.centTeamApprovalSelectedOption === null || this.state.centTeamApprovalSelectedOption === undefined || this.state.centTeamApprovalSelectedOption.length === 0) {
            isValid = false;
            Toast.fail("approval status line", 3000, () => { })
            styleStateObj.centTeamApprovalSelectedOption = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj,
            });
        } else {
            styleStateObj.centTeamApprovalSelectedOption = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj,
            });
        }


        if (this.state.centTeamApprovalSelectedOption) {
            if (!this.state.CentTeamComment || this.state.CentTeamComment === "" || this.state.CentTeamComment === null || this.state.CentTeamComment === undefined || this.state.CentTeamComment.length === 0) {
                isValid = false;
                Toast.fail("central team comment missing", 3000, () => { })
                styleStateObj.CentTeamComment = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.CentTeamComment = textBoxValidation(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }
        }








        return isValid;
    }
    // validate function ends here


    // submit opp function starts here



    setAction = () => {
        let localActionType = 'Opportunity Updated';
        if (this.state.centTeamApprovalSelectedOption && this.state.centTeamApprovalSelectedOption.value === 1) {
            localActionType = 'Deployment approved by Central Team'
        }
        if (this.state.centTeamApprovalSelectedOption && this.state.centTeamApprovalSelectedOption.value === 2) {
            localActionType = 'Deployment sent back by Central Team'
        }
        return localActionType;
    }

    viewDone = () => {
        this.props.history.push("/view-opportunities");
    }


    updateOpp = () => {
        if (this.validate()) {
            if (window.confirm("Are you sure you want to update the opportunity?")) {
                Toast.loading("updating opportunity...", () => { });
                let param = {
                    SaveType: "UpdateGenAIFieldsByCentralTeam",
                    isItIdeaOrGenAI: "GenAI",
                    OppName: this.state.OppName,
                    RecID: this.state.RecID,
                    RecIDAlpha: this.state.RecIDAlpha,

                    ModifiedBy: this.sessionData.profile.oHRId ? this.sessionData.profile.oHRId : "",
                    PlanDiscussDate: this.state.PlanDiscussDate,
                    DiscussEstDate: this.state.DiscussEstDate,

                    Account: this.state.Account,
                    Unit: this.state.Unit,
                    MangingDept: this.state.MangingDept,
                    Vertical: this.state.Vertical,
                    Segment: this.state.Segment,
                    Archetype: this.state.Archetype,
                    AccFTE: this.state.AccFTE,

                    GOLOHR: this.state.GOLOHR,
                    GOL: this.state.GOL,
                    SpocOHR_1: this.state.SpocOHR_1,
                    Spoc_1: this.state.Spoc_1,
                    SpocOHR_2: this.state.SpocOHR_2,
                    Spoc_2: this.state.Spoc_2,
                    BBOHR: this.state.BBOHR,
                    BB: this.state.BB,
                    MBBOHR: this.state.MBBOHR,
                    MBB: this.state.MBB,
                    SolnOwnerOHR: this.state.SolnOwnerOHR,
                    SolnOwner: this.state.SolnOwner,

                    ServiceLine: this.state.ServiceLine,
                    Process_L2: this.state.Process_L2,

                    ClientDtls: this.state.ClientDtls,
                    Problem: this.state.Problem,
                    Solution: this.state.Solution,
                    OverallFTE: Number(this.state.OverallFTE),
                    OverallSOP: Number(this.state.OverallSOP),
                    CXO: this.state.CXO,
                    LeadHelp: this.state.LeadHelp,
                    IsProposalShared: this.state.IsProposalSharedSelectedOption ? this.state.IsProposalSharedSelectedOption.label : "",
                    SolnEnviron: this.state.SolnEnviron.label,
                    SolnFund: this.state.SolnFund.label,
                    ClientDollar: Number(this.state.ClientDollar),
                    GenpactDollar: Number(this.state.GenpactDollar),
                    IsSOWScope: this.state.IsSOWScope.label,
                    IsSOWSigned: this.state.IsSOWSigned.label,
                    SOWSubDate: this.state.SOWSubDate,
                    SOWCloseDate: this.state.SOWCloseDate,
                    CompletionDate: this.state.CompletionDate,
                    TechOHR: this.state.TechOHR,
                    Tech: this.state.Tech,
                    FunctionalOHR: this.state.FunctionalOHR,
                    Functional: this.state.Functional,

                    Knowledge: this.state.Knowledge,
                    Algo: this.state.Algo,
                    Data: this.state.Data,
                    Infra: this.state.Infra,
                    ValueCreated: this.state.ValueCreated,

                    FTEUsage: Number(this.state.FTEUsage),

                    IsHero: this.state.IsHero,
                    Offering_1: this.state.Offering_1,
                    Offering_2: this.state.Offering_2,
                    Offering_1_Other: this.state.Offering_L1_Other ? this.state.Offering_L1_Other : "",
                    Offering_2_Other: this.state.Offering_L2_Other ? this.state.Offering_L2_Other : "",

                    Cohort: this.state.CohorSelectedOption ? this.state.CohorSelectedOption.label : "",
                    OppStage: this.state.OppStageSelectedOption ? this.state.OppStageSelectedOption.label : "",
                    OppState: this.state.OppStage,

                    Action: this.setAction(),
                    UpdaterOHR: this.sessionData.profile.oHRId ? this.sessionData.profile.oHRId : "",
                    Updater: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
                    UpdaterRole: "Central Team",

                    UnitOHR: this.state.oppDetails.UnitOHR,
                    UnitOHRName: this.state.oppDetails.UnitOHRName,
                    CentTeamOHR: this.sessionData.profile.oHRId,
                    CentTeam: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
                    CentTeamApproval: this.state.centTeamApprovalSelectedOption ? this.state.centTeamApprovalSelectedOption.label : "",
                    CentTeamComment: this.state.CentTeamComment,

                }
                console.log(param)
                // console.log(JSON.stringify(param))
                let finalJsonData = Checksum(param);
                let str1 = finalJsonData.slice(0, 15);
                let str2 = finalJsonData.slice(15, 30);
                let str3 = finalJsonData.slice(30, 40);
                let str4 = finalJsonData.slice(-10);
                let finalData = str3 + str1 + str4 + str2;
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.accessToken
                    },
                    body: JSON.stringify(param)
                };
                fetch(`${SUBMIT_GENAI_URL}Checksum=${finalData}`, requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        if (data.message === "Opportunity data succesfully Updated") {
                            Toast.hide();
                            Toast.info("Opportunity updated successfully!", 3000, () => {
                                window.location = "/gen-ai-central-team-approval-queue";
                            })

                        }
                    })
                    .catch(error => {
                        //reject(error);
                    });


            }
        } else {
            if (!this.isExceedlimit) {
                Toast.fail('Required fields are missing or have invalid values. Please correct and try again.', 3000, () => { });
            }
        }
    }
    // submit opp function ends here



    cancelOpp = () => {
        if (window.confirm("You will lose all entered data on this page if you click on cancel.")) {
            this.props.history.push("/home");
        }
    }

    raiseFlag = () => {

    }

    handleAuditLog = () => {
        this.setState({ auditLog: true }, () => { })
    }

    handleBIEditLog = (e) => {
        this.setState({ editBiModal: true }, () => { })
    }

    validateBi = () => {
        let isValid = true;
        this.isExceedlimit = false;

        if (this.state.OppStageSelectedOption) {
            if (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) {
                if (this.state.impactOptionSelected === null) {
                    isValid = false;
                    Toast.fail("Please select Value Delivery Type", 3000, () => { });
                }
                if (this.state.impactOptionSelected && this.state.impactOptionSelected.label !== "No Value Delivery") {
                    if (this.state.businessImpactValueArray.length === 0) {
                        Toast.fail("Value Delivery cannot be empty, please fill and resubmit.", 3000, () => { });
                        isValid = false;
                    } else {
                        if (this.state.finalImpactDollar <= 0) {
                            Toast.fail("Total Dollar Impact cannot be zero. Please check and resubmit.", 3000, () => { });
                            isValid = false;
                        }
                        if (this.state.finalImpactFTE > 0 && this.state.finalImpactDollar <= 0) {
                            Toast.fail("Total Impact Dollar cannot be zero when FTE is non-zero. Please check and resubmit.", 3000, () => { });
                            isValid = false;
                        }
                        this.state.businessImpactValueArray.filter((item) => {
                            if (
                                parseFloat(item.DollarImpact) === 0 ||
                                parseFloat(item.DollarImpact) === "" ||
                                !item.DollarImpact ||
                                item.DollarImpact === null
                            ) {
                                isValid = false;
                                Toast.fail("Dollar Impact value cannot be zero.", 3000, () => { });
                            }
                            if (item && item.isFTEPresent === true) {
                                if (parseFloat(item.FTEImpact)) {
                                } else {
                                    isValid = false;
                                    Toast.fail("FTE Impact value cannot be zero", 3000, () => { });
                                }
                                if (parseFloat(item.FTEImpact) === 0) {
                                    isValid = false;
                                    Toast.fail("FTE Impact value cannot be zero", 3000, () => { });
                                }
                                if (parseFloat(item.FTEImpact) > parseFloat(item.DollarImpact)) {
                                    isValid = false;
                                    Toast.fail("FTE impact value cannot be greater than dollar value", 3000, () => { });
                                }
                            }
                        });
                    }

                    if (this.state.impactOptionSelected && this.state.impactOptionSelected.label !== "No Value Delivery") {
                        if (this.state.monetizableProjectSelectedOption === null ||
                            this.state.monetizableProjectSelectedOption.length === 0 ||
                            this.state.monetizableProjectSelectedOption === undefined
                        ) {
                            isValid = false;
                            Toast.fail("Please select monetizatable project", 5000, () => { });
                        }
                    }

                    if (this.state.impactOptionSelected.label === "Value Delivered to Client + Genpact") {
                        if (this.state.businessImpactValueArray.length > 0) {
                            let impactGenpactTrue = this.state.businessImpactValueArray.some((vendor) => vendor["ImpactType"] === "Impact at Genpact");
                            let impactClientTrue = this.state.businessImpactValueArray.some((vendor) => vendor["ImpactType"] === "Impact at Client");
                            if (impactGenpactTrue === false) {
                                isValid = false;
                                Toast.fail("Please add both Impact types.", 3000, () => { });
                            }
                            if (impactClientTrue === false) {
                                isValid = false;
                                Toast.fail("Please add both Impact types.", 3000, () => { });
                            }
                        }
                    }
                    if (this.state.impactOptionSelected) {
                        if (this.state.monetizableProjectSelectedOption && this.state.monetizableProjectSelectedOption.label === "Yes") {
                            if (this.state.monetizationStatusSelectedOption === null || !this.state.monetizationStatusSelectedOption ||
                                this.state.monetizationStatusSelectedOption === undefined || this.state.monetizationStatusSelectedOption.length === 0
                            ) {
                                isValid = false;
                                Toast.fail("Since this project has been marked as Monetizable, monetizable status has to be selected", 5000, () => { });
                            }
                        }
                        if (this.state.monetizableProjectSelectedOption !== null && this.state.monetizationStatusSelectedOption !== null) {
                            if (this.state.monetizableProjectSelectedOption.label === "Yes")
                                if (this.state.businessImpactValueArray.filter((item) => item.IsMonetized === "Yes").length > 0) { }
                                else {
                                    isValid = false;
                                    Toast.fail("Since this is a monetizable project, there needs to be at least one impact type as ‘Impact at Genpact’ selected and category selected as a ‘Increase in Revenue’ .", 5000, () => { });
                                }
                        }

                    }
                }
            }
        }
        return isValid;
    }



    formatDateStringWithTime(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        });
    }






    handleParentIDChange = (e) => {

        this.setState({ ParentIDAlpha: e }, () => {

        })
    }


    renderServiceLineOptions = () => {
        let options = [];
        const uniqueServiceLineList = [...new Map(this.state.serviceLineList.map(item => [item.ServiceLineName, item])).values()];
        uniqueServiceLineList.map((item) => {
            options.push({ value: item.ServiceLineId, label: item.ServiceLineName });
            return true;
        });
        return options;

    }

    renderSubServiceLineOptions = () => {
        let options = [];
        if (this.state.ServiceLine) {
            let localServiceLine = this.state.ServiceLine.replace(/[|,]/g, ",")
            localServiceLine = localServiceLine.split(",");
            this.state.subServiceLineList && this.state.subServiceLineList.map((item) => {
                localServiceLine.map((local) => {
                    if (local === item.ServiceLineName) {
                        options.push({ value: item.SubServiceLineId, label: item.SubServiceLineName });
                        return true;
                    }
                    if (local === "All") {
                        options.push({ value: item.SubServiceLineId, label: item.SubServiceLineName });
                        return true;
                    }
                })
                return true;
            });
        }
        return options;
    }

    // ... auto fetch functional owner function starts here
    handleFunctionalOhronCut = (event) => {
        if (event.target.value.length === 9) {
            this.handleFunctionalOhrChange()
        } else if (event.target.value.length === 0) {
            this.setState({ FunctionalOHR: "", Functional: "" }, () => { })
            return null
        }
        else {
            Toast.fail("Functional owner OHR should be exactly of 9 digits", 3000, () => { })
            this.setState({ Functional: "" }, () => { })
        }
    }

    handleFunctionalOhrChange = (event) => {
        var numbers = /^[0-9]+$/;
        if (event !== null && event !== undefined) {
            if (event.target.value.match(numbers) && event.target.value.length !== 0) {
                if (event.target.value.length === 9) {
                    let localFunctionalOhr = event.target.value.trim();
                    this.setState({ FunctionalOHR: localFunctionalOhr }, () => { })
                    if (localFunctionalOhr) {
                        this.fetchSubmitFunctionalOHRDetails(localFunctionalOhr)
                    }
                }
                else {
                    this.setState({ FunctionalOHR: "", Functional: "" }, () => { })
                }
            } else {
                this.setState({ FunctionalOHR: "", Functional: "" }, () => { })
            }
        }
    }
    fetchSubmitFunctionalOHRDetails = async (localFunctionalOhr) => {
        Toast.loading("Fetching Functional Owner Full Name Details. Please wait...", () => { });
        try {
            const param = {
                EntityType: "SearchOHR",
                EntityName: localFunctionalOhr
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.accessToken
                },
                body: JSON.stringify(param)
            };
            const response = await fetch(GET_METADATA, requestOptions);
            const data = await response.json();
            console.log(data)
            let requestByName = data.oUser ? data.oUser[0].DisplayName : null
            if (requestByName === "") {
                requestByName = data.PowerAppUsers ? (data.PowerAppUsers.length > 0 ? data.PowerAppUsers[0].pg_username : "") : "";
            }
            if (requestByName === "" || requestByName === null || !requestByName) {
                Toast.fail("We could not find Functional Owner full name details based on the entered OHR. Please check and re-enter.", 5000, () => { });
                this.setState({ Functional: "" }, () => { })
            }
            else {
                setTimeout(() => { }, 2000);
                this.setState({ Functional: requestByName }, () => { })
            }
            Toast.hide();
        } catch (error) {
            Toast.hide();
            Toast.fail('Please enter a valid Functional Owner OHR', 5000, () => {
                console.log(JSON.stringify(error));
            });
            this.setState({ Functional: "", FunctionalOHR: "" }, () => { })
        }
    };
    //.. auto fetch function owner function ends here



    // ... auto fetch technical owner function starts here
    handleTechnicalOhronCut = (event) => {
        if (event.target.value.length === 9) {
            this.handleTechnicalOhrChange()
        } else if (event.target.value.length === 0) {
            this.setState({ TechOHR: "", Tech: "" }, () => { })
            return null
        }
        else {
            Toast.fail("Technical owner OHR should be exactly of 9 digits", 3000, () => { })
            this.setState({ Tech: "" }, () => { })
        }
    }

    handleTechnicalOhrChange = (event) => {
        var numbers = /^[0-9]+$/;
        if (event !== null && event !== undefined) {
            if (event.target.value.match(numbers) && event.target.value.length !== 0) {
                if (event.target.value.length === 9) {
                    let localTechnicalOhr = event.target.value.trim();
                    this.setState({ TechOHR: localTechnicalOhr }, () => { })
                    if (localTechnicalOhr) {
                        this.fetchSubmitTechnicalOHRDetails(localTechnicalOhr)
                    }
                }
                else {
                    this.setState({ TechOHR: "", Tech: "" }, () => { })
                }
            } else {
                this.setState({ TechOHR: "", Tech: "" }, () => { })
            }
        }
    }
    fetchSubmitTechnicalOHRDetails = async (localTechnicalOhr) => {
        Toast.loading("Fetching Technical Owner Full Name Details. Please wait...", () => { });
        try {
            const param = {
                EntityType: "SearchOHR",
                EntityName: localTechnicalOhr
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.accessToken
                },
                body: JSON.stringify(param)
            };
            const response = await fetch(GET_METADATA, requestOptions);
            const data = await response.json();
            console.log(data)
            let requestByName = data.oUser ? data.oUser[0].DisplayName : null
            if (requestByName === "") {
                requestByName = data.PowerAppUsers ? (data.PowerAppUsers.length > 0 ? data.PowerAppUsers[0].pg_username : "") : "";
            }
            if (requestByName === "" || requestByName === null || !requestByName) {
                Toast.fail("We could not find Technical Owner full name details based on the entered OHR. Please check and re-enter.", 5000, () => { });
                this.setState({ Tech: "" }, () => { })
            }
            else {
                setTimeout(() => { }, 2000);
                this.setState({ Tech: requestByName }, () => { })
            }
            Toast.hide();
        } catch (error) {
            Toast.hide();
            Toast.fail('Please enter a valid Functional Owner OHR', 5000, () => {
                console.log(JSON.stringify(error));
            });
            this.setState({ Tech: "", TechOHR: "" }, () => { })
        }
    };
    //.. auto fetch technical owner function ends here



    // ... auto fetch GOL function starts here
    handleGOLOhronCut = (event) => {
        if (event.target.value.length === 9) {
            this.handleGOLOhrChange()
        } else if (event.target.value.length === 0) {
            this.setState({ GOLOHR: "", GOL: "" }, () => { })
            return null
        }
        else {
            Toast.fail("GOL OHR should be exactly of 9 digits", 3000, () => { })
            this.setState({ GOL: "" }, () => { })
        }
    }

    handleGOLOhrChange = (event) => {
        var numbers = /^[0-9]+$/;
        if (event !== null && event !== undefined) {
            if (event.target.value.match(numbers) && event.target.value.length !== 0) {
                if (event.target.value.length === 9) {
                    let localGOLOhr = event.target.value.trim();
                    this.setState({ GOLOHR: localGOLOhr }, () => { })
                    if (localGOLOhr) {
                        this.fetchSubmitGOLOHRDetails(localGOLOhr)
                    }
                }
                else {
                    this.setState({ GOLOHR: "", GOL: "" }, () => { })
                }
            } else {
                this.setState({ GOLOHR: "", GOL: "" }, () => { })
            }
        }
    }
    fetchSubmitGOLOHRDetails = async (localGOLOhr) => {
        Toast.loading("Fetching GOL Owner Full Name Details. Please wait...", () => { });
        try {
            const param = {
                EntityType: "SearchOHR",
                EntityName: localGOLOhr
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.accessToken
                },
                body: JSON.stringify(param)
            };
            const response = await fetch(GET_METADATA, requestOptions);
            const data = await response.json();
            console.log(data)
            let requestByName = data.oUser ? data.oUser[0].DisplayName : null
            if (requestByName === "") {
                requestByName = data.PowerAppUsers ? (data.PowerAppUsers.length > 0 ? data.PowerAppUsers[0].pg_username : "") : "";
            }
            if (requestByName === "" || requestByName === null || !requestByName) {
                Toast.fail("We could not find GOL full name details based on the entered OHR. Please check and re-enter.", 5000, () => { });
                this.setState({ GOL: "" }, () => { })
            }
            else {
                setTimeout(() => { }, 2000);
                this.setState({ GOL: requestByName }, () => { })
            }
            Toast.hide();
        } catch (error) {
            Toast.hide();
            Toast.fail('Please enter a valid GOL Owner OHR', 5000, () => {
                console.log(JSON.stringify(error));
            });
            this.setState({ GOL: "", GOLOHR: "" }, () => { })
        }
    };
    //.. auto fetch GOL function function ends here



    handleEnvironmentChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.SolnEnviron = selectStyle(true);
        this.setState({ SolnEnviron: e, selectStyle: styleStateObj }, () => { })
    }





    // Deployment approval starts here
    handleDeploymentApprovalStatusChange = (e) => {
        if (e.value === 1) {
            this.setState({ OppStage: "Completed", CentTeamApproval: e.label, centTeamApprovalSelectedOption: e })
        }
        if (e.value === 2) {
            this.setState({ OppStage: "Pending Approval from Solution Owner", CentTeamApproval: e.label, centTeamApprovalSelectedOption: e })
        }
        let styleStateObj = this.state.selectStyle;
        styleStateObj.centTeamApprovalSelectedOption = selectStyle(true);
        this.setState({ selectStyle: styleStateObj })

    }

    handleDeploymentApprovalCommentChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 500) {
            styleStateObj.CentTeamComment = textBoxValidation(true);
            this.setState({
                CentTeamComment: e,

                selectStyle: styleStateObj,
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 500);
            this.setState({
                CentTeamComment: trimmedValue,
                selectStyle: styleStateObj,
            });
        }
    }
    // Deployment approval ends here
    renderCohort = () => {
        let options = [];
        this.state.CohortList && this.state.CohortList.map((item, index) => {
            options.push({
                value: index,
                label: item.Cohort
            })
        })
        return options;
    }

    handleCohortChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.CohorSelectedOption = selectStyle(true);
        this.setState({ Cohort: e.label, CohorSelectedOption: e,selectStyle: styleStateObj, }, () => { })
    }
    


    render() {
        //console.log(OPPMASTERSTAGES)

        const today = new Date().toISOString().split("T")[0];
        return (
            <section className="u-clearfix u-section-1 opp_create_page" id="sec-0f7f">
                <div className="u-clearfix u-sheet u-sheet-1" style={{ marginBottom: 20 }}>
                    <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
                        <div className="u-container-layout u-container-layout-1">
                            <div className="">
                                <div className="view_idea_top_header_btn_block">
                                    <div className="">
                                        {/* <button style={{ marginRight: "15px" }} className="u-active-custom-color-6 u-border-2 u-btn u-btn-round u-radius-6 update_parent_btn"
                                            onClick={this.showClusterModal}>Update Parent {this.state.RecID}
                                        </button> */}
                                    </div>
                                    <div className="">
                                        {/* <button style={{ marginRight: "15px" }} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                            onClick={this.raiseFlag}>Raise Flag
                                        </button> */}
                                    </div>
                                    <div className="">
                                        <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                            onClick={(e) => this.handleAuditLog(e)}
                                        //disabled={this.state.auditLogData && this.state.auditLogData.length === 0 ? false : true}
                                        >
                                            Audit Trail
                                        </button>
                                    </div>
                                </div>
                                <div className="text-left mt-4">
                                    {/* {this.state.clusterModal ? (
                                            <>
                                                <div className="cluster_modal_main_block">
                                                    <div className="custom_modal cluster_modal_block">
                                                        <div className="modal_body" style={{ width: "400px" }}>
                                                            <div className="row">
                                                                <div className="col-md-11">
                                                                    <h3 className="modal_title text-center">
                                                                        Update Parent
                                                                    </h3>
                                                                </div>
                                                                <div className="col-md-1">
                                                                    <button
                                                                        type="button"
                                                                        className="close text-right"
                                                                        onClick={(e) =>
                                                                            this.setState({ clusterModal: false })
                                                                        }
                                                                    >
                                                                        &times;
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="clustor_modal_fields_block">
                                                                <div className="clustor_modal_field">
                                                                    <label className="form-attribute">
                                                                        <span style={{ color: "red" }}>*</span> Project
                                                                        Type:
                                                                    </label>
                                                                    {this.state.displayParentAlpha ? (
                                                                        <>
                                                                            <input
                                                                                disabled={true}
                                                                                value={
                                                                                    this.state.projectTypeSelectedOption
                                                                                        ? this.state.projectTypeSelectedOption
                                                                                            .label
                                                                                        : ""
                                                                                }
                                                                                className="form-input"
                                                                                placeholder="Choose project type"
                                                                            />
                                                                        </>
                                                                    ) : (
                                                                        <Select
                                                                            isDisabled={
                                                                                this.state.enableClusterAction
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            isSearchable={false}
                                                                            options={projectType}
                                                                            value={this.state.projectTypeSelectedOption}
                                                                            styles={this.state.selectStyle.projectType}
                                                                            placeholder="Choose project type"
                                                                            onChange={this.changeProjectType}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="clustor_modal_field">
                                                                    <label className="form-attribute">
                                                                        <span style={{ color: "red" }}>*</span> Project
                                                                        ID:
                                                                    </label>

                                                                    {this.state.displayParentAlpha ? (
                                                                        <>
                                                                            <input
                                                                                value={
                                                                                    this.state.projectIDSelectedOption &&
                                                                                        this.state.projectIDSelectedOption.label
                                                                                        ? this.state.projectIDSelectedOption
                                                                                            .label
                                                                                        : ""
                                                                                }
                                                                                disabled={true}
                                                                                className="form-input"
                                                                                placeholder="Choose project ID"
                                                                            />
                                                                        </>
                                                                    ) : (
                                                                        <Select
                                                                            isSearchable
                                                                            onInputChange={
                                                                                this.handleClusterProjectInputChange
                                                                            }
                                                                            options={this.displayProjectID()}
                                                                            value={this.state.projectIDSelectedOption}
                                                                            styles={
                                                                                this.state.selectStyle
                                                                                    .projectIDSelectedOption
                                                                            }
                                                                            isDisabled={
                                                                                this.state.enableClusterAction
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            placeholder="Choose project ID"
                                                                            onChange={this.changeProjectID}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="cluster_modal_btn_group">
                                                                <button
                                                                    className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                                                    disabled={
                                                                        this.state.isClusterSaveDisabled ? true : false
                                                                    }
                                                                    onClick={this.saveParentLink}
                                                                >
                                                                    Save
                                                                </button>
                                                                <button
                                                                    className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6"
                                                                    onClick={this.removeParentFunc}
                                                                    style={{ marginRight: "15px" }}
                                                                    disabled={
                                                                        this.state.enableClusterAction ? false : true
                                                                    }
                                                                >
                                                                    Remove Parent
                                                                </button>
                                                                {this.state.displayParentAlpha ? (
                                                                    <>
                                                                        <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6 show_hierarchy_link_btn">
                                                                            <Link
                                                                                className="show_hierarchy_link_btn_anchor"
                                                                                to={`/showTree?ID=${this.state.ideaItem.IdeaId}`}
                                                                                target="_blank"
                                                                            >
                                                                                Show Hierarchy
                                                                            </Link>
                                                                        </button>
                                                                    </>
                                                                ) : (
                                                                    <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6 show_hierarchy_link_btn">
                                                                        Show Hierarchy
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : null} */}


                                </div>

                                <div className="gen_ai_audit_log">
                                    {
                                        this.state.centralTeamCommentLog && this.state.centralTeamCommentsOnly
                                            ?
                                            <>
                                                <div className="custom_modal">
                                                    <div className="modal_body">
                                                        <div className="col-md-12">
                                                            <div className="">
                                                                <div className="row">
                                                                    <div className="col-md-10">
                                                                        <h3 className="modal_title">
                                                                            Opportunity approval comment log
                                                                        </h3>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <button type="button" className="close text-right" onClick={(e) => this.setState({ centralTeamCommentLog: false, })}>
                                                                            &times;
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="genai_popup_fields">
                                                                <div className="qc_responsive_comment_log">
                                                                    <table className='table-responsive table-bordered' style={{ width: "100%" }}>
                                                                        <thead className="">
                                                                            <tr>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Date & Time</label>
                                                                                </th>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>OHR</label>
                                                                                </th>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Name</label>
                                                                                </th>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Opportunity State</label>
                                                                                </th>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Action</label>
                                                                                </th>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Comment</label>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {this.state.auditLogData && this.state.auditLogData.map((item, index) => {
                                                                                if (item.UpdaterRole === "Opp Solution Owner" || item.UpdaterRole === "Central Team") {
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td className="text-center audit_log_sub_fields_value_block">
                                                                                                <label>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                            </td>
                                                                                            <td className=" text-center audit_log_sub_fields_value_block">
                                                                                                <label>
                                                                                                    {item.UpdaterOHR}
                                                                                                </label>
                                                                                            </td>
                                                                                            <td className=" text-center audit_log_sub_fields_value_block">
                                                                                                <label>
                                                                                                    {item.Updater}
                                                                                                </label>
                                                                                            </td>
                                                                                            <td className=" text-center audit_log_sub_fields_value_block">
                                                                                                <label>
                                                                                                    {item.OppState}
                                                                                                </label>
                                                                                            </td>
                                                                                            <td className=" text-center audit_log_sub_fields_value_block">
                                                                                                <label>
                                                                                                    {item.Action}
                                                                                                </label>
                                                                                            </td>
                                                                                            <td className=" text-center audit_log_sub_fields_value_block">
                                                                                                <label>
                                                                                                    {item.Comment}
                                                                                                </label>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                }

                                                                            }
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }
                                </div>

                                <div className="gen_ai_audit_log">
                                    {
                                        this.state.auditLog
                                            ?
                                            <>
                                                <div className="custom_modal">
                                                    <div className="modal_body">
                                                        <div className="col-md-12">
                                                            <div className="">
                                                                <div className="row">
                                                                    <div className="col-md-10">
                                                                        <h3 className="modal_title">
                                                                            Audit Trail
                                                                        </h3>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <button type="button" className="close text-right" onClick={(e) => this.setState({ auditLog: false, })}>
                                                                            &times;
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="genai_popup_fields">
                                                                <div className="qc_responsive_comment_log">
                                                                    <table className='table-responsive table-bordered' style={{ width: "100%" }}>
                                                                        <thead className="">
                                                                            <tr>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Date & Time</label>
                                                                                </th>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>OHR</label>
                                                                                </th>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Name</label>
                                                                                </th>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Role</label>
                                                                                </th>
                                                                                {/* <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Action</label>
                                                                                </th> 
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Opportunity Stage</label>
                                                                                </th>*/}
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Opportunity State</label>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {this.state.auditLogData && this.state.auditLogData.map((item, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td className="text-center audit_log_sub_fields_value_block">
                                                                                            <label>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                        </td>
                                                                                        <td className=" text-center audit_log_sub_fields_value_block">
                                                                                            <label>
                                                                                                {item.UpdaterOHR}
                                                                                            </label>
                                                                                        </td>
                                                                                        <td className=" text-center audit_log_sub_fields_value_block">
                                                                                            <label>
                                                                                                {item.Updater}
                                                                                            </label>
                                                                                        </td>
                                                                                        <td className=" text-center audit_log_sub_fields_value_block">
                                                                                            <label>
                                                                                                {item.UpdaterRole}
                                                                                            </label>
                                                                                        </td>
                                                                                        {/* <td className=" text-center audit_log_sub_fields_value_block">
                                                                                            <label>
                                                                                                {item.Action}
                                                                                            </label>
                                                                                        </td>
                                                                                        <td className="text-center audit_log_sub_fields_value_block">
                                                                                            <label>
                                                                                                {item.OppStage}
                                                                                            </label>
                                                                                        </td> */}
                                                                                        <td className=" text-center audit_log_sub_fields_value_block">
                                                                                            <label>
                                                                                                {item.OppState}
                                                                                            </label>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }

                                </div>

                            </div>
                            <h4 style={{ marginTop: 45, marginBottom: -35, textAlign: 'center', color: '#00AECF', fontFamily: 'Arial', fontSize: 22 }}>Central team review opportunity: {this.state.RecIDAlpha}</h4>
                            <div className="u-align-left u-container-style u-expanded-width u-group u-shape-rectangle u-group-2">
                                <div className="u-container-layout u-container-layout-2">
                                    <div className="main_fields_block">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute"> Submitter OHR</label>
                                                    <input tabIndex={1} className="form-input" type="text" value={this.state.CreatedBy} disabled />
                                                </div>
                                                <div className="genai_fields">
                                                    <label className="form-attribute"> Submitter Name</label>
                                                    <input tabIndex={2} className="form-input" type="text" value={this.state.CreatedName} disabled />
                                                </div>
                                                {/* <div className="genai_fields">
                                                    <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> Parent Opportunity Id</label>
                                                    <input tabIndex={3} className="form-input form-attribute" type="text" value={this.state.ParentIDAlpha} onChange={(e)=>this.handleParentIDChange(e.target.value)} />
                                                </div> */}
                                                <div className="genai_fields">
                                                    <label className="form-attribute" > Opportunity Name</label>
                                                    <input tabIndex={4} className="form-input" type="text" disabled value={this.state.OppName} />
                                                </div>
                                                {
                                                    this.state.ModifiedDate
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" > Last Modified on</label>
                                                                <input tabIndex={4} className="form-input" type="text" disabled value={this.state.ModifiedDate} />
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute"> Account</label>
                                                    <input disabled placeholder="Account" value={this.state.Account} className="form-input" />
                                                </div>
                                                <div className="genai_fields">
                                                    <label className="form-attribute" > Vertical</label>
                                                    <input tabIndex={8} className="form-input" type="text" disabled value={this.state.Vertical} placeholder="Vertical" />
                                                </div>
                                                <div className="genai_fields">
                                                    <label className="form-attribute"> Owning Units </label>
                                                    <input tabIndex={6} className="form-input" type="text" disabled value={this.state.Unit} placeholder="Unit" />
                                                </div>
                                                {
                                                    this.state.ModifiedBy
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" > Last Modified by OHR</label>
                                                                <input tabIndex={4} className="form-input" type="text" disabled value={this.state.ModifiedBy} />
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" > Managing Entity</label>
                                                    <input tabIndex={7} placeholder="Managing Entity" value={this.state.MangingDept ? this.state.MangingDept : "NA"} className="form-input" disabled />
                                                </div>

                                                <div className="genai_fields">
                                                    <label className="form-attribute"> Segments</label>
                                                    <input tabIndex={9} className="form-input" type="text" disabled value={this.state.Segment} placeholder="Segment" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute"> Archetype</label>
                                                    <input tabIndex={10} className="form-input" type="text" disabled value={this.state.Archetype} placeholder="Archetype" />
                                                </div>
                                                <div className="genai_fields">
                                                    <label className="form-attribute" > FTE Count (inclusive of all regions)</label>
                                                    <input placeholder="Number of FTEs" disabled value={this.state.AccFTE} className="form-input" />
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >Unit SPOC</label>
                                                        <input className="form-input" placeholder="Unit SPOC" type="text" disabled value={this.state.oppDetails.UnitOHRName ? this.state.oppDetails.UnitOHRName : ""}  readOnly />

                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >Black Belt</label>
                                                        <input className="form-input" placeholder="Black Belt" type="text" disabled value={this.state.oppDetails.BB ? this.state.oppDetails.BB : ""}  readOnly />

                                                    </div>
                                                </div>


                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >Opp SPOC - 1</label>
                                                        <input className="form-input" placeholder="Opp SPOC 1" type="text" disabled value={this.state.oppDetails.Spoc_1 ? this.state.oppDetails.Spoc_1 : ""}  readOnly />
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >Master Black Belt</label>
                                                        <input className="form-input" placeholder="MBB" type="text" disabled value={this.state.oppDetails.MBB ? this.state.oppDetails.MBB : ""}  readOnly />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute">Opp SPOC - 2</label>
                                                        <input className="form-input" placeholder="Opp SPOC 2" type="text" disabled value={this.state.oppDetails.Spoc_2 ? this.state.oppDetails.Spoc_2 : ""}  readOnly />
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >GOL OHR</label>
                                                        <input className="form-input" type="text" placeholder="GOL OHR" disabled value={this.state.oppDetails.GOLOHR ? this.state.oppDetails.GOLOHR : ""} readOnly  />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute">Opp Solution Owner</label>
                                                        <input className="form-input" placeholder="Solution Owner" type="text" disabled value={this.state.oppDetails.SolnOwner ? this.state.oppDetails.SolnOwner : ""} readOnly />
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute">GOL</label>
                                                        <input className="form-input" type="text" disabled placeholder="GOL Name" value={this.state.oppDetails.GOL ? this.state.oppDetails.GOL : ""} readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" >Opportunity Stage</label>
                                                    <input disabled value={this.state.oppDetails.OppStage} className="form-input" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >
                                                            Opportunity State</label>
                                                        <input disabled value={this.state.OppState} className="form-input" />
                                                    </div>
                                                </div>


                                            </div>
                                            <div className="col-md-4">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" ><label className="mandatery_asterisk">* </label> Cohort</label>
                                                    <Select
                                                        placeholder="Cohort Value"
                                                        value={this.state.CohorSelectedOption}
                                                        onChange={(e) => this.handleCohortChange(e)}
                                                        options={this.renderCohort()}
                                                        styles={this.state.selectStyle.CohorSelectedOption}
                                                        isDisabled={ this.state.OppState && this.state.OppState === "Completed" ? true : false}
                                                        classNamePrefix={ this.state.OppState && this.state.OppState === "Completed" ? "an-simple-select" : ""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                            </div>
                                        </div>
                                    </div>



                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="">
                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >
                                                                        Service Line </label>
                                                                    <div>
                                                                        <textarea
                                                                            type="text" placeholder='Service line value'
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey" }}
                                                                            disabled value={this.state.ServiceLine} className="form-input textarea_width" />

                                                                    </div>



                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </div>

                                            </div>

                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" >Predominant Service Line</label>
                                                    <input
                                                        placeholder="Predominant service line"
                                                        value={this.state.oppDetails.ServiceLineMain}
                                                        disabled
                                                        className="form-input"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >
                                                                        Area / Process</label>

                                                                    <div>
                                                                        <textarea
                                                                            type="text" placeholder='Area / Process value'
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey" }}
                                                                            disabled value={this.state.Process_L2} className="form-input textarea_width" />

                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>

                                            </div>
                                            <div className="col-md-3"></div>
                                        </div>
                                    </div>


                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" >
                                                                    GenAI Offering L1</label>
                                                                <div className="">
                                                                    <input disabled value={this.state.oppDetails.Offering_1} className="form-input" />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="col-md-3">

                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" >
                                                                    GenAI Offering L2</label>
                                                                <div className="">
                                                                    {
                                                                        this.state.oppDetails.Offering_1 && this.state.oppDetails.Offering_1 === "Bespoke"
                                                                            ?
                                                                            <>
                                                                                <input disabled value={this.state.oppDetails.Offering_2_Other} className="form-input" readOnly />
                                                                            </>
                                                                            :
                                                                            <input disabled value={this.state.oppDetails.Offering_2} className="form-input" readOnly />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >
                                                                        Is a hero Product?</label>

                                                                    <input disabled value={this.state.oppDetails.IsHero} className="form-input" placeholder='Is this a hero product ?' />
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>

                                            </div>
                                            <div className="col-md-3"></div>
                                        </div>
                                    </div>



                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="">
                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >Client Background
                                                                        <span className="field_max_characters">(max. 2000 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea
                                                                            className={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6) ? "form-input textarea_width" : "form-input form-attribute textarea_width"}
                                                                            type="text" placeholder='Client Background'
                                                                            onChange={(e) => this.clientBGChange(e.target.value)} value={this.state.ClientDtls}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.ClientDtls }}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>
                                                <div className="">
                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >GenAI Solution
                                                                        <span className="field_max_characters">(max. 2000 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea
                                                                            className={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6) ? "form-input textarea_width" : "form-input form-attribute textarea_width"}
                                                                            type="text"
                                                                            placeholder='GenAI Solution' onChange={(e) => this.genAISolutionChange(e.target.value)} value={this.state.Solution}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Solution }}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>

                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">
                                                <div className="">
                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >

                                                                        Client Problem
                                                                        <span className="field_max_characters">(max. 2000 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea placeholder='Client Problem'
                                                                            className={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6) ? "form-input textarea_width" : "form-input form-attribute textarea_width"}
                                                                            type="text" onChange={(e) => this.clientProblemChange(e.target.value)} value={this.state.Problem}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Problem }}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-3">
                                            {
                                                this.state.OppStageSelectedOption
                                                    ?
                                                    <>
                                                        <div className="">
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" >
                                                                    Overall number of FTEs in scope</label>
                                                                <input className="form-input form-attribute hide_numeric_input_arrows" type="number" maxLength={5000}
                                                                    onChange={(e) => this.overAllFTEinScopeChange(e.target.value)} value={this.state.OverallFTE}
                                                                    placeholder="Max limit of 5000 FTEs"
                                                                    style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.OverallFTE }}
                                                                    onKeyDown={(evt) =>
                                                                        (evt.key === "e" ||
                                                                            evt.key === "E" ||
                                                                            evt.key === "+" ||
                                                                            evt.key === "-") &&
                                                                        evt.preventDefault()
                                                                    }
                                                                    onWheel={(e) => e.target.blur()}
                                                                    disabled={true}

                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }

                                        </div>
                                        <div className="col-md-3">
                                            <div className="">
                                                {
                                                    this.state.OppStageSelectedOption && (this.state.Offering_1 && this.state.Offering_1 === "CKA")
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" >
                                                                    Overall number of SOPs in scope</label>
                                                                <input className="form-input form-attribute hide_numeric_input_arrows" type="number" maxLength={1000}
                                                                    placeholder='Max limit of 1000 SOPs'
                                                                    onChange={(e) => this.overAllNumberofSOPChange(e.target.value)} value={this.state.OverallSOP}
                                                                    style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.OverallSOP }}
                                                                    onKeyDown={(evt) =>
                                                                        (evt.key === "e" ||
                                                                            evt.key === "E" ||
                                                                            evt.key === "+" ||
                                                                            evt.key === "-") &&
                                                                        evt.preventDefault()
                                                                    }
                                                                    onWheel={(e) => e.target.blur()}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                        </div>
                                        <div className="col-md-3"></div>
                                        <div className="col-md-3"></div>
                                    </div>



                                    <div className="row">
                                        <div className="col-md-5">
                                            {
                                                this.state.OppStageSelectedOption
                                                    ?
                                                    <>
                                                        <div className="genai_fields">
                                                            <label className="form-attribute">Help Needed from Leadership
                                                                <span className="field_max_characters">(max. 300 chars)</span>
                                                            </label>
                                                            <div className="">
                                                                <textarea placeholder="Help Needed from Leadership" className="form-input form-attribute textarea_width"
                                                                    type="text" onChange={(e) => this.needHelpChange(e.target.value)} value={this.state.LeadHelp}
                                                                    disabled={true}

                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }

                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-5">
                                            <div className="">
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute">CXO Report - Brief Status
                                                                    <span className="field_max_characters">(max. 300 chars)</span>
                                                                </label>
                                                                <div className="">
                                                                    <textarea placeholder="Provide value" className="form-input form-attribute textarea_width" type="text"
                                                                        onChange={(e) => this.CXOReportChange(e.target.value)} value={this.state.CXO}
                                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.CXO }}
                                                                        disabled={true}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                        </div>
                                    </div>


                                    {
                                        this.state.OppStageSelectedOption
                                            ?
                                            <>
                                                <div className="gapping_class">
                                                    <div className="">
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="">
                                                                    <div className="genai_fields">
                                                                        <label htmlFor="futureDate" className="form-attribute" >Next Planned Discussion Date</label>
                                                                        <input className="form-input form-attribute" type="date"
                                                                            value={this.state.PlanDiscussDate} min={today} id="futureDate"
                                                                            disabled={true}
                                                                            style={{ color: "#122539" }}
                                                                        //placeholder='dd-mm-yyyy'
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="">
                                                                    <div className="genai_fields">
                                                                        <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>Date by when discussion outcome will be known ?</label>
                                                                        <input className="form-input form-attribute" type="date"
                                                                            style={{ color: "#122539" }}
                                                                            disabled={true}
                                                                            value={this.state.DiscussEstDate}
                                                                        //placeholder='dd-mm-yyyy'
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }


                                    {
                                        this.state.OppStageSelectedOption
                                            ?
                                            <>
                                                <div className="gapping_class">
                                                    <div className="">
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="">
                                                                    <div className="">
                                                                        <div className="genai_fields">
                                                                            <label className="form-attribute" >Proposal Shared?</label>
                                                                            <div className="">
                                                                                <input disabled value={this.state.oppDetails.IsProposalShared} className="form-input" placeholder='Proposal shared ?' />
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                {/* <div className="genai_fields">
                                                                    <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> Proposal File <span className="file_max_size">Max size: 5MB</span></label>
                                                                    <div className="file_upload_plus_mark_block">
                                                                    
                                                                        <label htmlFor="proposed-file" className="file_upload_plus_mark">&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                                        <input id="proposed-file" 
                                                                            type="file" style={{ display: 'none' }} 
                                                                            onChange={(event) => this.uploadFile(event, "file1")} 
                                                                            accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" 
                                                                        />
                                                                        <button className="file_name">File name here</button>
                                                                    </div>
                                                                    <div className="">
                                                                        <label className="file_upload_instruction">[Note] Supported File Types : .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .ppt, .pptx, .pdf, .jpeg, .jpg, .png, .msg, .png, .mpp.</label>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                            <div className="col-md-3">

                                                            </div>
                                                            <div className="col-md-3">

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }

                                    {
                                        this.state.OppStageSelectedOption
                                            ?
                                            <>
                                                <div className="gapping_class">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >Environment</label>
                                                                    <div className="">
                                                                        <input disabled value={this.state.oppDetails.SolnEnviron} className="form-input" />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            {
                                                                this.state.OppStageSelectedOption
                                                                    ?
                                                                    <>
                                                                        <div className="genai_fields">
                                                                            <label className="form-attribute" >Funding</label>
                                                                            <input disabled value={this.state.oppDetails.SolnFund} className="form-input" />
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                            <div className="">


                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            {
                                                                this.state.SolnFund && (this.state.SolnFund.value === 1 || this.state.SolnFund.value === 3)
                                                                    ?
                                                                    <>
                                                                        <div className="">
                                                                            <div className="genai_fields">
                                                                                <label className="form-attribute" >Client Funding (USD) </label>
                                                                                <input className="form-input form-attribute hide_numeric_input_arrows" type="number" value={this.state.ClientDollar}
                                                                                    placeholder='Enter $ value'
                                                                                    onChange={(e) => this.clientFundingChange(e.target.value)}
                                                                                    style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.ClientDollar }}
                                                                                    onKeyDown={(evt) =>
                                                                                        (evt.key === "e" ||
                                                                                            evt.key === "E" ||
                                                                                            evt.key === "+" ||
                                                                                            evt.key === "-") &&
                                                                                        evt.preventDefault()
                                                                                    }
                                                                                    onWheel={(e) => e.target.blur()}
                                                                                    disabled={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }

                                                        </div>
                                                        <div className="col-md-3">
                                                            {
                                                                this.state.SolnFund && (this.state.SolnFund.value === 2 || this.state.SolnFund.value === 3)
                                                                    ?
                                                                    <>
                                                                        <div className="genai_fields">
                                                                            <label className="form-attribute" >Cost to Genpact for Build (USD)</label>
                                                                            <input className="form-input form-attribute hide_numeric_input_arrows" type="number"
                                                                                placeholder='Enter $ value'
                                                                                value={this.state.GenpactDollar} onChange={(e) => this.costToGenpactBuildChange(e.target.value)}
                                                                                style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.GenpactDollar }}
                                                                                onKeyDown={(evt) =>
                                                                                    (evt.key === "e" ||
                                                                                        evt.key === "E" ||
                                                                                        evt.key === "+" ||
                                                                                        evt.key === "-") &&
                                                                                    evt.preventDefault()
                                                                                }
                                                                                onWheel={(e) => e.target.blur()}
                                                                                disabled={true}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }

                                                        </div>


                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }




                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>Is a SOW in scope for this piece of work?</label>
                                                                <input disabled value={this.state.oppDetails.IsSOWScope} className="form-input" />
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="col-md-3">
                                                {
                                                    this.state.IsSOWScope && this.state.IsSOWScope.label === "Yes"
                                                        ?
                                                        <>
                                                            {
                                                                this.state.OppStageSelectedOption
                                                                    ?
                                                                    <>
                                                                        <div className="">
                                                                            <div className="genai_fields">
                                                                                <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>SOW signed & agreed with the client?</label>
                                                                                <input disabled value={this.state.oppDetails.IsSOWSigned} className="form-input" />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </>
                                                        :
                                                        null
                                                }


                                            </div>
                                            <div className="col-md-3">
                                                {
                                                    this.state.IsSOWScope && this.state.IsSOWScope.label === "Yes"
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" >Expected SOW submission Date / SOW submission Date</label>
                                                                <input className="form-input form-attribute" type="date" value={this.state.SOWSubDate}
                                                                    style={{ color: "#122539" }}
                                                                    disabled={true}
                                                                //placeholder='dd-mm-yyyy'
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }
                                                <div className="">
                                                    {/* <div className="genai_fields">
                                                    <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> Upload a copy of the SOW <span className="file_max_size">Max size: 5MB</span></label>
                                                    <div className="file_upload_plus_mark_block">
                                                        <label htmlFor="SOW-COPY" className="file_upload_plus_mark">&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                        <input id="SOW-COPY" 
                                                            type="file" style={{ display: 'none' }} 
                                                            onChange={(event) => this.uploadFile(event, "file1")} 
                                                            accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" 
                                                        />
                                                        <button className="file_name">File name here</button>
                                                    </div>
                                                    <div className="">
                                                        <label className="file_upload_instruction">[Note] Supported File Types : .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .ppt, .pptx, .pdf, .jpeg, .jpg, .png, .msg, .png, .mpp.</label>
                                                    </div>
                                                    
                                                </div> */}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                {
                                                    this.state.IsSOWScope && this.state.IsSOWScope.label === "Yes"
                                                        ?
                                                        <>
                                                            <div className="">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >Expected SOW Closure Date/ SOW closure Date</label>
                                                                    <input className="form-input form-attribute" type="date"
                                                                        value={this.state.SOWCloseDate}
                                                                        style={{ color: "#122539" }}
                                                                        disabled={true}
                                                                    //placeholder='dd-mm-yyyy'
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>Expected Deployment Date/ Go-Live Date</label>
                                                                    <input className="form-input form-attribute" type="date" value={this.state.CompletionDate}
                                                                        style={{ color: "#122539" }}
                                                                        disabled={true}
                                                                    //placeholder='dd-mm-yyyy'
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }
                                                {/* <div className="genai_fields">
                                                <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> SOW File</label>
                                                <div className="">
                                                    <button className="file_name">SOW File</button>
                                                </div>
                                            </div> */}
                                            </div>
                                        </div>
                                    </div>




                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >Functional Owner OHR</label>
                                                                    <input pattern="\d*" maxLength="9"
                                                                        placeholder='Functional owner OHR'
                                                                        defaultValue={this.state.FunctionalOHR}
                                                                        onChange={(e) => this.handleFunctionalOhrChange(e)}
                                                                        onCut={(e) => this.handleFunctionalOhronCut(e)}
                                                                        className="form-input form-attribute hide_numeric_arrows" type="number"
                                                                        onKeyDown={(evt) => (evt.key === '.' || evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault()}
                                                                        onWheel={(e) => e.target.blur()}
                                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.FunctionalOHR }}
                                                                        disabled={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="col-md-3">
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >Functional Owner</label>
                                                                    <input className="form-input" placeholder='Functional owner' type="text" disabled value={this.state.Functional} style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Functional }} />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="col-md-3">
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" >Technical Owner OHR</label>
                                                                <input pattern="\d*" maxLength="9"
                                                                    placeholder='Technical owner OHR'
                                                                    defaultValue={this.state.TechOHR}
                                                                    onChange={(e) => this.handleTechnicalOhrChange(e)}
                                                                    onCut={(e) => this.handleTechnicalOhronCut(e)}
                                                                    className="form-input form-attribute hide_numeric_arrows" type="number"
                                                                    onKeyDown={(evt) => (evt.key === '.' || evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault()}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.TechOHR }}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="col-md-3">
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >Technical Owner</label>
                                                                    <input className="form-input" placeholder='Technical owner' type="text" disabled value={this.state.Tech} style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Tech }} />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>

                                        </div>
                                    </div>




                                    <div className="row">
                                        <div className="col-md-3">
                                            {
                                                this.state.OppStageSelectedOption
                                                    ?
                                                    <>
                                                        <div className="genai_fields">
                                                            <label className="form-attribute" >
                                                                Discussion Led By</label>

                                                            <input disabled value={this.state.oppDetails.TransitionLed ? this.state.oppDetails.TransitionLed : "LDT led"} className="form-input" placeholder='Discussion Led By' />
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }

                                        </div>
                                    </div>


                                    <div className="">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="">
                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >Domain Knowledge
                                                                        <span className="field_max_characters">(max. 300 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea className="form-input form-attribute textarea_width" placeholder="Domain Knowledge"
                                                                            type="text" onChange={(e) => this.domainKnowledgeChange(e.target.value)} value={this.state.Knowledge}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Knowledge }}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }


                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >Data used
                                                                        <span className="field_max_characters">(max. 300 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea className="form-input form-attribute textarea_width"
                                                                            placeholder="Data used" type="text"
                                                                            onChange={(e) => this.dataUsedChange(e.target.value)}
                                                                            value={this.state.Data}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Data }}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >Algorithms Used
                                                                        <span className="field_max_characters">(max. 300 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea className="form-input form-attribute textarea_width"
                                                                            placeholder="Algorithms Used" type="text"
                                                                            onChange={(e) => this.algorithmChanged(e.target.value)}
                                                                            value={this.state.Algo}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Algo }}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }


                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >Tech Infrastructure used<span className="field_max_characters"> (max. 300 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea className="form-input form-attribute textarea_width" placeholder="Tech Infrastructure used"
                                                                            type="text" onChange={(e) => this.techInfraUsedChanged(e.target.value)} value={this.state.Infra}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Infra }}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }


                                            </div>
                                        </div>
                                    </div>


                                    <div className="">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="">
                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >Share holder value created
                                                                        <span className="field_max_characters">(max. 300 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea className="form-input form-attribute textarea_width" placeholder="Share holder value created" type="text"
                                                                            value={this.state.ValueCreated} onChange={(e) => this.shareHolderValueCreated(e.target.value)}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.ValueCreated }}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">

                                            </div>
                                        </div>
                                    </div>



                                    <div className="row">

                                        <div className="col-md-3">
                                            <div className="genai_fields">
                                                <label className="form-attribute" >&nbsp;SFDC OLI Number</label>
                                                <div className="">
                                                    <span><label>OLI-</label></span>
                                                    <input 
                                                        className="form-input hide_numeric_input_arrows"
                                                        value={this.state.oppDetails.OLINum}
                                                        type="number"
                                                        disabled
                                                        placeholder='OLI-XXXXXXX number'
                                                    />
                                                </div>



                                            </div>


                                        </div>

                                        <div className="col-md-3">
                                            <div className="genai_fields">
                                                <label className="form-attribute" >&nbsp;SFDC OPP ID</label>
                                                <div className="">
                                                    <span><label>Opp-</label></span>
                                                    <input
                                                        className="form-input hide_numeric_input_arrows"
                                                        value={this.state.oppDetails.OppID}
                                                        type="number"
                                                        disabled
                                                        placeholder='Opp-XXXXXXX number'
                                                    />
                                                </div>
                                            </div>


                                        </div>
                                        <div className="col-md-3">
                                            {
                                                this.state.OppStageSelectedOption
                                                    ?
                                                    <>
                                                        <div className="genai_fields">
                                                            <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>Number of FTEs currently using this solution</label>
                                                            <input 
                                                                className="form-input form-attribute hide_numeric_input_arrows" 
                                                                value={this.state.FTEUsage} type="number"
                                                                onChange={(e) => this.noOfFTEUsageChange(e.target.value)}
                                                                placeholder='Provide FTE count'
                                                                style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.FTEUsage }}
                                                                onKeyDown={(evt) =>
                                                                    (evt.key === "e" ||
                                                                        evt.key === "E" ||
                                                                        evt.key === "+" ||
                                                                        evt.key === "-") &&
                                                                    evt.preventDefault()
                                                                }
                                                                onWheel={(e) => e.target.blur()}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }

                                        </div>


                                    </div>


                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute">
                                                        &nbsp;Total People in the process
                                                    </label>
                                                    <input
                                                        value={this.state.oppDetails.ProcessUsers}
                                                        type="number"
                                                        placeholder='Total People in the process'
                                                        className="form-input hide_numeric_input_arrows"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;# of people with access to the solution
                                                    </label>
                                                    <input
                                                        value={this.state.oppDetails.AccessUsers}
                                                        type="number"
                                                        placeholder='# of people with access to the solution'
                                                        className="form-input hide_numeric_input_arrows"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;People using it daily
                                                    </label>
                                                    <input
                                                        value={this.state.oppDetails.DailyUsers}
                                                        type="number"
                                                        placeholder='People using it daily'
                                                        className="form-input hide_numeric_input_arrows"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;People using it weekly
                                                    </label>
                                                    <input
                                                        value={this.state.oppDetails.WeeklyUsers}
                                                        type="number"
                                                        placeholder='People using it weekly'
                                                        className="form-input hide_numeric_input_arrows"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;Total Weekly transactions
                                                    </label>
                                                    <input
                                                        value={this.state.oppDetails.WeeklyTrans}
                                                        type="number"
                                                        className="form-input hide_numeric_input_arrows"
                                                        placeholder="Total weekly transcations"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" >
                                                        &nbsp;Transactions processed through GenAI solution
                                                    </label>
                                                    <input
                                                        value={this.state.oppDetails.SolnTrans}
                                                        type="number"
                                                        placeholder="transaction processed through GenAI Solution"
                                                        className="form-input hide_numeric_input_arrows"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;Number of upvotes
                                                    </label>
                                                    <input
                                                        value={this.state.oppDetails.Upvotes}
                                                        type="number"
                                                        placeholder='Number of upvotes'
                                                        className="form-input hide_numeric_input_arrows"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;% of upvotes
                                                    </label>
                                                    <input
                                                        value={this.state.oppDetails.UpvotesPercent}
                                                        type="number"
                                                        className="form-input hide_numeric_input_arrows"
                                                        placeholder="% of upvotes"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;Accuracy of outcome
                                                    </label>
                                                    <input
                                                        value={this.state.oppDetails.Accuracy}
                                                        type="number"
                                                        placeholder='Accuracy of outcome'
                                                        disabled
                                                        className="form-input hide_numeric_input_arrows"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;Uptime of the solution
                                                    </label>
                                                    <input
                                                        value={this.state.oppDetails.UptimePercent}
                                                        type="number"
                                                        placeholder='Uptime of the solution'
                                                        disabled
                                                        className="form-input hide_numeric_input_arrows"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* Reason for stage change starts here  */}
                                    <div className="">
                                        <div className="row">
                                            <div className="col-md-6">                                            
                                                {
                                                    this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8 || this.state.OppStageSelectedOption.value === 9)
                                                    ?
                                                    <>
                                                        <div className="genai_fields">
                                                            <label className="form-attribute" >Reason for stage change<span className="field_max_characters">(max. 180 chars)</span>
                                                            </label>
                                                            <div className="">
                                                                <textarea
                                                                    disabled
                                                                    className="form-input textarea_width"
                                                                    type="text"
                                                                    placeholder='Please specify reason for the stage change.'
                                                                    value={this.state.oppDetails.OppStageChange}
                                                                    
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }
                                            </div>
                                        </div>
                                    </div>
                                    {/* Reason for stage change ends here */}


                                    <div className="row">
                                        <div className="col-md-3">
                                            {/* <div className="genai_fields">
                                                <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> Go-Live Upload <span className="file_max_size">Max size: 5MB</span></label>
                                                <div className="file_upload_plus_mark_block">
                                                    <label htmlFor="GO-LIVE" className="file_upload_plus_mark">&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                    <input id="GO-LIVE" 
                                                        type="file" style={{ display: 'none' }} 
                                                        onChange={(event) => this.uploadFile(event, "file1")} 
                                                        accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" 
                                                    />
                                                    <button className="file_name">File name here</button>
                                                </div>
                                                <div className="">
                                                        <label className="file_upload_instruction">[Note] Supported File Types : .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .ppt, .pptx, .pdf, .jpeg, .jpg, .png, .msg, .png, .mpp.</label>
                                                    </div>
                                            </div> */}
                                        </div>
                                        <div className="col-md-3">
                                            {/* <div className="genai_fields">
                                                <label className="form-attribute" ><label className="mandatery_asterisk">*</label> Go-Live email file name</label>
                                                <button className="file_name" > Go-Live email file name</button>
                                            </div> */}
                                        </div>
                                        <div className="col-md-3">
                                            {/* <div className="genai_fields">
                                                <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> GOL/FP&A/Client Approval <span className="file_max_size">Max size: 5MB</span></label>
                                                <div className="file_upload_plus_mark_block">
                                                    <label htmlFor="GOL/FP&A/Client-Approval" className="file_upload_plus_mark">&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                    <input id="GOL/FP&A/Client-Approval" 
                                                        type="file" style={{ display: 'none' }} 
                                                        onChange={(event) => this.uploadFile(event, "file1")} 
                                                        accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" 
                                                    />
                                                    <button className="file_name">File name here</button>
                                                </div>
                                                <div className="">
                                                        <label className="file_upload_instruction">[Note] Supported File Types : .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .ppt, .pptx, .pdf, .jpeg, .jpg, .png, .msg, .png, .mpp.</label>
                                                    </div>
                                                
                                            </div> */}
                                        </div>
                                        <div className="col-md-3">

                                            {/* <div className="genai_fields">
                                                <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> GOL/FP&A/Client Approval</label>
                                                <button className="file_name" >GOL/FP&A/Client Approval- filename</button>
                                            </div> */}
                                        </div>
                                    </div>

                                    {
                                        this.state.oppDetails.VDType
                                            ?
                                            <>

                                                <div className="">
                                                    <GenAIFPNAGOL
                                                        IdeaId={this.state.RecID}
                                                        viewIdea={true}
                                                        vdSelected={this.state.impactOptionSelected ? this.state.impactOptionSelected.label : this.state.oppDetails.VDType}
                                                        fetchPPTDeck={this.pullPPTDeck}
                                                        fetchClientDocName={this.pullClientDocName}
                                                        fetchClientDocType={this.pullClientDocType}
                                                        fetchClientDocContent={this.pullClientDocContent}
                                                        fetchGolDocName={this.pullgolFileName}
                                                        fetchGolDocType={this.pullgolFileType}
                                                        fetchGolDocContent={this.pullgolContent}
                                                        fetchfpnaDocName={this.pullfpnafileName}
                                                        fetchfpnaDocType={this.pullfpnafileType}
                                                        fetchfpnaDocContent={this.pullfpnafileContent}

                                                        fetchClientName={this.pullCustName}
                                                        fetchClientRole={this.pullClientDesign}
                                                        fetchClientSignOffDate={this.pullCustSignOffDate}

                                                        fetchGolOhr={this.pullGOLOHR}
                                                        fetchGolName={this.pullGolName}

                                                        fetchFpnaOhr={this.pullFpnaOhr}
                                                        fetchFpnaName={this.pullFpnaName}

                                                        fetchFileDetails={this.pull_fileDetails}
                                                        sendBitoFPNA={this.state.businessImpactValueArray}
                                                        sendIsFpnaRequired={this.state.isFpnaRequired}
                                                        showMandatory={this.state.OppStageSelectedOption && this.state.OppStageSelectedOption.value === 6 ? true : false}
                                                    />
                                                </div>


                                                <div className="gapping_class">
                                                    <GenAIValueDelivery
                                                        func={this.pull_data}
                                                        viewIdea={true}
                                                        vdType={this.handleVDTypeChange}
                                                        IdeaId={this.state.RecID}
                                                        fetchMonetizationFileDetails={this.pull_file_data}
                                                        fetchMonetizationFpnaOhr={this.pull_monetization_fpna_ohr}
                                                        fetchMonetizationFpnaName={this.pull_monetization_fpna_name}
                                                        fetchMonetizationSowID={this.pull_monetization_sowId}
                                                        bbMonetizationTotal={this.pull_monetizedTotal}
                                                        monetizableProject={this.pull_monetizableProject}
                                                        monetizableStatus={this.pull_monetizableStatus}
                                                        fetchFPAOHRforIncreaseInRevenue={this.fetchFPAOHRforIncreaseInRevenue}
                                                        bbMonetizationApproval={false}
                                                        postFpnaValid={true}
                                                        fetchDeletedBIRows={this.remove_bi_data_after_delete}
                                                        fetchMonetizationGOLName={this.pullMonetizationGOLName}
                                                        fetchMonetizationGOLOhr={this.pullMonetizationGOLOhr}
                                                        fetchCategoryNotListed={this.pullCategoryNotListed}
                                                        showMandatory={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? true : false}
                                                    />
                                                </div>
                                            </>
                                            :
                                            null
                                    }




                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10, marginTop: "0px" }}>Central Team Deployment Approval</p>
                                                <div className="">
                                                    <div className="">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" ><label className="mandatery_asterisk">*</label> Approval Status</label>
                                                                    <Select options={OPPAPPROVALOPTIONS} placeholder="Select approval status" onChange={(e) => this.handleDeploymentApprovalStatusChange(e)} value={this.state.centTeamApprovalSelectedOption} styles={this.state.selectStyle.centTeamApprovalSelectedOption} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <div className="" style={{ marginBottom: "15px", display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                                                            <div className="">
                                                                <label className="form-attribute" ><label className="mandatery_asterisk">*</label> Approval Comments
                                                                    <span style={{ fontWeight: "normal", fontSize: 12, fontStyle: "italic", color: "grey", }}>&nbsp;(max. 500 chars)</span>
                                                                </label>
                                                            </div>
                                                            <div className="" >
                                                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e) => this.setState({ centralTeamCommentLog: true })} disabled={this.state.centralTeamCommentsOnly ? false : true}>Comment Log</button>
                                                            </div>
                                                        </div>
                                                        <textarea
                                                            className="textarea-css form-attribute form-attribute"
                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.CentTeamComment }}
                                                            rows={3}
                                                            cols={88}
                                                            value={this.state.CentTeamComment}
                                                            placeholder="Please provide your comments in support of the deployment approval descision."
                                                            onChange={(e) => this.handleDeploymentApprovalCommentChange(e.target.value)}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>





                                    <div className="create_opp_btn_group">
                                        <div className="btn-group">
                                            {
                                                this.state.OppState && this.state.OppState === "Completed"
                                                    ?
                                                    <>
                                                        <button tabIndex={20} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={this.viewDone}>Done</button>
                                                    </>
                                                    :
                                                    <button tabIndex={20} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={this.updateOpp}>Update</button>
                                            }
                                            
                                            <button tabIndex={21} className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={this.cancelOpp}>Cancel</button>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        )
    }



}

export default GenAICentralTeamApproval

