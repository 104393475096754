import React, { Component } from "react";
import {
  GET_IDEA_URL,
  REVIEW_IDEA_URL,
  UPDATE_IDEA_URL,
  AWAITING_MBB_APPROVAL,
  DISPLAYPROJECTTHEME,
  DELEGATE_IDEA_URL,
  GET_METADATA,
  APPROVED,
  PENDING,
  SENT_BACK,
  REJECTED,
  DATA_BRIDGE_CAMPAIGN,
  X_POLLINATION_CAMPAIGN,
  ACCESS_TOKEN,
  COMPLETED_STATUS,
  USER_DATA,
  SIGNOFF_CONTENT,
  PPT_CONTENT,
  REQUEST_CANCELLATION,
  BI_FORM_DATE,
  GOL_APPROVAL_CONTENT,
  AWAITING_QAULITY_CHECK_REVIEW,
  modelOperationOptions,
  sustenanceMetricFreqOptions,
  YESORNO, DATABRIDGETYPES,
  AWAITING_DB_SPOC_APPROVAL, SOLUTIONCOMPLEXITY,DOBTOOLS,REPLICABILITYOPTIONS,DB_PEF_DATE,
  AI_DOC_LINK, AIFIRST
} from "../assets/constants/constants";
import Toast from "light-toast";
import Select from "react-select";
import {
  selectValidation as selectStyle,
  textBoxValidation,
} from "../utils/FieldValidation";
import Checksum from "../utils/Checksum";
import UpdatedSpeech from "./UpdatedSpeech";
import SortByColumn from "../utils/SortByColumns";
import { withRouter } from "react-router-dom";
import ValueDelivery from "../utils/ValueDelivery";
import FPNAGOL from "../utils/FPNAGOL";
import { Link } from "react-router-dom";
import DisplayTemplate from '../utils/DisplayTemplates'



const projectType = [
  {
    value: 0,
    label: "GB",
  },
  {
    value: 1,
    label: "BB",
  },
  {
    value: 2,
    label: "MBB",
  },
];

export class FinalReviewIdeaPage extends Component {
  sessionData = JSON.parse(localStorage.getItem(USER_DATA));
  accessToken = localStorage.getItem(ACCESS_TOKEN);
  constructor(props) {
    super(props);
    const isApprovalPage = `${this.props.location.approval}`;
    let propsData = this.props.location;
    this.state = {
      ideaItem: {},
      workflowStatus: "",
      Comment: "",
      finalComment: "",
      accountName: "",
      campaignName: "",
      serviceLineName: "",
      showFinalAdditionalDetails: false,
      workFlowStatusList: [],
      workFlowStatusSelectedOption: null,
      certificationLevelsList: [],
      certLevelsSelectedOption: null,
      certificationWorkTypeList: [],
      certWorkTypeSelectedOption: null,
      transformationLeversList: [],
      transSelectedOption: null,
      methodologyList: [],
      methodologySelectedOptions: null,
      expectedDate: new Date(),
      finalApproveOptionsValue: [
        {
          WorkflowStatusId: 1,
          WorkflowStatusName: "Approved",
        },
        {
          WorkflowStatusId: 2,
          WorkflowStatusName: "Rejected",
        },
        {
          WorkflowStatusId: 3,
          WorkflowStatusName: "Send Back",
        },
      ],
      finalImpactDollar: 0.0,
      finalImpactFTE: 0.0,
      typeOfImpactList: [],
      businessImpactCategoriesList: [],
      businessImpactSubCategoriesList: [],
      typeOfImpactSelectedOption: null,
      businessImpactSelectedOption: null,
      businessSubCatSelectedOption: null,
      finalTransSelectedOption: null,
      otherTools: "",
      showSourceId: false,
      sourceId: "1234",
      bestClass: false,
      approvalSelectedOption: null,
      selectStyle: {},
      businessImpactMasterList: null,
      businessImpactValueArray: [],
      fteTotal: 0,
      dollarTotal: 0.0,
      dataBridgeCampaign: false,
      xPollinationCampaign: false,
      zeroImpact: false,
      summaryArray: [],
      approvalPage: isApprovalPage,
      replicaIndex: [],
      selectStyle: {},
      reviewComments: [],
      propsData: propsData,
      Role: "",
      speechModal: false,
      previousTextForSpeech: "",
      CommentEmpty: false,
      filteredImpactTypeState: [],
      filteredImpactTypeSelectedOption: null,
      filteredMonetizableTypeState: [],
      filteredMonetizableSelectedOption: null,
      filteredBISubCategoryState: [],
      filteredBISubCategorySelectedOption: null,
      ideaCreatedDate: "",
      setGreyOutButton: false,
      disableAddButton: false,
      showHrefModal: false,
      zeroImpactForLastestBI: false,
      CompletedDate: "",
      showClientFields: false,
      showGOLFields: false,

      // PPT deck
      filename1: "",
      fileType1: "",
      base64File1: "",

      // client sign-off
      signOffFilename: "",
      signOffFileType: "",
      signOffContent: "",

      // gol file
      filename3: "",
      fileType3: "",
      base64File3: "",

      // fpna file
      filename4: "",
      fileType4: "",
      base64File4: "",

      clientName: "",
      clientRole: "",
      clientSignOffDate: "",

      golFilename: "",
      golContent: "",
      golFileType: "",
      golOhr: "",
      golEmail: "",
      golFullname: "",

      fpnaOhr: "",
      fpnaFilename: "",
      fpnaContent: "",
      fpnaFileType: "",
      fpnaName: "",

      submitIdeaCount: 0,
      biThreshold: "",
      awaitingQC: false,

      vrconditiontype: "",
      vrMapping: "",
      vrRegionMapping: "",
      vrVerticalSplit: "",
      vrResult: "",
      IsVDType: "",
      showBBFieldsValue: [],
      appropriateAttachment: false,
      mbbLimit: 50000,
      approvalStatusState: "",
      bbFieldValidationState: "",
      inputDollarValue: "",
      monetizableValues: [
        {
          value: 0,
          label: "Improvement in transaction based billing",
        },
        {
          value: 1,
          label: "Increase in billable HC",
        },
        {
          value: 2,
          label: "Increase in billable hours",
        },
        {
          value: 3,
          label: "Revenue from digital deployment",
        },
        {
          value: 4,
          label: "Revenue from special projects",
        },
        {
          value: 5,
          label: "Value share from improvement project",
        },
        {
          value: 6,
          label: "Fixed Fee",
        },
      ],
      postMonetizableOptions: [
        {
          value: 0,
          label: "Discussion to be initiated with Client",
        },
        {
          value: 1,
          label: "Discussion ongoing with Client",
        },
        {
          value: 2,
          label: "Rejected by Client",
        },
        {
          value: 4,
          label: "Approved by Client",
        },
      ],
      monetizableProjectSelectedOption: null,
      monetizableCheckbox: null,
      monetizableStatus: false,
      showClientApprovalFields: false,

      monetizationStatusSelectedOption: null,
      SowID: "",
      IsVDMonetizable: "",
      MonetizationStatus: "",
      FPnAOHRMonetization: "",
      FPnANameMonetization: "",
      FPnAEmailMonetization: "",
      FPnAFileNameMonetization: "",
      FPnAFileTypeMonetization: "",
      FPnAFileMonetization: "",
      IsSampleCheck: false,

      totalMonetizedFTE: 0,
      totalMonetizedDollar: 0,
      qcCommentLog: false,
      qcComments: [],
      impactChanged: false,
      replicaLatestOptions: [
        { id: 1, label: "1" },
        { id: 2, label: "2" },
        { id: 3, label: "3" },
        { id: 4, label: "4" },
        { id: 5, label: "5" },
      ],
      qcCommentsWithVD: [],
      golFpnaFileDetails: [],
      approvalMatrix: false,
      isFpnaRequired: false,
      displayProjectTheme: false,
      // clustor states starts here
      clusterModal: false,
      projectTypeSelectedOption: null,
      projectIDSelectedOption: null,
      clustorProjects: [],
      guidSelectedOption: null,
      showHirearchy: true,
      showTreeHirearchy: false,
      clusterData: [],
      isClusterSaveDisabled: true,
      clusterOptionSearched: false,

      // ...DB states start here...
      ideasDB: [],
      ideasDBDoc: [],

      dataBridgeType: [],
      mucIDSelectedOption: [],
      dbToolUsed: [],
      otherDBToolUsedText: "",
      modalsOperationsSelectedOption: { value: 0, label: "Deployed in Client Environment" },
      trackingApplicableSelectedOption: { value: 0, label: 'Yes' },
      sustenanceMetricFreq: [],
      predictionsAccurateSelectedOption: [],
      retrainedPostCompletionSelectedOption: [],
      actualRunsInMonth: '',
      sustenanceRemarks: "",
      dbSpocCommentMandatory: false,
      modelAccuracy: "",
      modelStillActiveSelectedOption: [],
      sustenanceMetrics: "",
      mucIDOptionsfromMetaData:[],
      mucIdTrimmed:"",
      RecType: "",

      displayDBFields:false,

      campaignList: [],
      campaignInsight:"",

      //AI
      ideasAI: [],
      isIdeaReplicable:"",
    };
    this.isExceedlimit = false;
    this.isFtePresent = false;
  }

  async componentDidMount() {
    Toast.loading("Fetching Idea...", () => { });
    await this.fetchIdea();
    Toast.hide();
    Toast.loading("Fetching Metadata...", () => { });
    await this.fetchMetadata();
    Toast.hide();
  }

  fetchMetadata = () => {
    return new Promise((resolve, reject) => {
      const param = {
        EntityType: "",
        EntityName: "",
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization":  "Bearer " + this.accessToken,
        },
        body: JSON.stringify(param),
      };
      fetch(GET_METADATA, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log("fetch metadata", data);
          this.setState(
            {
              workFlowStatusList: data ? data.WorkflowStatus : [],
              certificationLevelsList: data ? data.CertificationLevels : [],
              certificationWorkTypeList: data ? data.WorkType : [],
              transformationLeversList: data ? data.TransformationLevers : [],
              methodologyList: data ? data.Methodologies : [],
              businessImpactMasterList: data ? data.BIMasterDataVerseNew : [],
              biThreshold: data.BIQCThreshold ? data.BIQCThreshold[0] ? data.BIQCThreshold[0].pg_threshold : 0 : 0,
              vrconditiontype: data.BIQCVRMapping ? data.BIQCVRMapping[0] ? data.BIQCVRMapping[0].pg_vrconditiontype : [] : [],
              vrVerticalMapping: data.BIQCVRMapping ? data.BIQCVRMapping[0] ? data.BIQCVRMapping[0].pg_vertical ? data.BIQCVRMapping[0].pg_vertical.split("|") : "ALL" : "ALL" : "ALL",
              vrRegionMapping: data.BIQCVRMapping ? data.BIQCVRMapping[0] ? data.BIQCVRMapping[0].pg_region ? data.BIQCVRMapping[0].pg_region.split("|") : "ALL" : "ALL" : "ALL",
              mucIDOptionsfromMetaData: data.MUCID ? data.MUCID:[],
              campaignList: data ? data.Campaigns : [],


            },
            () => {
              if(this.state.ideaItem.CampaignName && this.state.campaignList){
                this.state.campaignList.map((campaign)=>{
                  if(campaign.CampaignName === this.state.ideaItem.CampaignName){
                    this.setState({campaignInsight:campaign.CampaignDescr},()=>{})
                  }
                })
              }



              if (this.state.mucIDOptionsfromMetaData) {
                const filteredData = this.state.mucIDOptionsfromMetaData.filter(item => item.pg_databridgeusecasecalculated !== null && item.pg_databridgeusecasecalculated !== undefined && item.pg_databridgeusecasecalculated !== '');
                this.setState({ mucIDOptionsfromMetaData: filteredData }, () => {})
              }

              console.log(this.state.ideaItem.MUCIDTitle)
              if (this.state.ideaItem.MUCIDTitle) {
                let selectedOption = [];
                let mucIdUseCase = "";
                this.state.mucIDOptionsfromMetaData && this.state.mucIDOptionsfromMetaData.map((item, index) => {
                  if (item.pg_usecasetitle.trim() === this.state.ideaItem.MUCIDTitle.trim()) {
                    selectedOption = {
                      value: item.pg_mcuid,
                      label: item.pg_databridgeusecasecalculated,
                    };
                    mucIdUseCase = item.pg_usecasetitle.trim();
                  }
                  return true;
                });
                this.setState({ mucIDSelectedOption: selectedOption, mucIdTrimmed: mucIdUseCase }, () => {
                  console.log("muc id trimmed =>", this.state.mucIdTrimmed)
                  console.log("mucid selected option=> ", this.state.mucIDSelectedOption)
                })
              }
              this.setSelectedOptions();
              resolve();
            }
          );
          resolve();
        })
        .catch((error) => {
          Toast.fail(
            "Error occured while getting metadata idea. Please try again!",
            3000,
            () => {
              console.log(JSON.stringify(error));
              this.setState(
                {
                  workFlowStatusList: [],
                  certificationLevelsList: [],
                  certificationWorkTypeList: [],
                  transformationLeversList: [],
                  methodologyList: [],
                  typeOfImpactOriginalList: [],
                  typeOfImpactList: [],
                  businessImpactCategoriesList: [],
                  businessImpactSubCategoriesList: [],
                  businessImpactMasterList: [],
                },
                () => {
                  resolve();
                }
              );
            }
          );
        });
    });
  };


  setSelectedOptions = () => {
    this.state.workFlowStatusList.map((item, index) => {
      if (this.state.ideaItem.WorkflowStatus === item.WorkflowStatusName) {
        this.setState({
          workFlowStatusSelectedOption: {
            value: item.WorkflowStatusId,
            label: item.WorkflowStatusName,
          },
        });
      }
      return 1;
    });

    this.state.finalApproveOptionsValue.map((item, index) => {
      if (this.state.ideaItem.ApprovalStatus === item.WorkflowStatusName) {
        this.setState({
          approvalSelectedOption: {
            value: item.WorkflowStatusId,
            label: item.WorkflowStatusName,
          },
        });
      }
      return 1;
    });

    this.state.certificationLevelsList.map((item) => {
      if (
        this.state.ideaItem.CertificationLevelId === item.CertificationLevelId
      ) {
        this.setState({
          certLevelsSelectedOption: {
            value: item.CertificationLevelId,
            label: item.CertificationLevelName,
          },
        });
      }
      return 1;
    });

    this.state.certificationWorkTypeList.map((item) => {
      if (this.state.ideaItem.CertificationWorkTypeName === item.WorkTypeName) {
        this.setState({
          certWorkTypeSelectedOption: {
            value: item.WorkTypeName,
            label: item.WorkTypeName,
          },
        });
      }
      return 1;
    });

    this.state.transformationLeversList.map((item) => {
      if (
        this.state.ideaItem.TransformationLeverId === item.TransformationLeverId
      ) {
        this.setState({
          transSelectedOption: {
            value: item.TransformationLeverId,
            label: item.TransformationLeverName,
          },
        });
      }
      return 1;
    });

    // this.state.transformationLeversList.map((item) => {
    //     if (this.state.ideaItem.FinalTransformationLeverName === item.TransformationLeverName) {
    //         this.setState({
    //             finalTransSelectedOption: {
    //                 value: item.TransformationLeverId,
    //                 label: item.TransformationLeverName
    //             }
    //         })
    //     }
    //     return 1;
    // });

    // this.state.methodologyList.map((item) => {
    //     if (this.state.ideaItem.MethodologyTypeID === item.MethodologyId) {
    //         this.setState({
    //             methodologySelectedOptions: {
    //                 value: item.MethodologyId,
    //                 label: item.MethodologyName
    //             }
    //         })
    //     }
    //     return 1;
    // });

    this.state.typeOfImpactList.map((item) => {
      if (this.state.ideaItem.ImpactName === item.ImpactName) {
        this.setState({
          typeOfImpactSelectedOption: {
            value: item.ImpactId,
            label: item.ImpactName,
          },
        });
      }
      return 1;
    });

    this.state.businessImpactCategoriesList.map((item) => {
      if (this.state.ideaItem.BusinessImpactName === item.BusinessImpactName) {
        this.setState({
          businessImpactSelectedOption: {
            value: item.BusinessImpactId,
            label: item.BusinessImpactName,
          },
        });
      }
      return 1;
    });

    this.state.businessImpactSubCategoriesList.map((item) => {
      if (
        this.state.ideaItem.BusinessImpactSubName === item.BusinessImpactSubName
      ) {
        this.setState({
          businessSubCatSelectedOption: {
            value: item.BusinessImpactSubId,
            label: item.BusinessImpactSubName,
          },
        });
      }
      return 1;
    });
  };

  fetchIdea = () => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization":  "Bearer " + this.accessToken,
        },
      };
      fetch(
        `${GET_IDEA_URL}IdeaId=${this.props.location.state}&RecID=''&isItIdeaOrGenAI='Idea'`, requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("fetch idea", data);
          // const projectDeckMetadata = data.ResultSets.Table1[0].ProjectPPTDeck ? data.ResultSets.Table1[0].ProjectPPTDeck : "";
          // const signOffMetadata = data.ResultSets.Table1[0].CustomerLeadSignOff ? data.ResultSets.Table1[0].CustomerLeadSignOff : "";
          const projectFilename = data.ResultSets.Table1[0].ProjectPPTFileName
            ? data.ResultSets.Table1[0].ProjectPPTFileName
            : "";
          const signOffFilename = data.ResultSets.Table1[0]
            .CustomerSignoffFileName
            ? data.ResultSets.Table1[0].CustomerSignoffFileName
            : "";
          const projectFileType = data.ResultSets.Table1[0].ProjectPPTType
            ? data.ResultSets.Table1[0].ProjectPPTType
            : "";
          const signOffFileType = data.ResultSets.Table1[0].CustomerSignoffType
            ? data.ResultSets.Table1[0].CustomerSignoffType
            : "";

          // const projectContent = "data:" + projectFileType + ";base64," + projectDeckMetadata;
          // const signOffContent = "data:" + signOffFileType + ";base64," + signOffMetadata;
          this.setState(
            {
              ideaItem: data.ResultSets.Table1[0],
              reviewComments: data.ReviewComments.Table1,
              finalReviewComments: data.FinalReviewComments.Table1,
              workflowStatus: data.ResultSets.Table1[0].WorkflowStatus,
              Comment: data.ResultSets.Table1[0].Comment,
              projectDeckFilename: projectFilename,
              signOffFilename: signOffFilename,
              projectFileType: projectFileType,
              signOffFileType: signOffFileType,
              // projectDeckContent: projectContent,
              // signOffContent: signOffContent,
              finalComment: data.ResultSets.Table1[0].FinalComments,
              finalImpactDollar: data.ResultSets.Table1[0].FinalImpactDollar,
              finalImpactFTE: data.ResultSets.Table1[0].FinalImpactFTE,
              expectedDate:
                data.ResultSets.Table1[0].ExpectedFinishDate === null
                  ? new Date()
                  : new Date(data.ResultSets.Table1[0].ExpectedFinishDate),
              otherTools: data.ResultSets.Table1[0].FinalOtherTools,
              bestClass: data.ResultSets.Table1[0].IsBestInClass,
              showSourceId:
                data.ResultSets.Table1[0].FinalTransformationLeverName ===
                  "Digital"
                  ? true
                  : false,
              dataBridgeCampaign:
                data.ResultSets.Table1[0].CampaignName === DATA_BRIDGE_CAMPAIGN,
              xPollinationCampaign:
                data.ResultSets.Table1[0].CampaignName ===
                X_POLLINATION_CAMPAIGN,
              ideaCreatedDate: data.ResultSets.Table1[0].CreatedDate,
              golFilename: data.ResultSets.Table1[0].GOLFileName
                ? data.ResultSets.Table1[0].GOLFileName
                : "",
              //golContent:,
              golFileType: data.ResultSets.Table1[0].GOLFileType,
              fpnaFilename: data.ResultSets.Table1[0].FPnAFileName,
              //fpnaContent:,
              fpnaFileType: data.ResultSets.Table1[0].FPnAFileType,
              IsVDType: data.ResultSets.Table1[0].VDType,
              fpnaOhr: data.ResultSets.Table1[0]
                ? data.ResultSets.Table1[0].FPnAOHR
                : null,
              fpnaName: data.ResultSets.Table1[0]
                ? data.ResultSets.Table1[0].FPnAName
                : null,
              IsSampleCheck: data.SampleCheck.Table1[0].IsSampleCheck,
              qcComments: data.QcComments.Table1 ? data.QcComments.Table1 : [],
              replicaIndex: data.ResultSets.Table1[0]
                ? data.ResultSets.Table1[0].ReplicabilityIndex
                : null,
              qcCommentsWithVD: data.QcComments.Table2
                ? data.QcComments.Table2
                : [],
              filename1: data.ResultSets.Table1[0].ProjectPPTFileName
                ? data.ResultSets.Table1[0].ProjectPPTFileName
                : "",
              clusterData: data.ClusterData.Table1
                ? data.ClusterData.Table1
                : [],
              ideasDB: data.ResultSets.Table3 && data.ResultSets.Table3[0].Result === "NULL" ? [] : data.ResultSets.Table3[0],
              ideasDBDoc: data.ResultSets.Table4 && data.ResultSets.Table4[0].Result === "NULL" ? [] : data.ResultSets.Table4[0],
              RecType: data.Templates.ResultSets.Table1 && data.Templates.ResultSets.Table1[0].Result === "NULL" ? "" : data.Templates.ResultSets.Table1[0].RecType,
              DPA: data.Templates.ResultSets.Table2 && data.Templates.ResultSets.Table2[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table2,
              SOrdTable: data.Templates.ResultSets.Table5 && data.Templates.ResultSets.Table5[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table5,
              ideasAI: data.ResultSets.Table2 ? data.ResultSets.Table2[0] : [],
            },
            () => {


              console.log("idea created date=> ",this.state.ideaCreatedDate)

              if (this.state.ideaItem.CreatedDate) {
                let created = new Date(this.state.ideaItem.CreatedDate);
                let abc = new Date(DB_PEF_DATE);
                if (created.getTime() < abc.getTime()) {
                  this.setState({displayDBFields:false},()=>{})
                  console.log("created date is lesser")
                } else {
                  this.setState({displayDBFields:true},()=>{})
                  console.log("created date is greater")
                }
              }



              

              if (this.state.ideaItem && this.state.ideaItem.DBProjectType) {
                let localDBProjectType = this.state.ideaItem.DBProjectType;
                if (localDBProjectType) {
                  DATABRIDGETYPES.map(item => {
                    if (item.label === localDBProjectType) {
                      this.setState({ dataBridgeType: item }, () => { })
                    }
                  })
                }
              }
              

              if (data.ResultSets.Table3 && data.ResultSets.Table3[0].DBTool) {
                let localDBTool = data.ResultSets.Table3[0].DBTool;
                if (localDBTool) {
                  DOBTOOLS.map((item) => {
                    if (item.label === localDBTool) {
                      this.setState({ dbToolUsed: item }, () => { })
                    }
                  })
                }
              }
              if (data.ResultSets.Table3 && data.ResultSets.Table3[0].OtherDBTool) {
                let localOtherDBToolsUsed = data.ResultSets.Table3[0].OtherDBTool;
                if (localOtherDBToolsUsed) {
                  this.setState({
                    otherDBToolUsedText: localOtherDBToolsUsed
                  }, () => { })
                }
              }
              if (data.ResultSets.Table3 && data.ResultSets.Table3[0].IsSustenance) {
                let localTrackingApplicable = data.ResultSets.Table3[0].IsSustenance;
                if (localTrackingApplicable) {
                  YESORNO.map((item) => {
                    if (item.label === localTrackingApplicable) {
                      this.setState({ trackingApplicableSelectedOption: item }, () => { })
                    }
                  })
                }
              }
              if (data.ResultSets.Table3 && data.ResultSets.Table3[0].ModelSummary) {
                let localModelSummary = data.ResultSets.Table3[0].ModelSummary;
                if (localModelSummary) {
                  modelOperationOptions.map((item) => {
                    if (item.label === localModelSummary) {
                      this.setState({ modalsOperationsSelectedOption: item }, () => { })
                    }
                  })
                }
              }
              if (data.ResultSets.Table3 && data.ResultSets.Table3[0].SustenanceFreq) {
                let localSustenanceMetricFrequency = data.ResultSets.Table3[0].SustenanceFreq;
                if (localSustenanceMetricFrequency) {
                  sustenanceMetricFreqOptions.map((item) => {
                    if (item.label === localSustenanceMetricFrequency) {
                      this.setState({ sustenanceMetricFreq: item }, () => { })
                    }
                  })
                }
              }
              if (data.ResultSets.Table3 && data.ResultSets.Table3[0].SustenanceMetric) {
                let localSustenanceMetric = data.ResultSets.Table3[0].SustenanceMetric;
                if (localSustenanceMetric) {
                  this.setState({ sustenanceMetrics: localSustenanceMetric }, () => { })
                }
              }

              if (this.state.ideasDB) {
                YESORNO.map((item) => {
                  if (item.label === this.state.ideasDB.IsModelActive_1) {
                    this.setState({ modelStillActiveSelectedOption: item }, () => { })
                  }
                  if (item.label === this.state.ideasDB.IsRetrained_1) {
                    this.setState({ retrainedPostCompletionSelectedOption: item }, () => { })
                  }
                  if (item.label === this.state.ideasDB.IsModelPrediction_1) {
                    this.setState({ predictionsAccurateSelectedOption: item }, () => { })
                  }
                })

                this.setState({
                  sustenanceRemarks: this.state.ideasDB.Remarks_1,
                  actualRunsInMonth: this.state.ideasDB.ActualRuns_1,
                  modelAccuracy: this.state.ideasDB.ModelAccuracy_1
                })
              }




              if (this.state.clusterData && this.state.clusterData.length > 0) {
                const targetRecID = this.state.ideaItem.LeanID;
                const filteredData = this.state.clusterData.filter(
                  (item) => item.RecAlpha === targetRecID
                );
                let getParentAlpa = "";
                this.state.clusterData.map((item) => {
                  if (item.RecAlpha === targetRecID) {
                    getParentAlpa = item.ParentAlpha;
                  }
                });
                if (getParentAlpa) {
                  this.state.clusterData.map((item) => {
                    if (getParentAlpa === item.RecAlpha) {
                      const selectedOption = {
                        value: 0,
                        label: item.ProjectType,
                      };
                      this.state.projectTypeSelectedOption = selectedOption;
                      this.setState(
                        { projectTypeSelectedOption: selectedOption },
                        () => { }
                      );
                    }
                  });
                }

                if (
                  filteredData &&
                  filteredData.length > 0 &&
                  filteredData !== undefined &&
                  filteredData !== null
                ) {
                  const selectedOption = {
                    value: 0,
                    label: filteredData[0].ParentAlpha,
                  };
                  this.setState(
                    { projectIDSelectedOption: selectedOption },
                    () => {
                      //console.log("project type selected option",this.state.projectTypeSelectedOption)
                      this.setState(
                        { projectIDSelectedOption: selectedOption },
                        () => {
                          if (
                            this.state.projectTypeSelectedOption &&
                            (this.state.projectTypeSelectedOption.label ===
                              null ||
                              this.state.projectTypeSelectedOption.label === "")
                          ) {
                            if (
                              this.state.projectIDSelectedOption.label.startsWith(
                                "GB"
                              )
                            ) {
                              const selectedOptionGB = {
                                value: 0,
                                label: "GB",
                              };
                              this.state.projectTypeSelectedOption =
                                selectedOptionGB;
                              this.setState(
                                { projectTypeSelectedOption: selectedOptionGB },
                                () => {
                                  //console.log(this.state.projectTypeSelectedOption)
                                }
                              );
                            } else {
                              const selectedOptionBB = {
                                value: 0,
                                label: "BB",
                              };
                              this.state.projectTypeSelectedOption =
                                selectedOptionBB;
                              this.setState(
                                { projectTypeSelectedOption: selectedOptionBB },
                                () => {
                                  //console.log(this.state.projectTypeSelectedOption)
                                }
                              );
                            }
                          }
                        }
                      );
                    }
                  );

                  this.setState(
                    {
                      isClusterSaveDisabled: true,
                      displayParentAlpha: filteredData[0].ParentAlpha,
                    },
                    () => { }
                  );
                } else {
                  this.setState({ displayParentAlpha: "" }, () => { });
                }
              } else {
                this.setState({ displayParentAlpha: "" }, () => { });
              }

              if (this.state.ideaItem.CreatedDate) {
                let created = new Date(this.state.ideaItem.CreatedDate);
                let abc = new Date(DISPLAYPROJECTTHEME);
                if (created.getTime() < abc.getTime()) {
                  //console.log("created date is lesser")
                  this.setState({ displayProjectTheme: true });
                } else {
                  this.setState({ displayProjectTheme: false });
                  //console.log("created date is greater ")
                }
              }
              REPLICABILITYOPTIONS.map((item) => {
                if (item.value === this.state.replicaIndex) {
                  const selectedOption = {
                    value: item.value,
                    label: item.label,
                  };
                  this.setState({ replicaIndex: selectedOption }, () => {
                    console.log(this.state.replicaIndex);
                  });
                }
              });
              //this.setCampaignName();
              //this.setServiceLineName();


                if (this.state.ideasAI) {
                  if(this.state.ideasAI.IsIdeaReplicable ){
                      this.setState({ isIdeaReplicable: "Yes" }, () => { })
                  }else{
                      this.setState({ isIdeaReplicable: "No" }, () => { })
                  }
                }




              resolve();
              if (
                this.state.ideaItem.PrevApprovalStatus ===
                "Documents Uploaded" &&
                this.state.ideaItem.ApprovalStatus === "Documents Uploaded"
              ) {
                this.setState(
                  { CommentEmpty: true, finalComment: "" },
                  () => { }
                );
              }
              if (
                this.state.IsVDType === "Value Delivered to Client + Genpact"
              ) {
                this.setState(
                  { showClientFields: true, showGOLFields: true },
                  () => { }
                );
              }
              if (this.state.IsVDType === "No Value Delivery") {
                this.setState(
                  { showClientFields: false, showGOLFields: false },
                  () => { }
                );
              }
              if (this.state.IsVDType === "Value Delivered to Client") {
                this.setState(
                  { showClientFields: true, showGOLFields: false },
                  () => { }
                );
              }
              if (this.state.IsVDType === "Value Delivered to Genpact") {
                this.setState(
                  { showClientFields: false, showGOLFields: true },
                  () => { }
                );
              }
            }
          );
        })
        .catch((error) => {
          Toast.fail(
            "Error while fetching idea. Please try again!",
            3000,
            () => { }
          );
          reject();
        });
    });
  };


  validate = () => {
    this.FinalApproverCheck();
    let isValid = true;
    this.isExceedlimit = false;
    let styleStateObj = this.state.selectStyle;

    if (
      this.state.approvalSelectedOption === null ||
      this.state.approvalSelectedOption === "" ||
      this.state.approvalSelectedOption === undefined
    ) {
      isValid = false;
      
      styleStateObj.approvalSelectedOption = selectStyle(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      styleStateObj.approvalSelectedOption = selectStyle(true);
      this.setState({
        selectStyle: styleStateObj,
      });
    }
    if (this.state.bbFieldValidationState.length === 0) {
      isValid = false;
      styleStateObj.showBBFieldsValidation = selectStyle(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      styleStateObj.showBBFieldsValidation = selectStyle(true);
      this.setState({
        selectStyle: styleStateObj,
      });
    }

    if (this.state.finalComment === "") {
      isValid = false;
      // Toast.fail("final comments", 3000, () => { })
      styleStateObj.finalComment = {
        ...textBoxValidation(false),
        border: "1px solid red",
      };
      this.setState({ selectStyle: styleStateObj });
    } else {
      if (this.state.finalComment && this.state.finalComment.length > 500) {
        Toast.fail(
          "Comment cannot be more than 500 characters, please change and resubmit.",
          3000
        );
        isValid = false;
        this.isExceedlimit = true;
        styleStateObj.finalComment = textBoxValidation(false);
        this.setState({ selectStyle: styleStateObj });
      } else {
        styleStateObj.finalComment = {
          ...textBoxValidation(true),
          border: "1px solid lightgrey",
        };
        this.setState({ selectStyle: styleStateObj });
      }
    }

    if (
      this.state.approvalSelectedOption &&
      this.state.approvalSelectedOption.label === "Approved" &&
      this.state.impactOptionSelected
    ) {
      if (!this.state.replicaIndex || this.state.replicaIndex.length === 0 || this.state.replicaIndex === null || this.state.replicaIndex === undefined) {
        isValid = false;
        styleStateObj.replicaIndex = selectStyle(false);
        this.setState({
          selectStyle: styleStateObj,
        });
      } else {
        styleStateObj.replicaIndex = selectStyle(true);
        this.setState({
          selectStyle: styleStateObj,
        });
      }

      if (this.state.impactOptionSelected.label !== "No Value Delivery") {
        if (
          this.state.businessImpactValueArray.length === 0 ||
          this.state.businessImpactValueArray === null
        ) {
          Toast.fail(
            "Value Delivery cannot be empty, please fill and resubmit.",
            3000,
            () => { }
          );
          isValid = false;
        }
        if (
          this.state.businessImpactValueArray &&
          this.state.businessImpactValueArray !== null
        ) {
          if (parseInt(this.state.finalImpactDollar) <= 0) {
            Toast.fail(
              "Total Dollar Impact cannot be zero. Please check and resubmit.",
              3000,
              () => { }
            );
            isValid = false;
          }
          if (
            this.state.finalImpactFTE > 0 &&
            this.state.finalImpactDollar <= 0
          ) {
            Toast.fail(
              "Total Impact Dollar cannot be zero when FTE is non-zero. Please check and resubmit.",
              3000,
              () => { }
            );
            isValid = false;
          }
          this.state.businessImpactValueArray.filter((item) => {
            if (
              parseFloat(item.DollarImpact) === 0 ||
              parseFloat(item.DollarImpact) === "" ||
              !item.DollarImpact ||
              item.DollarImpact === null
            ) {
              isValid = false;
              Toast.fail("Dollar Impact value cannot be zero.", 3000, () => { });
            }
            if (item && item.isFTEPresent === true) {
              if (parseFloat(item.FTEImpact)) {
              } else {
                isValid = false;
                Toast.fail("FTE Impact value cannot be zero", 3000, () => { });
              }
              if (parseFloat(item.FTEImpact) === 0) {
                isValid = false;
                Toast.fail("FTE Impact value cannot be zero", 3000, () => { });
              }
              if (parseFloat(item.FTEImpact) > parseFloat(item.DollarImpact)) {
                isValid = false;
                Toast.fail(
                  "FTE impact value cannot be greater than dollar value",
                  3000,
                  () => { }
                );
              }
            }
          });
        }
      }

      if (
        this.state.impactOptionSelected.label ===
        "Value Delivered to Client + Genpact"
      ) {
        if (this.state.businessImpactValueArray.length > 0) {
          let impactGenpactTrue = this.state.businessImpactValueArray.some(
            (vendor) => vendor["ImpactType"] === "Impact at Genpact"
          );
          let impactClientTrue = this.state.businessImpactValueArray.some(
            (vendor) => vendor["ImpactType"] === "Impact at Client"
          );
          if (impactGenpactTrue === false) {
            isValid = false;
            Toast.fail("Please add both Impact types.", 3000, () => { });
          }
          if (impactClientTrue === false) {
            isValid = false;
            Toast.fail("Please add both Impact types.", 3000, () => { });
          }
        }
      }

      if(this.state.impactOptionSelected){
        if(this.state.impactOptionSelected.label !== "No Value Delivery"){
          // if (this.state.businessImpactValueArray.filter((item) => item.IsMonetized === "Yes").length === 0) {
            
          // }
          if (this.state.monetizableProjectSelectedOption === null || 
            this.state.monetizableProjectSelectedOption.length === 0 ||
            this.state.monetizableProjectSelectedOption === undefined 
          ) {
            isValid = false;
            Toast.fail("Please select monetizatable project", 5000, () => { });
          }
        }
      }
      
      if (this.state.monetizableProjectSelectedOption) {
        if (this.state.monetizableProjectSelectedOption.label === "Yes") {
          if (this.state.monetizationStatusSelectedOption === null || !this.state.monetizationStatusSelectedOption ||
            this.state.monetizationStatusSelectedOption === undefined || this.state.monetizationStatusSelectedOption.length === 0
            ) {
            isValid = false;
            Toast.fail(
              "Since this project has been marked as Monetizable, monetizable status has to be selected",
              5000,
              () => { }
            );
          }
        }
      }
      if (
        this.state.monetizableProjectSelectedOption &&
        this.state.monetizableProjectSelectedOption.label === "Yes" &&
        this.state.monetizationStatusSelectedOption !== null
      ) {
        if (
          this.state.businessImpactValueArray.filter(
            (item) => item.IsMonetized === "Yes"
          ).length > 0
        ) {
        } else {
          isValid = false;
          Toast.fail(
            "Since this is a monetizable project, there needs to be at least one impact type as ‘Impact at Genpact’ selected and category selected as a ‘Increase in Revenue’ .",
            5000,
            () => { }
          );
        }
      }

      if (this.state.showClientApprovalFields) {
        if (
          this.state.SowID === "" ||
          this.state.SowID === null ||
          this.state.SowID === undefined ||
          this.state.SowID.length === 0 ||
          !this.state.SowID
        ) {
          isValid = false;
          Toast.fail(
            "Please fill SOW ID/SFDC/OLI ID for monetization.",
            3000,
            () => { }
          );
        }
      }

      // this is for the first time without VD type change
      if (this.state.impactOptionSelected === undefined) {
        if (
          this.state.ideaItem.VDType === "Value Delivered to Client" ||
          this.state.ideaItem.VDType === "Value Delivered to Client + Genpact"
        ) {
          if (!this.state.clientName) {
            isValid = false;
            Toast.fail("Please fill Customer Name", 3000, () => { });
          }
          if (!this.state.clientRole) {
            isValid = false;
            Toast.fail("Please fill Customer Role", 3000, () => { });
          }
          if (!this.state.clientSignOffDate) {
            isValid = false;
            Toast.fail("Please fill Customer Sign-off date", 3000, () => { });
          }
          if (!this.state.signOffFilename) {
            isValid = false;
            Toast.fail(
              "Please Upload Customer Sign-off document",
              3000,
              () => { }
            );
          }
        }
        if (
          this.state.impactOptionSelected.label === "Value Delivered to Client" ||
          this.state.impactOptionSelected.label === "Value Delivered to Genpact" ||
          this.state.impactOptionSelected.label === "Value Delivered to Client + Genpact"
        ) {
          if (!this.state.golFullname) {
            isValid = false;
            Toast.fail("Please fill GOL OHR to fetch GOL Name", 3000, () => { });
          }
          console.log("gol ohr at validate function=>", this.state.golOhr);
          if (!this.state.golOhr) {
            isValid = false;
            Toast.fail("Please fill GOL OHR", 3000, () => { });
          }
          this.state.businessImpactValueArray &&
            this.state.businessImpactValueArray.map((item) => {
              if (item.ImpactType === "Impact at Genpact") {
                if (
                  item.MonetizableType === "Increase in Revenue" ||
                  item.MonetizableType === "Reduction in Cost"
                ) {
                  if (!this.state.fpnaOhr) {
                    isValid = false;
                    Toast.fail("Please fill FP&A OHR", 3000, () => { });
                  }
                  if (!this.state.fpnaName) {
                    isValid = false;
                    Toast.fail(
                      "Please fill FP&A OHR to fetch FP&A Name",
                      3000,
                      () => { }
                    );
                  }
                }
              }
            });
          console.log("file name 3", this.state.filename3);
          if (!this.state.filename3) {
            isValid = false;
            Toast.fail("Please upload GOL Approval Document.", 3000, () => { });
          }
        }
      }

      // this is for VD change
      if (this.state.impactOptionSelected) {
        if (
          this.state.impactOptionSelected.label ===
          "Value Delivered to Client" ||
          this.state.impactOptionSelected.label ===
          "Value Delivered to Client + Genpact"
        ) {
          console.log(this.state.clientName);
          if (!this.state.clientName) {
            isValid = false;
            Toast.fail("Please fill Customer Name", 3000, () => { });
          }
          if (!this.state.clientRole) {
            isValid = false;
            Toast.fail("Please fill Customer Role", 3000, () => { });
          }
          if (!this.state.clientSignOffDate) {
            isValid = false;
            Toast.fail("Please fill Customer Sign-off date", 3000, () => { });
          }
          if (!this.state.signOffFilename) {
            isValid = false;
            Toast.fail(
              "Please Upload Customer Sign-off Document.",
              3000,
              () => { }
            );
          }
        }
        if (
          this.state.impactOptionSelected.label === "Value Delivered to Client" ||
          this.state.impactOptionSelected.label === "Value Delivered to Genpact" ||
          this.state.impactOptionSelected.label === "Value Delivered to Client + Genpact"
        ) {
          if (!this.state.golFullname) {
            isValid = false;
            Toast.fail("Please fill GOL OHR to fetch GOL Name", 3000, () => { });
          }
          console.log("gol ohr at validate function=>", this.state.golOhr);
          if (!this.state.golOhr) {
            isValid = false;
            Toast.fail("Please fill gol OHR", 3000, () => { });
          }
          this.state.businessImpactValueArray &&
            this.state.businessImpactValueArray.map((item) => {
              if (item.ImpactType === "Impact at Genpact") {
                if (
                  item.MonetizableType === "Increase in Revenue" ||
                  item.MonetizableType === "Reduction in Cost"
                ) {
                  if (!this.state.fpnaOhr) {
                    isValid = false;
                    Toast.fail("Please fill FP&A OHR", 3000, () => { });
                  }
                  if (!this.state.fpnaName) {
                    isValid = false;
                    Toast.fail(
                      "Please fill FP&A OHR to fetch FP&A Name",
                      3000,
                      () => { }
                    );
                  }
                }
              }
            });

          if (
            this.state.golFpnaFileDetails !== null ||
            !this.state.golFpnaFileDetails
          ) {
            if (
              this.state.golFpnaFileDetails[0].label === "" &&
              this.state.golFpnaFileDetails[1].label === ""
            ) {
              isValid = false;
              Toast.fail(
                "Please upload the GOL / FP&A Approval Document",
                3000,
                () => { }
              );
            }
          }
        }
      }
      if (
        this.state.filename1 === "" ||
        this.state.filename1 === undefined ||
        this.state.filename1 === null ||
        !this.state.filename1
      ) {
        isValid = false;
        Toast.fail("Please upload Project Presentation Deck.", 3000, () => { });
      }
    }


    // Sustenance metrix 1 validations starts here
    if (this.state.ideaItem.TransformationLeverName && this.state.ideaItem.TransformationLeverName === "Data" && this.state.displayDBFields) {
      if(this.state.approvalSelectedOption && this.state.approvalSelectedOption.label === "Approved"){
        if (
          this.state.dataBridgeType === null ||
          this.state.dataBridgeType === undefined ||
          !this.state.dataBridgeType ||
          this.state.dataBridgeType.length === 0
        ) {
          isValid = false;
          styleStateObj.dataBridgeType = selectStyle(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.dataBridgeType = selectStyle(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
  
        if (
          this.state.mucIDSelectedOption === null ||
          this.state.mucIDSelectedOption === undefined ||
          !this.state.mucIDSelectedOption ||
          this.state.mucIDSelectedOption.length === 0
        ) {
          isValid = false;
          styleStateObj.mucIDSelectedOption = selectStyle(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.mucIDSelectedOption = selectStyle(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
  
  
        if (
          this.state.dbToolUsed === null ||
          this.state.dbToolUsed === undefined ||
          !this.state.dbToolUsed ||
          this.state.dbToolUsed.length === 0
        ) {
          isValid = false;
          styleStateObj.dbToolUsed = selectStyle(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.dbToolUsed = selectStyle(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
  
        if (this.state.dbToolUsed && this.state.dbToolUsed.label === "Yes") {
          if (
            this.state.otherDBToolUsedText === "" ||
            this.state.otherDBToolUsedText === null ||
            this.state.otherDBToolUsedText === undefined ||
            !this.state.otherDBToolUsedText
          ) {
            isValid = false;
            styleStateObj.otherDBToolUsedText = textBoxValidation(false);
            this.setState({
              selectStyle: styleStateObj,
            });
          } else {
            styleStateObj.otherDBToolUsedText = textBoxValidation(true);
            this.setState({
              selectStyle: styleStateObj,
            });
          }
        }
  
  
        if (
          !this.state.modalsOperationsSelectedOption ||
          this.state.modalsOperationsSelectedOption.length === 0 ||
          this.state.modalsOperationsSelectedOption === null ||
          this.state.modalsOperationsSelectedOption === undefined
        ) {
          isValid = false;
          styleStateObj.modalsOperationsSelectedOption = selectStyle(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.modalsOperationsSelectedOption = selectStyle(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
  
  
        if (
          !this.state.trackingApplicableSelectedOption ||
          this.state.trackingApplicableSelectedOption.length === 0 ||
          this.state.trackingApplicableSelectedOption === null ||
          this.state.trackingApplicableSelectedOption === undefined
        ) {
          isValid = false;
          styleStateObj.trackingApplicableSelectedOption = selectStyle(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.trackingApplicableSelectedOption = selectStyle(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }

        console.log("freq=>",this.state.sustenanceMetricFreq)
        console.log("metrics =>",this.state.sustenanceMetrics)
  
        if (this.state.trackingApplicableSelectedOption && this.state.trackingApplicableSelectedOption.label === "Yes") {
          if (
            !this.state.sustenanceMetricFreq ||
            this.state.sustenanceMetricFreq.length === 0 ||
            this.state.sustenanceMetricFreq === null ||
            this.state.sustenanceMetricFreq === undefined
          ) {
            isValid = false;
            styleStateObj.sustenanceMetricFreq = selectStyle(false);
            this.setState({
              selectStyle: styleStateObj,
            });
          } else {
            styleStateObj.sustenanceMetricFreq = selectStyle(true);
            this.setState({
              selectStyle: styleStateObj,
            });
          }
  
          if (this.state.sustenanceMetrics === "" || this.state.sustenanceMetrics === null || this.state.sustenanceMetrics === undefined) {
            isValid = false;
            styleStateObj.sustenanceMetrics = {
              ...textBoxValidation(false),
              border: "1px solid red",
            };
            this.setState({ selectStyle: styleStateObj });
          } else {
            styleStateObj.sustenanceMetrics = {
              ...textBoxValidation(true),
              border: "1px solid lightgrey",
            };
            this.setState({ selectStyle: styleStateObj });
          }
        }

        console.log("data bridge type =>",this.state.dataBridgeType)
        console.log("rec type =>",this.state.RecType)
        
        if(this.state.dataBridgeType){
          if(this.state.dataBridgeType.label==="Descriptive"){
            console.log("rec type =>",this.state.RecType)
            if(this.state.RecType === undefined || this.state.RecType=== "" || this.state.RecType=== null || !this.state.RecType){
              isValid = false;
              Toast.fail("Please fill up the data of Descriptive template",3000,()=>{})
            }

            if(this.state.RecType && this.state.RecType === "DPA"){
              isValid = false;
              Toast.fail("Please fill up the data of Descriptive template",3000,()=>{})
            }
          }
          if(this.state.dataBridgeType.label==="Diagnostic" || this.state.dataBridgeType.label==="Prescriptive"){
            if(this.state.dataBridgeType.label !== this.state.ideaItem.DBProjectType){
              isValid = false;
              Toast.fail("Please fill up the data of data bridge selected template",3000,()=>{})
            }else{
              if(this.state.RecType === undefined || this.state.RecType=== "" || this.state.RecType=== null || !this.state.RecType){
                isValid = false;
                Toast.fail("Please fill up the data of " + this.state.dataBridgeType.label + " template",3000,()=>{})
              }
              if(this.state.RecType && this.state.RecType === "DA"){
                isValid = false;
                Toast.fail("Please fill up the data of " + this.state.dataBridgeType.label + " template",3000,()=>{})
              }
            }
          }
          if(this.state.dataBridgeType.label==="Predictive"){
            if(this.state.SOrdTable.length === 0 ){
              isValid = false;
              Toast.fail("Please fill up atleast one model from Predictive template",3000,()=>{})
            }else{
              this.state.SOrdTable.map((item, index) => {
                if (item.SOrd === 0) {
                  if(item.ModelType=== null|| item.ModelType === undefined || item.ModelType.length ===0){
                    isValid = false;
                    Toast.fail("Please fill up atleast one model from Predictive template",3000,()=>{})
                  }
                }
              });
            }
          }
        }
      }
    }


   
    return isValid;
  };

  updateIdea = (event) => {
    let param = {
      ProjectPPTDeck: this.state.base64File1,
      ProjectPPTFilename: this.state.filename1
        ? this.state.filename1
        : this.state.ideaItem.ProjectPPTFileName,
      ProjectPPTType: this.state.fileType1
        ? this.state.fileType1
        : this.state.ideaItem.ProjectPPTType,

      CustomerLeadSignOff: this.state.signOffContent,
      CustomerSignoffFilename: this.state.signOffFilename
        ? this.state.signOffFilename
        : this.state.ideaItem.CustomerSignoffFileName,
      CustomerSignoffType: this.state.signOffFileType
        ? this.state.signOffFileType
        : this.state.ideaItem.CustomerSignoffType,


          

      // golApproval:this.state.base64File3,
      // golApprovalFileName:this.state.filename3 ? this.state.filename3 : this.state.ideaItem.GOLFileName,
      // golApprovalType:this.state.fileType3 ? this.state.fileType3 : this.state.ideaItem.GOLFileType,
      golApproval: this.state.base64File3,
      golApprovalFileName: this.state.filename3,
      golApprovalType: this.state.fileType3,

      // fpnaApproval:this.state.base64File4,
      // fpnaApprovalFileName:this.state.filename4 ? this.state.filename4 : this.state.ideaItem.FPnAFileName,
      // fpnaApprovalType:this.state.fileType4 ? this.state.fileType4 : this.state.ideaItem.FPnAFileType,
      fpnaApproval: this.state.base64File4,
      fpnaApprovalFileName: this.state.filename4,
      fpnaApprovalType: this.state.fileType4,
      IdeaId: this.props.location.state,
      ModifiedByOHR: this.sessionData.profile.oHRId,
      Role: "Mentor",
      ClientName: this.state.clientName,
      ClientRole: this.state.clientRole,
      ClientSignOffDate: this.state.clientSignOffDate,
      GolOhr: this.state.golFullname ? this.state.golOhr : "",
      FpnaName: this.state.fpnaName,
      GolFullname: this.state.golFullname,
      FpnaOhr: this.state.fpnaName ? this.state.fpnaOhr : "",
      CTestimony: this.state.CTestimony ? this.state.CTestimony : "",
      CTestimonyFileName: this.state.ideaItem.CTestimonyFileName,
      CTestimonyFileType: this.state.ideaItem.CTestimonyFileType,
    };
    console.log(param);
    let finalJsonData = Checksum(param);
    let str1 = finalJsonData.slice(0, 15);
    let str2 = finalJsonData.slice(15, 30);
    let str3 = finalJsonData.slice(30, 40);
    let str4 = finalJsonData.slice(-10);
    let finalData = str3 + str1 + str4 + str2;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization":  "Bearer " + this.accessToken,
      },
      body: JSON.stringify(param),
    };

    fetch(`${UPDATE_IDEA_URL}Checksum=${finalData}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      });
  };

  submitIdea = () => {
    if (this.validate()) {
      this.updateIdea();
      if (window.confirm("Are you sure you want to submit the lean project?")) {
        this.saveDBDatabyBB();
        Toast.loading("Updating lean project...", () => { });
        this.state.businessImpactValueArray =
        this.state.businessImpactValueArray && this.state.businessImpactValueArray
            .map((item) => ({
              ...item,
              pg_srno: item.SrNo,
              dollarValue: item.DollarImpact,
              fteValue: item.FTEImpact,
            }))
            .map((item) => {
              return item;
            });

        let filteredBIData = this.state.businessImpactValueArray && this.state.businessImpactValueArray.filter(
          function (e) {
            return (
              e !== null &&
              e !== undefined &&
              parseFloat(e.DollarImpact) > 0 &&
              ((e.isFTEPresent && parseFloat(e.FTEImpact) > 0) ||
                !e.isFTEPresent)
            );
          }
        );
        let param = {};
        param = {
          IdeaName: this.state.ideaItem.IdeaName,
          CertificationLevelId: this.state.ideaItem.CertificationLevelId,
          CertificationWorkTypeName:
            this.state.ideaItem.CertificationWorkTypeName,
          CertificationLevelName: this.state.ideaItem.CertificationLevelName,
          DigitalProjectType: this.state.ideaItem.DigitalProjectType,
          IsDigital: this.state.ideaItem.IsDigital,
          IsReplicationVerified: this.state.ideaItem.IsReplicationVerified,
          LDTId: this.state.ideaItem.LDTId,
          LDTName: this.state.ideaItem.LDTLeadName,
          MethodologyTypeID: this.state.ideaItem.MethodologyTypeID,
          MethodologyName: this.state.ideaItem.MethodologyName,
          PotentialImpactDollar: this.state.ideaItem.PotentialImpactDollar,
          PotentialImpactFTE: this.state.ideaItem.PotentialImpactFTE,
          Problem: this.state.ideaItem.Problem,
          Solution: this.state.ideaItem.Solution,
          ServiceLineId: this.state.ideaItem.ServiceLineId,
          ServiceLineName: this.state.ideaItem.ServiceLineName,
          SubServiceLine: this.state.ideaItem.SubServiceLine,
          NewOrReplicatedIdea: this.state.ideaItem.NewOrReplicatedIdea,
          ReplicationProjectId: this.state.ideaItem.ReplicationProjectId,
          TransformationLeverId: this.state.ideaItem.TransformationLeverId,
          TransformationLeverName: this.state.ideaItem.TransformationLeverName,
          UpdaterOHR: this.sessionData.profile.oHRId,
          UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
          UpdaterName: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
          WorkflowStatus: this.state.approvalSelectedOption.label,
          ApprovalStatus: this.state.approvalStatusState,
          FinalComments: this.state.finalComment,
          IdeaId: this.props.location.state,
          IsBestInClass: this.state.showFinalAdditionalDetails ? this.state.bestClass : null,
          SourceId: this.state.sourceId,
          IsSourceInValidated: true,
          FinalImpactDollar: this.state.finalImpactDollar ? this.state.finalImpactDollar : this.state.ideaItem.FinalImpactDollar,
          FinalImpactFTE: this.state.finalImpactFTE
            ? this.state.finalImpactFTE
            : this.state.ideaItem.FinalImpactFTE,
          TypeOfImpactName: this.state.showFinalAdditionalDetails
            ? this.state.typeOfImpactSelectedOption
              ? this.state.typeOfImpactSelectedOption.label
              : null
            : null,
          BusinessImpactName: this.state.showFinalAdditionalDetails
            ? this.state.businessImpactSelectedOption
              ? this.state.businessImpactSelectedOption.label
              : null
            : null,
          BusinessImpactSubName: this.state.showFinalAdditionalDetails
            ? this.state.businessSubCatSelectedOption
              ? this.state.businessSubCatSelectedOption.label
              : null
            : null,
          FinalTransformationLeverName: this.state.showFinalAdditionalDetails
            ? this.state.finalTransSelectedOption
              ? this.state.finalTransSelectedOption.label
              : null
            : null,
          FinalOtherTools: this.state.showFinalAdditionalDetails
            ? this.state.otherTools
            : null,
          IsZeroImpactProject: this.state.zeroImpact,
          DataBridgeTheme: this.state.ideaItem.DataBridgeTheme,
          SubCampaignName: this.state.ideaItem.SubCampaignName,
          BusinessImpactData: filteredBIData,
          CampaignName: this.state.ideaItem.CampaignName,
          Comment: this.state.ideaItem.Comment,

          Role: "Mentor",

          CompletedDate: new Date().toLocaleString(),
          FpnaApprovalAttachedSelectedOption: this.state.showBBFieldsValue  ? "Yes" : "No",

          IsFPnAApprovalAttached: this.state.ideaItem.IsFPnAApprovalAttached,
          ImpactTyepChanged: this.state.ideaItem.IsVDImpactTagged,
          DeliveryCategorizedSelect: this.state.ideaItem.IsVDCategorized,
          DeliveryComputedCorrectlySelected: this.state.ideaItem.IsVDComputed,
          SignOffDeliverySelected: this.state.ideaItem.IsVDClientSignOff,
          IsVDReviewed: this.state.ideaItem.IsVDReviewed,

          QcOHR: this.state.ideaItem.QCOHR ? this.state.ideaItem.QCOHR : "",
          QcEmail: this.state.ideaItem.QCOHR
            ? this.state.ideaItem.QCOHR + "@genpact.com"
            : "",
          QcUpdaterName: this.state.ideaItem.QCName
            ? this.state.ideaItem.QCName
            : "",

          FPnAOHR: this.state.fpnaOhr
            ? this.state.fpnaOhr
            : this.state.ideaItem.FPnAOHR,
          FPnAName: this.state.fpnaName
            ? this.state.fpnaName
            : this.state.ideaItem.FPnAName,

          FPnAOHRMonetization: this.state.FPnAOHRMonetizationInput
            ? this.state.FPnAOHRMonetizationInput
            : this.state.FPnAOHRMonetization,
          FPnANameMonetization: this.state.FPnANameMonetizationInput
            ? this.state.FPnANameMonetizationInput
            : this.state.FPnANameMonetization,
          SowID: this.state.SowID
            ? this.state.SowID
            : this.state.ideaItem.SowID
              ? this.state.ideaItem.SowID
              : "",
          IsVDMonetizable: this.state.monetizableProjectSelectedOption
            ? this.state.monetizableProjectSelectedOption.label
            : this.state.ideaItem.IsVDMonetizable
              ? this.state.ideaItem.IsVDMonetizable
              : "",
          MonetizationStatus: this.state.monetizationStatusSelectedOption
            ? this.state.monetizationStatusSelectedOption.label
            : this.state.ideaItem.MonetizationStatus
              ? this.state.ideaItem.MonetizationStatus
              : "",
          IsMbbMonetizable: this.state.ideaItem.IsMbbMonetizable
            ? this.state.ideaItem.IsMbbMonetizable
            : "",
          VDType: this.state.impactOptionSelected
            ? this.state.impactOptionSelected.label
            : this.state.ideaItem.VDType,
          ReplicabilityIndex: this.state.replicaIndex
            ? this.state.replicaIndex.value
            : "",
          updaterAction: this.state.approvalSelectedOption
            ? this.state.approvalSelectedOption.label === "Completed"
              ? "Approved"
              : this.state.approvalSelectedOption.label
            : "",

          GOLNameMonetization: this.state.golMonetizationName
            ? this.state.golMonetizationName
            : this.state.ideaItem.GOLName,
          GOLOHRMonetization: this.state.golMonetizationOhr
            ? this.state.golMonetizationName
            : this.state.ideaItem.GOLOHR,




          
          
        };

        console.log(param);
        let finalJsonData = Checksum(param);
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization":  "Bearer " + this.accessToken,
          },
          body: JSON.stringify(param),
        };
        fetch(`${REVIEW_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            Toast.hide();
            Toast.success("Updated successfully.", 3000, () => {
              this.props.history.push("/review-page?page=leanapproval");
              console.log(JSON.stringify(data));
            });
          });
      }
    } else {
      if (!this.isExceedlimit) {
        Toast.fail(
          "Required fields are missing or have invalid values. Please correct and try again.",
          3000,
          () => { }
        );
      }
    }
    return false;
  };

  renderStatusOptions = () => {
    let options = [];
    if (
      this.state.bbFieldValidationState &&
      this.state.bbFieldValidationState.label ==="No" 
    ) {
      this.state.finalApproveOptionsValue.slice(-2).map((item) => {
        options.push({
          value: item.WorkflowStatusId,
          label: item.WorkflowStatusName,
        });
        return options;
      });
    } 
    if(this.state.bbFieldValidationState && this.state.bbFieldValidationState.label ==="Yes" )
    {
      this.state.finalApproveOptionsValue.map((item) => {
        options.push({
          value: item.WorkflowStatusId,
          label: item.WorkflowStatusName,
        });
        return options;
      });
    }
    return options;
  };

  handleApprovalStatus = (e) => {
    this.setState({ finalComment: "" }, () => { });
    this.setState(
      {
        approvalSelectedOption: {
          value: e.value,
          label: e.label,
        },
      },
      () => { }
    );
    let styleStateObj = this.state.selectStyle;
    styleStateObj.approvalSelectedOption = selectStyle(true);
    this.setState({
      selectStyle: styleStateObj,
    });
  };

  renderCertLevelsOptions = () => {
    let options = [];
    this.state.certificationLevelsList.map((item) => {
      options.push({
        value: item.CertificationLevelId,
        label: item.CertificationLevelName,
      });
      return options;
    });
    return options;
  };

  handleCertLevelChange = (e) => {
    this.setState({
      certLevelsSelectedOption: {
        value: e.value,
        label: e.label,
      },
    });
  };

  handleTypeOfImpactChange = (e) => {
    this.setState({
      typeOfImpactSelectedOption: {
        value: e.value,
        label: e.label,
      },
      businessImpactSelectedOption: null,
      businessSubCatSelectedOption: null,
    });
    console.log("Selected Type:" + e.label + ":" + e.value);
    this.renderBusinessImpactOptions(e);
  };

  handlebusinessImpactChange = (e) => {
    this.setState({
      businessImpactSelectedOption: {
        value: e.value,
        label: e.label,
      },
      businessSubCatSelectedOption: null,
    });
    this.renderBusinessSubOptions(e);
  };

  renderCertWorkTypeOptions = (selectedCert) => {
    let options = [];
    this.state.certificationWorkTypeList.map((item) => {
      if (!selectedCert || selectedCert.label === item.CertifiationLevelName) {
        options.push({
          value: item.WorkTypeName,
          label: item.WorkTypeName,
        });
      }
      return options;
    });
    return options;
  };

  renderBusinessImpactOptions = (selectedCategory) => {
    let options = [];
    this.state.typeOfImpactOriginalList.map((item) => {
      if (!selectedCategory) {
        options.push({
          value: item.BusinessImpactId,
          label: item.BusinessImpactName,
        });
      } else {
        if (selectedCategory.label === item.ImpactName) {
          options.push({
            value: item.BusinessImpactId,
            label: item.BusinessImpactName,
          });
        }
      }
      return options;
    });
    return options;
  };

  renderBusinessSubOptions = (selectedCategory) => {
    let options = [];
    this.state.businessImpactSubCategoriesList.map((item) => {
      if (
        !selectedCategory ||
        selectedCategory.label === item.BusinessImpactName
      ) {
        options.push({
          value: item.BusinessImpactSubId,
          label: item.BusinessImpactSubName,
        });
      }
      return options;
    });
    return options;
  };

  handleCertWorkTypeChange = (e) => {
    this.setState({
      certWorkTypeSelectedOption: {
        value: e.value,
        label: e.label,
      },
    });
  };

  handleSubCategoryChange = (e) => {
    this.setState({
      businessSubCatSelectedOption: {
        value: e.value,
        label: e.label,
      },
    });
  };

  renderTransLeversOptions = () => {
    let options = [];
    this.state.transformationLeversList.map((item) => {
      options.push({
        value: item.TransformationLeverId,
        label: item.TransformationLeverName,
      });
      return options;
    });
    return options;
  };

  renderFinalTransLeversOptions = () => {
    let options = [];
    this.state.transformationLeversList.map((item) => {
      options.push({
        value: item.TransformationLeverId,
        label: item.TransformationLeverName,
      });
      return options;
    });
    return options;
  };

  handleTransLeversChange = (e) => {
    this.setState({
      transSelectedOption: {
        value: e.value,
        label: e.label,
      },
    });
  };

  handleFinalTransLeversChange = (e) => {
    this.setState({
      finalTransSelectedOption: {
        value: e.value,
        label: e.label,
      },
      showSourceId: e.value === 3 ? true : false,
    });
  };

  renderMethodologyOptions = () => {
    let options = [];
    this.state.methodologyList.map((item) => {
      options.push({
        value: item.MethodologyId,
        label: item.MethodologyName,
      });
      return options;
    });
    return options;
  };

  handleMethodologyChange = (e) => {
    this.setState({
      methodologySelectedOptions: {
        value: e.value,
        label: e.label,
      },
    });
  };

  handleSourceIdChange = (event) => {
    this.setState({
      sourceId: event.target.value,
    });
  };

  handleOtherToolsChange = (event) => {
    this.setState({
      otherTools: event.target.value,
    });
  };

  handleFinalCommentChange = (event) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.finalComment = {
      ...textBoxValidation(true),
      border: "1px solid lightgray",
    };
    this.setState({finalComment: event.target.value, selectStyle: styleStateObj},() => {});
    
    
  };
  handleTranscriptFinalApprovalComment = (e) => {
    setTimeout(() => {
      if (e !== null && e !== undefined) {
        let finalComment = e;
        this.setState({
          finalComment: finalComment,
        });
      }
    }, 1000);
  };
    newBusinessImpactSummary=() =>{
      let myArray = [...this.state.businessImpactValueArray];
      const groupedData = myArray.reduce((accumulator, currentValue) => {
        const impactType = currentValue.impactType;
        const monetizableType = currentValue.monetizableType;
        const fteValue = parseFloat(currentValue.fteValue) || 0.0;
        const dollarValue = parseFloat(currentValue.dollarValue) || 0.0;

        const existingRow = accumulator.find(
          (row) =>
            row.impactType === impactType &&
            row.monetizableType === monetizableType
        );
        if (existingRow) {
          existingRow.fteValue += fteValue;
          existingRow.dollarValue += dollarValue;
        } else {
          accumulator.push({
            impactType,
            monetizableType,
            fteValue,
            dollarValue,
          });
        }
        return accumulator;
      }, []);
      this.setState({ summaryArray: groupedData }, () => { });
      this.setState(
        { businessImpactValueArray: this.state.businessImpactValueArray },
        () => {}
      );
      this.setState({ isFpnaRequired: false }, () => { });
      this.state.businessImpactValueArray &&
        this.state.businessImpactValueArray.map((item) => {
          if (item.ImpactType === "Impact at Genpact") {
            if (
              item.MonetizableType === "Increase in Revenue" ||
              item.MonetizableType === "Reduction in Cost"
            ) {
              this.setState({ isFpnaRequired: true }, () => { });
            }
          }
      });
    
  }

  FinalApproverCheck() {
    console.log("approval status is=>", this.state.ideaItem.ApprovalStatus);
    if (this.state.ideaItem.TransformationLeverName === "Data" && this.state.displayDBFields && (this.state.ideaItem.QCOHR ==="" && this.state.ideaItem.QCName === "")) {
      let Approver = "";
      if (this.state.approvalSelectedOption) {
        if (this.state.approvalSelectedOption.label === "Approved") {
          Approver = AWAITING_DB_SPOC_APPROVAL
        } else if (this.state.approvalSelectedOption.label === "Rejected") {
          Approver = "Rejected"
        } else if (this.state.approvalSelectedOption.label === "Send Back") {
          Approver = "Pending Documents"
        } else { }
      }
      this.state.approvalStatusState = Approver;
      this.setState(
        {
          approvalStatusState: Approver,
        },
        () => {
          console.log(this.state.approvalStatusState);
        }
      );

    } else {
      if (this.state.ideaItem.QCOHR && this.state.ideaItem.QCName) {
        let Approver = "Awaiting Project Review";
        if (this.state.finalImpactDollar >= 50000) {
          Approver = AWAITING_MBB_APPROVAL;
        }
        if (this.state.monetizableProjectSelectedOption !== null) {
          if (this.state.monetizableProjectSelectedOption.label === "Yes") {
            Approver = AWAITING_MBB_APPROVAL;
          }
        }
        if (this.state.ideaItem.ApprovalStatus === "Rework - QC") {
          Approver = "Awaiting Project Review";
        }
        if (this.state.approvalSelectedOption !== null) {
          if (this.state.approvalSelectedOption.label === "Send Back") {
            Approver = "Pending Documents";
          } else if (this.state.approvalSelectedOption.label === "Rejected") {
            Approver = "Rejected";
          }
        }
        this.state.approvalStatusState = Approver;
        this.setState(
          {
            approvalStatusState: Approver,
          },
          () => {
            console.log(this.state.approvalStatusState);
          }
        );
      }
      else {
        let Approver = COMPLETED_STATUS;
        if (
          this.state.biThreshold !== 0 &&
          this.state.finalImpactDollar !== 0 &&
          this.state.impactOptionSelected &&
          this.state.impactOptionSelected.label !== "No Value Delivery"
        ) {
          if (this.state.finalImpactDollar >= this.state.biThreshold) {
            if (this.state.vrconditiontype === "AND") {
              if (
                (this.state.vrVerticalMapping.includes(
                  this.state.ideaItem.VerticalManual
                ) ||
                  this.state.vrVerticalMapping === "ALL") &&
                (this.state.vrRegionMapping.includes(
                  this.state.ideaItem.RegionManual
                ) ||
                  this.state.vrRegionMapping === "ALL") &&
                this.state.IsSampleCheck
              ) {
                Approver = AWAITING_QAULITY_CHECK_REVIEW;
              }
            } else {
              if (
                this.state.vrVerticalMapping.includes(
                  this.state.ideaItem.VerticalManual
                ) ||
                this.state.vrVerticalMapping === "ALL" ||
                ((this.state.vrRegionMapping.includes(
                  this.state.ideaItem.RegionManual
                ) ||
                  this.state.vrRegionMapping === "ALL") &&
                  this.state.IsSampleCheck)
              ) {
                Approver = AWAITING_QAULITY_CHECK_REVIEW;
              }
            }
          }
        }

        if (this.state.finalImpactDollar >= 50000) {
          Approver = AWAITING_MBB_APPROVAL;
        }
        if (this.state.monetizableProjectSelectedOption !== null) {
          if (this.state.monetizableProjectSelectedOption.label === "Yes") {
            Approver = AWAITING_MBB_APPROVAL;
          }
        }
        if (this.state.ideaItem.ApprovalStatus === "Rework - QC") {
          Approver = "Awaiting Project Review";
        }
        if (this.state.approvalSelectedOption !== null) {
          if (this.state.approvalSelectedOption.label === "Send Back") {
            Approver = "Pending Documents";
          } else if (this.state.approvalSelectedOption.label === "Rejected") {
            Approver = "Rejected";
          }
        }
        this.state.approvalStatusState = Approver;
        this.setState(
          {
            approvalStatusState: Approver,
          },
          () => {
            console.log(this.state.approvalStatusState);
          }
        );
      }
    }


  }

  handleClassChange = (event) => {
    this.setState((previousState, currentProps) => {
      return {
        bestClass: !previousState.bestClass,
      };
    });
  };

  formatDateString(dateparam) {
    return dateparam.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }

  cancelEvent = () => {
    this.props.history.push("/review-page?page=leanapproval");
  };

  

  replicaIndexChangeHandler = (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.replicaIndex = selectStyle(true);
    this.setState(
      {
        replicaIndex:e,
        selectStyle: styleStateObj,
      },
      () => {
        
      }
    );
  };

  formatDateStringWithTime(dateparam) {
    return dateparam.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }

  toggleTranscriptFunction = (id) => {
    this.setState({ speechModal: !this.state.speechModal, modalIndex: id });
    if (id === 7) {
      if (this.state.finalComment) {
        this.setState({ previousTextForSpeech: this.state.finalComment }, () =>
          console.log(this.state.previousTextForSpeech)
        );
      } else {
        this.setState({ previousTextForSpeech: "" }, () =>
          console.log(this.state.previousTextForSpeech)
        );
      }
    }
  };

  closeModalFunction = () => {
    this.setState({ speechModal: false });
  };

  showBBFields = (e) => {
    this.setState({ approvalSelectedOption: "", finalComment: "" }, () => { });
    this.setState({ bbFieldValidationState: e }, () => {
      let styleStateObj = this.state.selectStyle;
      styleStateObj.showBBFieldsValidation = selectStyle(true);
      this.setState({
        selectStyle: styleStateObj,
      });
    });
    if (e !== null && e !== undefined && e.label === "Yes") {
      this.setState({ approvalMatrix: true }, () => { });
    }
    if (e !== null && e !== undefined && e.label === "No") {
      this.setState({
        zeroImpact: false,
        bestClass: false,
        otherTools: null,
        //replicaIndex: [],
        showBIForm: true,
        approvalMatrix: false,
      });
    }
  };

  pull_data = ({ businessImpactValueArray }) => {
    this.setState(
      { businessImpactValueArray: businessImpactValueArray },
      () => {
        this.newBusinessImpactSummary();
      }
    );
    let dollarTotal = 0.0;
    let fteValue = 0.0;
    if(this.state.businessImpactValueArray){
      if(this.state.businessImpactValueArray.length > 0){
        this.state.businessImpactValueArray.map((item) => {
          if (item && item.DollarImpact) {
            dollarTotal += parseFloat(item.DollarImpact);
          }
          if (item && item.dollarValue) {
            dollarTotal += parseFloat(item.dollarValue);
          }
          if (item && item.FTEImpact) {
            fteValue += parseFloat(item.FTEImpact);
          }
          if (item && item.fteValue) {
            fteValue += parseFloat(item.fteValue);
          }
        });
      }
    }
    this.setState({ finalImpactDollar: dollarTotal }, () => {});
    this.setState({ finalImpactFTE: fteValue }, () => { });
    if(this.state.impactOptionSelected && this.state.impactOptionSelected.label === "No Value Delivery"){
      this.setState({finalImpactDollar: 0,finalImpactFTE:0},() => {});
    }
  };
  remove_bi_data_after_delete = (filteredIdeaArray) => {
    if (filteredIdeaArray) {
      this.setState({ businessImpactValueArray: filteredIdeaArray }, () => {
        this.newBusinessImpactSummary();
        let dollarTotal = 0.0;
        let fteValue = 0.0;
        this.state.businessImpactValueArray.map((item) => {
          if (item && item.DollarImpact) {
            dollarTotal += parseFloat(item.DollarImpact);
          }
          if (item && item.dollarValue) {
            dollarTotal += parseFloat(item.dollarValue);
          }
          if (item && item.FTEImpact) {
            fteValue += parseFloat(item.FTEImpact);
          }
          if (item && item.fteValue) {
            fteValue += parseFloat(item.fteValue);
          }
        });
        this.setState({ finalImpactDollar: dollarTotal }, () => {
          //console.log("inside call back function $ total",this.state.finalImpactDollar)
        });
        this.setState({ finalImpactFTE: fteValue }, () => {
          //console.log("inside call back function fte value",this.state.finalImpactFTE)
        });
      });
    }
  };

  pull_file_data = ({
    fileName,
    fileType,
    fileContent,
    showClientApprovalFields,
    SowID,
    FPnAOHRMonetization,
    FPnANameMonetization,
  }) => {
    if (fileName) {
      this.setState({ FPnAFileNameMonetization: fileName }, () => { });
    }
    if (fileType) {
      this.setState({ FPnAFileTypeMonetization: fileType }, () => { });
    }
    if (fileContent) {
      this.setState({ FPnAFileMonetization: fileContent }, () => { });
    }

    this.setState(
      { showClientApprovalFields: showClientApprovalFields },
      () => { }
    );
    this.setState(
      {
        SowID: SowID,
        FPnAOHRMonetization: FPnAOHRMonetization,
        FPnANameMonetization: FPnANameMonetization,
      },
      () => { }
    );
  };
  pull_monetizedTotal = ({ fteValue, dollarValue }) => {
    if (fteValue) {
      this.setState({ totalMonetizedDollar: fteValue }, () => { });
    }
    if (dollarValue) {
      this.setState({ totalMonetizedFTE: dollarValue }, () => { });
    }
  };
  pull_monetizableProject = (e) => {
    //console.log("monetizable project at props=>",e)
    this.setState({ monetizableProjectSelectedOption: e }, () => {
      //console.log("monetizable Project Selected Option =>",this.state.monetizableProjectSelectedOption)
    });
  };
  pull_monetizableStatus = (e) => {
    //console.log("monetizable status at props=>",e)
    this.setState({ monetizationStatusSelectedOption: e }, () => {
      //console.log("monetizable Project status Selected Option =>",this.state.monetizationStatusSelectedOption)
    });
  };
  pull_monetization_fpna_name = (localFpnaName) => {
    this.setState({ FPnANameMonetization: localFpnaName }, () => { });
  };
  pull_monetization_fpna_ohr = (localFpnaohr) => {
    this.setState({ FPnAOHRMonetization: localFpnaohr }, () => { });
  };
  handleVDTypeChange = ({ e }) => {

    if (e) {
      this.setState({ impactOptionSelected: e, impactChanged: true }, () => {
        if (this.state.impactOptionSelected.label === "No Value Delivery") {
          this.setState(
            {
              showClientFields: false,
              showGOLFields: false,
              finalImpactDollar: 0,
              monetizableProjectSelectedOption: null,
              businessImpactValueArray:[],
              businessImpactValueArray:null
            },
            () => { 
              
            }
          );
        }
        if (
          this.state.impactOptionSelected.label === "Value Delivered to Client"
        ) {
          this.setState({ showClientFields: true, showGOLFields: true });
        }
        if (
          this.state.impactOptionSelected.label === "Value Delivered to Genpact"
        ) {
          this.setState({ showClientFields: false, showGOLFields: true });
        }
        if (
          this.state.impactOptionSelected.label ===
          "Value Delivered to Client + Genpact"
        ) {
          this.setState({ showClientFields: true, showGOLFields: true });
        }
      });
    }
  };

  fetchFPAOHRforIncreaseInRevenue = (e) => {
    if (e === true) {
      this.setState({ appropriateAttachment: true }, () => { });
    } else {
      this.setState({ appropriateAttachment: false }, () => { });
    }
  };
  pull_monetization_sowId = (localSowId) => {
    this.setState({ SowID: localSowId }, () => {
      //console.log("sow id from pull_monetization_sowId function =>",this.state.SowID)
    });
  };

  pullMonetizationGOLName = (localGOLName) => {
    this.setState({ golMonetizationName: localGOLName }, () => {
      //console.log(this.state.golMonetizationName)
    });
  };
  pullMonetizationGOLOhr = (localGOLOhr) => {
    this.setState({ golMonetizationOhr: localGOLOhr }, () => {
      //console.log(this.state.golMonetizationOhr)
    });
  };

  pullCategoryNotListed = (localShowHref) => {
    this.setState({ showHrefModal: localShowHref }, () => {
      console.log("hrf modal =>",this.state.showHrefModal)
    });
  };




  // function for FPNAGOL component starts here

  // ppt deck file details
  pullPPTDeck = ({ fileName, fileType, fileContent }) => {
    this.setState(
      { filename1: fileName, fileType1: fileType, base64File1: fileContent },
      () => {
        //console.log("filename ",this.state.filename1, "file type ",this.state.fileType1, "content ",this.state.base64File1)
      }
    );
  };
  pullClientDocName = (fileName) => {
    this.setState({ signOffFilename: fileName }, () => {
      //console.log("client approval name =>",this.state.signOffFilename)
    });
  };
  pullClientDocType = (fileType) => {
    this.setState({ signOffFileType: fileType }, () => {
      //console.log("client approval type =>",this.state.signOffFileType)
    });
  };
  pullClientDocContent = (fileContent) => {
    this.setState({ signOffContent: fileContent }, () => {
      //console.log("client approval contnet =>",this.state.signOffContent)
    });
  };

  // gol file details
  pullgolFileName = (fileName) => {
    this.setState({ filename3: fileName }, () => {
      //console.log("gol approval doc name",this.state.filename3)
    });
  };
  pullgolFileType = (fileType) => {
    this.setState({ fileType3: fileType }, () => {
      //console.log("gol approval doc type",this.state.fileType3)
    });
  };
  pullgolContent = (fileContent) => {
    this.setState({ base64File3: fileContent }, () => {
      //console.log("gol approval doc content",this.state.base64File3)
    });
  };

  // fpna file details
  pullfpnafileName = (fileName) => {
    this.setState({ filename4: fileName }, () => {
      //console.log("fpna doc name",this.state.filename4)
    });
  };
  pullfpnafileType = (fileType) => {
    this.setState({ fileType4: fileType }, () => {
      //console.log("fpna doc type",this.state.fileType4)
    });
  };
  pullfpnafileContent = (fileContent) => {
    this.setState({ base64File4: fileContent }, () => {
      //console.log("fpna doc file content",this.state.base64File4)
    });
  };

  pullClientName = (e) => {
    this.setState({ clientName: e }, () => {
      //console.log(this.state.clientName)
    });
  };
  pullClientRole = (e) => {
    this.setState({ clientRole: e }, () => {
      // console.log(this.state.clientRole)
    });
  };
  pullClientSignOff = (e) => {
    this.setState({ clientSignOffDate: e }, () => {
      //console.log(this.state.clientSignOffDate)
    });
  };
  pullFpnaName = (name) => {
    this.setState({ fpnaName: name }, () => {
      //console.log(this.state.fpnaName)
    });
  };
  pullGolName = (name) => {
    this.setState({ golFullname: name }, () => {
      //console.log(this.state.golFullname)
    });
  };
  pullFpnaOhr = (e) => {
    this.setState({ fpnaOhr: e }, () => {
      //console.log(this.state.fpnaOhr)
    });
  };
  pullGolOhr = (e) => {
    this.setState({ golOhr: e }, () => {
      //console.log(this.state.golOhr)
    });
  };
  pull_fileDetails = (obj) => {
    this.setState({ golFpnaFileDetails: obj }, () => {
      //console.log(this.state.golFpnaFileDetails)
    });
  };

  // function for FPNAGOL component ends here

  // clustering function starts here
  showClusterModal = () => {
    if (
      this.state.ideaItem.LeanID !== "" ||
      this.state.ideaItem.LeanID !== null ||
      this.state.ideaItem.LeanID !== undefined ||
      !this.state.ideaItem.LeanID
    ) {
      this.setState({
        clusterModal: !this.state.clusterModal,
      });
    }
  };
  changeProjectType = (e) => {
    this.setState({ projectIDSelectedOption: null }, () => { });
    if (e) {
      this.setState({ projectTypeSelectedOption: e }, () => {
        Toast.loading("Fetching Cluster Projects...", () => { });
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization":  "Bearer " + this.accessToken,
          },
        };
        let encodedAccount = encodeURIComponent(
          this.state.ideaItem.AccountManual
        );
        let encodedVertical = encodeURIComponent(
          this.state.ideaItem.VerticalManual
        );
        fetch(
          `${GET_IDEA_URL}Account=${encodedAccount}&Vertical=${encodedVertical}&ProjectType=${this.state.projectTypeSelectedOption.label}&IdeaId=${this.state.ideaItem.IdeaId}&case=new&casetype=fetchclusterprojects&mucid=muc001`,
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            Toast.hide();
            this.setState(
              {
                clustorProjects: data.ClusterProjects,
              },
              () => {
                // this.state.clustorProjects.map(item=>{
                //     console.log(item.pg_projectid)
                // })
              }
            );
          })
          .catch((error) => {
            Toast.fail(
              "Error while fetching cluster projects. Please try again!",
              3000,
              () => { }
            );
            Toast.hide();
            console.log(error);
          });
      });
    }
  };

  displayProjectID = () => {
    if (this.state.clustorProjects) {
      let options = [];
      if (this.state.clusterOptionSearched) {
        this.state.clustorProjects.map((item, index) => {
          options.push({
            value: index,
            Display: item.pg_projectid,
            label: item.pg_projectid + " - " + item.pg_projowner,
          });
        });
      } else {
        this.state.clustorProjects.map((item, index) => {
          if (index < 19) {
            options.push({
              value: index,
              Display: item.pg_projectid,
              label: item.pg_projectid + " - " + item.pg_projowner,
            });
          }
        });
      }
      return options;
    }
  };

  handleClusterProjectInputChange = (inputValue) => {
    if (inputValue && inputValue.length > 0) {
      this.setState({ clusterOptionSearched: true }, () => { });
    } else {
      this.setState({ clusterOptionSearched: false }, () => { });
    }
  };

  changeProjectID = (e) => {
    this.setState(
      { projectIDSelectedOption: e, isClusterSaveDisabled: false },
      () => {
        this.state.clustorProjects.map((item, index) => {
          if (
            item.pg_projectid === this.state.projectIDSelectedOption.Display
          ) {
            this.setState({ guid: item.msdyn_projectid }, () => { });
          }
        });
      }
    );
  };

  saveParentLink = async () => {
    if (
      this.state.ideaItem.ApprovalStatus &&
      this.state.ideaItem.ApprovalStatus !== "Rejected" &&
      this.state.projectTypeSelectedOption &&
      this.state.projectIDSelectedOption
    ) {
      if (
        window.confirm(
          "Are you sure you'd like to add Project ID: " +
          this.state.projectIDSelectedOption.Display +
          " as the Parent Project ID?"
        )
      ) {
        if (
          parseInt(this.state.ideaItem.OwnerOHR) ===
          parseInt(this.sessionData.profile.oHRId) ||
          parseInt(this.state.ideaItem.BlackBeltUserId) ===
          parseInt(this.sessionData.profile.oHRId)
        ) {
          if (
            this.state.projectTypeSelectedOption &&
            this.state.projectIDSelectedOption
          ) {
            Toast.loading("Linking...", () => { });
            let param = {
              case: "new",
              casetype: "updatecluster",
              RecID: this.props.location.state,
              RecAlpha: this.state.ideaItem.LeanID,
              ParentRecID: this.state.guid,
              ParentProjectType: this.state.projectTypeSelectedOption
                ? this.state.projectTypeSelectedOption.label
                : "",
              ParentAlpha: this.state.projectIDSelectedOption
                ? this.state.projectIDSelectedOption.Display
                : "",
              UpdatedByName: this.sessionData
                ? this.sessionData.profile.lastName +
                ", " +
                this.sessionData.profile.firstName
                : "",
              UpdatedByOHR: this.sessionData
                ? this.sessionData.profile.oHRId
                : " ",
              Vertical: this.state.ideaItem
                ? this.state.ideaItem.VerticalManual
                : "",
              Account: this.state.ideaItem
                ? this.state.ideaItem.AccountManual
                : "",
            };
            console.log(param);
            let finalJsonData = Checksum(param);
            const requestOptions = {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                "Authorization":  "Bearer " + this.accessToken,
              },
              body: JSON.stringify(param),
            };
            fetch(
              `${DELEGATE_IDEA_URL}Checksum=${finalJsonData}`,
              requestOptions
            )
              .then((response) => response.json())
              .then((data) => {
                Toast.success("Parent linked successfully!", 5000, () => {
                  this.setState({
                    clusterModal: !this.state.clusterModal,
                  });
                  this.updateClusterData();
                });
                this.setState({ isClusterSaveDisabled: true }, () => { });
              })
              .catch((error) => {
                console.log(error);
                Toast.fail("Parent linked failed", 3000, () => { });
              });
          } else {
            Toast.fail(
              "Please select project type & project ID",
              3000,
              () => { }
            );
          }
        } else {
          Toast.fail(
            "Only idea owner & idea BB can link the ideas",
            3000,
            () => { }
          );
        }
      }
    } else {
      Toast.fail("Rejected ideas cannot be linked.", 3000, () => { });
    }
  };
  removeParentFunc = () => {
    console.log(this.state.projectIDSelectedOption);
    if (
      this.state.ideaItem.ApprovalStatus &&
      this.state.ideaItem.ApprovalStatus !== "Rejected"
    ) {
      if (
        parseInt(this.state.ideaItem.OwnerOHR) ===
        parseInt(this.sessionData.profile.oHRId) ||
        parseInt(this.state.ideaItem.BlackBeltUserId) ===
        parseInt(this.sessionData.profile.oHRId)
      ) {
        if (!this.state.clusterData || this.state.clusterData.length === 0) {
          if (
            (this.state.projectTypeSelectedOption === null ||
              this.state.projectTypeSelectedOption === undefined ||
              !this.state.projectTypeSelectedOption) &&
            (this.state.projectIDSelectedOption === null ||
              this.state.projectIDSelectedOption === undefined ||
              !this.state.projectIDSelectedOption)
          ) {
            Toast.fail("Only linked ideas can only be removed", 3000, () => { });
          }
        }
        if (this.state.clusterData && this.state.clusterData.length > 0) {
          console.log(this.state.projectIDSelectedOption);
          if (
            this.state.projectTypeSelectedOption &&
            this.state.projectIDSelectedOption
          ) {
            if (
              window.confirm(
                "Are you sure you'd like to remove Project ID: " +
                this.state.projectIDSelectedOption.label +
                " as the Parent Project ID?"
              )
            ) {
              Toast.loading("Removing parent link...", () => { });
              let param = {
                case: "new",
                casetype: "updatecluster",
                RecID: this.props.location.state,
                RecAlpha: this.state.ideaItem.LeanID,
                ParentRecID: "",
                ParentAlpha: "",
                ParentProjectType: "",
                UpdatedByName: this.sessionData
                  ? this.sessionData.profile.lastName +
                  ", " +
                  this.sessionData.profile.firstName
                  : "",
                UpdatedByOHR: this.sessionData
                  ? this.sessionData.profile.oHRId
                  : " ",
                Vertical: this.state.ideaItem
                  ? this.state.ideaItem.VerticalManual
                  : "",
                Account: this.state.ideaItem
                  ? this.state.ideaItem.AccountManual
                  : "",
              };
              console.log(param);
              let finalJsonData = Checksum(param);
              const requestOptions = {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param),
              };
              fetch(
                `${DELEGATE_IDEA_URL}Checksum=${finalJsonData}`,
                requestOptions
              )
                .then((response) => response.json())
                .then((data) => {
                  Toast.success("Parent unlinked successfully!", 3000, () => {
                    this.setState({
                      clusterModal: !this.state.clusterModal,
                    });
                  });
                  this.updateClusterData();
                  this.setState(
                    {
                      projectTypeSelectedOption: null,
                      projectIDSelectedOption: null,
                    },
                    () => { }
                  );
                })
                .catch((error) => {
                  console.log(error);
                  Toast.fail("Parent unlinked failed", 3000, () => { });
                });
            }
          }
        }
      } else {
        Toast.fail(
          "Only idea owner & idea BB can unlink the Parent",
          3000,
          () => { }
        );
      }
    } else {
      Toast.fail(
        "Rejected ideas cannot be unlinked / removed.",
        3000,
        () => { }
      );
    }
  };
  updateClusterData() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":  "Bearer " + this.accessToken,
      },
    };
    fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log("fetch idea for cluster data", data);
        this.setState(
          {
            ideaItem: data.ResultSets.Table1[0],
            clusterData: data.ClusterData.Table1 ? data.ClusterData.Table1 : [],
          },
          () => {
            if (this.state.clusterData && this.state.clusterData.length > 0) {
              const targetRecID = this.state.ideaItem.LeanID;
              const filteredData = this.state.clusterData.filter(
                (item) => item.RecAlpha === targetRecID
              );

              let getParentAlpa = "";
              this.state.clusterData.map((item) => {
                if (item.RecAlpha === targetRecID) {
                  getParentAlpa = item.ParentAlpha;
                }
              });
              if (getParentAlpa) {
                this.state.clusterData.map((item) => {
                  if (getParentAlpa === item.RecAlpha) {
                    const selectedOptionProjectType = {
                      value: 0,
                      label: item.ProjectType,
                    };
                    this.state.projectTypeSelectedOption =
                      selectedOptionProjectType;
                    this.setState(
                      { projectTypeSelectedOption: selectedOptionProjectType },
                      () => {
                        //console.log(this.state.projectTypeSelectedOption)
                      }
                    );
                  }
                });
              }
              if (
                filteredData &&
                filteredData.length > 0 &&
                filteredData !== undefined &&
                filteredData !== null &&
                filteredData !== ""
              ) {
                this.setState(
                  { displayParentAlpha: filteredData[0].ParentAlpha },
                  () => { }
                );
                const selectedOption = {
                  value: 0,
                  label: filteredData[0].ParentAlpha,
                };
                this.setState(
                  { projectIDSelectedOption: selectedOption },
                  () => {
                    this.setState(
                      { projectIDSelectedOption: selectedOption },
                      () => {
                        if (
                          this.state.projectTypeSelectedOption &&
                          (this.state.projectTypeSelectedOption.label ===
                            null ||
                            this.state.projectTypeSelectedOption.label === "")
                        ) {
                          if (
                            this.state.projectIDSelectedOption.label.startsWith(
                              "GB"
                            )
                          ) {
                            const selectedOptionGB = {
                              value: 0,
                              label: "GB",
                            };
                            this.state.projectTypeSelectedOption =
                              selectedOptionGB;
                            this.setState(
                              { projectTypeSelectedOption: selectedOptionGB },
                              () => {
                                //console.log(this.state.projectTypeSelectedOption)
                              }
                            );
                          } else {
                            const selectedOptionBB = {
                              value: 0,
                              label: "BB",
                            };
                            this.state.projectTypeSelectedOption =
                              selectedOptionBB;
                            this.setState(
                              { projectTypeSelectedOption: selectedOptionBB },
                              () => {
                                //console.log(this.state.projectTypeSelectedOption)
                              }
                            );
                          }
                        }
                      }
                    );
                  }
                );
              } else {
                this.setState({ displayParentAlpha: "" }, () => { });
              }
            } else {
              this.setState({ displayParentAlpha: "" }, () => { });
            }
          }
        );
      })
      .catch((error) => {
        Toast.fail(
          "Error while fetching idea. Please try again!",
          3000,
          () => { }
        );
        Toast.hide();
      });
  }
  // clustering function ends here


  // DB sustenance metrics performance survey
  

  handleModalsOperationsChange = (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.modalsOperationsSelectedOption = selectStyle(true);
    this.setState(
      {
        modalsOperationsSelectedOption: {
          value: e.value,
          label: e.label,
        },
        selectStyle: styleStateObj,
      },
      () => { }
    );
  };

  handleTrackingChange = (e) => {
    this.setState({ sustenanceMetricFreq: [], sustenanceMetrics: "" })
    let styleStateObj = this.state.selectStyle;
    styleStateObj.additionalSolutions = selectStyle(true);
    this.setState(
      {
        trackingApplicableSelectedOption: {
          value: e.value,
          label: e.label,
        },
        selectStyle: styleStateObj,
      },
      () => {
        console.log("selected =>", this.state.trackingApplicableSelectedOption)
      }
    );
  };
  handleSusFreqChange = (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.sustenanceMetricFreq = selectStyle(true);
    this.setState(
      {
        sustenanceMetricFreq: {
          value: e.value,
          label: e.label,
        },
        selectStyle: styleStateObj,
      },
      () => { }
    );
  };

  handleSustenanceMetricsTextChange = (e) => {
    let nonWhiteSpace= e.replace(/^\s+|\s+$/gm,'');
    let styleStateObj = this.state.selectStyle;
    styleStateObj.sustenanceMetrics = {
      ...textBoxValidation(true),
      border: "1px solid lightgray",
    };
    this.setState({ selectStyle: styleStateObj });
    if(nonWhiteSpace.length < 50){
      this.setState(
        {
          sustenanceMetrics: e
        },
        () => {}
      );
    }else{
      const trimmedValue = nonWhiteSpace.slice(0, 50);
      this.setState(
        {
          sustenanceMetrics: trimmedValue
        },
        () => {}
      );
    }
  }

  handledbToolUsedChange = (e) => {
    this.setState({ otherDBToolUsedText: "" })
    let styleStateObj = this.state.selectStyle;
    styleStateObj.dbToolUsed = selectStyle(true);
    this.setState(
      {
        dbToolUsed: {
          value: e.value,
          label: e.label,
        },
        selectStyle: styleStateObj,
      },
      () => { }
    );
  };

  handleMUCIDChange = (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.mucIDSelectedOption = selectStyle(true);
    this.setState(
      {
        mucIDSelectedOption: {
          value: e.value,
          label: e.label,
        },
        selectStyle: styleStateObj,
      },
      () => {
        this.state.mucIDOptionsfromMetaData && this.state.mucIDOptionsfromMetaData.filter((item) => {
          if (item.pg_databridgeusecasecalculated === e.label) {
            this.setState({ mucIdTrimmed: item.pg_usecasetitle }, () => {
              console.log(this.state.mucIdTrimmed)
            })
          }
        })
        console.log(this.state.mucIDSelectedOption);
      }
    );
  };
  handleOtherDBToolUsedChange = (e) => {
    if(e.length < 250){
      let styleStateObj = this.state.selectStyle;
      styleStateObj.otherDBToolUsedText = selectStyle(true);
      this.setState({
        selectStyle: styleStateObj,
        otherDBToolUsedText: e,
      });
    }else{
      window.alert("Other DB Tools fields character cannot exceed characters more than 250.")
      return;
    }
    
  };

  handleDataBridgeTypeChange = (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.dataBridgeType = selectStyle(true);
    this.setState(
      {
        dataBridgeType: {
          value: e.value,
          label: e.label,
        },
        selectStyle: styleStateObj,
      },
      () => { }
    );
  };
  renderMUCIDOptions = () => {
    let options = [];
    let databridgeuseCase="";
    this.state.mucIDOptionsfromMetaData.map((item, index) => {
      databridgeuseCase = item.pg_databridgeusecasecalculated.toString().toLowerCase();
      options.push({
        value: item.pg_mcuid,
        label: item.pg_databridgeusecasecalculated,
      });
      return true;
    });
    return options;
  }

  templateRedirect = (template) => {
    if (this.state.ideaItem.LeanID !== "" || this.state.ideaItem.LeanID !== null || this.state.ideaItem.LeanID !== undefined || !this.state.ideaItem.LeanID) {
      if ((parseInt(this.state.ideaItem.OwnerOHR) === parseInt(this.sessionData.profile.oHRId)) || (parseInt(this.state.ideaItem.BlackBeltUserId) === parseInt(this.sessionData.profile.oHRId))) {
        if (template === "Descriptive") {
          if (template === this.state.ideaItem.DBProjectType) {
            setTimeout(() => {
              this.savePartialDBData();
            }, 1000);
            this.props.history.push({
              pathname: "/descriptive-analytics-template",
              state: this.state.ideaItem.IdeaId,
            });
          }
          if (template !== this.state.ideaItem.DBProjectType) {
            if (this.state.ideaItem.DBProjectType === null || this.state.ideaItem.DBProjectType === undefined || this.state.ideaItem.DBProjectType === "") {
              setTimeout(() => {
                this.savePartialDBData();
              }, 1000);
              this.props.history.push({
                pathname: "/descriptive-analytics-template",
                state: this.state.ideaItem.IdeaId,
              });
            } else {
              if (window.confirm("User can only save one template among all the templates. If you wish to save another template the previous template data will be erased. Do you wish to continue ?")) {
                setTimeout(() => {
                  this.deletePartialDBData(template);
                }, 2000);
                setTimeout(() => {
                  this.savePartialDBData();
                }, 2000);
              }
            }

          }
        }
        if (template === "Diagnostic") {
          if (template === this.state.ideaItem.DBProjectType) {
            setTimeout(() => {
              this.savePartialDBData();
            }, 1000);
            this.props.history.push({
              pathname: "/diagnostic-or-prescriptive-template",
              state: this.state.ideaItem.IdeaId,
              templateType: template
            });
          }
          if (template !== this.state.ideaItem.DBProjectType) {
            if (this.state.ideaItem.DBProjectType === null || this.state.ideaItem.DBProjectType === undefined || this.state.ideaItem.DBProjectType === "") {
              setTimeout(() => {
                this.savePartialDBData();
              }, 1000);
              this.props.history.push({
                pathname: "/diagnostic-or-prescriptive-template",
                state: this.state.ideaItem.IdeaId,
                templateType: template
              });
            } else {
              if (window.confirm("User can only save one template among all the templates. If you wish to save another template the previous template data will be erased. Do you wish to continue ?")) {
                setTimeout(() => {
                  this.deletePartialDBData(template);
                }, 2000);
                setTimeout(() => {
                  this.savePartialDBData();
                }, 2000);
              }
            }

          }
        }
        if (template === "Prescriptive") {

          if (template === this.state.ideaItem.DBProjectType) {
            setTimeout(() => {
              this.savePartialDBData();
            }, 1000);
            this.props.history.push({
              pathname: "/diagnostic-or-prescriptive-template",
              state: this.state.ideaItem.IdeaId,
              templateType: template
            });
          }
          if (template !== this.state.ideaItem.DBProjectType) {
            if (this.state.ideaItem.DBProjectType === null || this.state.ideaItem.DBProjectType === undefined || this.state.ideaItem.DBProjectType === "") {
              setTimeout(() => {
                this.savePartialDBData();
              }, 1000);
              this.props.history.push({
                pathname: "/diagnostic-or-prescriptive-template",
                state: this.state.ideaItem.IdeaId,
                templateType: template
              });
            } else {
              if (window.confirm("User can only save one template among all the templates. If you wish to save another template the previous template data will be erased. Do you wish to continue ?")) {
                setTimeout(() => {
                  this.deletePartialDBData(template);
                }, 2000);
                setTimeout(() => {
                  this.savePartialDBData();
                }, 2000);
              }
            }

          }
        }

        if (template === "Predictive") {
          if (template === this.state.ideaItem.DBProjectType) {
            setTimeout(() => {
              this.savePartialDBData();
            }, 1000);
            this.props.history.push({
              pathname: "/predictive-template",
              state: this.state.ideaItem.IdeaId,
            });
          }
          if (template !== this.state.ideaItem.DBProjectType) {
            if (this.state.ideaItem.DBProjectType === null || this.state.ideaItem.DBProjectType === undefined || this.state.ideaItem.DBProjectType === "") {
              setTimeout(() => {
                this.savePartialDBData();
              }, 1000);
              this.props.history.push({
                pathname: "/predictive-template",
                state: this.state.ideaItem.IdeaId,
              });
            } else {
              if (window.confirm("User can only save one template among all the templates. If you wish to save another template the previous template data will be erased. Do you wish to continue ?")) {
                setTimeout(() => {
                  this.deletePartialDBData(template);
                }, 2000);
                setTimeout(() => {
                  this.savePartialDBData();
                }, 2000);
              }
            }

          }
        }
      }
      else {
        Toast.fail("Only Project owner and project Mentor can fill templates", 3000, () => { })
      }
    }
    else {
      Toast.fail("Only Projects which have been converted into lean can only be viewed", 3000, () => { })
    }
  }

deletePartialDBData=async(template)=>{
  try{
      Toast.loading("Deleting template data...", () => { });
      let param = {};
      param = {
          IdeaId: this.props.location.state,
          case: "new",
          casetype: "partialDeleteDPA",
          updaterRole: "Idea Owner",
          ProjectID: this.state.ideaItem.LeanID ? this.state.ideaItem.LeanID : "",
          DBProjectType: this.state.dataBridgeType ? this.state.dataBridgeType.label : "",
      }
      console.log(param);
      let finalJsonData = Checksum(param);
      const requestOptions = {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            "Authorization":  "Bearer " + this.accessToken,
          },
          body: JSON.stringify(param)
      };

      const response = await fetch(`${REVIEW_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
      const data = await response.json();
      Toast.hide();
      Toast.success('Template data deleted successfully!', 3000, () => {
          if(template === "Descriptive"){
              this.props.history.push({
                  pathname: "/descriptive-analytics-template",
                  state: this.state.ideaItem.IdeaId,
              });
          }
          if(template === "Diagnostic"){
              this.props.history.push({
                pathname: "/diagnostic-or-prescriptive-template",
                state: this.state.ideaItem.IdeaId,
                templateType: template
            })
          }
          if(template === "Prescriptive"){
              this.props.history.push({
                  pathname: "/diagnostic-or-prescriptive-template",
                  state: this.state.ideaItem.IdeaId,
                  templateType: template
              })
          }
          if(template === "Predictive"){
            this.props.history.push({
              pathname: "/predictive-template",
              state: this.state.ideaItem.IdeaId,
            })
          }
      });
      
  }catch (error) {
      console.log(error);
      Toast.fail("Error while deleting template data. Please try again!", 3000, () => { });
  }
  
  
}

savePartialDBData=()=>{
  let param = {};
  param = {
    IdeaId: this.props.location.state,
    case: "new",
    casetype: "DBPartialSave",
    updaterRole: "Mentor",

    DB_COO: this.state.ideaItem.COO,
    DB_COOOHR: this.state.ideaItem.COOOHR,

    ProcessOwner: this.state.ideaItem.ProcessOwner,
    ProcessOwnerOHR: this.state.ideaItem.ProcessOwnerOHR,

    IsSharedGOL: this.state.ideasDB.IsSharedGOL,
    DB_GOL: this.state.ideasDB.GOL,
    DB_GOLOHR: this.state.ideasDB.GOLOHR,

    IsSharedClient: this.state.ideasDB.IsSharedClient,
    DB_ClientName: this.state.ideasDB.ClientName,
    DB_ClientRole: this.state.ideasDB.ClientRole,

    DBProjectType: this.state.dataBridgeType ? this.state.dataBridgeType.label : "",
    MUCID: this.state.mucIDSelectedOption ? this.state.mucIDSelectedOption.value : this.state.ideaItem.MUCID,
    MUCIDTitle: this.state.mucIdTrimmed ? this.state.mucIdTrimmed : this.state.ideaItem.MUCIDTitle,
    DBTool: this.state.dbToolUsed ? this.state.dbToolUsed.label : "",
    OtherDBTool: this.state.dbToolUsed && this.state.dbToolUsed.label === "Others" ? (this.state.otherDBToolUsedText ? this.state.otherDBToolUsedText : "") : "",

    IsSustenance: this.state.trackingApplicableSelectedOption ? this.state.trackingApplicableSelectedOption.label : "",
    SustenanceFreq: this.state.trackingApplicableSelectedOption && this.state.trackingApplicableSelectedOption.label === "Yes" ? (this.state.sustenanceMetricFreq ? this.state.sustenanceMetricFreq.label : "") : "",
    SustenanceMetric: this.state.trackingApplicableSelectedOption && this.state.trackingApplicableSelectedOption.label === "Yes" ? (this.state.sustenanceMetrics ? this.state.sustenanceMetrics : "") : "",
    ModelSummary: this.state.modalsOperationsSelectedOption ? this.state.modalsOperationsSelectedOption.label : "",
  }
  console.log(param);
  let finalJsonData = Checksum(param);
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      "Authorization":  "Bearer " + this.accessToken,
    },
    body: JSON.stringify(param)
  };
  fetch(`${REVIEW_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
  .then(response => response.json())
 
}




saveDBDatabyBB=()=>{
  // API Mapping for DB
  console.log("mucid selected option is =>", this.state.mucIDSelectedOption )
  if(this.state.ideaItem.TransformationLeverName && this.state.ideaItem.TransformationLeverName === "Data"
  && this.state.displayDBFields)
  {
    let param = {};
    param = {
      IdeaId: this.props.location.state,
      case: "new",
      casetype: "BBUpdateDB",
      updaterRole: "Mentor",
      
      DBProjectType: this.state.dataBridgeType ? this.state.dataBridgeType.label : this.state.ideaItem.DBProjectType,
      MUCID: this.state.mucIDSelectedOption ? this.state.mucIDSelectedOption.value : this.state.ideaItem.MUCID,
      MUCIDTitle: this.state.mucIdTrimmed ? this.state.mucIdTrimmed : this.state.ideaItem.MUCIDTitle,
      DBTool: this.state.dbToolUsed ? this.state.dbToolUsed.label : this.state.ideasDB.DBTool,
      OtherDBTool: this.state.dbToolUsed && this.state.dbToolUsed.label === "Others" ? (this.state.otherDBToolUsedText ? this.state.otherDBToolUsedText : "") : this.state.ideasDB.OtherDBTool,

      IsSustenance: this.state.trackingApplicableSelectedOption ? this.state.trackingApplicableSelectedOption.label : "",
      SustenanceFreq: this.state.trackingApplicableSelectedOption && this.state.trackingApplicableSelectedOption.label === "Yes" ? (this.state.sustenanceMetricFreq ? this.state.sustenanceMetricFreq.label : "") : "",
      SustenanceMetric: this.state.trackingApplicableSelectedOption && this.state.trackingApplicableSelectedOption.label === "Yes" ? (this.state.sustenanceMetrics ? this.state.sustenanceMetrics : "") : "",
      ModelSummary: this.state.modalsOperationsSelectedOption ? this.state.modalsOperationsSelectedOption.label : "",

    }
    console.log(param);
    let finalJsonData = Checksum(param);
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            "Authorization":  "Bearer " + this.accessToken,
        },
        body: JSON.stringify(param)
    };
    fetch(`${REVIEW_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
    .then(response => response.json())
  }
}





fetchClientTestimonyContent = () => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":  "Bearer " + this.accessToken,
      },
    };
    let clientTestimonyFileType = this.state.ideaItem.CTestimonyFileType;
    fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true&clientTestimonycontent=true`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        const clientTestimonyFileMetadata = data.fpnaContent ? data.fpnaContent : "";
        const clientTestimonyFileContent = "data:" + clientTestimonyFileType + ";base64," + clientTestimonyFileMetadata;
        this.setState(
          {
            CTestimony: clientTestimonyFileContent,
          },
          () => {
            resolve();
            Toast.hide();
          }
        );
      })
      .catch((error) => {
        Toast.fail(
          "Error while Client Testimony file content. Please try again!",
          3000,
          () => { }
        );
        resolve();
      });
  });
};
downloadClientTestimony = () => {
  Toast.loading("Fetching Client Testimony content...", () => { });
  this.fetchClientTestimonyContent().then(() => {
    var fileData = this.state.CTestimony;
    var fileName = this.state.ideaItem.CTestimonyFileName;
    var w = window.open("");
    const downloadLink = w.document.createElement("a");
    downloadLink.href = fileData;
    downloadLink.download = fileName;
    downloadLink.click();
    Toast.hide();
  });
};





  render() {
    let templateType = this.state.dataBridgeType ? this.state.dataBridgeType.label :"";
    let convertIdeaID = window.btoa(this.state.ideaItem.IdeaId);
    let IDEA_APPROVED = this.state.ideaItem.WorkflowStatus === "Approve";
    let IDEA_REJECTED = this.state.ideaItem.WorkflowStatus === "Reject";
    let IDEA_SEND_BACK = this.state.ideaItem.WorkflowStatus === "Send Back";

    let IsSolnFeasibleValue = "";
        if (this.state.ideasAI.IsSolnFeasible === false) {
            IsSolnFeasibleValue = "No"
        }
        if (this.state.ideasAI.IsSolnFeasible === true) {
            IsSolnFeasibleValue = "Yes"
        }


    return (
      <>
        <section className="u-clearfix u-section-1" id="sec-0f7f">
          <div
            className="u-clearfix u-sheet u-sheet-1"
            style={{ marginBottom: 20 }}
          >
            <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
              <div className="u-container-layout u-container-layout-1">
                {/* ...clustor btn group starts here..... */}
                <div className="u-container-layout u-container-layout-2">
                  <div className="row">
                    <div className="col-md-10">&nbsp;</div>
                    <div className="col-md-2">
                      <div className="cluster_modal_btn_group">
                        <button
                          className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-white u-btn u-btn-round  u-radius-6 update_parent_btn"
                          onClick={this.showClusterModal}
                        >
                          Update Parent
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="">
                  {this.state.clusterModal ? (
                    <>
                      <div className="cluster_modal_main_block">
                        <div className="custom_modal cluster_modal_block">
                          <div
                            className="modal_body"
                            style={{ width: "400px" }}
                          >
                            <div className="row">
                              <div className="col-md-11">
                                <h3 className="modal_title text-center">
                                  Update Parent
                                </h3>
                              </div>
                              <div className="col-md-1">
                                <button
                                  type="button"
                                  className="close text-right"
                                  onClick={(e) =>
                                    this.setState({ clusterModal: false })
                                  }
                                >
                                  &times;
                                </button>
                              </div>
                            </div>
                            <div className="clustor_modal_fields_block">
                              <div className="clustor_modal_field">
                                <label className="form-attribute">
                                  <span style={{ color: "red" }}>*</span>{" "}
                                  Project Type:
                                </label>
                                {this.state.displayParentAlpha ? (
                                  <>
                                    <input
                                      disabled={true}
                                      value={
                                        this.state.projectTypeSelectedOption &&
                                          this.state.projectTypeSelectedOption
                                            .label
                                          ? this.state.projectTypeSelectedOption
                                            .label
                                          : ""
                                      }
                                      className="form-input"
                                      placeholder="Choose project type"
                                    />
                                  </>
                                ) : (
                                  <Select
                                    isSearchable={false}
                                    options={projectType}
                                    value={this.state.projectTypeSelectedOption}
                                    styles={this.state.selectStyle.projectType}
                                    placeholder="Choose project type"
                                    onChange={this.changeProjectType}
                                  />
                                )}
                              </div>
                              <div className="clustor_modal_field">
                                <label className="form-attribute">
                                  <span style={{ color: "red" }}>*</span>{" "}
                                  Project ID:
                                </label>
                                {this.state.displayParentAlpha ? (
                                  <>
                                    <input
                                      value={
                                        this.state.projectIDSelectedOption &&
                                          this.state.projectIDSelectedOption.label
                                          ? this.state.projectIDSelectedOption
                                            .label
                                          : ""
                                      }
                                      disabled={true}
                                      className="form-input"
                                      placeholder="Choose project ID"
                                    />
                                  </>
                                ) : (
                                  <Select
                                    isSearchable
                                    onInputChange={
                                      this.handleClusterProjectInputChange
                                    }
                                    options={this.displayProjectID()}
                                    value={this.state.projectIDSelectedOption}
                                    styles={
                                      this.state.selectStyle
                                        .projectIDSelectedOption
                                    }
                                    isDisabled={
                                      this.state.projectTypeSelectedOption
                                        ? false
                                        : true
                                    }
                                    placeholder="Choose project ID"
                                    onChange={this.changeProjectID}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="cluster_modal_btn_group">
                              <button
                                className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                disabled={
                                  this.state.isClusterSaveDisabled
                                    ? true
                                    : false
                                }
                                onClick={this.saveParentLink}
                              >
                                Save
                              </button>
                              <button
                                className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6"
                                onClick={this.removeParentFunc}
                                style={{ marginRight: "15px" }}
                              >
                                Remove Parent
                              </button>
                              {this.state.displayParentAlpha ? (
                                <>
                                  <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6 show_hierarchy_link_btn">
                                    <Link
                                      className="show_hierarchy_link_btn_anchor"
                                      to={`/showTree?ID=${this.state.ideaItem.IdeaId}`}
                                      target="_blank"
                                    >
                                      Show Hierarchy
                                    </Link>
                                  </button>
                                </>
                              ) : (
                                <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6 show_hierarchy_link_btn">
                                  Show Hierarchy
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {/* ....cluster modal ends here.... */}
                </div>

                {/* ....clustor btn group ends here.... */}
                <h4
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "#00AECF",
                    fontFamily: "Arial",
                    fontSize: 22,
                  }}
                >
                  Idea Final Review :{" "}
                  {this.state.ideaItem.IdeaIDAlpha
                    ? this.state.ideaItem.IdeaIDAlpha
                    : this.state.ideaItem.IdeaId}{" "}
                </h4>
                <div className="u-align-left u-container-style u-expanded-width u-group u-shape-rectangle u-group-2">
                  <div
                    className="u-container-layout u-container-layout-2"
                    style={{ textAlign: "center" }}
                  >
                    <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-3">
                      <div className="u-container-layout u-container-layout-3">
                        <p
                          className="u-align-left u-text u-text-2"
                          style={{ marginBottom: 10 }}
                        >
                          Owner details
                        </p>
                        <div className="row">
                          <div className="col-md-5">
                            <span className="form-attribute">Owner OHR</span>{" "}
                          </div>
                          <div className="col-md-6">
                            <input
                              className="form-input"
                              disabled
                              type="text"
                              value={this.state.ideaItem.OwnerOHR}
                            />
                          </div>
                        </div>
                        <div style={{ marginBottom: 15 }} />
                        <div className="row">
                          <div className="col-md-5">
                            <span className="form-attribute">Owner Name</span>{" "}
                          </div>
                          <div className="col-md-6">
                            <input
                              className="form-input"
                              disabled
                              type="text"
                              value={this.state.ideaItem.OwnerName}
                            />
                          </div>
                        </div>
                        <div style={{ marginBottom: -10 }} />
                        <p
                          className="u-align-left u-text u-text-7 u-text-custom-color-3"
                          style={{ fontSize: 11, marginBottom: 10 }}
                        >
                          (If Idea was raised for someone else, their OHR ID is
                          below)
                        </p>
                        <div className="row">
                          <div className="col-md-5">
                            <span className="form-attribute">
                              Request For OHR
                            </span>{" "}
                          </div>
                          <div className="col-md-6">
                            <input
                              className="form-input"
                              disabled
                              type="text"
                              value={this.state.ideaItem.CreatorOHR}
                              onChange={this.handleCreatorOHRChange}
                              placeholder="Enter OHR"
                            />
                          </div>
                        </div>
                        <div style={{ marginBottom: 10 }} />
                        <div className="row">
                          <div className="col-md-5">
                            <span className="form-attribute">Name</span>{" "}
                          </div>
                          <div className="col-md-6">
                            <input
                              className="form-input"
                              disabled
                              type="text"
                              value={this.state.ideaItem.CreatorName}
                              onChange={this.handleCreatorNameChange}
                              placeholder="Auto Load Name"
                            />
                          </div>
                        </div>
                        <div style={{ marginBottom: 10 }} />
                        <div className="row">
                          <div className="col-md-5">
                            <label
                              style={{
                                verticalAlign: "bottom",
                                marginBottom: -5,
                              }}
                            >
                              Project ID
                            </label>{" "}
                          </div>
                          <div className="col-md-6">
                            <input
                              className="form-input"
                              disabled
                              type="text"
                              defaultValue={this.state.ideaItem.LeanID}
                            />
                          </div>
                        </div>
                        <div style={{ marginBottom: 10 }} />
                      </div>
                    </div>

                    <p
                      className="visible-md-block visible-sm-block visible-xs-block"
                      style={{ marginTop: 235 }}
                    >
                      &nbsp;
                    </p>

                    <div
                      className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-4"
                      style={{ marginTop: "-303px" }}
                    >
                      <div
                        className=""
                        style={{ display: "flex", marginTop: "30px" }}
                      >
                        <div className="u-container-style u-group u-shape-rectangle u-group-5">
                          <div className="u-container-layout u-container-layout-5">
                            <p
                              className="u-align-left u-text u-text-8"
                              style={{ marginBottom: 13 }}
                            >
                              Account Details
                            </p>
                            <div className="row">
                              <div className="col-md-5">
                                <span className="form-attribute">
                                  Account&nbsp;Name
                                </span>
                              </div>
                              <div className="col-md-7">
                                <input
                                  className="form-input"
                                  disabled
                                  list="accounts"
                                  name="accounts"
                                  placeholder="Choose Account"
                                  value={this.state.ideaItem.AccountManual}
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            <div className="row">
                              <div className="col-md-5">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  Holding&nbsp;Account
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  className="form-input"
                                  disabled
                                  type="text"
                                  value={this.state.ideaItem.HoldingAccount}
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            <div className="row">
                              <div className="col-md-5">
                                <span className="form-attribute">Vertical</span>
                              </div>
                              <div className="col-md-7">
                                <input
                                  className="form-input"
                                  disabled
                                  list="vertical"
                                  name="vertical"
                                  placeholder="Choose Vertical"
                                  value={this.state.ideaItem.VerticalManual}
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            <div className="row">
                              <div className="col-md-5">
                                <span className="form-attribute">
                                  Industry&nbsp;Vertical
                                </span>
                              </div>
                              <div className="col-md-7">
                                <input
                                  className="form-input"
                                  disabled
                                  list="ideationtype"
                                  name="ideationtype"
                                  placeholder="Choose Industry&nbsp;Vertical"
                                  value={this.state.ideaItem.SubVerticalName}
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />

                            {/* units and segments starts here */}
                            <div className="row">
                              <div className="col-md-5">
                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Segment</label>
                              </div>
                              <div className="col-md-7">
                                <input className="form-input" disabled name="segment" value={this.state.ideaItem.Segment ? this.state.ideaItem.Segment : ""} style={{ fontWeight: 'normal !important' }} />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            <div className="row">
                              <div className="col-md-5">
                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Unit</label>
                              </div>
                              <div className="col-md-7">
                                <input className="form-input" disabled name="unit" value={this.state.ideaItem.Unit ? this.state.ideaItem.Unit : ""} style={{ fontWeight: 'normal !important' }} />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            {/* units and segments ends here */}

                            
                            <div className="row">
                              <div className="col-md-5">
                                <span className="form-attribute">
                                  Campaign&nbsp;Name
                                </span>
                              </div>
                              <div className="col-md-7">
                                <input
                                  className="form-input"
                                  disabled
                                  list="campaign"
                                  name="campaign"
                                  placeholder="Choose Idea Type"
                                  value={this.state.ideaItem.CampaignName}
                                />
                              </div>

                              <div className="">
                                <div className="campaign_modal_bulb campaign_tool_tip">
                                  <img 
                                      src={require("../assets/images/question_mark_2.png")}
                                      alt=""
                                      className=""
                                  />
                                  {
                                      this.state.campaignInsight
                                      ?
                                      <>
                                          <span className="my_tooltiptext">{this.state.campaignInsight}</span>
                                      </>
                                      :
                                      null
                                  }
                                </div>
                              </div>


                            </div>
                            <div style={{ marginBottom: 15 }} />

                            {this.state.ideaItem.CampaignName ===
                              "Gen AI" ? (
                              <>
                                <div className="row">
                                  <div className="col-md-5">
                                    <label
                                      style={{
                                        verticalAlign: "bottom",
                                        marginBottom: -5,
                                      }}
                                    >
                                      Gen AI Theme
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      className="form-input"
                                      disabled
                                      list="subcampaign"
                                      name="subcampaign"
                                      placeholder="Choose Gen AI Theme"
                                      value={
                                        this.state.ideaItem.SubCampaignName
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            ) : null}

                            {this.state.displayProjectTheme &&
                              this.state.dataBridgeCampaign && (
                                <>
                                  <div className="row">
                                    <div className="col-md-5">
                                      <label
                                        style={{
                                          verticalAlign: "bottom",
                                          marginBottom: -5,
                                        }}
                                      >
                                        Data Bridge Project Theme
                                      </label>
                                    </div>
                                    <div className="col-md-7">
                                      <input
                                        className="form-input"
                                        disabled
                                        list="databridgetype"
                                        name="databridgetype"
                                        placeholder="Choose Data Bridge Project Type"
                                        value={
                                          this.state.ideaItem.DataBridgeTheme
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div style={{ marginBottom: 26 }} />
                                  <p
                                    className="visible-md-block visible-sm-block visible-xs-block"
                                    style={{ marginBottom: 30 }}
                                  >
                                    &nbsp;
                                  </p>
                                </>
                              )}
                            {this.state.xPollinationCampaign && (
                              <>
                                <div className="row">
                                  <div className="col-md-5">
                                    <label
                                      style={{
                                        verticalAlign: "bottom",
                                        marginBottom: -5,
                                      }}
                                    >
                                      Sub Campaign
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      className="form-input"
                                      disabled
                                      list="subcampaign"
                                      name="subcampaign"
                                      placeholder="Choose Sub Campaign"
                                      value={
                                        this.state.ideaItem.SubCampaignName
                                      }
                                    />
                                  </div>
                                </div>
                                <div style={{ marginBottom: 26 }} />
                                <p
                                  className="visible-md-block visible-sm-block visible-xs-block"
                                  style={{ marginBottom: 30 }}
                                >
                                  &nbsp;
                                </p>
                              </>
                            )}

                            {this.state.displayParentAlpha &&
                              this.state.displayParentAlpha.length > 0 &&
                              this.state.displayParentAlpha !== null &&
                              this.state.displayParentAlpha !== undefined &&
                              this.state.displayParentAlpha !== "" ? (
                              <>
                                <div className="row">
                                  <div className="col-md-5">
                                    <label
                                      style={{
                                        verticalAlign: "bottom",
                                        marginBottom: -5,
                                      }}
                                    >
                                      Parent ID
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      className="form-input"
                                      disabled
                                      placeholder="Choose Parent ID"
                                      value={this.state.displayParentAlpha}
                                    />
                                  </div>
                                </div>
                              </>
                            ) : null}
                            {/* <div style={{ marginBottom: 18 }}>&nbsp;</div> */}
                            {
                              this.state.ideaItem.CampaignName && this.state.ideaItem.CampaignName === AIFIRST
                                ?
                                <>
                                  <div className="row">
                                    <div className="col-md-5">
                                      <label
                                        style={{
                                          verticalAlign: "bottom",
                                          marginBottom: -5,
                                        }}
                                      >
                                        <i>AI First Sample Ideas</i>
                                      </label>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="ai_first_doc_link ai_first_doc_red_highlight">
                                        <a
                                          className="ldt-list1"
                                          href={AI_DOC_LINK}
                                          target="_blank"
                                          rel="noreferrer"
                                          style={{ textAlign: "left", fontSize: 16, color: "red" }}
                                        >Document Link </a>
                                      </div>

                                    </div>
                                  </div>

                                  {
                                    this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No")
                                      ?
                                      <>
                                        <div style={{ marginBottom: 20 }} />
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-5">
                                            <span className="form-attribute">
                                              AI Recommendation
                                            </span>
                                          </div>
                                          <div className="col-md-7">
                                            <input
                                              className="form-input"
                                              disabled
                                              name="methodology"
                                              value={this.state.ideaItem.isAI}
                                            />
                                          </div>
                                        </div>
                                      </>
                                      :
                                      null

                                  }


                                  {
                                    this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No")
                                      ?
                                      <>
                                        <div style={{ marginBottom: 20 }} />
                                        <div className="row">
                                          <div className="col-md-5">
                                            <span className="form-attribute">
                                              AI First BRD
                                            </span>
                                          </div>
                                          <div className="col-md-7">
                                            <div className="ai_first_doc_link">
                                              <textarea className="ai_first_brd_textarea" disabled value={this.state.ideasAI.LinkBRD} ></textarea>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                      :
                                      null
                                  }

                                </>
                                :
                                null
                            }
                          </div>
                        </div>
                        <div className="u-container-layout u-container-layout-6">
                          <div className="row" style={{ marginTop: "10px" }}>
                            <div className="col-md-5">
                              <label
                                style={{
                                  verticalAlign: "bottom",
                                  marginBottom: -5,
                                }}
                              >
                                Country
                              </label>
                            </div>
                            <div className="col-md-7">
                              <input
                                className="form-input"
                                disabled
                                list="country"
                                name="country"
                                placeholder="Choose Country"
                                value={this.state.ideaItem.Country}
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: 15 }} />
                          <div className="row">
                            <div className="col-md-5">
                              <span className="form-attribute">Region</span>
                            </div>
                            <div className="col-md-7">
                              <input
                                className="form-input"
                                disabled
                                list="regions"
                                name="regions"
                                placeholder="Choose Region"
                                value={this.state.ideaItem.RegionManual}
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: 15 }} />
                          <div className="row">
                            <div className="col-md-5">
                              <label
                                style={{
                                  verticalAlign: "bottom",
                                  marginBottom: -5,
                                }}
                              >
                                Sub-Region
                              </label>
                            </div>
                            <div className="col-md-7">
                              <input
                                className="form-input"
                                disabled
                                list="subregions"
                                name="subregions"
                                placeholder="Choose Sub-Region"
                                value={this.state.ideaItem.SubRegion}
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: 15 }} />
                          <div className="row">
                            <div className="col-md-5">
                              <span className="form-attribute">
                                Blackbelt&nbsp;Name
                              </span>
                            </div>
                            <div className="col-md-7">
                              <input
                                className="form-input"
                                disabled
                                list="blackbelt"
                                name="blackbelt"
                                placeholder="Choose Blackbelt"
                                title={
                                  "OHR ID: " +
                                  this.state.ideaItem.BlackBeltUserId
                                }
                                value={this.state.ideaItem.BlackBeltUserName}
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: 15 }} />
                          <div className="row">
                            <div className="col-md-5">
                              <label
                                style={{
                                  verticalAlign: "bottom",
                                  marginBottom: -5,
                                }}
                              >
                                MBB
                              </label>
                            </div>
                            <div className="col-md-7">
                              <input
                                className="form-input"
                                disabled
                                name="blackbelt"
                                placeholder="Choose MBB"
                                title={"OHR ID: " + this.state.ideaItem.MBBOHR}
                                value={this.state.ideaItem.MBBName}
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: 15 }} />


                          {
                            this.state.ideaItem.CampaignName && this.state.ideaItem.CampaignName === AIFIRST &&
                              this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No")
                              ?
                              <>
                                <div style={{ marginBottom: 20 }} />
                                <div className="row">
                                  <div className="col-md-12">
                                    <span className="form-attribute">
                                      AI First Reason
                                    </span>
                                  </div>
                                  <div className="col-md-12">
                                    <textarea
                                      disabled
                                      value={this.state.ideasAI.AIReason}
                                      placeholder="Please provide value"
                                      className="form-input"
                                      rows={4}
                                      cols={80}
                                      style={{
                                        width: "100%",
                                        border: "1px solid lightgrey",
                                        resize: "none"
                                      }}
                                    />
                                  </div>
                                </div>
                                <div style={{ marginBottom: 20 }} />
                              </>
                              :
                              null
                          }


                          {/* <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>LDA</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="blackbelt" placeholder="Choose LDA" title={"OHR ID: " +  this.state.ideaItem.LDAOHR} value={this.state.ideaItem.LDA} />
                                                        </div>
                                                    </div> */}
                          <div style={{ marginBottom: 15 }} />
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        borderBottom: "1px solid lightgrey",
                        marginBottom: 15,
                      }}
                    />

                    <p
                      className="visible-md-block visible-sm-block visible-xs-block"
                      style={{ marginTop: -25 }}
                    >
                      &nbsp;
                    </p>

                    <div
                      id="idea1"
                      className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                      style={{ marginTop: 15 }}
                    >
                      <div className="u-container-layout u-container-layout-7">
                        <div className="u-container-style u-group u-shape-rectangle u-group-8">
                          <div className="u-container-layout u-container-layout-8">
                            <p
                              id="idea1title"
                              className="u-align-left u-text u-text-2"
                              style={{ marginBottom: 10 }}
                            >
                              Idea details
                            </p>
                            <div className="row">
                              <div className="col-md-4">
                                <span className="form-attribute">
                                  Service Line
                                </span>
                              </div>
                              <div className="col-md-8">
                                <input
                                  className="form-input"
                                  disabled
                                  list="serviceline"
                                  name="serviceline"
                                  placeholder="Choose Service Line"
                                  value={this.state.ideaItem.ServiceLineName}
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            <div className="row">
                              <div className="col-md-4">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  Sub Service Line
                                </label>{" "}
                              </div>
                              <div className="col-md-8">
                                <input
                                  className="form-input"
                                  disabled
                                  name="serviceline"
                                  placeholder="Choose Sub Service Line"
                                  value={this.state.ideaItem.SubServiceLine}
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            <div className="row">
                              <div className="col-md-5">
                                <span className="form-attribute">
                                  What is the Problem ?
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <textarea
                                  className="form-input"
                                  disabled
                                  rows={6}
                                  cols={80}
                                  style={{
                                    maxWidth: "100%",
                                    border: "1px solid lightgrey",
                                  }}
                                  value={this.state.ideaItem.Problem}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="u-container-style u-group u-shape-rectangle u-group-9">
                          <div
                            className="u-container-layout u-container-layout-9"
                            style={{ marginTop: 4 }}
                          >
                            <div className="row">
                              <div className="col-md-4">
                                <span className="form-attribute">
                                  Idea Name
                                </span>
                              </div>
                              <div
                                className="col-md-8"
                                style={{ alignItems: "flex-start" }}
                              >
                                <input
                                  className="form-input"
                                  disabled
                                  type="text"
                                  value={this.state.ideaItem.IdeaName}
                                  placeholder="Eg: Cash flow improvement through process restructuring"
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: 66 }} />
                            <p
                              className="u-align-left u-text u-text-custom-color-4 u-text-25"
                              style={{ width: "177px" }}
                            >
                              <span className="u-text-black form-attribute">
                                {" "}
                                How to resolve the problem
                              </span>
                              &nbsp;
                            </p>
                            <div className="row">
                              <div className="col-md-12">
                                <textarea
                                  className="form-input"
                                  disabled
                                  rows={6}
                                  cols={80}
                                  style={{
                                    maxWidth: "100%",
                                    border: "1px solid lightgrey",
                                  }}
                                  value={this.state.ideaItem.Solution}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: 20 }}>
                          <div className="col-md-5">
                            <span className="form-attribute">
                              Are you trying to replicate an existing Idea?
                            </span>
                          </div>
                          <div className="col-md-4">
                            <label className="switch">
                              <input
                                disabled
                                type="checkbox"
                                checked={
                                  this.state.ideaItem.NewOrReplicatedIdea ===
                                    null
                                    ? false
                                    : this.state.ideaItem.NewOrReplicatedIdea
                                }
                              />
                              <span className="slider round" />
                            </label>
                          </div>
                        </div>

                        {this.state.ideaItem.NewOrReplicatedIdea && (
                          <>
                            <div
                              className="col-md-8"
                              style={{ marginBottom: 15, width: "100%" }}
                            />
                            <div className="row">
                              <div className="col-md-5">
                                <span className="form-attribute">
                                  Provide the ID of the executed Idea which you
                                  want to replicate?
                                </span>
                              </div>
                              <div className="col-md-2">
                                <input
                                  className="form-input"
                                  disabled
                                  type="text"
                                  value={
                                    this.state.ideaItem.ReplicationProjectId
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}

                        <div
                          className="col-md-8"
                          style={{ marginBottom: 15 }}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        borderBottom: "1px solid lightgrey",
                        marginBottom: 15,
                      }}
                    />

                    <div
                      id="Approval Status"
                      className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                    >
                      <div className="u-container-layout u-container-layout-7">
                        <p
                          className="u-align-left u-text u-text-2"
                          style={{ marginBottom: 10 }}
                        >
                          Approval details
                        </p>
                        <div className="row">
                          <div className="col-md-2">
                            <label
                              style={{
                                verticalAlign: "bottom",
                                marginBottom: -5,
                              }}
                            >
                              Approval Status
                            </label>
                          </div>
                          <div className="col-md-2">
                            <input
                              className="form-input"
                              disabled
                              name="Status"
                              value={
                                IDEA_APPROVED
                                  ? APPROVED
                                  : IDEA_REJECTED
                                    ? REJECTED
                                    : IDEA_SEND_BACK
                                      ? SENT_BACK
                                      : PENDING
                              }
                            />
                          </div>
                          {/* <div className="col-md-3"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Expected timeline for execution</label></div>
                                                    <div className="col-md-2">
                                                        <input disabled name="ExpectedDate" value={this.state.ideaItem.ExpectedFinishDate ? this.formatDateString(new Date(this.state.ideaItem.ExpectedFinishDate)) : ""} />
                                                    </div> */}
                        </div>
                        <div
                          className="col-md-9"
                          style={{ marginBottom: 5, width: "100%" }}
                        />
                        {/* <div className="row">
                                                    <div className="col-md-12">
                                                        <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                                            <span className="u-text-black form-attribute">Comments</span>
                                                        </p>
                                                    </div>
                                                </div> */}
                        {/* <div className="row" style={{marginBottom:15}}>
                                                    <div className="col-md-12">
                                                        <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.Comment} />
                                                    </div>
                                                </div> */}
                        <div className="row" style={{ marginTop: 15 }}>
                          <div className="col-md-5">
                            <label
                              style={{
                                verticalAlign: "bottom",
                                marginBottom: -5,
                              }}
                            >
                              Comments
                            </label>
                          </div>
                          <div className="col-md-2" style={{ marginLeft: 20 }}>
                            <button
                              className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                              onClick={(e) =>
                                this.setState({
                                  commentLog: !this.state.commentLog,
                                })
                              }
                            >
                              Comment Log
                            </button>
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: 5 }}>
                          <div className="col-md-12">
                            <textarea
                              className="form-input"
                              disabled
                              rows={3}
                              cols={88}
                              style={{
                                maxWidth: "100%",
                                border: "1px solid lightgrey",
                              }}
                              name="Comments"
                              value={this.state.ideaItem.Comment}
                            />
                          </div>
                        </div>
                        <div className="text-left mt-4">
                          {this.state.commentLog &&
                            this.state.reviewComments ? (
                            <>
                              <div className="custom_modal">
                                <div className="modal_body">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <h3 className="modal_title">
                                        Idea Approval Comment Log
                                      </h3>
                                    </div>
                                    <div className="col-md-4">
                                      <button
                                        type="button"
                                        className="close text-right"
                                        onClick={(e) =>
                                          this.setState({ commentLog: false })
                                        }
                                      >
                                        &times;
                                      </button>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div
                                      className="col-md-2 text-center"
                                      style={{
                                        border: "1px solid lightgrey",
                                        backgroundColor: "gainsboro",
                                      }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: 100,
                                          verticalAlign: "bottom",
                                        }}
                                      >
                                        Date & Time
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-2 text-center"
                                      style={{
                                        border: "1px solid lightgrey",
                                        backgroundColor: "gainsboro",
                                      }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: 100,
                                          verticalAlign: "bottom",
                                        }}
                                      >
                                        OHR
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-2 text-center"
                                      style={{
                                        border: "1px solid lightgrey",
                                        backgroundColor: "gainsboro",
                                      }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: 100,
                                          verticalAlign: "bottom",
                                        }}
                                      >
                                        Name
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-1 text-center"
                                      style={{
                                        border: "1px solid lightgrey",
                                        backgroundColor: "gainsboro",
                                      }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: 100,
                                          verticalAlign: "bottom",
                                        }}
                                      >
                                        Role
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-2 text-center"
                                      style={{
                                        border: "1px solid lightgrey",
                                        backgroundColor: "gainsboro",
                                      }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: 100,
                                          verticalAlign: "bottom",
                                        }}
                                      >
                                        Action
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-3 text-center"
                                      style={{
                                        border: "1px solid lightgrey",
                                        backgroundColor: "gainsboro",
                                      }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: 100,
                                          verticalAlign: "bottom",
                                        }}
                                      >
                                        Comment
                                      </label>
                                    </div>
                                  </div>
                                  {this.state.reviewComments.map(
                                    (item, index) => {
                                      return (
                                        <div className="row" key={index}>
                                          <div
                                            className="col-md-2 text-center"
                                            style={{
                                              border: "1px solid lightgrey",
                                            }}
                                          >
                                            <label
                                              style={{
                                                lineheight: 2,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              {this.formatDateStringWithTime(
                                                new Date(item.CreatedDate)
                                              )}
                                            </label>
                                          </div>
                                          <div
                                            className="col-md-2 text-center"
                                            style={{
                                              border: "1px solid lightgrey",
                                            }}
                                          >
                                            <label
                                              style={{
                                                lineheight: 2,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              {item.UpdaterOHR}
                                            </label>
                                          </div>
                                          <div
                                            className="col-md-2 text-center"
                                            style={{
                                              border: "1px solid lightgrey",
                                            }}
                                          >
                                            <label
                                              style={{
                                                lineheight: 2,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              {item.UpdaterName}
                                            </label>
                                          </div>
                                          <div
                                            className="col-md-1 text-center role_over_flow_class"
                                            style={{
                                              border: "1px solid lightgrey",
                                            }}
                                          >
                                            <label
                                              style={{
                                                lineheight: 2,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              {item.UpdaterRole
                                                ? item.UpdaterRole
                                                : item.Action ===
                                                  "Idea Updated by Admin" ||
                                                  item.Action ===
                                                  "Idea Reinitiated by Admin"
                                                  ? "Admin"
                                                  : item.Action ===
                                                    "Delegate Action"
                                                    ? "BB"
                                                    : item.UpdaterOHR ===
                                                      this.state.ideaItem
                                                        .BlackBeltUserId
                                                      ? "BB"
                                                      : item.UpdaterOHR ===
                                                        this.state.ideaItem.MBBOHR
                                                        ? "MBB"
                                                        : item.UpdaterOHR ===
                                                          this.state.ideaItem.OwnerOHR
                                                          ? "Idea Owner"
                                                          : "LDT"}
                                            </label>
                                          </div>
                                          <div
                                            className="col-md-2 text-center"
                                            style={{
                                              border: "1px solid lightgrey",
                                            }}
                                          >
                                            <label
                                              style={{
                                                lineheight: 2,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              {item.UpdaterAction
                                                ? item.UpdaterAction
                                                : item.Action ===
                                                  "Idea Reinitiated by Admin"
                                                  ? "Idea Reinitiated"
                                                  : item.Action ===
                                                    "Idea Updated by Admin"
                                                    ? item.WorkflowStatus !==
                                                      "Pending"
                                                      ? item.WorkflowStatus
                                                      : "Idea Updated"
                                                    : item.Action ===
                                                      "Delegate Action"
                                                      ? "Delegated"
                                                      : item.WorkflowStatus ===
                                                        "Pending"
                                                        ? "Discard Cancellation"
                                                        : item.WorkflowStatus}
                                            </label>
                                          </div>
                                          <div
                                            className="col-md-3 text-center"
                                            style={{
                                              border: "1px solid lightgrey",
                                            }}
                                          >
                                            <label
                                              style={{
                                                lineheight: 2,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              {item.Comment
                                                ? item.Comment
                                                : "-"}
                                            </label>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        borderBottom: "1px solid lightgrey",
                        marginTop: 15,
                        marginBottom: 15,
                      }}
                    />

                    <div
                      id="Potential Impact"
                      className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                    >
                      <div className="u-container-layout u-container-layout-7" style={{display:"flex", alignItems:"flex-start", padding: "30px", marginTop: "20px"}}>
                        <div className="u-container-style u-group u-shape-rectangle u-group-8">
                          <div className="u-container-layout">
                            <p
                              id="Potential Impact"
                              className="u-align-left u-text u-text-2"
                              style={{ marginBottom: "30px" }}
                            >
                              Additional details
                            </p>
                            <div className="row">
                              <div className="col-md-6">
                                <span className="form-attribute">
                                  LDT Lead Name
                                </span>
                              </div>
                              <div className="col-md-6">
                                <input
                                  className="form-input"
                                  disabled
                                  list="ldtName"
                                  name="ldtName"
                                  placeholder="Choose LDT Lead"
                                  title={"OHR ID: " + this.state.ideaItem.LDTId}
                                  value={this.state.ideaItem.LDTLeadName}
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />


                            {
                              this.state.ideaItem.CampaignName && this.state.ideaItem.CampaignName === AIFIRST
                              && this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes"  || this.state.ideaItem.isAI === "No")
                              ?
                                <>
                                  <div style={{ marginBottom: 15 }} />
                                  <div className="row d_flex_align_items_center">
                                    <div className="col-md-6">
                                      <span className="form-attribute">
                                        Is the solution feasible ?
                                      </span>
                                    </div>
                                    <div className="col-md-6">
                                      <input
                                        className="form-input"
                                        disabled
                                        value={IsSolnFeasibleValue}
                                      />
                                    </div>
                                  </div>
                                  <div style={{ marginBottom: 15 }} />
                                  <div className="row d_flex_align_items_center">
                                    <div className="col-md-6">
                                      <span className="form-attribute">
                                        Solution Complexity
                                      </span>
                                    </div>
                                    <div className="col-md-6">
                                      <input
                                        className="form-input"
                                        disabled
                                        name="methodology"
                                        value={
                                          this.state.ideasAI.SolnComplexity
                                        }
                                      />
                                    </div>
                                  </div>


                                  <div style={{ marginBottom: 15 }} />
                                  <div className="row d_flex_align_items_center">
                                    <div className="col-md-6">
                                      <span className="form-attribute">
                                        Is the Idea replicable
                                      </span>
                                    </div>
                                    <div className="col-md-6">
                                      <input
                                        className="form-input"
                                        disabled
                                        value={this.state.isIdeaReplicable}
                                      />
                                    </div>
                                  </div>
                                  <div style={{ marginBottom: 15 }} />
                                  
                                </>
                                :
                                null
                            }



                            {/* <div className="row">
                                                            <div className="col-md-6"><span className="form-attribute">Potential Impact (# of FTE)</span></div>
                                                            <div className="col-md-6">
                                                                <input className="form-input" disabled type="number" value={this.state.ideaItem.PotentialImpactFTE} placeholder={0} max={10} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-6"><span className="form-attribute">Potential Impact ($)</span></div>
                                                            <div className="col-md-6">
                                                                <input className="form-input" disabled type="number" value={this.state.ideaItem.PotentialImpactDollar} placeholder={0.0} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 5 }} />
                                                        <div className="u-align-left u-text u-text-custom-color-3" style={{ fontSize: 11, fontStyle: 'italic' }}>(If Potential Impact $ is greater than 250K OR FTE number is greater than 10, please review thoroughly)
                                                                </div> */}
                            <div style={{ marginBottom: 5 }} />
                            <div style={{ marginTop: 10, marginBottom: 14 }} />
                            {this.state.ideaItem.NewOrReplicatedIdea && (
                              <div className="row">
                                <div className="col-md-10">
                                  <label
                                    style={{
                                      verticalAlign: "bottom",
                                      marginBottom: -5,
                                    }}
                                  >
                                    <span style={{ color: "red" }}>*</span> Have
                                    you verified the replication id and confirm
                                    this to be replication project?
                                  </label>
                                </div>
                                <div className="col-md-2">
                                  <label className="switch">
                                    <input
                                      disabled
                                      type="checkbox"
                                      checked={
                                        this.state.ideaItem
                                          .IsReplicationVerified
                                      }
                                    />
                                    <span className="slider round" />
                                  </label>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        

                        <div className="u-container-style u-group u-shape-rectangle ">
                          <div
                            className="u-container-layout u-container-layout-9"
                            style={{paddingTop: "15px"}}
                          >
                            <div className="row">
                              <div className="col-md-5">
                                <span className="form-attribute">
                                  Certification Level
                                </span>{" "}
                              </div>
                              <div className="col-md-7">
                                <input
                                  className="form-input"
                                  disabled
                                  name="certlevel"
                                  value={
                                    this.state.ideaItem.CertificationLevelName
                                  }
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            <div className="row">
                              <div className="col-md-5">
                                <span className="form-attribute">
                                  Transformation Lever
                                </span>{" "}
                              </div>
                              <div className="col-md-7">
                                <input
                                  className="form-input"
                                  disabled
                                  name="translever"
                                  value={
                                    this.state.ideaItem.TransformationLeverName
                                  }
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />

                            {this.state.ideaItem.DigitalProjectType ? (
                              <>
                                <div style={{ marginBottom: 15 }} />
                                <div className="row">
                                  <div className="col-md-5">
                                    <label
                                      style={{
                                        verticalAlign: "bottom",
                                        marginBottom: -5,
                                      }}
                                    >
                                      Digital Project Type
                                    </label>{" "}
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      className="form-input"
                                      disabled
                                      value={
                                        this.state.ideaItem.DigitalProjectType
                                      }
                                      list="transformationLever"
                                      name="transformationLever"
                                      placeholder="Transformation Lever Level"
                                    />
                                  </div>
                                </div>
                              </>
                            ) : null}
                            <div style={{ marginBottom: 15 }} />

                            {this.state.ideaItem.CertificationWorkTypeName !==
                              null &&
                              this.state.ideaItem.CertificationWorkTypeName !==
                              "" ? (
                              <>
                                <div className="row">
                                  <div className="col-md-5">
                                    <label
                                      style={{
                                        verticalAlign: "bottom",
                                        marginBottom: -5,
                                      }}
                                    >
                                      Work Type
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      className="form-input"
                                      disabled
                                      list="certificationWorkType"
                                      value={
                                        this.state.ideaItem
                                          .CertificationWorkTypeName
                                      }
                                      name="certificationWorkType"
                                    />
                                  </div>
                                </div>
                              </>
                            ) : null}
                            <div style={{ marginBottom: 15 }} />

                            <div className="row">
                              <div className="col-md-5">
                                <span className="form-attribute">
                                  Tools/Methodology Used
                                </span>
                              </div>
                              <div className="col-md-7">
                                <input
                                  className="form-input"
                                  disabled
                                  name="methodology"
                                  value={this.state.ideaItem.MethodologyName}
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: 5 }} />
                          </div>
                        </div>
                      </div>
                    </div>

                    {this.state.ideaItem.ApprovalStatus &&
                      this.state.finalReviewComments && (
                        <>
                          <div
                            style={{
                              borderBottom: "1px solid lightgrey",
                              marginBottom: 15,
                            }}
                          />
                          <div
                            id="Approval Status"
                            className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                          >
                            <div className="u-container-layout u-container-layout-7">
                              <p
                                className="u-align-left u-text u-text-2"
                                style={{ marginBottom: 10 }}
                              >
                                Lean Approval details
                              </p>
                              <div className="row">
                                <div className="col-md-2">
                                  <span className="form-attribute">
                                    Approval Status
                                  </span>{" "}
                                </div>
                                <div className="col-md-2">
                                  <input
                                    className="form-input"
                                    disabled
                                    name="finalapprovalstatus"
                                    value={this.state.ideaItem.ApprovalStatus}
                                  />
                                </div>
                              </div>
                              <div className="row" style={{ marginTop: 15 }}>
                                <div className="col-md-5">
                                  <label
                                    style={{
                                      verticalAlign: "bottom",
                                      marginBottom: -5,
                                    }}
                                  >
                                    Approval Comments
                                  </label>
                                </div>
                                <div
                                  className="col-md-2"
                                  style={{ marginLeft: 20 }}
                                >
                                  <button
                                    className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                    onClick={(e) =>
                                      this.setState({
                                        finalCommentLog:
                                          !this.state.finalCommentLog,
                                      })
                                    }
                                  >
                                    Comment Log
                                  </button>
                                </div>
                              </div>
                              <div className="row" style={{ marginTop: 5 }}>
                                <div className="col-md-12">
                                  <textarea
                                    className="form-input"
                                    disabled
                                    rows={3}
                                    cols={88}
                                    style={{
                                      maxWidth: "100%",
                                      border: "1px solid lightgrey",
                                    }}
                                    name="Comments"
                                    value={this.state.ideaItem.FinalComments}
                                  />
                                </div>
                              </div>
                              <div className="text-left mt-4">
                                {this.state.finalCommentLog &&
                                  this.state.finalReviewComments ? (
                                  <>
                                    <div className="custom_modal">
                                      <div className="modal_body">
                                        <div className="row">
                                          <div className="col-md-8">
                                            <h3 className="modal_title">
                                              Lean Approval Comment Log
                                            </h3>
                                          </div>
                                          <div className="col-md-4">
                                            <button
                                              type="button"
                                              className="close text-right"
                                              onClick={(e) =>
                                                this.setState({
                                                  finalCommentLog: false,
                                                })
                                              }
                                            >
                                              &times;
                                            </button>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div
                                            className="col-md-2 text-center"
                                            style={{
                                              border: "1px solid lightgrey",
                                              backgroundColor: "gainsboro",
                                            }}
                                          >
                                            <label
                                              style={{
                                                fontWeight: 100,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              Date & Time
                                            </label>
                                          </div>
                                          <div
                                            className="col-md-2 text-center"
                                            style={{
                                              border: "1px solid lightgrey",
                                              backgroundColor: "gainsboro",
                                            }}
                                          >
                                            <label
                                              style={{
                                                fontWeight: 100,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              OHR
                                            </label>
                                          </div>
                                          <div
                                            className="col-md-2 text-center"
                                            style={{
                                              border: "1px solid lightgrey",
                                              backgroundColor: "gainsboro",
                                            }}
                                          >
                                            <label
                                              style={{
                                                fontWeight: 100,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              Name
                                            </label>
                                          </div>
                                          <div
                                            className="col-md-1 text-center"
                                            style={{
                                              border: "1px solid lightgrey",
                                              backgroundColor: "gainsboro",
                                            }}
                                          >
                                            <label
                                              style={{
                                                fontWeight: 100,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              Role
                                            </label>
                                          </div>
                                          <div
                                            className="col-md-2 text-center"
                                            style={{
                                              border: "1px solid lightgrey",
                                              backgroundColor: "gainsboro",
                                            }}
                                          >
                                            <label
                                              style={{
                                                fontWeight: 100,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              Action
                                            </label>
                                          </div>
                                          <div
                                            className="col-md-3 text-center"
                                            style={{
                                              border: "1px solid lightgrey",
                                              backgroundColor: "gainsboro",
                                            }}
                                          >
                                            <label
                                              style={{
                                                fontWeight: 100,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              Comment
                                            </label>
                                          </div>
                                        </div>
                                        {this.state.finalReviewComments.map(
                                          (item, index) => {
                                            return (
                                              <div className="row" key={index}>
                                                <div
                                                  className="col-md-2 text-center"
                                                  style={{
                                                    border:
                                                      "1px solid lightgrey",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      lineheight: 2,
                                                      verticalAlign: "bottom",
                                                    }}
                                                  >
                                                    {this.formatDateStringWithTime(
                                                      new Date(item.CreatedDate)
                                                    )}
                                                  </label>
                                                </div>
                                                <div
                                                  className="col-md-2 text-center"
                                                  style={{
                                                    border:
                                                      "1px solid lightgrey",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      lineheight: 2,
                                                      verticalAlign: "bottom",
                                                    }}
                                                  >
                                                    {item.UpdaterOHR}
                                                  </label>
                                                </div>
                                                <div
                                                  className="col-md-2 text-center"
                                                  style={{
                                                    border:
                                                      "1px solid lightgrey",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      lineheight: 2,
                                                      verticalAlign: "bottom",
                                                    }}
                                                  >
                                                    {item.UpdaterName}
                                                  </label>
                                                </div>
                                                <div
                                                  className="col-md-1 text-center"
                                                  style={{
                                                    border:
                                                      "1px solid lightgrey",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      lineheight: 2,
                                                      verticalAlign: "bottom",
                                                    }}
                                                  >
                                                    {item.UpdaterRole
                                                      ? item.UpdaterRole
                                                      : item.Action ===
                                                        "Idea Updated by Admin" ||
                                                        item.Action ===
                                                        "Idea Reinitiated by Admin"
                                                        ? "Admin"
                                                        : item.Action ===
                                                          "Idea was reviewed by MBB"
                                                          ? "MBB"
                                                          : item.Action ===
                                                            "Idea was reviewed by LDT"
                                                            ? "LDT"
                                                            : item.UpdaterOHR ===
                                                              this.state.ideaItem
                                                                .OwnerOHR
                                                              ? "Idea Owner"
                                                              : "BB"}
                                                  </label>
                                                </div>
                                                <div
                                                  className="col-md-2 text-center"
                                                  style={{
                                                    border:
                                                      "1px solid lightgrey",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      lineheight: 2,
                                                      verticalAlign: "bottom",
                                                    }}
                                                  >
                                                    {item.UpdaterAction ? item.UpdaterAction : item.Action === "Idea Reinitiated by Admin" ? "Idea Reinitiated" : item.Action ==="Idea Updated by Admin"
                                                      ? item.ApprovalStatus : item.WorkflowStatus ==="Completed" || item.WorkflowStatus === "Approve" || item.WorkflowStatus === "Approved"
                                                            ? "Approved"
                                                            : item.WorkflowStatus ===
                                                              "Rejected" ||
                                                              item.WorkflowStatus ===
                                                              "Reject"
                                                              ? "Rejected"
                                                              : item.WorkflowStatus ===
                                                                REQUEST_CANCELLATION
                                                                ? REQUEST_CANCELLATION
                                                                : item.WorkflowStatus ===
                                                                  "Pending Documents"
                                                                  ? "Discard Cancellation"
                                                                  : item.WorkflowStatus ===
                                                                    "Cancelled"
                                                                    ? "Cancelled"
                                                                    : "Sent Back"}
                                                  </label>
                                                </div>
                                                <div
                                                  className="col-md-3 text-center"
                                                  style={{
                                                    border:
                                                      "1px solid lightgrey",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      lineheight: 2,
                                                      verticalAlign: "bottom",
                                                    }}
                                                  >
                                                    {item.Comment
                                                      ? item.Comment
                                                      : "-"}
                                                  </label>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    <div
                      style={{
                        borderBottom: "1px solid lightgrey",
                        marginBottom: 15,
                      }}
                    />

                    {this.state.ideaItem.CertificationLevelName === "Lean" ? (
                      <>
                        <FPNAGOL
                          IdeaId={this.state.ideaItem.IdeaId}
                          viewIdea={false}
                          vdSelected={
                            this.state.impactOptionSelected
                              ? this.state.impactOptionSelected.label
                              : this.state.ideaItem.VDType
                          }
                          fetchPPTDeck={this.pullPPTDeck}
                          fetchClientDocName={this.pullClientDocName}
                          fetchClientDocType={this.pullClientDocType}
                          fetchClientDocContent={this.pullClientDocContent}
                          fetchGolDocName={this.pullgolFileName}
                          fetchGolDocType={this.pullgolFileType}
                          fetchGolDocContent={this.pullgolContent}
                          fetchfpnaDocName={this.pullfpnafileName}
                          fetchfpnaDocType={this.pullfpnafileType}
                          fetchfpnaDocContent={this.pullfpnafileContent}
                          fetchClientName={this.pullClientName}
                          fetchClientRole={this.pullClientRole}
                          fetchClientSignOffDate={this.pullClientSignOff}
                          fetchFpnaName={this.pullFpnaName}
                          fetchGolName={this.pullGolName}
                          fetchFpnaOhr={this.pullFpnaOhr}
                          fetchGolOhr={this.pullGolOhr}
                          fetchFileDetails={this.pull_fileDetails}
                          sendBitoFPNA={this.state.businessImpactValueArray}
                          sendIsFpnaRequired={this.state.isFpnaRequired}
                        />
                      </>
                    ) : null}

                    {this.state.impactOptionSelected ? (
                      <>
                        <div
                          id="Potential Impact"
                          className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                        >
                          <div
                            className="u-container-layout u-container-layout-7"
                            style={{
                              borderBottom: "1px solid lightgray",
                              paddingBottom: "35px",
                              marginBottom: "20px",
                            }}
                          >
                            <div className="u-container-style u-group u-shape-rectangle u-group-8">
                              <div className="u-container-layout u-container-layout-8">
                                <p
                                  id="Potential Impact"
                                  className="u-align-left u-text u-text-2"
                                  style={{ marginBottom: 10 }}
                                >
                                  Final additional details
                                </p>
                                <div className="row">
                                  <div className="col-md-6">
                                    <span className="form-attribute">
                                      <span style={{ color: "red" }}> *</span>{" "}
                                      Final Impact (# of FTE)
                                    </span>{" "}
                                  </div>
                                  <div className="col-md-6">
                                    <input
                                      className="form-input"
                                      disabled
                                      type="number"
                                      value={this.state.finalImpactFTE}
                                      placeholder={0}
                                      max={10}
                                      style={{
                                        ...this.state.selectStyle
                                          .FinalImpactFTE,
                                      }}
                                    />
                                  </div>
                                </div>
                                <div style={{ marginBottom: 15 }} />
                                <div className="row">
                                  <div className="col-md-6">
                                    <span className="form-attribute">
                                      <span style={{ color: "red" }}>*</span>{" "}
                                      Final Impact ($)
                                    </span>{" "}
                                  </div>
                                  <div className="col-md-6">
                                    <input
                                      className="form-input"
                                      disabled
                                      type="number"
                                      value={this.state.finalImpactDollar}
                                      placeholder={0.0}
                                      style={{
                                        ...this.state.selectStyle
                                          .FinalImpactDollar,
                                      }}
                                    />
                                  </div>
                                </div>
                                <div style={{ marginBottom: 5 }} />
                                <div
                                  className="u-align-left u-text u-text-custom-color-3"
                                  style={{ fontSize: 11, fontStyle: "italic" }}
                                >
                                  (If Final Impact $ is greater than 250K OR
                                  Final FTE number is greater than 10, please
                                  review thoroughly)
                                </div>
                                <div
                                  style={{ marginTop: 5, marginBottom: 15 }}
                                />
                                <div className="row">
                                  <div className="col-md-5">
                                    <span className="form-attribute">
                                      <span style={{ color: "red" }}>*</span> Is
                                      it a Zero Impact Project
                                    </span>
                                  </div>
                                  <div className="col-md-6">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        id="isZeroImpactProject"
                                        checked={
                                          this.state.impactOptionSelected &&
                                            this.state.impactOptionSelected
                                              .label === "No Value Delivery"
                                            ? true
                                            : false
                                        }
                                      />
                                      <span className="slider round" />
                                    </label>
                                  </div>
                                </div>
                                <div style={{ marginBottom: 22 }} />
                              </div>
                            </div>
                            <p
                              className="visible-md-block visible-sm-block visible-xs-block"
                              style={{ marginTop: 22 }}
                            >
                              &nbsp;
                            </p>
                            <div className="u-container-style u-group u-shape-rectangle u-group-9">
                              <div
                                className="u-container-layout u-container-layout-9"
                                style={{ marginTop: 90, marginBottom: 36 }}
                              >
                                <p
                                  className="visible-md-block visible-sm-block visible-xs-block"
                                  style={{ marginTop: "-100px" }}
                                >
                                  &nbsp;
                                </p>
                                <div className="row">
                                  <div className="col-md-5">
                                    <span className="form-attribute">
                                      <span style={{ color: "red" }}>*</span>{" "}
                                      Best in Class
                                    </span>
                                  </div>
                                  <div className="col-md-6">
                                    <label className="switch best_in_class_slider">
                                      <input
                                        type="checkbox"
                                        id="isReplicationIdeaBtn"
                                        checked={this.state.bestClass}
                                        value={this.state.bestClass}
                                        onClick={this.handleClassChange}
                                      />
                                      <span className="slider round" />
                                    </label>
                                  </div>
                                </div>
                                <div className="row" style={{ marginTop: 15 }}>
                                  <div className="col-md-5">
                                    <span
                                      style={{ cursor: "pointer" }}
                                      className="form-attribute"
                                      title="Replicability Index is the measure of ‘ease of replication’ or ‘how easily can this same project be implemented’ in another Account. The higher the rating, the easier it is to replicate in another account and vice versa."
                                    >
                                      <span style={{ color: "red" }}>*</span>{" "}
                                      Replicability Index
                                    </span>{" "}
                                  </div>
                                  <div className="col-md-6">
                                    <Select
                                      isSearchable={false}
                                      options={REPLICABILITYOPTIONS}
                                      value={this.state.replicaIndex}
                                      styles={
                                        this.state.selectStyle.replicaIndex
                                      }
                                      placeholder="Choose replicabilty index"
                                      onChange={(e)=>this.replicaIndexChangeHandler(e)}
                                    />
                                  </div>
                                </div>
                                <div style={{ marginBottom: 15 }} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}

                    {this.state.ideaItem.CertificationLevelName === "Lean" &&
                      this.state.ideaItem.IdeaId ? (
                      <>
                        <ValueDelivery
                          func={this.pull_data}
                          viewIdea={false}
                          vdType={this.handleVDTypeChange}
                          IdeaId={this.state.ideaItem.IdeaId}
                          fetchMonetizationFileDetails={this.pull_file_data}
                          bbMonetizationTotal={this.pull_monetizedTotal}
                          monetizableProject={this.pull_monetizableProject}
                          monetizableStatus={this.pull_monetizableStatus}
                          fetchFPAOHRforIncreaseInRevenue={this.fetchFPAOHRforIncreaseInRevenue}
                          bbMonetizationApproval={false}
                          postFpnaValid={false}
                          fetchMonetizationSowID={this.pull_monetization_sowId}
                          fetchDeletedBIRows={this.remove_bi_data_after_delete}
                          fetchMonetizationFpnaOhr={this.pull_monetization_fpna_ohr}
                          fetchMonetizationFpnaName={this.pull_monetization_fpna_name}
                          fetchMonetizationGOLName={this.pullMonetizationGOLName}
                          fetchMonetizationGOLOhr={this.pullMonetizationGOLOhr}
                          fetchCategoryNotListed={this.pullCategoryNotListed}
                          
                        />
                        {
                          this.state.impactOptionSelected && this.state.impactOptionSelected.label === "No Value Delivery"
                          ?
                          <>
                          
                          </>
                          :
                          <div className="" style={{ borderBottom: "1px solid lightgrey" }}/> 
                        }
                        
                      </>
                    ) : null}

                    {this.state.ideaItem.QCName && this.state.ideaItem.QCOHR ? (
                      <>
                        <div
                          id="Approval Status"
                          className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                        >
                          <div
                            className="u-container-layout u-container-layout-7"
                            style={{
                              borderBottom: " 1px solid lightgray",
                              paddingTop: "40px",
                            }}
                          >
                            <p
                              className="u-align-left u-text u-text-2"
                              style={{ marginBottom: 10 }}
                            >
                              Quality Check Details Approval
                            </p>
                            <div className="row" style={{ marginTop: 15 }}>
                              <div className="col-md-5">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: "-13px",
                                  }}
                                >
                                  Approval Comments
                                </label>
                              </div>
                              <div
                                className="col-md-2"
                                style={{ marginLeft: 20 }}
                              >
                                <button
                                  className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                  onClick={(e) =>
                                    this.setState({ qcCommentLog: true })
                                  }
                                >
                                  Comment Log
                                </button>
                              </div>
                            </div>
                            <div className="row" style={{ marginTop: 5 }}>
                              <div className="col-md-12">
                                <textarea
                                  className="form-input"
                                  disabled
                                  rows={3}
                                  cols={88}
                                  style={{
                                    maxWidth: "100%",
                                    border: "1px solid lightgrey",
                                  }}
                                  name="Comments"
                                  value={this.state.ideaItem.FinalComments}
                                />
                              </div>
                            </div>
                            <div className="text-left mt-4">
                              {this.state.qcCommentLog &&
                                this.state.qcComments ? (
                                <>
                                  <div className="custom_modal">
                                    <div className="modal_body">
                                      <div className="row">
                                        <div className="col-md-8">
                                          <h3 className="modal_title">
                                            QC Comment Log
                                          </h3>
                                        </div>
                                        <div className="col-md-4">
                                          <button
                                            type="button"
                                            className="close text-right"
                                            onClick={(e) =>
                                              this.setState({
                                                qcCommentLog: false,
                                              })
                                            }
                                          >
                                            &times;
                                          </button>
                                        </div>
                                      </div>

                                      <div className="qc_responsive_comment_log">
                                        <div className="table table-responsive table-bordered">
                                          <thead className="text-center">
                                            <tr>
                                              <th className="qcHeaderbg qcVDHeader">
                                                <label
                                                  style={{
                                                    fontWeight: 100,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  Date & Time
                                                </label>
                                              </th>
                                              <th className="qcHeaderbg qcVDHeader">
                                                <label
                                                  style={{
                                                    fontWeight: 100,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  OHR
                                                </label>
                                              </th>
                                              <th className="qcHeaderbg qcVDHeader">
                                                <label
                                                  style={{
                                                    fontWeight: 100,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  Name
                                                </label>
                                              </th>
                                              <th className="qcHeaderbg qcVDHeader">
                                                <label
                                                  style={{
                                                    fontWeight: 100,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  Role
                                                </label>
                                              </th>
                                              <th className="qcHeaderbg qcVDHeader">
                                                <label
                                                  style={{
                                                    fontWeight: 100,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  Action
                                                </label>
                                              </th>
                                              <th
                                                className="qcHeaderbg"
                                                style={{ minWidth: "250px" }}
                                              >
                                                <label
                                                  style={{
                                                    fontWeight: 100,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  Comment
                                                </label>
                                              </th>
                                              <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthOne">
                                                <label
                                                  style={{
                                                    fontWeight: 100,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  Is the impact correctly tagged
                                                  to Genpact or Client ?
                                                </label>
                                              </th>
                                              <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthTwo">
                                                <label
                                                  style={{
                                                    fontWeight: 100,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  Has the Value Delivery been
                                                  correctly categorized ?
                                                </label>
                                              </th>
                                              <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthThree">
                                                <label
                                                  style={{
                                                    fontWeight: 100,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  Has the Value Delivery been
                                                  computed correctly ?
                                                </label>
                                              </th>
                                              <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthFour">
                                                <label
                                                  style={{
                                                    fontWeight: 100,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  Do the value delivery approvals (applicable as per the value playbook) clearly articulate what improved and for which time-period ?
                                                </label>
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.qcComments.map(
                                              (item, index) => {
                                                return (
                                                  <tr key={index}>
                                                    <td>
                                                      <label
                                                        style={{
                                                          lineheight: 2,
                                                          verticalAlign:
                                                            "bottom",
                                                        }}
                                                      >
                                                        {this.formatDateStringWithTime(
                                                          new Date(
                                                            item.CreatedDate
                                                          )
                                                        )}
                                                      </label>
                                                    </td>
                                                    <td>
                                                      <label
                                                        style={{
                                                          lineheight: 2,
                                                          verticalAlign:
                                                            "bottom",
                                                        }}
                                                      >
                                                        {item.UpdaterOHR}
                                                      </label>
                                                    </td>
                                                    <td>
                                                      <label
                                                        style={{
                                                          lineheight: 2,
                                                          verticalAlign:
                                                            "bottom",
                                                        }}
                                                      >
                                                        {item.UpdaterName}
                                                      </label>
                                                    </td>
                                                    <td>
                                                      <label
                                                        style={{
                                                          lineheight: 2,
                                                          verticalAlign:
                                                            "bottom",
                                                        }}
                                                      >
                                                        {item.UpdaterRole
                                                          ? item.UpdaterRole
                                                          : item.Action ===
                                                            "Idea Updated by Admin" ||
                                                            item.Action ===
                                                            "Idea Reinitiated by Admin"
                                                            ? "Admin"
                                                            : item.Action ===
                                                              "Delegate Action"
                                                              ? "BB"
                                                              : item.UpdaterOHR ===
                                                                this.state.MBBOHR
                                                                ? "MBB"
                                                                : item.UpdaterOHR ===
                                                                  this.state.ideaItem
                                                                    .OwnerOHR
                                                                  ? "Idea Owner"
                                                                  : item.ApprovalStatus ===
                                                                    "Pending Documents" &&
                                                                    item.WorkflowStatus ===
                                                                    "Approved"
                                                                    ? "BB"
                                                                    : item.ApprovalStatus ===
                                                                      "NA" &&
                                                                      item.WorkflowStatus ===
                                                                      "Pending"
                                                                      ? "BB"
                                                                      : item.UpdaterRole}
                                                      </label>
                                                    </td>
                                                    <td>
                                                      {/* <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.ApprovalStatus==="Documents Uploaded" ? "Send Back" : ((item.WorkflowStatus==="Rejected" || item.WorkflowStatus==="Reject" || item.ApprovalStatus==="Reject" || item.ApprovalStatus==="Rejected" )? "Rejected ":"Approved")}</label> */}
                                                      <label
                                                        style={{
                                                          lineheight: 2,
                                                          verticalAlign:
                                                            "bottom",
                                                        }}
                                                      >
                                                        {item.UpdaterAction
                                                          ? item.UpdaterAction
                                                          : item.ApprovalStatus ===
                                                            "Documents Uploaded"
                                                            ? "Send Back"
                                                            : item.WorkflowStatus ===
                                                              "Rejected" ||
                                                              item.WorkflowStatus ===
                                                              "Reject" ||
                                                              item.ApprovalStatus ===
                                                              "Reject" ||
                                                              item.ApprovalStatus ===
                                                              "Rejected"
                                                              ? "Rejected "
                                                              : item.Action ===
                                                                "Idea was Rework - QC"
                                                                ? "Rework"
                                                                : "Approved"}
                                                      </label>
                                                    </td>
                                                    <td>
                                                      <label
                                                        style={{
                                                          lineheight: 2,
                                                          verticalAlign:
                                                            "bottom",
                                                        }}
                                                      >
                                                        {item.Comment !== ""
                                                          ? item.Comment
                                                          : "-"}
                                                      </label>
                                                    </td>
                                                    {this.state
                                                      .qcCommentsWithVD &&
                                                      this.state.qcCommentsWithVD
                                                        .length > 0 ? (
                                                      <>
                                                        {this.state.qcCommentsWithVD.map(
                                                          (pg_item) => {
                                                            if (
                                                              Number(
                                                                pg_item.ProjectTeamID
                                                              ) === item.TrackId
                                                            ) {
                                                              return (
                                                                <>
                                                                  <td>
                                                                    <label
                                                                      style={{
                                                                        lineheight: 2,
                                                                        verticalAlign:
                                                                          "bottom",
                                                                      }}
                                                                    >
                                                                      {JSON.parse(
                                                                        pg_item.AuditDataJSON
                                                                      )
                                                                        .pg_isvdimpacttagged
                                                                        ? JSON.parse(
                                                                          pg_item.AuditDataJSON
                                                                        )
                                                                          .pg_isvdimpacttagged
                                                                        : "-"}
                                                                    </label>
                                                                  </td>
                                                                  <td>
                                                                    <label
                                                                      style={{
                                                                        lineheight: 2,
                                                                        verticalAlign:
                                                                          "bottom",
                                                                      }}
                                                                    >
                                                                      {JSON.parse(
                                                                        pg_item.AuditDataJSON
                                                                      )
                                                                        .pg_isvdcategorized
                                                                        ? JSON.parse(
                                                                          pg_item.AuditDataJSON
                                                                        )
                                                                          .pg_isvdcategorized
                                                                        : "-"}
                                                                    </label>
                                                                  </td>
                                                                  <td>
                                                                    <label
                                                                      style={{
                                                                        lineheight: 2,
                                                                        verticalAlign:
                                                                          "bottom",
                                                                      }}
                                                                    >
                                                                      {JSON.parse(
                                                                        pg_item.AuditDataJSON
                                                                      )
                                                                        .pg_isvdcomputed
                                                                        ? JSON.parse(
                                                                          pg_item.AuditDataJSON
                                                                        )
                                                                          .pg_isvdcomputed
                                                                        : "-"}
                                                                    </label>
                                                                  </td>
                                                                  <td>
                                                                    <label
                                                                      style={{
                                                                        lineheight: 2,
                                                                        verticalAlign:
                                                                          "bottom",
                                                                      }}
                                                                    >
                                                                      {JSON.parse(
                                                                        pg_item.AuditDataJSON
                                                                      )
                                                                        .pg_isvdclientsignoff
                                                                        ? JSON.parse(
                                                                          pg_item.AuditDataJSON
                                                                        )
                                                                          .pg_isvdclientsignoff
                                                                        : "-"}
                                                                    </label>
                                                                  </td>
                                                                </>
                                                              );
                                                            }
                                                          }
                                                        )}
                                                      </>
                                                    ) : (
                                                      <>
                                                        <td>
                                                          <label
                                                            style={{
                                                              lineheight: 2,
                                                              verticalAlign:
                                                                "bottom",
                                                            }}
                                                          >
                                                            -
                                                          </label>
                                                        </td>
                                                        <td>
                                                          <label
                                                            style={{
                                                              lineheight: 2,
                                                              verticalAlign:
                                                                "bottom",
                                                            }}
                                                          >
                                                            -
                                                          </label>
                                                        </td>
                                                        <td>
                                                          <label
                                                            style={{
                                                              lineheight: 2,
                                                              verticalAlign:
                                                                "bottom",
                                                            }}
                                                          >
                                                            -
                                                          </label>
                                                        </td>
                                                        <td>
                                                          <label
                                                            style={{
                                                              lineheight: 2,
                                                              verticalAlign:
                                                                "bottom",
                                                            }}
                                                          >
                                                            -
                                                          </label>
                                                        </td>
                                                      </>
                                                    )}
                                                  </tr>
                                                );
                                              }
                                            )}
                                          </tbody>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}




                    {/* ...latest DB changes starts here..... */}
                    {
                      this.state.ideaItem.TransformationLeverName && this.state.ideaItem.TransformationLeverName === "Data"
                      && this.state.displayDBFields
                        ?
                        <>
                          <div className="" style={{ marginBottom:"20px" }}>
                            <div className="">
                              <div className="">
                                <p className="u-align-left u-text u-text-2" style={{ margin: "15px 0px 0px 0px" }}>Data Bridge Fields</p>
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="submit_page_new_fields">
                                      <div className="row">
                                        <div className="col-md-5">
                                          <label
                                            className="form-attribute"
                                            style={{
                                              verticalAlign: "bottom",
                                              marginBottom: -5,
                                            }}
                                          >
                                            <label
                                              style={{
                                                color: "red",
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              *
                                            </label>{" "}
                                            DB Project Type
                                          </label>
                                        </div>
                                        <div className="col-md-7">
                                          <Select
                                            isSearchable={true}
                                            value={this.state.dataBridgeType}
                                            options={DATABRIDGETYPES}
                                            placeholder="Please choose"
                                            onChange={(e) => this.handleDataBridgeTypeChange(e)}
                                            styles={this.state.selectStyle.dataBridgeType}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                      {
                                        this.state.dataBridgeType
                                            ?
                                            <>
                                              <div className="submit_page_new_fields">
                                                  <div className="row">
                                                      <div className="col-md-5">
                                                          <span className="form-attribute non_mandatory_field_alignment_for_span">
                                                            Template Type
                                                          </span>
                                                      </div>
                                                      <div className="col-md-7">
                                                          <div className="ai_first_doc_link">
                                                          
                                                            <label className="template_type_label" onClick={(e) => this.templateRedirect(this.state.dataBridgeType.label)}>{this.state.dataBridgeType.label}</label>
                                                              {/* <div className="ai_first_doc_link">
                                                                {
                                                                  this.state.dataBridgeType && this.state.dataBridgeType.label === "Descriptive"
                                                                  ?
                                                                  <>
                                                                    <label className="template_type_label" style={{ marginBottom: "0px" }}><Link target='_blank' to={`/descriptive-analytics-template?SUQ=${convertIdeaID}`} >Descriptive</Link></label>
                                                                  </>
                                                                  :
                                                                  null
                                                                }
                                                                {
                                                                  this.state.dataBridgeType && this.state.dataBridgeType.label === "Diagnostic"
                                                                  ?
                                                                  <>
                                                                      
                                                                    <label className="template_type_label" style={{ marginBottom: "0px" }}><Link target='_blank' to={{ pathname:`/diagnostic-or-prescriptive-template?SUQ=${convertIdeaID}`, state:"Diagnostic"}} >Diagnostic</Link></label>
                                                                  </>
                                                                  :
                                                                  null
                                                                }
                                                                {
                                                                  this.state.dataBridgeType && this.state.dataBridgeType.label === "Prescriptive" 
                                                                  ?
                                                                  <>
                                                                    <label className="template_type_label" style={{ marginBottom: "0px" }}><Link target='_blank' to={`/diagnostic-or-prescriptive-template?SUQ=${convertIdeaID}`} >Prescriptive</Link></label>
                                                                  </>
                                                                  :
                                                                  null
                                                                }
                                                                {
                                                                  this.state.dataBridgeType && this.state.dataBridgeType.label === "Predictive"
                                                                  ?
                                                                  <>
                                                                    <label className="template_type_label" style={{ marginBottom: "0px" }}><Link target='_blank' to={`/predictive-template?SUQ=${convertIdeaID}`} >Predictive</Link></label>
                                                                  </>
                                                                  :
                                                                  null
                                                                }
                                                                
                                                              </div> */}
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </>
                                          :
                                          null
                                      }

                                    <div className="submit_page_new_fields">
                                      <div className="row d_flex_align_items_center">
                                        <div className="col-md-5">
                                          <label className="form-attribute non_mandatory_field_alignment">
                                            COO</label>
                                        </div>
                                        <div className="col-md-7">
                                          <input value={this.state.ideaItem.COO} type="text" placeholder="Provide Value" className="form-input  disabled_input"
                                            disabled />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="submit_page_new_fields">
                                      <div className="row">
                                        <div className="col-md-5">
                                          <label
                                            className="form-attribute"
                                            style={{
                                              verticalAlign: "bottom",
                                              marginBottom: -5,
                                            }}
                                          >
                                            <label
                                              style={{
                                                color: "red",
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              *
                                            </label>{" "}
                                            MUC ID
                                          </label>
                                        </div>
                                        <div className="col-md-7">
                                          <Select
                                            isSearchable={true}
                                            value={this.state.mucIDSelectedOption}
                                            options={this.renderMUCIDOptions()}
                                            placeholder="Please choose"
                                            onChange={(e) =>
                                              this.handleMUCIDChange(e)
                                            }
                                            styles={
                                              this.state.selectStyle
                                                .mucIDSelectedOption
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div className="submit_page_new_fields">
                                      <div className="row d_flex_align_items_center">
                                        <div className="col-md-5">
                                          <label className="form-attribute non_mandatory_field_alignment">
                                            Shown to Client</label>
                                        </div>
                                        <div className="col-md-7">
                                          <input value={this.state.ideasDB.IsSharedClient} placeholder="Please choose" disabled className="form-input disabled_input" />
                                        </div>
                                      </div>
                                    </div>
                                    {
                                      this.state.ideasDB.IsSharedClient && this.state.ideasDB.IsSharedClient === "Yes"
                                        ?
                                        <>
                                          <div className="">
                                            <div className="row">
                                              <div className="col-md-12">
                                                <div className="submit_page_new_fields">
                                                  <div className="row d_flex_align_items_center">
                                                    <div className="col-md-5">
                                                      <label className="form-attribute non_mandatory_field_alignment">
                                                        Client Testimony</label>
                                                    </div>
                                                    <div className="col-md-7">
                                                      <button className={this.state.ideaItem.CTestimonyFileName && this.state.ideaItem.CTestimonyFileName.length > 50?"upload_file_text_overflow":""} style={{ backgroundColor: 'transparent', textAlign: "left", color: '#337ab7', padding: 0 }} onClick={(e) => this.downloadClientTestimony()}>{this.state.ideaItem.CTestimonyFileName}</button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-md-2"></div>
                                              <div className="col-md-5">

                                              </div>
                                            </div>
                                          </div>


                                        </>
                                        :
                                        null
                                    }
                                    <div className="submit_page_new_fields">
                                      <div className="row d_flex_align_items_center">
                                        <div className="col-md-5">
                                          <label className="form-attribute non_mandatory_field_alignment">
                                            Shown to GOL</label>
                                        </div>
                                        <div className="col-md-7">
                                          <input value={this.state.ideasDB.IsSharedGOL} placeholder="Provide value" className="form-input disabled_input" disabled />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="submit_page_new_fields">
                                      <div className="" style={{ textAlign: "left", margin: "15px 0px" }}>
                                        <div className="row">
                                          <div className="col-md-5">
                                            <label
                                              className="form-attribute"
                                              style={{
                                                verticalAlign: "bottom",
                                                marginBottom: -5,
                                              }}
                                            >
                                              <label
                                                style={{
                                                  color: "red",
                                                  verticalAlign: "bottom",
                                                }}
                                              >
                                                *
                                              </label>{" "}
                                              DB Tool Used
                                            </label>
                                          </div>
                                          <div className="col-md-7">
                                            <Select
                                              isSearchable={true}
                                              value={this.state.dbToolUsed}
                                              options={DOBTOOLS}
                                              placeholder="Please choose"
                                              onChange={(e) => this.handledbToolUsedChange(e)}
                                              styles={this.state.selectStyle.dbToolUsed}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>



                                  </div>
                                  <div className="col-md-4">

                                    <div className="submit_page_new_fields">
                                      <div className="row d_flex_align_items_center">
                                        <div className="col-md-5">
                                          <label className="form-attribute" style={{
                                            verticalAlign: 'bottom', marginBottom: -5
                                          }}>
                                            Process Owner OHR</label>
                                        </div>
                                        <div className="col-md-7">
                                          <input type="text"
                                            disabled
                                            placeholder="Provide Value"
                                            value={this.state.ideaItem.ProcessOwnerOHR}
                                            className="form-input form-attribute" />

                                        </div>
                                      </div>
                                    </div>
                                    <div className="submit_page_new_fields">
                                      <div className="row d_flex_align_items_center">
                                        <div className="col-md-5">
                                          <label className="form-attribute" style={{
                                            verticalAlign: 'bottom', marginBottom: -5
                                          }}>
                                            Process Owner Name</label>
                                        </div>
                                        <div className="col-md-7">
                                          <input value={this.state.ideaItem.ProcessOwner} type="text" placeholder="Provide Value" className="form-input disabled_input"
                                            disabled />
                                        </div>
                                      </div>
                                    </div>
                                    {
                                      this.state.ideasDB.IsSharedClient && this.state.ideasDB.IsSharedClient === "Yes"
                                        ?
                                        <>
                                          <div className="submit_page_new_fields">
                                            <div className="row d_flex_align_items_center">
                                              <div className="col-md-5">
                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                  Client Role</label>
                                              </div>
                                              <div className="col-md-7">
                                                <input value={this.state.ideasDB.ClientRole} type="text" placeholder="Provide value" className="form-input disabled_input"
                                                  disabled />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="submit_page_new_fields">
                                            <div className="row d_flex_align_items_center">
                                              <div className="col-md-5">
                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                  Client Name</label>
                                              </div>
                                              <div className="col-md-7">
                                                <input value={this.state.ideasDB.ClientName} type="text" disabled className="form-input  disabled_input" />
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                        :
                                        null
                                    }
                                    {
                                      this.state.ideasDB.IsSharedGOL && this.state.ideasDB.IsSharedGOL === "Yes"
                                        ?
                                        <>
                                          <div className="submit_page_new_fields">
                                            <div className="row d_flex_align_items_center">
                                              <div className="col-md-5">
                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                  GOL OHR</label>
                                              </div>
                                              <div className="col-md-7">
                                                <input value={this.state.ideasDB.GOLOHR} type="text" placeholder="Provide value" className="form-input form-attribute"
                                                  disabled />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="submit_page_new_fields">
                                            <div className="row d_flex_align_items_center">
                                              <div className="col-md-5">
                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                  GOL Name</label>
                                              </div>
                                              <div className="col-md-7">
                                                <input type="text" placeholder="Provide value" disabled value={this.state.ideasDB.GOL}
                                                  className="form-input disabled_input" />
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                        :
                                        null
                                    }
                                    
                                    {
                                      this.state.dbToolUsed && this.state.dbToolUsed.label === "Others" ?
                                        <>
                                          <div className="submit_page_new_fields">
                                            <div className="row">
                                              <div className="col-md-5">
                                                <label
                                                  className="form-attribute"
                                                  style={{
                                                    verticalAlign: "bottom",
                                                    marginBottom: -5,
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      color: "red",
                                                      verticalAlign: "bottom",
                                                    }}
                                                  >
                                                    *
                                                  </label>{" "}
                                                  Other DB Tool Used
                                                </label>
                                              </div>
                                              <div className="col-md-7">
                                                <input
                                                  data-toggle="tooltip" 
                                                  title={this.state.otherDBToolUsedText} 
                                                  type="text"
                                                  value={this.state.otherDBToolUsedText}
                                                  placeholder="Provide value"
                                                  onChange={(e) =>
                                                    this.handleOtherDBToolUsedChange(
                                                      e.target.value
                                                    )
                                                  }
                                                  className="textarea-css form-attribute"
                                                  style={
                                                    this.state.selectStyle
                                                      .otherDBToolUsedText
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                        :
                                        null
                                    }
                                  </div>
                                  <div className="col-md-4 " style={{ paddingRight: "30px" }}>
                                    <div className="submit_page_new_fields">
                                      <div className="row d_flex_align_items_center">
                                        <div className="col-md-8">
                                          <label
                                            className="form-attribute"
                                            style={{
                                              verticalAlign: "bottom",
                                              marginBottom: -5,
                                            }}
                                          >
                                            <span style={{ color: "red" }}>*&nbsp;
                                              {/* {this.state.approvalSelectedOption && this.state.approvalSelectedOption.label === "Approved" ? "*" : ''} {" "} */}
                                            </span> 
                                            Is Sustenance tracking applicable ? </label>
                                        </div>
                                        <div className="col-md-4">
                                          <Select
                                            value={this.state.trackingApplicableSelectedOption}
                                            options={YESORNO}
                                            placeholder="Provide choose"
                                            styles={this.state.selectStyle.trackingApplicableSelectedOption}
                                            onChange={(e) => this.handleTrackingChange(e)}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="submit_page_new_fields">
                                      <div className="row d_flex_align_items_center">
                                        <div className="col-md-12">
                                          <label
                                            className="form-attribute"
                                            style={{
                                              verticalAlign: "bottom",
                                              marginBottom:"0px"
                                            }}
                                          >
                                            <span style={{ color: "red" }}>* {" "}</span>
                                            How is the model operationalized ?
                                          </label>
                                        </div>
                                        <div className="col-md-12">
                                          <Select
                                            value={
                                              this.state.modalsOperationsSelectedOption
                                            }
                                            options={modelOperationOptions}
                                            placeholder="Please choose"
                                            onChange={(e) =>
                                              this.handleModalsOperationsChange(e)
                                            }
                                            styles={
                                              this.state.selectStyle
                                                .modalsOperationsSelectedOption
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {this.state.trackingApplicableSelectedOption && this.state.trackingApplicableSelectedOption.label === "Yes" ? (
                                      <>
                                        <div className="submit_page_new_fields">
                                          <div className="row d_flex_align_items_center">
                                            <div className="col-md-8">
                                              <label
                                                className="form-attribute"
                                                style={{
                                                  verticalAlign: "bottom",
                                                  marginBottom: -5,
                                                }}
                                              >
                                               <label style={{ color: "red" }}>{this.state.trackingApplicableSelectedOption && this.state.trackingApplicableSelectedOption.label === "Yes" ? " * " :""}</label> {" "}
                                                Sustenance tracking frequency ?
                                              </label>
                                            </div>
                                            <div className="col-md-4">
                                              <Select
                                                value={this.state.sustenanceMetricFreq}
                                                options={sustenanceMetricFreqOptions}
                                                placeholder="Please choose"
                                                onChange={(e) =>
                                                  this.handleSusFreqChange(e)
                                                }
                                                styles={this.state.selectStyle.sustenanceMetricFreq}
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div className="submit_page_new_fields">
                                          <div className="row d_flex_align_items_center">
                                            <div className="col-md-12">
                                              <label
                                                className="form-attribute"
                                                style={{
                                                  verticalAlign: "bottom",
                                                  marginBottom:"-5px"
                                                }}
                                              >
                                                <label style={{ color: "red" }}>{this.state.trackingApplicableSelectedOption && this.state.trackingApplicableSelectedOption.label === "Yes" ? " * " :""}</label> {" "}
                                                Sustenance metric <span style={{fontWeight: "normal",fontSize: 12,fontStyle: "italic",color: "grey"}}>&nbsp;(max. 50 chars)</span>
                                              </label>
                                            </div>
                                            <div className="col-md-12">
                                              <textarea
                                                className="textarea-css form-attribute"
                                                value={this.state.sustenanceMetrics}
                                                placeholder="Please provide sustenance metrics"
                                                onChange={(e) =>
                                                  this.handleSustenanceMetricsTextChange(e.target.value)
                                                }
                                                style={{
                                                  width: "100%",
                                                  border: "1px solid lightgrey",
                                                  ...this.state.selectStyle.sustenanceMetrics,
                                                }}
                                                rows={3}
                                              />
                                            </div>
                                          </div>
                                        </div>


                                      </>
                                    ) : null}

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                        :
                        null

                    }
                    {/* ...latest DB changes ends here..... */}
                    
                    {
                      this.state.ideaItem.TransformationLeverName === "Data" && this.state.ideaItem.IdeaId && templateType && this.state.displayDBFields
                      ?
                      <>
                        <DisplayTemplate IdeaId={this.state.ideaItem.IdeaId} templateType={templateType} />
                        <div className="" style={{borderBottom:"1px solid lightgrey"}} />
                      </>
                      :
                      null
                    }



                    {/* DB SPOC Fields starts here */}
                    {
                          this.state.ideaItem.TransformationLeverName === "Data" && this.state.ideasDB.IsSustenance === "Yes" && 
                          this.state.ideaItem.DBSPocOHR && this.state.displayDBFields
                            ?
                            <>
                              <div className="" style={{ paddingRight: "30px",borderBottom:"1px solid lightgrey", paddingTop: "10px", marginTop: "30px" }}>
                                <p className="u-align-left u-text u-text-2">DB questionnaire</p>
                                <div className="new_fields_blocks">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-7">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Is the MUC ID tagged correctly ?</label>
                                          </div>
                                          <div className="col-md-5">
                                            <input
                                              value={this.state.ideasDB.IsMUCTag}
                                              placeholder='Provide value'
                                              disabled
                                              className="form-input disabled_input"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-5">
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-7">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Is the DB theme captured correctly?</label>
                                          </div>
                                          <div className="col-md-5">
                                            <input placeholder='Provide value'
                                              disabled
                                              value={this.state.ideasDB.IsDBTheme}
                                              className="form-input disabled_input"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="new_fields_blocks">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-7">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Is the Tool used captured correctly ?</label>
                                          </div>
                                          <div className="col-md-5">
                                            <input placeholder='Provide value'
                                              className="form-input disabled_input"
                                              disabled
                                              value={this.state.ideasDB.IsToolCorrect}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-5">
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-7">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Are the model details captured appropriately?</label>
                                          </div>
                                          <div className="col-md-5">
                                            <input placeholder='Provide value'
                                              className="form-input disabled_input"
                                              disabled
                                              value={this.state.ideasDB.IsModelCorrect}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="new_fields_blocks">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-7">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Are the model deployment details captured appropriately?</label>
                                          </div>
                                          <div className="col-md-5">
                                            <input placeholder='Provide value'
                                              className="form-input disabled_input"
                                              disabled
                                              value={this.state.ideasDB.IsModelDeploy}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-5">
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-7">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Do the provided signoffs meet value delivery guidelines?</label>
                                          </div>
                                          <div className="col-md-5">
                                            <input placeholder='Provide value'
                                              className="form-input disabled_input"
                                              disabled
                                              value={this.state.ideasDB.IsSignOff}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="new_fields_blocks">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-7">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Are the monetization details captured appropriately?</label>
                                          </div>
                                          <div className="col-md-5">
                                            <input placeholder='Provide value'
                                              className="form-input disabled_input"
                                              disabled={true}
                                              value={this.state.ideasDB.IsMonetization}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-5">
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-7">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Are sustenance tracking details OK ?</label>
                                          </div>
                                          <div className="col-md-5">
                                            <input placeholder='Provide value'
                                              className="form-input disabled_input"
                                              disabled
                                              value={this.state.ideasDB.IsSPocSustenance}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="new_fields_blocks">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-7">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Is there any other issue you would like to report ?</label>
                                          </div>
                                          <div className="col-md-5">
                                            <input placeholder='Provide value'
                                              className="form-input disabled_input"
                                              disabled
                                              value={this.state.ideasDB.IsIssue}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-5">
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-7">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Is there any exception applied on the project approval ?</label>
                                          </div>
                                          <div className="col-md-5">
                                            <input placeholder='Provide value'
                                              className="form-input disabled_input"
                                              disabled
                                              value={this.state.ideasDB.IsException}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="" style={{ paddingBottom: "40px", marginBottom: "15px" }} />
                              </div >
                            </>
                            :
                            null
                        }
                        {/* DB SPOC Fields ends here */}


                    <div
                      className="gol_fields_block text-left"
                      style={{ borderBottom: "0px", marginTop: "20px" }}
                    >
                      <p
                        className="u-align-left u-text u-text-2"
                        style={{ margin: "10px 0px 10px" }}
                      >
                        Approval Section
                      </p>
                      <div className="row">
                        <div className="col-md-10">
                          <div className="row">
                            <div className="col-md-10 gol_field_div text-left">
                              <span className="form-attribute">
                                <span style={{ color: "red" }}>* </span>
                                I acknowledge that I have reviewed the value delivery and I agree with the value apportioning between Client/Genpact, categorization and computation along with the necessary approvals recorded on this project ?
                              </span>
                            </div>
                            <div className="col-md-2">
                              <Select
                                styles={
                                  this.state.selectStyle.showBBFieldsValidation
                                }
                                options={YESORNO}
                                onChange={(e) => this.showBBFields(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id="Approval Status"
                      className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                    >
                      <div
                        className="u-container-layout u-container-layout-7"
                        style={{
                          borderTop: "1px solid lightgray",
                          marginBottom: "20px",
                          paddingTop: "37px",
                        }}
                      >
                        <p
                          className="u-align-left u-text u-text-2"
                          style={{ marginBottom: 10 }}
                        >
                          Approval details
                        </p>
                        <div className="row d_flex_align_items_center ">
                          <div className="col-md-2">
                            <span className="form-attribute">
                              <span style={{ color: "red" }}>*</span> Approval
                              Status
                            </span>{" "}
                          </div>
                          <div className="col-md-2">
                            <Select
                              isSearchable={false}
                              options={this.renderStatusOptions()}
                              value={this.state.approvalSelectedOption}
                              placeholder="Choose Status"
                              onChange={this.handleApprovalStatus}
                              styles={
                                this.state.selectStyle.approvalSelectedOption
                              }
                            />
                          </div>
                        </div>
                        <div
                          className="col-md-8"
                          style={{ marginBottom: 5, width: "100%" }}
                        />
                        <div className="row">
                          <div className="col-md-7">
                            <div className="row">
                              <div className="col-md-11">
                                <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                  <span className="u-text-black form-attribute">
                                    <span style={{ color: "red" }}>*</span>
                                    &nbsp;Approval Comments
                                    <span
                                      style={{
                                        fontWeight: "normal",
                                        fontSize: 12,
                                        fontStyle: "italic",
                                        color: "grey",
                                      }}
                                    >&nbsp;
                                      (max. 500 chars)
                                    </span>
                                  </span>
                                </p>
                              </div>
                              <div
                                className="col-md-1"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  onClick={(e) =>
                                    this.toggleTranscriptFunction(7)
                                  }
                                  src={require("../assets/images/voice.png")}
                                  className="w100"
                                  alt=""
                                  style={{ width: "24px", cursor: "pointer" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            {this.state.CommentEmpty ? (
                              <textarea
                                className="textarea-css form-attribute"
                                style={{
                                  maxWidth: "100%",
                                  border: "1px solid lightgrey",
                                  ...this.state.selectStyle.finalComment,
                                }}
                                rows={3}
                                cols={88}
                                placeholder="Please provide your comments in support of the Approval decision."
                                onChange={this.handleFinalCommentChange}
                                value={this.state.finalComment}
                              />
                            ) : (
                              <textarea
                                className="textarea-css form-attribute"
                                style={{
                                  maxWidth: "100%",
                                  border: "1px solid lightgrey",
                                  ...this.state.selectStyle.finalComment,
                                }}
                                rows={3}
                                cols={88}
                                placeholder="Please provide your comments in support of the Approval decision."
                                onChange={this.handleFinalCommentChange}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      {this.state.speechModal ? (
                        <UpdatedSpeech
                          modalOpen={true}
                          onFinalApprovalComment={
                            this.handleTranscriptFinalApprovalComment
                          }
                          modalId={this.state.modalIndex}
                          closeModalFunction={this.closeModalFunction}
                          previousText={this.state.previousTextForSpeech}
                        />
                      ) : null}
                    </div>

                    {this.state.showHrefModal ? (
                      <>
                        <div className="custom_modal href_modal">
                          <div className="modal_body href_modal_body">
                            <div className="href_modal_dialog">
                              <p>
                                To add a new category please raise a Helpmate
                                ticket, Click here to View SOP
                              </p>
                              <div className="href_modal_body_buttons">
                                <div className="btn-group href_modal_body_buttons">
                                  <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3">
                                    <a
                                      href="https://genpactonline.sharepoint.com/:b:/r/sites/LeanDigitalTransformation/Documents/KM%20Page%20-%202021/Certification%20Guidelines,%20SOPs%20%26%20Handbooks/Certification%20guidelines,%20SOPs%20%26%20Handbooks/Systems%20%26%20Tools/Helpmate-Data%20Related%20Request/ProGEAR%20Value%20Delivery%20Category%20Addition%20SOP.pdf?csf=1&web=1&e=5kKbFg"
                                      style={{ color: "#FFF" }}
                                      target="_blank"
                                    >
                                      Click Here
                                    </a>
                                  </button>
                                  <button
                                    className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6"
                                    onClick={(e) =>
                                      this.setState({ showHrefModal: false })
                                    }
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                    <div
                      style={{
                        borderBottom: "1px solid lightgrey",
                        marginTop: 10,
                        marginBottom: 15,
                      }}
                    />
                    <div className="btn-group" style={{ marginTop: 15 }}>
                      <button
                        className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                        onClick={(e) =>
                          this.state.submitIdeaCount === 0
                            ? this.submitIdea()
                            : this.setState({
                              submitIdeaCount: this.state.submitIdeaCount + 1,
                            })
                        }
                      >
                        Submit
                      </button>
                      <button
                        className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6"
                        onClick={this.cancelEvent}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default FinalReviewIdeaPage;
